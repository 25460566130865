import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class HygieneService {
    private url = environment;

    constructor(public http: HttpClient) {}

    /**
     * Get list of all the merchants available on consumer platform
     * @param page page number in the table
     * @returns list of merchants
     */
    getHygieneMerchants(page: number = 0) {
        return this.http.get(this.url.adminUrl + '/v2/merchants_hygiene_overview?page_no=' + (page + 1));
    }

    /**
     * Get list of all the merchants available on consumer platform
     * @param page page number in the table
     * @returns list of merchants
     */
     getHygieneMerchantsDirect(page: number = 0) {
        let params = new HttpParams();
        params = params.append('platform','microsite');
        params = params.append('page_no', page + 1); 
        return this.http.get(this.url.adminUrl + '/v2/merchants_hygiene_overview',{params});
    }

    /**
     * Get list of all the merchants available on consumer platform
     * @param page page number in the table
     * @returns list of merchants
     */
     getHygieneMerchantsONDC(page: number = 0) {
        let params = new HttpParams();
        params = params.append('platform','ondc');
        params = params.append('page_no', page + 1); 
        return this.http.get(this.url.adminUrl + '/v2/merchants_hygiene_overview',{params});
    }

    /**
     * Get filtered list of all the merchants available on consumer platform
     * @param filters stringified object of filter applied by the user
     * @param page page number in the table
     * @returns list of merchants
     */
    filterMerchants(filters: any, page: number,platform?:string) {
        let params = new HttpParams();
        params = params.append('filters', filters);
        params = params.append('page_no', page + 1);
        if(platform) 
        params = params.append('platform',platform); 
        
        return this.http.get(this.url.adminUrl + '/v2/merchants_hygiene_overview', {params});
    }

    /**
     * 
     * @returns list of all the cities
     */
    getCities() {
        return this.http.get(this.url.adminUrl + '/admin/dashboard/cities/');
    }

    /**
     * update status of merchant on conusmer app to go live or go offline
     * @param data toggle status data 
     * @returns status if successfully exececuted or not
     */
    changeStatus(data:any) {
        return this.http.put(this.url.adminUrl + '/v2/merchants_hygiene_overview', data);
    }

    /**
     * download excel sheet of all the merchants available on consumer app with their data points
     * @returns status if successfully exececuted or not
     */
    getSheet(platform?:string) {
        let params = new HttpParams();
        if(platform)
        params = params.append('platform', platform);
        return this.http.post(this.url.adminUrl + '/v2/generate_hygiene_overview_report', params);
    }
    // FIND popularity score
    /**
     * Get merchant on the basis of keyword search
     * @param searchQuery keyword search
     * @returns merchant list
     */
    getMerchants(searchQuery:string,merchantId?:string){
        let apiUrl:string;
        if(merchantId) {
            apiUrl = '/v1/third_party_ratings/restaurant_search?page_no=1&merchant_id='+merchantId;
        } else {
            apiUrl = '/v1/third_party_ratings/restaurant_search?page_no=1&search_query='+searchQuery;
        }
        return this.http.get(this.url.consumerUrl+apiUrl); 
    }
    /**
     * map restaurant with merchant
     * @param id search restaurant id
     * @param merchantId merchant id where restaurant map
     * @returns status
     */
    mapMerchantPopularity(id:number,merchantId:number,popularityScore?:number){
        let apiUrl:string;
        if(popularityScore){
            apiUrl = '/v1/third_party_ratings/'+id+'?merchant_id='+merchantId+'&popularity_score='+popularityScore;
        } else {
            apiUrl = '/v1/third_party_ratings/'+id+'?merchant_id='+merchantId;
        }
        return this.http.put(this.url.consumerUrl+apiUrl,null);
    }
    /**
     * add popularity score to the selected restaurant
     * @param popularityScore user enter score
     * @param merchantId merchant id where score we need to add
     * @returns return status
     */
    addPopularityScore(popularityScore:number,merchantId:number){
        return this.http.post(this.url.consumerUrl+'/v1/third_party_ratings?merchant_id='+merchantId+'&popularity_score='+popularityScore,{});
    }

    // Find google id
    /**
     * Get merchant on the basis of keyword search
     * @param searchQuery keyword search
     * @returns merchant list
     */
    getRestaurants(searchQuery:string){
        return this.http.get(this.url.consumerUrl+'/v1/feed/restaurants?page_no=1&query='+searchQuery); 
    }
    /**
     * help to find restaurant 
     * @param placeId place id which found from backend
     * @returns return  reaturant object
     */
    getRestaurantViaPlaceId(placeId:string){
        return this.http.get(this.url.consumerUrl+'/v1/feed/restaurants/places_restaurant_details?place_id='+placeId);  
    }

    mapRestaurant(body:{id:number,restaurant_id?:string,request_type:string}){
        return this.http.post(this.url.adminUrl+'/v2/map_restaurant_with_merchant',body);
    }

}
