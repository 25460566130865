import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}


    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let token = localStorage.getItem('token');
        if (token) {
            if (!request.url.match(environment.s3Url)) {
                if(!request.url.includes('/validate_merchant_slug')){
                    request = request.clone({
                        headers: request.headers.set('x-api-key',environment.xApiKey).set('Authorization', token)
                    });
                }
            }
        }
        return next.handle(request);
    }
}
