import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {provideRouter} from '@angular/router';
import {provideAnimations} from '@angular/platform-browser/animations';
import {routes} from './app/routes';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient} from '@angular/common/http';
import {AuthInterceptor} from './app/auth.interceptor';
import {importProvidersFrom} from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material/core';

bootstrapApplication(AppComponent, {
    providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}, provideRouter(routes), provideAnimations(), provideHttpClient(), importProvidersFrom(HttpClientModule), {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}]
}).catch((err) => console.error(err));
