import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThriveTagsService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getThriveTags(page: number) {
        let params = new HttpParams();
        params = params.append('page', page);
        return this.http.get(this.url.adminUrl + '/item_tags', {params});
    }

    getThriveTagDetails(id: number|string) {
        return this.http.get(this.url.adminUrl + `/item_tags/${id}`);
    }

    toggleThriveTagStatus(itemTag:any){
        return this.http.put(this.url.adminUrl + `/item_tags/${itemTag.id}`, {item_tag:itemTag});
    }

    createThriveTag(itemTag:any){
        return this.http.post(this.url.adminUrl + `/item_tags/`, {item_tag:itemTag});
    }

    editThriveTag(itemTag:any){
        return this.http.put(this.url.adminUrl + `/item_tags/${itemTag.id}`, {item_tag:itemTag});
    }
}
