import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {Router, RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ThriveTagsService } from './thrive-tags.service';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-thrive-tags',
    templateUrl: './thrive-tags.component.html',
    styleUrls: ['./thrive-tags.component.scss'],
    standalone: true,
    imports: [CommonModule, 
        MatIconModule, 
        TableComponent, 
        MatButtonModule, 
        RouterModule, 
        MatTooltipModule, 
        MatInputModule,
        AccessControlDirective
    ],
    providers: [ThriveTagsService]
})
export class ThriveTagsComponent implements OnInit {
    data: any;
    pageSize: number;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    page: any;
    per_page: number;

    constructor(private router: Router,
        private thriveTagsService: ThriveTagsService) {}

    ngOnInit(): void {
        this.initialise();
    }

    initialise(){
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id'
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'color',
                header: 'Color',
                cell: 'colour_code'
            },
            {
                columnDef: 'thrive_tag',
                header: 'Thrive tag',
                cell: 'thrive_tag',
                isToggle: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'name'];
        this.getThriveTagsList(1);
    }

    /**
     * Get list of thrive tags created
     */
    getThriveTagsList(page: number) {
        this.thriveTagsService.getThriveTags(page).subscribe((tags: any) => {
            this.data = tags.tags;
            this.resultsLength = tags.total_count;
            this.pageSize = tags.per_page;
        });
    }


    /**
     * update tags list on page change
     * @param event table event containing page index and size
     */
    changePage(event:any) {
        this.getThriveTagsList(event.pageIndex+1);
    }
    

    /**
     * 
     * @param tag 
     */
    actionClicked(tag:any) {
        if (tag.action === 'status') {
            tag.row['thrive_tag']=tag.checked;
            this.thriveTagsService.toggleThriveTagStatus(tag.row).subscribe(() => {
                let msg:string=tag.checked?'Tag enabled!':'Tag disabled!';
                Swal.fire({title: 'Success', text: msg, icon: 'success', timer: 4000, timerProgressBar: true});
            });
        }
    }

    /**
     * go to thrive tag create/edit page
     * @param event 
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('thrive-tags/' + event.id);
    }

  
}
