<div id="thrivePricing" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <span>
                <h2>Thrive Pricing</h2>
            </span>
        </div>
    </div>

    <div class="filters">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose filter option</mat-label>
            <mat-select [(value)]="filter_category" (selectionChange)="clearText()">
                <mat-option value="id">Merchant id</mat-option>
                <mat-option value="name">Merchant name</mat-option>
                <mat-option value="chain_id">Chain id</mat-option>
                <mat-option value="platform">Type</mat-option>
                <mat-option value="commission_for_new_customer">Commission(New)</mat-option>
                <mat-option value="commission_for_repeat_customer">Commission(Repeat)</mat-option>
                <mat-option value="commission_for_direct_platform">Commission(Direct Platform)</mat-option>
                <mat-option value="pg_plan">PG plan</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- search by id -->
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'id'">
            <mat-label >Merchant Id</mat-label>
            <input matInput [(ngModel)]="search" [placeholder]="'Enter Your Merchant Id'" />
        </mat-form-field>

        <!-- search by name -->
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'name'">
            <mat-label >Merchant Name</mat-label>
            <input matInput [(ngModel)]="search" [placeholder]="'Enter Your Merchant Name'" />
        </mat-form-field>

         <!-- search by chain id -->
         <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'chain_id'">
            <mat-label >Chain Id</mat-label>
            <input matInput [(ngModel)]="search" [placeholder]="'Enter Your Chain Id'" />
        </mat-form-field>

        <!-- search by platform type -->
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'platform'">
            <mat-label>Type</mat-label>
            <mat-select [(value)]="search">
                <mat-option [value]="1">Consumer</mat-option>
                <mat-option [value]="0">Direct</mat-option>
                <mat-option [value]="2">Both</mat-option>
                <mat-option [value]="3">CRM only</mat-option>
            </mat-select>
        </mat-form-field>

         <!-- commision  -->
        <div class="filters-commission" *ngIf="filter_category == 'commission_for_new_customer' || filter_category == 'commission_for_repeat_customer' || filter_category == 'commission_for_direct_platform'">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100"  >
                <mat-label>{{commissionLable[filter_category]}}</mat-label>
                <mat-select [(value)]="search">
                    <mat-option [value]="'>'">Greater than</mat-option>
                    <mat-option [value]="'<'">Less than</mat-option>
                    <mat-option [value]="'between'">Between</mat-option>
                    <mat-option [value]="'<='">Less than equal to</mat-option>
                    <mat-option [value]="'>='">Greater than equal to</mat-option>
                    <mat-option [value]="'='">Equal to</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- commission value min -->
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label> {{search === 'between' ? 'Min' : 'Value'}}</mat-label>
                <input matInput [(ngModel)]="commission.min" [placeholder]="'Enter value'" />
            </mat-form-field>
            <!-- commission value max -->
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="search === 'between'">
                <mat-label >Max</mat-label>
                <input matInput [(ngModel)]="commission.max" [placeholder]="'Enter value'" />
            </mat-form-field>
        </div>

        <!-- search by pg plan -->
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'pg_plan'">
            <mat-label>Select PG plan</mat-label>
            <mat-select [(value)]="search">
                <mat-option [value]="1">Plan A</mat-option>
                <mat-option [value]="2">Plan B</mat-option>
                <mat-option [value]="3">Plan C</mat-option>
                <mat-option [value]="4">Plan D</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- search button -->
        <button class="btn-thrive_primary" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="goToSearch(0)" *ngIf="filter_category">
            <span>Apply</span>
        </button>
    </div>

    <div class="tableoverflowdiv">
        <tn-table
            [title]="'Thrive Pricing'"
            [data]="data"
            [columns]="columns"
            [desktopDisplayedColumns]="desktopDisplayedColumns"
            [mobileDisplayedColumns]="mobileDisplayedColumns"
            [resultsLength]="resultsLength"
            [pageSize]="pageSize"
            [mobileTemplate]="mobileTemplate"
            (changePage)="changePage($event)"
            [specialHTML]="typeColumn"
            (rowClick)="navigateTo($event)" 
        >
        </tn-table>
    </div>
</div>

<ng-template #typeColumn let-option>
    <span>{{ option.row.id }}</span>
</ng-template>
<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Location</td>
            <td>{{ option.row.location }}</td>
        </tr>
        <tr>
            <td>City</td>
            <td>{{ option.row.city }}</td>
        </tr>
        <tr>
            <td>Type</td>
            <td>{{ option.row.platform }}</td>
        </tr>
        <tr>
            <td>Thrive Promise</td>
            <td>{{ option.row.thrive_promise}}</td>
        </tr>
        <tr>
            <td>Commission(New customer)</td>
            <td>{{ option.row.commission_for_new_customer }}</td>
        </tr>
        <tr>
            <td>Commission(Repeat customer)</td>
            <td>{{ option.row.commission_for_repeat_customer }}</td>
        </tr>
        <tr>
            <td>Commission(Direct Platform)</td>
            <td>{{ option.row.commission_for_direct_platform}}</td>
        </tr>
        <tr>
            <td>PG plan</td>
            <td>{{ option.row.pg_plan}}</td>
        </tr>
        
        <tr>
            <td>Popularity</td>
            <td>{{ option.row.manual_popularity}}</td>
        </tr>
    </table>
</ng-template>
