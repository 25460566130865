import { CommonModule } from '@angular/common';
import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {CustomMenuService} from '../custom-menu.service';
import Swal from 'sweetalert2';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {
    tap,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    filter,
    finalize,
  } from 'rxjs';
  export function forbiddenObjectValidator(control: AbstractControl) {
    return typeof control.value !== 'object' || control.value === null ? { 'forbiddenObject': true } : null;
  }
@Component({
  selector: 'app-custome-menu-edit',
  standalone: true,
  templateUrl: './custom-menu-edit.component.html',
  styleUrls: ['./custom-menu-edit.component.scss'],
  imports: [CommonModule, 
            RouterModule, 
            MatIconModule, 
            FormsModule, 
            ReactiveFormsModule, 
            MatFormFieldModule, 
            MatInputModule, 
            MatButtonModule,
            MatAutocompleteModule,
            MatSelectModule],
  providers: [CustomMenuService]
})


export class CustomMenuEditComponent {
    public searchResto:FormControl = new FormControl();
    edit: any;
    customMenuForm: FormGroup = this.fb.group({
      item_name: new FormControl('',Validators.required),
      restaurant_id: new FormControl('',[Validators.required,forbiddenObjectValidator,]),
      item_status: new FormControl('',Validators.required)
    });
    filteredOptions:any;
    isLoading: boolean = false;
    submitted:boolean = false;
    constructor(private fb: FormBuilder, 
      private router: Router, 
      private route: ActivatedRoute, 
      private customMenuService: CustomMenuService) {
      this.getRestoList(true); 
      this.edit = this.route.snapshot.params;
    }

    ngOnInit() {
      this.customMenuForm.get('restaurant_id').valueChanges.pipe(
        filter(res => {
          return res !== null && res.length >= 2
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filteredOptions = [];
          this.isLoading = true;
        }),
        switchMap(value => this.customMenuService.getRestaurant(1,value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        if (!data.restaurants.length) {
          this.filteredOptions = [];
        } else {
          this.filteredOptions = data.restaurants;
        }
      });
    }
    /**
     * Get custom item details
     */
    getCustmeItem(){
      this.customMenuService.getCustomItem(this.edit).subscribe((response:any)=>{
        response.restaurant_details['restaurant_id'] = response.restaurant_details.id.toString();
        this.customMenuForm.patchValue(response);
        this.customMenuForm.get('restaurant_id').setValue(response.restaurant_details);
      }); 
    }
  /**
   * Fetch restaurent list
   * @param intialCall fetch custom item details
   */
    getRestoList(intialCall?:any){
      this.customMenuService.getRestaurant(1,'').subscribe((response:any)=>{
        if(intialCall && Object.keys(this.edit).length){
          this.getCustmeItem();  
        }
      });
    }
  /**
   * Show restaurent name in auto complete option 
   * @param option to fetch object from front end
   * @returns Return display value
   */
    getOptioResto(option) {
      return option.name;
    }
    /**
     * submit form data
     */
    submitCustomMenu() {
      if (this.customMenuForm.valid) {
        this.submitted = true;
        this.customMenuForm.value.restaurant_id = this.customMenuForm.value.restaurant_id.restaurant_id;
        if (Object.keys(this.edit).length) {
          this.customMenuService.updateCustomItem(this.edit.id, this.customMenuForm.value).subscribe(
            () => {
              Swal.fire('Updated!', 'Custom item has been updated!', 'success');
              setTimeout(() => {
                this.router.navigateByUrl('/custom-menu');
              }, 500);
            },
            (err) => {
              this.submitted = false;
            }
          );
        } else {
          this.customMenuService.createCustomItem(this.customMenuForm.value).subscribe(
            () => {
              Swal.fire('Created!', 'Custom item has been successfully created!', 'success');
              setTimeout(() => {
                this.router.navigateByUrl('/custom-menu');
              }, 500);
            },
            (err) => {
              this.submitted = false;
            }
          );
        }
      }
    }
}
