import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatChipInputEvent,MatChipsModule } from '@angular/material/chips';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { RestaurantsService } from '../../restaurants.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-image-details-popup',
  standalone: true,
  imports: [CommonModule, 
            MatDialogModule, 
            MatButtonModule, 
            MatIconModule, 
            MatFormFieldModule, 
            MatInputModule, 
            ReactiveFormsModule,
            MatAutocompleteModule, 
            MatSelectModule,
            MatChipsModule],
  templateUrl: './image-details-popup.component.html',
  styleUrls: ['./image-details-popup.component.scss']
})
export class ImageDetailsPopupComponent {
  mediaForm: FormGroup = this.fb.group({
    status:new FormControl(''),
    tags:new FormControl('')
  });
  status: string;
  userRestaurants:any={};
  formSubmitted:boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  maxTagCharLimit: number = 30;
  tagKeyWord=new FormControl('');
  tags:Array<string>=[];
  selectedTags:Array<string>=[];
  searchTags:FormControl=new FormControl('');
  @ViewChild('media') mediaEl: ElementRef<HTMLInputElement>;
    
  constructor(
    public dialogRef: MatDialogRef<ImageDetailsPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private restaurantSearvice:RestaurantsService,
    private fb: FormBuilder) {
      this.selectedTags = this.data?.tags;
      let status:string
      if(this.data.user_restaurant?.approved)
      status = 'approved'
      if(this.data.user_restaurant?.is_deleted)
      status = 'rejected'

      this.mediaForm.get('status').setValue(status);
      this.mediaForm.get('tags').setValue(this.selectedTags);
       //autocomplete for image tags
       this.searchTags.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged())
        .subscribe((keyword:string) => {
        if(keyword && keyword.trim().length>1){
            this.getTagList(keyword);
        } else {
            this.tags = [];
        }
    });
  }

  /**
  * search tags
  * @param searchQuery search keyword
  */
  getTagList(searchQuery:string){
    this.restaurantSearvice.getTagsList({'search_query':searchQuery,'tag_type':'restaurant_photo'}).subscribe((tags:any) => {
        this.tags = tags;
    });
  }

  /**
   * Add a tag
   * @param event Represents an input event on a matChipInput.
   */
  addTag(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();
      // Trim the input text to the character limit
      const trimmedInput = value.substr(0, this.maxTagCharLimit);

      if (value.length > this.maxTagCharLimit)
      Swal.fire('Note!', `Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

      if (value) this.selectedTags.push(trimmedInput);

      event.chipInput!.clear();
      this.mediaForm.controls['tags'].setValue(this.selectedTags);
  }

  /**
   * Select a tag
   * @param event Event object that is emitted when an autocomplete option is selected
   */
  selectTag(event: MatAutocompleteSelectedEvent): void {
      this.selectedTags.push(event.option.viewValue);
      this.mediaEl.nativeElement.value = '';
      this.mediaForm.controls['tags'].setValue(this.selectedTags);
  }

  /**
   * Remove the tag
   * @param tag tag to be removed
   */
  removeTag(tag: string): void {
      const index:number = this.selectedTags.indexOf(tag);
      if (index >-1) {
          this.selectedTags.splice(index, 1);
      }
      this.mediaForm.controls['tags'].setValue(this.selectedTags);
  }
  /**
   * submit form value
   */ 
  submit(){
    this.formSubmitted = true;
    Object.assign(this.userRestaurants,this.data.user_restaurant);
    this.userRestaurants.approved = this.mediaForm.value.status === 'approved' ? true : false;
    this.userRestaurants.is_deleted = this.mediaForm.value.status === 'rejected' ? true : false;
    if(this.mediaForm.value.tags && this.data.user_restaurant.attribute_name === "photos"){
      this.userRestaurants['restaurant_photo_tag'] = this.mediaForm.value.tags;
    } else {
      this.userRestaurants['restaurant_video_tag'] = this.mediaForm.value.tags;
    }

    this.restaurantSearvice.updateUserRestaurant(this.data.user_restaurant.id,this.userRestaurants).subscribe((response:any)=>{
      Swal.fire('Updated!', this.data.user_restaurant.attribute_name === 'video' ? 'Video details updated successfully!' : 'Image details updated successfully!', 'success');
      this.onClose(true);
    },(error:any)=>{
      this.formSubmitted = false;
      let errMsg:string='Something went wrong. Please try agaian later.';
      if(error?.error?.errors){
          errMsg=typeof error.error.errors === 'string'?error.error.errors:error.error.errors[0];
      }
      Swal.fire('Error', errMsg, 'error');
      this.onClose(false);
    });
  }
  /**
   * To close the dialog with or without any submission
   */
  onClose(status:boolean): void {
      this.dialogRef.close(status);
  }
}
