<div class="dialog-content">
    <div class="upload-media">
        <ng-container *ngIf="data.type==='old'">
            <img *ngIf="data.image.media_type === 'image'" [src]="data.image.media_url" />

            <video *ngIf="data.image.media_type === 'video'" width="100" controls>
                <source [src]="data.image.media_url">
                Your browser does not support the video tag.
            </video>
        </ng-container>

        <ng-container *ngIf="data.type==='new'">
            <img *ngIf="data.image.match('data:image')" [src]="data.image" />

            <video *ngIf="data.image.match('data:video')" width="100" controls>
                <source [src]="data.image">
                Your browser does not support the video tag.
            </video>
        </ng-container>
    </div>
</div>