import { CommonModule } from '@angular/common';
import {Component, OnInit,ViewChild, Input} from '@angular/core';
import {ControlContainer, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import Swal from 'sweetalert2';
import {MerchantService} from '../../merchant.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
@Component({
  selector: 'app-custom-message',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,],
  templateUrl: './custome-message.component.html',
  styleUrls: ['./custome-message.component.scss'],
  viewProviders: [
    {
        provide: ControlContainer,
        useExisting: FormGroupDirective
    }
]
})
export class CustomeMessageComponent implements OnInit{
  @Input() merchantDetails:any;
  merchantForm:FormGroup;
  @ViewChild("messagecontents",{ static: false }) public messagecontents: any;
  customMessageTypes: any = [];
  customMsgArray = [];
  selectedMsgTypes = [];
  constructor(private merchantService: MerchantService,private parent: FormGroupDirective ){
    
  }
  ngOnInit(): void {
    this.getCustomMessageTypes(this.merchantDetails);
    this.merchantForm = this.parent.form;
  }

  //function to get custom messaage types
  getCustomMessageTypes(businessInfo: any) {
      this.merchantService.getCustomMessage(businessInfo).subscribe((customMessageTypes) => {
          this.customMessageTypes = customMessageTypes;
          //if the custom messages are already present
          businessInfo.miscellaneous.custom_messages.forEach(custom => {
              let index = this.selectedMsgTypes.indexOf(custom.prevType);
              if (index > -1) {
                  this.selectedMsgTypes.splice(index, 1);
              }
              let i = this.customMessageTypes.findIndex(msgObj => msgObj.type === custom.message_type);
              if (i > -1) {
                  this.customMsgArray.push({
                      id: custom.id,
                      type: custom.message_type,
                      message: custom.message,
                      tags: this.customMessageTypes[i].tags,
                      prevType: custom.message_type,
                      prev_msg:custom.message,
                      valid: true,
                      is_enable: true,
                      chain_id:custom.chain_id?custom.chain_id:null
                  });
                  this.selectedMsgTypes.push(custom.message_type);
              }
          });
          //if custom messages are not present
          if(this.customMsgArray.length===0){
            this.customMsgArray.push({
                id: null,
                type: "",
                message: "",
                tags: [],
                prevType: "",
                valid: false,
                is_enable: true
            });
          }
      });
  }

  addCustomControl() {
      let custom = this.customMsgArray[this.customMsgArray.length - 1];
      custom.valid = true;
      if (custom.type && custom.message) {
        this.customMsgArray.push({
          id: null,
          type: '',
          message: '',
          tags: [],
          prevType: '',
          valid: false,
          is_enable: true
        });
      }
    }

  onChange(message:any, idx:any) {
    let index = this.selectedMsgTypes.indexOf(message.prevType);
    if (index > -1) {
      this.selectedMsgTypes.splice(index, 1);
    }
    let i = this.customMessageTypes.findIndex(msgObj => msgObj.type === message.type);
    if (i > -1) {
      this.customMsgArray[idx].tags = this.customMessageTypes[i].tags;
      message.prevType = message.type;
      this.selectedMsgTypes.push(message.type);
    }
  }

  addTag(messageObj, tag) {
    messageObj.message = messageObj.message.concat(' ' + tag + ' ');
  }
  
  removeCustomControl(messageObj, idx) {
    let i = this.selectedMsgTypes.indexOf(messageObj.type);
    if (i > -1) {
      this.selectedMsgTypes.splice(i, 1);
    }
    if (messageObj.type === '' && messageObj.message === '') {
      this.customMsgArray.splice(idx, 1);
    }
    else {
      this.customMsgArray[idx].is_enable = false;
    }
  }
  showRemoveBtn() {
    let count = 0;
    this.customMsgArray.forEach(msg => {
      if (msg.is_enable) {
        count++;
        msg["count"] = count;
      }
    });
    return count;
  }
  checkForUnicodeMessage() {
    var ascii = /^[ -~\t\n\r]+$/;
    let unicodeMsg = false;
    this.customMsgArray.forEach((custom, index) => {
      if (custom.message && !ascii.test(custom.message)) {
        custom.message = custom.message.replace(/[^\000-\177]/g, "?");

        custom.isUnicode = true;
        if (!unicodeMsg) {
            Swal.fire({
                icon: 'error',
                text: 'You seem to have copy-pasted text. Certain characters have an unknown formatting, please review your text and make the necessary changes.'
            })
          unicodeMsg = true;

          setTimeout(() => {
            this.messagecontents.toArray()[index].nativeElement.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 500);
        }
      }
      else {
        custom.isUnicode = false;
      }
    });
    return unicodeMsg;
  }

  customMessageChanged(custom) {
    var ascii = /^[ -~\t\n\r]+$/;
    if (custom.message && !ascii.test(custom.message)) {
      custom.message = custom.message.replace(/[^\000-\177]/g, "?");
    Swal.fire({
        icon: 'error',
        text: 'You seem to have copy-pasted text. Certain characters have an unknown formatting, please review your text and make the necessary changes.'
    })
    }
  }

  submitCustomMessage(){
    this.customMsgArray = this.customMsgArray.map((custom)=>{
      delete custom.isUnicode;
      if(this.merchantForm.value.role.toLowerCase() === 'merchant' &&
        custom.chain_id &&
        custom.prev_msg !== custom.message){
        custom['merchant_id']=this.merchantDetails.merchant.id;
      }
      return custom;
    });
    this.merchantForm.value.miscellaneous['custom_messages'] = Object.assign([], this.customMsgArray);
    if(this.merchantForm.value.miscellaneous.is_custom_messages){
      let found:boolean=false;
      for(let i:number=0;i<this.selectedMsgTypes.length;i++){
        if(this.selectedMsgTypes[i].includes("Redemption")){
          found=true;
          break;
        }
      }
      this.merchantForm.value.miscellaneous['is_custom_redemption_message']=found;
    }else{
      this.merchantForm.value.miscellaneous['is_custom_redemption_message']=false;
    }
  }
}
