import { FormGroup, ValidatorFn, Validators } from "@angular/forms";
// Validation to validate ordering type is selected or not
export function atLeastOneChecked(min_required = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];
        if (control.value) {
          checked++;
        }
      });
      if (checked < min_required) {
        return {
          notchecked: true,
        };
      }
      return null;
    };
  }
  
// export const outlet:any={
//     ChainOwner:{
//         'Account details':'Account details',
//         'Restaurant Information':'Chain Information',
//         'Miscellaneous Details':'Miscellaneous Details',
//         'Pricing + Order Mode':'Pricing + Order Mode',
//         'Review Merchant':'Review Merchant'
//     },
//     Merchant:{
//         'Account details':'Account details',
//         'Restaurant Information':'Restaurant Information',
//         'Restaurant location and contact details':'Restaurant location and contact details',
//         'Miscellaneous Details':'Miscellaneous Details',
//         'Pricing + Order Mode':'Pricing + Order Mode',
//         'Review Merchant':'Review Merchant'
//     },
//     Lead:{
//         'Review Merchant':'Review Merchant'
//     },
//     ['Not in use']:{
//         'Review Merchant':'Review Merchant'  
//     }
// };

// export const crmOutlet:any = {
//     ChainOwner:{
//         'Account details':'Account details',
//         'Restaurant Information':'Chain Information',
//         'Miscellaneous Details':'Miscellaneous Details',
//         'Review Merchant':'Review Merchant'
//     },
//     Merchant:{
//         'Account details':'Account details',
//         'Restaurant Information':'Restaurant Information',
//         'Restaurant location and contact details':'Restaurant location and contact details',
//         'Miscellaneous Details':'Miscellaneous Details',
//         'Review Merchant':'Review Merchant'
//     },
//     Lead:{
//         'Review Merchant':'Review Merchant'
//     },
//     ['Not in use']:{
//         'Review Merchant':'Review Merchant'  
//     }
// }

// 1: AccountDetails
//2: Restaurant Information
//3: Restaurant location and contact details
//4: Miscellaneous Details
//5: Pricing + Order Mode
//6: Review Merchant

export const stepNumber:any={
    account_details:1,
    restaurant_info:2,
    contact_details:3,
    miscellaneous: 4,
    pricing: 5,
    bank_account_details:6
};

export const thriveMerchant:any={
    ChainOwner:[null,true,true,true,true,true,false,true],
    Merchant:[null,true,true,true,true,true,true,true],
    Lead:[null,false,false,false,false,false,false,true],
    ['Not in use']:[null,false,false,false,false,false,false,false]
};

export const crmOnlyMerchant:any = {
    ChainOwner:[null,true,true,true,true,false,false,true],
    Merchant:[null,true,true,true,true,false,true,true],
    Lead:[null,false,false,false,false,false,false,true],
    ['Not in use']:[null,false,false,false,false,false,false,true]
};

export const preDefineSlots = [
    {
        day: 'Monday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Tuesday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Wednesday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Thursday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Friday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Saturday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    },
    {
        day: 'Sunday',
        closed: false,
        open_slot: [
            {
                start: '09:30',
                end: '23:00'
            }
        ]
    }
];

export const orderingTypes: any = [
    { name: 'Delivery', id: 'delivery'},
    { name: 'Pickup', id: 'take_away'},
    { name: 'Dine-in', id: 'dine_in'},
];


export const companyTypesList: any = [
    { id: 'Private Limited Company', name: 'Private Limited Company' },
    { id: 'Limited Liability Partnership', name: 'Limited Liability Partnership'},
    { id: 'Proprietorship', name: 'Proprietorship' },
    { id: 'Public Limited Company', name: 'Public Limited Company' },
    { id: 'Society/ Club/ Trust/ AOP', name: 'Society/ Club/ Trust/ AOP' },
    { id: 'Partnership', name: 'Partnership' },
    { id: 'Hindu Undivided Family', name: 'Hindu Undivided Family' },
    { id: 'Government Department', name: 'Government Department' },
];

export const requiredFields :any = {
    'Merchant':['part_of_chain',
        'account_details.city',
        'account_details.staff_pos',
        'contact_details.search_address',
        'contact_details.outlet_address',
        'contact_details.pincode',
        'contact_details.contact_number',
        'contact_details.lon',
        'contact_details.lat',
        'contact_details.location',
        'contact_details.email',
        // bank details
        'bank_account_details.account_holder_name',
        'bank_account_details.account_number',
        'bank_account_details.confirm_account_number',
        'bank_account_details.ifsc_code',
        'bank_account_details.business_type',
        'bank_account_details.bank_name',
        'bank_account_details.branch',
        'bank_account_details.city',
        'bank_account_details.state',
        'bank_account_details.is_verified'
    ]
};

export const validationsForRequiredFields:any ={
    'part_of_chain':[Validators.required],
    'account_details.city':[Validators.required],
    'account_details.staff_pos':[Validators.required],
    'contact_details.search_address':[Validators.required],
    'contact_details.outlet_address':[Validators.required],
    'contact_details.pincode':[Validators.required,Validators.pattern(/^[1-9][0-9]{5}$/)],
    'contact_details.contact_number':[Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
    'contact_details.email':[Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)],
    'contact_details.lon':[Validators.required,Validators.pattern(/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/)],
    'contact_details.lat':[Validators.required,Validators.pattern(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/)],
    'contact_details.location':[Validators.required],
    // bank details
    'bank_account_details.account_holder_name':[Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)],
    'bank_account_details.account_number':[Validators.required],
    'bank_account_details.confirm_account_number':[Validators.required],
    'bank_account_details.ifsc_code':[Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)],
    'bank_account_details.business_type':[Validators.required],
    'bank_account_details.bank_name':[Validators.required],
    'bank_account_details.branch':[Validators.required],
    'bank_account_details.city':[Validators.required],
    'bank_account_details.state':[Validators.required],
    'bank_account_details.is_verified':Validators.requiredTrue
}

export const notRequiredFields :any = {
    'ChainOwner':['part_of_chain',
        'account_details.city',
        'account_details.staff_pos',
        'restaurant_info.gst_number',
        'restaurant_info.gst_registered_date',
        // 'restaurant_info.gst_registered_email',
        'restaurant_info.part_of_hotel',
        'restaurant_info.pan_number',
        'restaurant_info.declaration',
        'restaurant_info.fssai_application_no',
        'restaurant_info.fssai_application_date',
        'restaurant_info.fssai_license_no',
        'contact_details.search_address',
        'contact_details.outlet_address',
        'contact_details.pincode',
        'contact_details.contact_number',
        'contact_details.lat',
        'contact_details.lon',
        'contact_details.location',
        // 'contact_details.email'
        // bank details
        'bank_account_details.account_holder_name',
        'bank_account_details.account_number',
        'bank_account_details.confirm_account_number',
        'bank_account_details.ifsc_code',
        'bank_account_details.business_type',
        'bank_account_details.bank_name',
        'bank_account_details.branch',
        'bank_account_details.city',
        'bank_account_details.state',
        'bank_account_details.is_verified'
    ]
};

export const errorMessagesToShow:any={
    'required':'is required.',
    'minlength':"is violating minimum characters/digits limit.",
    'maxlength':"is violating maximum characters/digits limit.",
    'pattern':'has invalid format.',
    'email':'has invalid format.',
    'notchecked':'is not checked. At least one option should be checked.'
};