<div id="thriveTagsEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/thrive-tags'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
        </div>
    </div>
    <form [formGroup]="thriveTagForm" *ngIf="thriveTagForm">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input type="text" matInput placeholder="Enter tag name" formControlName="name" />
            <mat-error *ngIf="thriveTagForm.get('name').hasError('required') && thriveTagForm.get('name').touched">Required</mat-error>
        </mat-form-field>
        <div [class.color-picker-focus]="colorPickerId == 'thriveTagColorCode'">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Color code</mat-label>
                <input matInput formControlName="colour_code" [(colorPicker)]="colorThriveTag" (colorPickerChange)="thriveTagForm.get('colour_code').setValue($event)" (focus)="(colorPickerId == 'thriveTagColorCode')" />
                <span matSuffix class="color-circle" [style.background-color]="colorThriveTag"></span>
            </mat-form-field>
        </div>
        <mat-slide-toggle formControlName="thrive_tag" color="primary">Enable thrive tag</mat-slide-toggle>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button 
            accessControl
            moduleType="thrive_tags"
            (click)="submit()" 
            [disabled]="!thriveTagForm.valid">
                <span>{{ editId !== 'new' ? 'Update' : 'Save' }}</span>
            </button>
        </div>
    </div>
</div>
