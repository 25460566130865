<div id="thrivePricingCreate" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/thrive-pricing'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Update Pricing</h2>
        </div>
    </div>

    <div class="form-card">
        <form  [formGroup]="pricingForm">

           <div [ngClass]="{disabled:pricingDetails?.temporary}" class="form-control mt-30">

                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Thrive Promise</mat-label>
                    <mat-select placeholder="Choose Thrive Promise" formControlName="thrive_exclusive_status">
                        <mat-option [value]="1">Yes</mat-option>
                        <mat-option [value]="0">No</mat-option>
                    </mat-select>
                    <mat-error *ngIf="pricingForm.get('thrive_exclusive_status').hasError('required') && pricingForm.get('thrive_exclusive_status').touched">Required</mat-error>
                </mat-form-field>

                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>PG plan</mat-label>
                    <mat-select placeholder="Choose PG plan" formControlName="pg_plan">
                        <mat-option [value]="1">Plan A(Default plan)</mat-option>
                        <mat-option [value]="2">Plan B</mat-option>
                        <mat-option [value]="3">Plan C</mat-option>
                        <mat-option [value]="4">Plan D</mat-option>
                    </mat-select>
                    <mat-error *ngIf="pricingForm.get('pg_plan').hasError('required') && pricingForm.get('pg_plan').touched">Required</mat-error>
                </mat-form-field>

                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Popularity</mat-label>
                    <input  type="text" matInput placeholder="Enter value between 0 to 1000." formControlName="popularity" />
                    <mat-error *ngIf="pricingForm.get('popularity').hasError('required') && pricingForm.get('popularity').touched">Required</mat-error>
                    <mat-error *ngIf="pricingForm.get('popularity').hasError('min') && pricingForm.get('popularity').touched">Minimum 0.</mat-error>
                    <mat-error *ngIf="pricingForm.get('popularity').hasError('max') && pricingForm.get('popularity').touched">Maximum 1000.</mat-error>
                </mat-form-field>
            </div>

            <div class="subtitle mt-30" [ngClass]="{disabled:pricingDetails?.temporary}"  *ngIf="showConsumer">Consumer App</div>
            <div class="form-control mt-20"  [ngClass]="{disabled:pricingDetails?.temporary}" *ngIf="showConsumer">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Commission - new customer</mat-label>
                    <input type="text" matInput placeholder="Enter value in percentage" formControlName="new_customer_price" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <mat-error *ngIf="pricingForm.get('new_customer_price').hasError('required') && pricingForm.get('new_customer_price').touched">Required</mat-error>
                    <mat-error *ngIf="pricingForm.get('new_customer_price').hasError('min') && pricingForm.get('new_customer_price').touched">Minimum 1.</mat-error>
                </mat-form-field>

                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                    <mat-label>Commission - repeat customer</mat-label>
                    <input type="text" matInput placeholder="Enter value in percentage" formControlName="repeat_customer_price" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    <mat-error *ngIf="pricingForm.get('repeat_customer_price').hasError('required') && pricingForm.get('repeat_customer_price').touched">Required</mat-error>
                    <mat-error *ngIf="pricingForm.get('repeat_customer_price').hasError('min') && pricingForm.get('repeat_customer_price').touched">Minimum 1.</mat-error>
                </mat-form-field>
            </div>
            <div formGroupName="direct" [ngClass]="{disabled:pricingDetails?.temporary}" *ngIf="showDirect">
                <div class="subtitle mt-30">Direct </div>

                <div class="form-control mt-20">
                    
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                        <mat-label>Commission - Delivery order</mat-label>
                        <input type="text" matInput placeholder="Enter value in percentage" formControlName="delivery" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('direct.delivery').hasError('required') && pricingForm.get('direct.delivery').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('direct.delivery').hasError('min') && pricingForm.get('direct.delivery').touched">Minimum 1.</mat-error>
                    </mat-form-field>

                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                        <mat-label>Commission - Pick up order</mat-label>
                        <input type="text" matInput placeholder="Enter value in percentage" formControlName="take_away" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('direct.take_away').hasError('required') && pricingForm.get('direct.take_away').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('direct.take_away').hasError('min') && pricingForm.get('direct.take_away').touched">Minimum 1.</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 " >
                        <mat-label>Commission - Dine in order</mat-label>
                        <input type="text"  matInput placeholder="Enter value in percentage" formControlName="dine_in" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('direct.dine_in').hasError('required') && pricingForm.get('direct.dine_in').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('direct.dine_in').hasError('min') && pricingForm.get('direct.dine_in').touched">Minimum 1.</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div formGroupName="ondc" [ngClass]="{disabled:pricingDetails?.temporary}" *ngIf="showOndc">
                <div class="subtitle mt-30">ONDC</div>
                <div class="form-control mt-20">
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                        <mat-label>Commission - Delivery order</mat-label>
                        <input type="text" matInput placeholder="Enter value in percentage" formControlName="delivery" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('ondc.delivery').hasError('required') && pricingForm.get('ondc.delivery').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('ondc.delivery').hasError('min') && pricingForm.get('ondc.delivery').touched">Minimum 1.</mat-error>
                    </mat-form-field>

                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                        <mat-label>Commission - Pick up order</mat-label>
                        <input type="text" matInput placeholder="Enter value in percentage" formControlName="take_away" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('ondc.take_away').hasError('required') && pricingForm.get('ondc.take_away').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('ondc.take_away').hasError('min') && pricingForm.get('ondc.take_away').touched">Minimum 1.</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 ">
                        <mat-label>Commission - Dine in order</mat-label>
                        <input type="text"  matInput placeholder="Enter value in percentage" formControlName="dine_in" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                        <mat-error *ngIf="pricingForm.get('ondc.dine_in').hasError('required') && pricingForm.get('ondc.dine_in').touched">Required</mat-error>
                        <mat-error *ngIf="pricingForm.get('ondc.dine_in').hasError('min') && pricingForm.get('ondc.dine_in').touched">Minimum 1.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="thrive-card no-hover" *ngIf="pricingDetails?.location !== 'All Outlets'">
                <div class="toggle-button" [ngClass]="{disabled:pricingDetails?.temporary}">
                    <p class="subtitle">Temporary commission</p>
                    <mat-slide-toggle color="primary" formControlName="temporary"  (change)="onToggleTemporary($event)"></mat-slide-toggle>
                </div>

                <div *ngIf="pricingForm.get('temporary_data')" formGroupName="temporary_data">
                    <div class="subtitle mt-20"  *ngIf="showConsumer && !pricingDetails?.temporary">Consumer App</div>
                    <div class="form-control mt-20"  *ngIf="showConsumer && !pricingDetails?.temporary">
                        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                            <mat-label>Commission - new customer</mat-label>
                            <input type="text" matInput placeholder="Enter value in percentage" formControlName="new_customer_price" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                            <mat-error *ngIf="pricingForm.get('temporary_data.new_customer_price').hasError('required') && pricingForm.get('temporary_data.new_customer_price').touched">Required</mat-error>
                            <mat-error *ngIf="pricingForm.get('temporary_data.new_customer_price').hasError('min') && pricingForm.get('temporary_data.new_customer_price').touched">Minimum 1.</mat-error>
                        </mat-form-field>

                        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                            <mat-label>Commission - repeat customer</mat-label>
                            <input type="text" matInput placeholder="Enter value in percentage" formControlName="repeat_customer_price" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                            <mat-error *ngIf="pricingForm.get('temporary_data.repeat_customer_price').hasError('required') && pricingForm.get('temporary_data.repeat_customer_price').touched">Required</mat-error>
                            <mat-error *ngIf="pricingForm.get('temporary_data.repeat_customer_price').hasError('min') && pricingForm.get('temporary_data.repeat_customer_price').touched">Minimum 1.</mat-error>
                        </mat-form-field>
                    </div>
                    <div formGroupName="direct" *ngIf="showDirect && !pricingDetails?.temporary">
                        <div class="subtitle mt-30">Direct </div>
        
                        <div class="form-control mt-20">
                            
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                                <mat-label>Commission - Delivery order</mat-label>
                                <input type="text" matInput placeholder="Enter value in percentage" formControlName="delivery" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('direct.delivery').hasError('required') && pricingForm.get('direct.delivery').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('direct.delivery').hasError('min') && pricingForm.get('direct.delivery').touched">Minimum 1.</mat-error>
                            </mat-form-field>
        
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                                <mat-label>Commission - Pick up order</mat-label>
                                <input type="text" matInput placeholder="Enter value in percentage" formControlName="take_away" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('direct.take_away').hasError('required') && pricingForm.get('direct.take_away').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('direct.take_away').hasError('min') && pricingForm.get('direct.take_away').touched">Minimum 1.</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 ">
                                <mat-label>Commission - Dine in order</mat-label>
                                <input type="text"  matInput placeholder="Enter value in percentage" formControlName="dine_in" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('direct.dine_in').hasError('required') && pricingForm.get('direct.dine_in').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('direct.dine_in').hasError('min') && pricingForm.get('direct.dine_in').touched">Minimum 1.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div formGroupName="ondc" *ngIf="showOndc && !pricingDetails?.temporary">
                        <div class="subtitle mt-30">ONDC</div>
                        <div class="form-control mt-20">
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                                <mat-label>Commission - Delivery order</mat-label>
                                <input type="text" matInput placeholder="Enter value in percentage" formControlName="delivery" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('ondc.delivery').hasError('required') && pricingForm.get('ondc.delivery').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('ondc.delivery').hasError('min') && pricingForm.get('ondc.delivery').touched">Minimum 1.</mat-error>
                            </mat-form-field>
        
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" >
                                <mat-label>Commission - Pick up order</mat-label>
                                <input type="text" matInput placeholder="Enter value in percentage" formControlName="take_away" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('ondc.take_away').hasError('required') && pricingForm.get('ondc.take_away').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('ondc.take_away').hasError('min') && pricingForm.get('ondc.take_away').touched">Minimum 1.</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 ">
                                <mat-label>Commission - Dine in order</mat-label>
                                <input type="text"  matInput placeholder="Enter value in percentage" formControlName="dine_in" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                <mat-error *ngIf="pricingForm.get('ondc.dine_in').hasError('required') && pricingForm.get('ondc.dine_in').touched">Required</mat-error>
                                <mat-error *ngIf="pricingForm.get('ondc.dine_in').hasError('min') && pricingForm.get('ondc.dine_in').touched">Minimum 1.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div [ngClass]="{'delete-temporary':pricingDetails?.temporary}">
                        <div [ngClass]="{disabled:pricingDetails?.temporary}">
                            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20">
                                <mat-label>Commission - validity</mat-label>
                                <mat-date-range-input [min]="minDate" [rangePicker]="picker"  (dateInput)="onDateChange($event)">
                                <input readonly matStartDate formControlName="start_date" placeholder="From">
                                <input readonly matEndDate formControlName="end_date" placeholder="To">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker ></mat-date-range-picker>
                            </mat-form-field>
                            <mat-error *ngIf="pricingForm.get('temporary_data.start_date').hasError('required') && pricingForm.get('temporary_data.start_date').touched || 
                            pricingForm.get('temporary_data.end_date').hasError('required') && pricingForm.get('temporary_data.end_date').touched">Start date and end date required.</mat-error>
                            <mat-error *ngIf="pricingForm.get('temporary_data.start_date').hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="pricingForm.get('temporary_data.end_date').hasError('matEndDateInvalid')">Invalid end date</mat-error>
                        </div>
                        <button *ngIf="pricingDetails?.temporary" class="btn-thrive_primary" (click)="disabledTemporaryCommission()">Delete</button>
                    </div>
                </div>
            </div>
        </form>

        <div class="action-footer">
            <div class="footer-container">
                <button class="btn-thrive_primary" [ngClass]="{disabled:pricingDetails?.temporary}" mat-button 
                accessControl
                moduleType="thrive_pricing"
                (click)="submitForm()" 
                [disabled]="submitted">
                    <mat-icon>add</mat-icon>
                    <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
                </button>
            </div>
        </div>
    </div>
</div>
