<div id="thriveLoader" class="thrive-card no-hover mt-0" [style.height]="loaderHeight">
    <div class="pan-loader">
        <div class="loader"></div>
        <div class="pan-container">
            <div class="pan"></div>
            <div class="handle"></div>
        </div>
        <div class="shadow"></div>
    </div>
    <p>Loading</p>
</div>