import {Component, OnInit} from '@angular/core';
import {SourceManagementService} from '../source-management.service';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {MatIconModule} from '@angular/material/icon';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive'
@Component({
    selector: 'app-source-management-edit',
    templateUrl: './source-management-edit.component.html',
    styleUrls: ['./source-management-edit.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,AccessControlDirective],
    providers: [SourceManagementService]
})
export class SourceManagementEditComponent implements OnInit {
    sourceForm: FormGroup;
    selectedIndex: any;
    editId: string;
    allSources: Object;

    constructor(private sourceManagementService: SourceManagementService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.editId = this.route.snapshot.params.id;
        this.createForm();
        this.showSources();
    }

    /**
     * to create a form to edit data of a source or create a new source
     * @param sources to check if source data is already present or new data needs to be added
     */
    createForm(sources?) {
        this.sourceForm = this.fb.group<any>({
            name: new FormControl('', Validators.required),
            sort_order: new FormControl('', [Validators.required, Validators.min(0)])
        });

        //in case of editing a source
        if (this.editId !== 'new') {
            this.sourceManagementService.getSourcesById(this.editId).subscribe((sources: any) => {
                this.sourceForm.patchValue({...sources.data});
            });
        }
    }

    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if (this.editId !== 'new') {
            this.sourceManagementService.updateSources(this.editId, this.sourceForm.value).subscribe(() => {
                Swal.fire('Updated!', 'Source has been updated!', 'success');
                this.router.navigateByUrl('/source-management');
            });
        } else {
            this.sourceManagementService.createSources(this.sourceForm.value).subscribe(() => {
                Swal.fire('Created!', 'Source has been successfully created!', 'success');
                this.router.navigateByUrl('/source-management');
            });
        }
    }

    /**
     * get API call to fetch the sources
     */
    showSources() {
        this.sourceManagementService.getSources().subscribe((all_sources) => {
            this.allSources = all_sources;
        });
    }
}
