<div class="dialog-header">
    <span>Comments</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content p-0">
    <input style="display: none" type="file" (change)="fileChangeEvent($event)" #imageUpload />
    <div class="image__area flex animation">
        <image-cropper
            [imageChangedEvent]="image_changed_event"
            [imageBase64]="image_base64"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="contain_within_aspect_ratio"
            [aspectRatio]="data.aspectRatio"
            [cropperMinWidth]="80"
            [onlyScaleDown]="true"
            [canvasRotation]="canvas_rotation"
            [transform]="transform"
            [alignImage]="'center'"
            [style.display]="show_cropper ? null : 'none'"
            format="png,jpeg"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
        ></image-cropper>
    </div>
    <p class="text-center black-text">Drag to reposition photo</p>
    <div class="slider__area">
        <div class="w-100">
            <div class="flex flex_attribute slider__label">
                <div>Zoom</div>
                <div>{{ image_zoom / 10 }}</div>
            </div>
            <mat-slider class="average" min="1" max="20" showTickMarks step="1" name="image_zoom" [discrete]="true" ngDefaultControl #ngSlider>
                <input
                    matSliderThumb
                    (input)="
                        zoom({
                            source: ngSliderThumb,
                            parent: ngSlider,
                            value: ngSliderThumb.value
                        })
                    "
                    [(ngModel)]="image_zoom"
                    #ngSliderThumb="matSliderThumb"
                />
            </mat-slider>
        </div>
        <div class="w-100">
            <div class="flex flex_attribute slider__label">
                <div>Straighten</div>
                <div>{{ image_straighten }}</div>
            </div>
            <mat-slider class="average" min="-10" max="10" showTickMarks step="1" aria-label="units" name="image_straighten" [discrete]="true" ngDefaultControl #ngSlider>
                <input
                    matSliderThumb
                    (input)="
                        straighten({
                            source: ngSliderThumb,
                            parent: ngSlider,
                            value: ngSliderThumb.value
                        })
                    "
                    [(ngModel)]="image_straighten"
                    #ngSliderThumb="matSliderThumb"
                />
            </mat-slider>
        </div>
        <mat-icon class="rotate__image" matTooltip="Rotate by 90 degree" (click)="rotateRight()">refresh</mat-icon>
    </div>
    <div class="thrive-toast_info mt-20 mr-20 ml-20" *ngIf="data.message">
        <span>Info:</span>
        {{ data.message }}
    </div>
</div>
<div class="dialog-footer">
    <button (click)="deleteImage()" class="btn-thrive_icon delete-btn" mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>
    <div class="flex">
        <button (click)="imageUpload.click()" class="btn-thrive_secondary" mat-button>Change Image</button>
        <button (click)="submitCropImage()" class="btn-thrive_primary ml-20" mat-button>Apply</button>
    </div>
</div>
