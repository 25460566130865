import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {restaurantFormDataIf,userRestaurantIf} from './restaurants-edit/reataurant';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService {
  private url = environment;

  constructor(public http: HttpClient) {}

    getRestaurantsList(page: number = 0,perPage:number,filterObj?:any) {
        let params = new HttpParams();
        params = params.append('page_no', page+1);
        params = params.append('from_admin_panel',true);
        params = params.append('per_page',perPage);
        if(filterObj){
            Object.keys(filterObj).forEach((key:string)=>{
                params = params.append(key, filterObj[key]);
            });
        }
        return this.http.get(this.url.adminUrl + '/feed/restaurants',{params});
    }

    getRestaurantDetails(restaurantId:string){
        let params = new HttpParams();
        params = params.append('from_admin_panel',true);
        return this.http.get(this.url.adminUrl+'/feed/restaurants/'+restaurantId,{params});
    }

    getTagsList(params){
        return this.http.get(this.url.consumerUrl+'/v1/feed/internal_tags',{params});
    }


    createRestaurant(body:restaurantFormDataIf){
        return this.http.post(this.url.consumerUrl+'/v1/feed/restaurants',body);
    }

    updateRestaurant(restaurantId:string,body:restaurantFormDataIf){
        return this.http.put(this.url.consumerUrl+'/v1/feed/restaurants/'+restaurantId,body);
    }

    updateUserRestaurant(restaurantId:string,body:userRestaurantIf){
        let resto={ 
            'from_admin_panel':true,
            user_restaurants: {
                ...body
            }   
        }
        return this.http.put(this.url.adminUrl+'/feed/user_restaurants/'+restaurantId,resto);  
    }

    getMenuCards(restaurantId:string){
        return this.http.get(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories');
    }

    publishMenuCategory(menuCategoryId:number,restaurantId:string){
        return this.http.put(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories/'+menuCategoryId+'/publish',{publish_on_app:true});
    }

    updateMenuCategories(restaurantId:string,params:any){
        return this.http.put(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories/bulk_update_categories',params);
    }

    getMenuCategory(restaurantId:string,menuCategoryId:number){
        return this.http.get(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories/'+menuCategoryId);
    }

    createMenuCategory(restaurantId:string,body:any){
        return this.http.post(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories',body);
    }

    updateMenuCategory(restaurantId:string,menuCategoryId:number,body:any){
        return this.http.put(this.url.adminUrl+'/feed/restaurants/'+restaurantId+'/restaurant_menu_categories/'+menuCategoryId,body)
    }

}