import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient} from '@angular/common/http';
import { environment} from '../environments/environment';
import { RbacService } from './shared/rbac/rbac.service';
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule,RouterModule]
})
export class AppComponent implements OnInit{
  url = environment.adminUrl;
  constructor(private http: HttpClient,private rbacService:RbacService) {}
  ngOnInit(): void {
    //select drop down crash issue resolved
    document.addEventListener('DOMNodeInserted', function () {
      const elements = document.querySelectorAll('[aria-owns]');
      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });
    if(localStorage.getItem('token'))
    this.getAccessFeatures();
  }
  /**
   * get access control from backend
   */
  getAccessFeatures(){
    this.http.get(this.url+'/admin_access_control').subscribe((res:any)=>{
      this.rbacService.setDataInStore('access_control',res.access_control);
    });
  }
}
