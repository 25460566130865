<div id="questionEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/moderation-tool'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Feed</h2>
        </div>
    </div>
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="changeTab($event)">
        <mat-tab label="Feed">
            <form [formGroup]="postForm">
                <div class="post-details" *ngIf="editId != 'new'">
                    <div class="mr-20">
                        <span class="basetitle">User name : </span>
                         {{postDetails?.user_data.username}}
                    </div>
                    <div class="mr-20">
                        <span class="basetitle">User id : </span>
                         {{postDetails?.user_data.id}}
                    </div>
                    <div class="mr-20">
                        <span class="basetitle">Created at : </span>
                         {{postDetails?.created_date}}
                    </div>
                    <div>
                        <span class="basetitle">Sentiment score : </span>
                         {{postDetails?.sentiment_score}}
                    </div>
                </div>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                    <mat-label>Select Feed Type</mat-label>
                    <mat-select placeholder="Select Post Type" formControlName="post_type" (selectionChange)="postTypeChange($event)">
                        <mat-option value="question">Question</mat-option>
                        <mat-option value="simple_post">Thread (Post)</mat-option>
                        <mat-option value="list">List</mat-option>
                    </mat-select>
                    <mat-error *ngIf="postForm.get('post_type').invalid">Required</mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="postForm.get('body') && postForm.get('post_type').value !== 'simple_post' " subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  mb-20">
                    <mat-label>Enter Title</mat-label>
                    <textarea matInput placeholder="Enter Title" formControlName="body"></textarea>
                    <mat-error *ngIf="postForm.get('body').hasError('required')">Required</mat-error>
                    <mat-error *ngIf="postForm.controls['body'].hasError('maxlength')">Maximum {{postForm.controls['body'].errors.maxlength.requiredLength}} char allowed.</mat-error>
                </mat-form-field>
                <div class="post-container">
                    <div [ngStyle]="postForm.get('post_type').value === 'simple_post'?{'display': 'block'} : {'display': 'none'}">
                        <div 
                            class="editable-div"
                            contenteditable="true"
                            placeholder="Use @ to trigger mention"
                            [mention]="mentions | async" 
                            [mentionConfig]="mentionConfig"
                            [mentionListTemplate]="mentionListTemplate" 
                            (searchTerm)="triggerSearch($event)"
                            (input)="editComment()"
                            #mentionDiv
                        >
                        </div>
                        <mat-error  *ngIf="postForm?.get('body')?.hasError('required')">Required</mat-error>
                        <mat-error *ngIf="postForm?.controls['body']?.hasError('maxlength')">Maximum {{postForm.controls['body'].errors?.maxlength?.requiredLength}} char allowed.</mat-error>
                    </div>
                    <div *ngIf="postForm.get('restaurant_ad_hoc_tags') && postForm.get('post_type').value === 'simple_post'" formGroupName="restaurant_ad_hoc_tags">
                        <mat-list *ngIf="selectedMentions?.length" class="item-list mb-20">
                            <mat-list-item class="item" *ngFor="let entry of selectedMentions">
                                @{{ entry.name }} ({{ entry.restaurant_id }})
                                <div class="mb-20 mt-10" *ngIf="postForm.get('restaurant_ad_hoc_tags').get([entry.restaurant_id])">
                                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                                        <mat-label>Tags</mat-label>
                                        <mat-chip-grid #chipGrid aria-label="Tags selection">
                                            <mat-chip-row *ngFor="let restoTag of selectedTags[entry.restaurant_id]" (removed)="removeRestoTag(restoTag,entry.restaurant_id)">
                                                {{ restoTag }}
                                                <button matChipRemove [attr.aria-label]="'remove ' + restoTag">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                            </mat-chip-row>
                                        </mat-chip-grid>
                                        <input placeholder="Start typing..." #restaurantAdHocTags [formControlName]="entry.restaurant_id" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addRestoTag($event,entry.restaurant_id)" />
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRestoTag($event,entry.restaurant_id)">
                                            <mat-option *ngFor="let tag of listTag.restaurant_ad_hoc" [value]="tag">
                                                {{ tag }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                            </mat-list-item>
                            
                        </mat-list>
                    </div>
                </div>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  mb-20" *ngIf="editId == 'new' && postForm.get('user_id')">
                    <mat-label>Select User</mat-label>
                    <mat-select placeholder="Select User" formControlName="user_id">
                        <mat-option *ngFor="let user of botUsers" [value]="user.id">{{ user.fname }} {{ user.lname }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="postForm.get('user_id').invalid">Required</mat-error>
                </mat-form-field>

                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100  mb-20" *ngIf="editId != 'new'">
                    <mat-label>Change Status</mat-label>
                    <mat-select placeholder="Select User" formControlName="status">
                        <mat-option value="to_review" disabled>To Review</mat-option>
                        <mat-option value="approved">Approved</mat-option>
                        <mat-option value="rejected">Rejected</mat-option>
                    </mat-select>
                    <mat-error *ngIf="postForm.get('status').invalid">Required</mat-error>
                </mat-form-field>

                <!-- Created At -->
                <mat-form-field *ngIf="editId !== 'new'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                    <mat-label>Created At</mat-label>
                    <input  matInput type="datetime-local" (focus)="datetime2.showPicker()" #datetime2 placeholder="Created At" formControlName="created_at" />
                    <mat-error *ngIf="postForm.get('created_at').invalid">Required</mat-error>
                </mat-form-field>

                <!-- tags  -->
                <div class="mb-20" *ngIf="postForm.get('tag_names')">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                        <mat-label>Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Fruit selection">
                            <mat-chip-row *ngFor="let simpleTag of selectedTags.tags" (removed)="remove(simpleTag,'tags','tag_names')">
                                {{ simpleTag }}
                                <button matChipRemove [attr.aria-label]="'remove ' + simpleTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Start typing..." #tags formControlName="tag_names" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event,'tags','tag_names')" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,'tags','tags','tag_names')">
                            <mat-option *ngFor="let tag of listTag.tags" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="postForm.get('tag_names').errors?.maxTag ">Maximum 5 tags allowed.</mat-error>
                </div>

                <!-- Cohort  -->
                <div class="mb-20" *ngIf="postForm.get('category_tag_names')">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                        <mat-label>Cohort Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="cohort tag selection">
                            <mat-chip-row *ngFor="let cohortTag of selectedTags.cohort" (removed)="remove(cohortTag,'cohort','category_tag_names')">
                                {{ cohortTag }}
                                <button matChipRemove [attr.aria-label]="'remove ' + cohortTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Start typing..." #cohort formControlName="category_tag_names" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event,'cohort','category_tag_names')" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,'cohort','cohort','category_tag_names')">
                            <mat-option *ngFor="let tag of listTag.cohort" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="postForm.get('category_tag_names').errors?.maxTag">Maximum 5 cohort tags allowed.</mat-error>
                </div>

                <!-- Cuisine -->
                <div class="mb-20" *ngIf="postForm.get('cuisines_tag_names')">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                        <mat-label>Cuisine Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="cohort tag selection">
                            <mat-chip-row *ngFor="let cuisineTag of selectedTags.cuisine" (removed)="remove(cuisineTag,'cuisine','category_tag_names')">
                                {{ cuisineTag }}
                                <button matChipRemove [attr.aria-label]="'remove ' + cuisineTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Start typing..." #cuisine formControlName="cuisines_tag_names" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event,'cuisine','cuisines_tag_names')" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,'cuisine','cuisine','cuisines_tag_names')">
                            <mat-option *ngFor="let tag of listTag.cuisine" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="postForm.get('cuisines_tag_names').errors?.maxTag ">Maximum 5 cohort tags allowed.</mat-error>
                </div>

                <!-- Ad-hoc -->
                <div class="mb-20" *ngIf="postForm.get('cuisines_tag_names')">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                        <mat-label>Ad hoc Tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="adhoc tag selection">
                            <mat-chip-row *ngFor="let adhocTag of selectedTags.adhoc" (removed)="remove(adhocTag,'adhoc','ad_hoc_tag_names')">
                                {{ adhocTag }}
                                <button matChipRemove [attr.aria-label]="'remove ' + adhocTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Start typing..." #adhoc formControlName="ad_hoc_tag_names" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event,'adhoc','ad_hoc_tag_names')" />
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,'adhoc','adhoc','ad_hoc_tag_names')">
                            <mat-option *ngFor="let tag of listTag.adhoc" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="postForm.get('ad_hoc_tag_names').errors?.maxTag ">Maximum 5 adhoc tags allowed.</mat-error>
                </div>

                <div class="mb-20" *ngIf="postForm.get('file')">
                    <input formControlName="file" hidden #file type="file" accept="video/*,image/*" (change)="onFileChange($event)">
                    <div class="all-media mb-20">
                        <div class="upload-media" *ngFor="let files of uploadFiles.uploadedData;let i = index;" (click)="viewImage(i, 'old', files)">
                            <button class="btn-thrive_icon delete-icon mt-20" mat-icon-button [matTooltip]="'Delete media'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="deleteMedia('old',i)">
                                <mat-icon class="mr-0 ">delete_outline</mat-icon>
                            </button>

                            <img *ngIf="files.media_type === 'image'" [src]="files.media_url" />

                            <video *ngIf="files.media_type === 'video'" width="100">
                                <source [src]="files.media_url">
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div class="upload-media" *ngFor="let files of uploadFiles.filesBase64;let i = index;" (click)="viewImage(i, 'new', files)">
                            <button class="btn-thrive_icon delete-icon mt-20" mat-icon-button [matTooltip]="'Delete media'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="deleteMedia('new',i)">
                                <mat-icon class="mr-0 ">delete_outline</mat-icon>
                            </button>
                            <img *ngIf="files.match('data:image')" [src]="files" />

                            <video *ngIf="files.match('data:video')" width="100">
                                <source [src]="files">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div class="upload-media cursur-pointer" (click)="file.click()">
                            <mat-icon>photo_camera</mat-icon>
                            <span>Add media </span>
                        </div>
                    </div>
                </div>
                <mat-form-field *ngIf="postForm.get('post_type').value === 'list'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                    <mat-label>Search for restaurants</mat-label>
                    <mat-icon matSuffix>search</mat-icon>
                    <input matInput placeholder="Search for restaurants" [formControl]="searchResto" />
                </mat-form-field>
                <mat-error class="mb-20" *ngIf="restoNotFound">Restaurant not found!</mat-error>
                <mat-error class="mb-20" *ngIf="selectedRestoControl?.invalid">Select at least one restaurant.</mat-error>
                <div class="show-search_resto mb-20" *ngIf="restoList?.length">
                    <div class="thrive-card no-hover" *ngFor="let resto of restoList">
                        <div class="resto-list">
                            <div>
                                <div class="basetitle">{{resto?.name}}</div>
                                <div class="basetitle normal">{{resto?.locality}}</div>
                            </div>

                            <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}" [(ngModel)]="resto['checked']" (change)="selectResto($event,resto)"></mat-checkbox>

                        </div>
                        <mat-form-field *ngIf="resto?.checked && resto.merchant_id" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20 mt-20">
                            <mat-label>Select menu</mat-label>
                            <mat-chip-grid #chipGrid aria-label="Menu selection">
                                <mat-chip-row *ngFor="let item of resto.selected_items" (removed)="removeItem(item,resto)">
                                    {{ item?.item_name }}
                                    <button matChipRemove [attr.aria-label]="'remove ' + item">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                            </mat-chip-grid>
                            <input placeholder="Start typing..." #restoInput [formControl]="resto.items_search" [matChipInputFor]="chipGrid" [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItemsFn($event,resto,restoInput)">
                            <ng-container *ngFor="let items of resto.items">
                                <mat-option  *ngIf="showValue(items,resto)" [value]="items">
                                   <span > {{items?.item_name}} </span>
                                </mat-option>
                            </ng-container>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <mat-slide-toggle class="w-100" color="primary" formControlName="is_pinned">Pinned</mat-slide-toggle>
            </form>
        </mat-tab>

        <mat-tab label="Comments" *ngIf="editId !== 'new'">
            <div class="pt-20" id="commentsTable">
                <div class="title pl-20 pr-20">
                    <h2>{{ resultsLengthComments }} Comments</h2>
                    <button class="btn-thrive_primary" accessControl moduleType="moderation_tool" mat-button (click)="addComment()">
                        <mat-icon>add</mat-icon>
                        <span>New</span>
                    </button>
                </div>
                <tn-table
                          [title]="'Comments'"
                          [data]="dataComments"
                          [columns]="columnsComments"
                          [desktopDisplayedColumns]="desktopDisplayedColumnsComments"
                          [mobileDisplayedColumns]="mobileDisplayedColumnsComments"
                          [resultsLength]="resultsLengthComments"
                          [hidePageSize]="true"
                          [pageSize]="pageSizeComments"
                          [specialButton]="approveButton"
                          [mobileTemplate]="mobileComments"
                          (changePage)="changePage($event)"
                          (rowClick)="commentEdit($event)">
                </tn-table>
            </div>
        </mat-tab>
        <mat-tab label="Recommendations" *ngIf="editId !== 'new' && postForm.get('post_type').value === 'question'">
            <div id="recommendationsTable">
                <tn-table
                          [title]="'Recommendations'"
                          [data]="dataRecommendations"
                          [columns]="columnsRecommendations"
                          [desktopDisplayedColumns]="desktopDisplayedColumnsRecommendations"
                          [mobileDisplayedColumns]="mobileDisplayedColumnsRecommendations"
                          [resultsLength]="resultsLengthRecommendations"
                          [hidePageSize]="true"
                          [specialHTML]="orderModeTemplate"
                          [mobileTemplate]="mobileRecommendations"
                          [pageSize]="pageSizeRecommendations"
                          (changePage)="changePage($event)">
                </tn-table>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="action-footer" *ngIf="selectedIndex == 0">
        <div class="footer-container">
            <button class="btn-thrive_primary" accessControl moduleType="moderation_tool" mat-button (click)="submitPost()" [disabled]="!postForm.valid || submitted">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #orderModeTemplate let-option>
    <span *ngIf="option.row.merchant_id && option.row.available_on_consumer_app">Consumer App</span>
    <span *ngIf="option.row.merchant_id && !option.row.available_on_consumer_app">Microsite</span>
    <span *ngIf="!option.row.merchant_id">Not On Thrive</span>
</ng-template>

<ng-template #mobileComments let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Status</td>
            <td>{{ option.row.status | titlecase }}</td>
        </tr>
        <tr>
            <td></td>
            <td>
                <button class="btn-thrive_icon p-0" accessControl moduleType="moderation_tool" mat-icon-button (click)="approve(option.row); $event.stopPropagation()" [matTooltip]="'Reject Comment'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon class="mr-0">change_circle</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #mobileRecommendations let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Votes</td>
            <td>{{ option.row.total_upvotes }}</td>
        </tr>
        <tr>
            <td>Ordering Mode</td>
            <td>
                <span *ngIf="option.row.merchant_id && option.row.available_on_consumer_app">Consumer App</span>
                <span *ngIf="option.row.merchant_id && !option.row.available_on_consumer_app">Microsite</span>
                <span *ngIf="!option.row.merchant_id">Not On Thrive</span>
            </td>
        </tr>
    </table>
</ng-template>

<ng-template #approveButton let-option>
    <button class="btn-thrive_icon p-0" accessControl moduleType="moderation_tool" mat-icon-button (click)="approve(option.row); $event.stopPropagation()" [matTooltip]="'Reject Comment'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon class="mr-0">change_circle</mat-icon>
    </button>
</ng-template>

<ng-template #mentionListTemplate let-item="item">
    <span>{{item.name + ', ' + item.locality}}</span>
</ng-template>