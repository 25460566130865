<div id="restaurants" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Restaurants</h2>
            <button
            accessControl
            moduleType="moderation_tool"  
            class="btn-thrive_primary"
             mat-button routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    
    <div class="filters">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose filter option</mat-label>
            <mat-select [(value)]="filterCategory" (selectionChange)="clearText()">
                <mat-option value="id">Id</mat-option>
                <mat-option value="merchant_id">Merchant id</mat-option>
                <mat-option value="query">Name</mat-option>
                <mat-option value="locality">Locality</mat-option>
                <!-- <mat-option value="image_file_name">Image name</mat-option> -->
                <mat-option value="created_at">Created at</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filterCategory&&filterCategory !== 'created_at'">
            <mat-label>Enter here</mat-label>
            <input matInput [(ngModel)]="search" [placeholder]="'Enter here'" />
        </mat-form-field>
    
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filterCategory == 'created_at'">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start (DD-MM-YYYY)" />
                <input matEndDate formControlName="end" placeholder="End (DD-MM-YYYY)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    
        <button class="btn-thrive_primary" 
            matTooltipPosition="below" 
            matTooltipClass="thrive-tooltip" 
            (click)="applyFilters(0,pageSize)" 
            [disabled]="!search&&filterCategory !== 'created_at'" *ngIf="filterCategory">
            <span>Apply</span>
        </button>
        <button class="btn-thrive_primary" 
            matTooltipPosition="below" 
            matTooltipClass="thrive-tooltip" 
            (click)="clearFilter()" 
            [disabled]="!search && !(range?.controls?.start?.value&&range?.controls?.end?.value)" 
            *ngIf="filterCategory">
            <span>Clear filter</span>
        </button>
    </div>
    <div class="table-overflowdiv">
        <tn-table
            [title]="'Restaurants'"
            [data]="data"
            [columns]="columns"
            [desktopDisplayedColumns]="desktopDisplayedColumns"
            [mobileDisplayedColumns]="mobileDisplayedColumns"
            [resultsLength]="resultsLength"
            [pageSize]="pageSize"
            [mobileTemplate]="mobileTemplate"
            [pageIndex]="page"
            [hidePageSize]="true"
            (changePage)="changePage($event)"
            (rowClick)="navigateTo($event)"
        >
        </tn-table>
    </div>
</div>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Id</td>
            <td>{{ option.row.id }}</td>
        </tr>
        <tr>
            <td>Merchant Id</td>
            <td>{{ option.row.merchant_id }}</td>
        </tr>
        <tr>
            <td>Latitude</td>
            <td>{{ option.row.lat }}</td>
        </tr>
        <tr>
            <td>Longitude</td>
            <td>{{ option.row.lon }}</td>
        </tr>
        <tr>
            <td>Locality</td>
            <td>{{ option.row.locality }}</td>
        </tr>
        <tr>
            <td>Address</td>
            <td>{{ option.row.full_address }}</td>
        </tr>
        <tr>
            <td>Image</td>
            <td>{{ option.row.image_url }}</td>
        </tr>
        <tr>
            <td>Image name</td>
            <td>{{ option.row.image_file_name }}</td>
        </tr>
        <tr>
            <td>Image type</td>
            <td>{{ option.row.image_content_type }}</td>
        </tr>
        <tr>
            <td>Cuisines</td>
            <td>{{ option.row.cuisines }}</td>
        </tr>
        <tr>
            <td>Cost for two</td>
            <td>{{ option.row.cost_for_two }}</td>
        </tr>
        <tr>
            <td>Ad hoc tag</td>
            <td>{{ option.row.ad_hoc_tag }}</td>
        </tr>
        <tr>
            <td>Created at</td>
            <td>{{ option.row.created_at }}</td>
        </tr>
    </table>
</ng-template>

