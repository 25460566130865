<div  class="mb-30" *ngFor="let custom of customMsgArray;let i = index;let isLast = last;"> 
    <div class="custom-message mb-20" *ngIf="custom.is_enable" >
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Restaurant Type</mat-label>
            <mat-select required #inputValue="ngModel" name="custom_type{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="custom.type" (selectionChange)="onChange(custom,i)">
                <mat-option value="" disabled selected hidden>Message Type</mat-option>
                <mat-option *ngFor="let msg of customMessageTypes;" [disabled]="selectedMsgTypes.includes(msg.type)" [value]="msg.type">{{msg.type}}</mat-option>
            </mat-select>
            <mat-error *ngIf="inputValue.invalid">Required</mat-error>
        </mat-form-field>

        <button mat-icon-button class="btn-thrive_icon" *ngIf="showRemoveBtn() > 1" (click)="removeCustomControl(custom,i)"> 
            <mat-icon>delete_outline</mat-icon>
        </button>
        
        <button mat-icon-button class="btn-thrive_icon" (click)="addCustomControl()" *ngIf="(showRemoveBtn() < customMessageTypes.length) && (custom.count === showRemoveBtn())">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div *ngIf="custom.is_enable" class="mb-20">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Message Content</mat-label>
            <textarea required matInput #messagecontents="ngModel" rows="2" maxlength="160" [ngModelOptions]="{standalone: true}" name="custom_message{{i}}" [(ngModel)]="custom.message" [class.invalid]="custom.isUnicode" (input)="customMessageChanged(custom)"></textarea>
            <mat-error *ngIf="messagecontents.invalid">Required</mat-error>
        </mat-form-field>
        <div *ngIf="custom?.tags?.length" class="tag-area">
            <div class="tag" *ngFor="let tag of custom.tags" (click)="addTag(custom,tag)">{{tag}}</div>
        </div>
    </div>
</div>