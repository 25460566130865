import { Directive, Input, OnInit, ElementRef } from "@angular/core";
import { RbacService } from '../../shared/rbac/rbac.service';
@Directive({
  selector: "[accessControl]",
  standalone:true
})
export class AccessControlDirective implements OnInit {
  @Input("moduleType") moduleType: string;
  @Input("accessType") accessType: string;
  @Input('navbar') navbar:boolean = false;
  constructor(private elementRef: ElementRef,private rbacService:RbacService) {}

  ngOnInit() {
    this.checkAccess();
  }
   checkAccess() {
    let access_control:any = this.rbacService.getDataFromStore('access_control');
    if(access_control){
    let module: any = access_control.find(access => access.feature === this.moduleType)
      if(module && !module.write && module.read && !this.navbar){
        this.elementRef.nativeElement.classList.add('disabled');
      } else if(module && !module.write && !module.read && this.navbar){
        this.elementRef.nativeElement.style.display = 'none'
      }
    }
  }
}