<div id="Admin-User" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Admin</h2>
            <button class="btn-thrive_primary"
                accessControl
                moduleType="admin_users" 
                mat-button 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <div style="overflow-x: auto">
        <tn-table
            [title]="'Admin Users'"
            [data]="data"
            [columns]="columns"
            [desktopDisplayedColumns]="desktopDisplayedColumns"
            [mobileDisplayedColumns]="mobileDisplayedColumns"
            [resultsLength]="resultsLength"
            [pageSize]="pageSize"
            [mobileTemplate]="mobileTemplate"
            (rowClick)="navigateTo($event)"
            (actionClick)="actionClicked($event)"
            [featureAccessControl]="'admin_users'"
        >
        </tn-table>
    </div>
    <ng-template #mobileTemplate let-option>
        <table class="thrive-table_mobile">
            <tr>
                <td>Mobile</td>
                <td>{{ option.row.mobile }}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>{{ option.row.email }}</td>
            </tr>
            <tr>
                <td>Team</td>
                <td>{{ option.row.team }}</td>
            </tr>
            <tr>
                <td>Created At</td>
                <td>{{ option.row.created_at }}</td>
            </tr>
            <tr>
                <td>Updated At</td>
                <td>{{ option.row.updated_at }}</td>
            </tr>
            <tr>
                <td>Role</td>
                <td>{{ option.row.role_title }}</td>
            </tr>
            <tr>
                <td>Status</td>
                <td>
                    <mat-slide-toggle
                        accessControl
                        moduleType="admin_users"  
                        color="primary" 
                        (click)="$event.stopPropagation()" 
                        (change)="actionClicked({row: option.row, checked: $event.checked, action: 'status'})" 
                        [checked]="option.row.status">
                    </mat-slide-toggle>
                </td>
            </tr>
        </table>
    </ng-template>
</div>
