export const menuItems: any[] = [
    // { icon: 'home', link: '/overview', name: 'Home', display: true },
    {icon: 'home', link: 'merchant', name: 'Merchant', display: true, access_control: 'merchant_master'},
    {icon: 'local_shipping', link: 'delivery-radius', name: 'Delivery Radius', display: true, access_control: 'delivery_radius'},
    {icon: 'card_giftcard', link:'bulk-offer-upload', name:'Bulk Offer Upload', display:true, access_control: 'merchant_master'},
    {
        icon: 'groups_3',
        name: 'Moderation Tool',
        display: true,
        access_control: 'moderation_tool',
        show_drop_down: false,
        sub_menu: [
            {link: '/moderation-tool', name: 'Post', display: true},
            {link: '/moderation-tool/comment', name: 'Comment', display: true},
            {link: '/custom-menu', name: 'Custom Menu', display: true},
            {link: '/tags', name: 'Tags', display: true},
            {link: '/restaurants', name: 'Restaurant', display: true},
            {link: '/user-profile', name: 'User Profile', display: true},
        ]
    },
    {icon: 'wallpaper', link: 'app-banner', name: 'App Banners', display: true, access_control: 'app_banners'},
    {icon: 'category', link: 'app-category', name: 'App Category', display: true, access_control: 'app_category'},
    {icon: 'info', link: 'app-version', name: 'App Version', display: true, access_control: 'app_version'},
    {icon: 'person', link: 'admin-user', name: 'Admin user', display: true, access_control: 'admin_users'},
    {icon: 'folder_managed', link: 'source-management', name: 'Source Management', display: true, access_control: 'source_management'},
    {icon: 'group', link: 'merchant-users', name: 'Merchant users', display: true, access_control: 'merchant_users'},
    {
        icon: 'view_timeline',
        name: 'Merchant Hygiene',
        display: true,
        access_control: 'merchant_hygiene',
        show_drop_down: false,
        sub_menu: [
            {link: '/merchant-hygiene/consumer', name: 'Consumer App', display: true},
            {link: '/merchant-hygiene/direct', name: 'Microsite', display: true},
            {link: '/merchant-hygiene/ondc', name: 'ONDC', display: true}
        ]
    },
    {icon: 'workspaces', link: 'brand-collaboration', name: 'Brand Collaboration', display: true, access_control: 'brand_collaboration'},
    {icon: 'request_quote', link: 'thrive-pricing', name: 'Thrive pricing', display: true, access_control: 'thrive_pricing'},
    {icon: 'label', link: 'thrive-tags', name: 'Thrive tags', display: true, access_control: 'thrive_tags'},
    // { icon: 'home_work', link: 'business', name: 'Business', display: true },
    // { icon: 'contact_support', link: 'inquiries', name: 'Inquiries', display: true },
    {icon: 'logout', name: 'Logout', display: true}
];
