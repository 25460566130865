<div id="restaurantEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/restaurants'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Restaurant</h2>
        </div>
    </div>
    <form [formGroup]="restaurantForm">
        <mat-accordion class="grid-item">
            <!-- account details -->
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" >
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Restaurant details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="form-controls mb-16 center">
                    <p class="basetitle">Select type of merchant<sup>*</sup></p>
                    <div>
                        <mat-radio-group [ngClass]="{'disabled':restaurantId}" formControlName="role" color="primary" (change)="onRestaurantTypeChange($event?.value)">
                            <mat-radio-button value="owner">Chain</mat-radio-button>
                            <mat-radio-button value="member" >Merchant</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="restaurantForm.get('role').invalid">Required</mat-error>
                    </div>
                </div>
                <div *ngIf="restaurantForm.get('role')?.value === 'member'" >
                    <div class="form-controls center mb-20" >
                        <p class="basetitle">Belongs to a chain?<sup>*</sup></p>
                        <div>
                            <mat-radio-group [ngClass]="{'disabled':(restaurantInfo?.restaurant_chain | json) !== '{}' && restaurantId}" formControlName="part_of_chain" color="primary" >
                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                <mat-radio-button value="no">No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="restaurantForm.get('part_of_chain').invalid">Required</mat-error>
                        </div>
                    </div>
                </div>
                <!-- Select and search merchant -->
                <div [ngClass]="{'disabled':(restaurantInfo?.restaurant_chain | json) !== '{}' && restaurantId}">
                    <mat-form-field *ngIf="restaurantForm.get('part_of_chain')?.value === 'yes' && restaurantForm.get('role')?.value === 'member'"  subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20" [ngClass]="{'disabled':disablePartOfChain}">
                        <mat-label>Select chain</mat-label>
                        <mat-select  formControlName="chain_id" (selectionChange)="onSelectChain($event)">
                            <mat-option>
                                <ngx-mat-select-search
                                                    placeholderLabel="Search"
                                                    noEntriesFoundLabel="Store not found"
                                                    ngDefaultControl
                                                    [formControl]="search">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="chain.chain_restaurant_id" *ngFor="let chain of chainList">{{chain.name}} <span *ngIf="chain.restaurant_id">({{chain.restaurant_id}})</span></mat-option>
                        </mat-select>
                        <mat-error *ngIf="restaurantForm.get('chain_id')?.invalid">Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-controls">
                    <!-- Merchant id -->
                    <mat-form-field *ngIf="restaurantForm.get('role')?.value === 'member'" id="merchantId" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Merchant Id</mat-label>
                        <input matInput placeholder="Enter merchant id" formControlName="merchant_id" />
                        <mat-error *ngIf="restaurantForm.get('merchant_id').hasError('pattern')">Digits only</mat-error>
                    </mat-form-field>
                    <!-- Place id -->
                    <mat-form-field  id="googlePlaceId" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Place Id</mat-label>
                        <input matInput placeholder="Enter place id" formControlName="google_place_id" />
                        <mat-error *ngIf="restaurantForm.get('google_place_id').hasError('required') && restaurantForm.get('google_place_id').touched">Required</mat-error>
                    </mat-form-field>
                    <!-- Name -->
                    <mat-form-field id="name" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Enter restaurant name" formControlName="name" />
                        <mat-error *ngIf="restaurantForm.get('name').hasError('required') && restaurantForm.get('name').touched">Required*</mat-error>
                    </mat-form-field>
                    <!-- Latitude -->
                    <mat-form-field *ngIf="restaurantForm.get('role')?.value === 'member'"  id="lat" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Latitude</mat-label>
                        <input matInput placeholder="Enter latitude" formControlName="lat" />
                        <mat-error *ngIf="restaurantForm.get('lat').hasError('required') && restaurantForm.get('lat').touched">Required</mat-error>
                        <mat-error *ngIf="restaurantForm.get('lat').hasError('pattern')">Enter correct latitude</mat-error>
                    </mat-form-field>
                    <!-- Longitude -->
                    <mat-form-field *ngIf="restaurantForm.get('role')?.value === 'member'" id="lon" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Longitude</mat-label>
                        <input matInput placeholder="Enter longitude" formControlName="lon" />
                        <mat-error *ngIf="restaurantForm.get('lon').hasError('required') && restaurantForm.get('lon').touched">Required</mat-error>
                        <mat-error *ngIf="restaurantForm.get('lon').hasError('pattern')">Enter correct longitude</mat-error>
                    </mat-form-field>
                    <!-- Locality -->
                    <mat-form-field *ngIf="restaurantForm.get('role')?.value === 'member'" id="locality" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                        <mat-label>Locality</mat-label>
                        <input matInput placeholder="Enter locality" formControlName="locality" />
                        <mat-error *ngIf="restaurantForm.get('locality').hasError('required') && restaurantForm.get('locality').touched">Required</mat-error>
                        <mat-error *ngIf="restaurantForm.get('locality').hasError('pattern')">Enter correct locality</mat-error>
                    </mat-form-field>
                    <!-- Address -->
                    <mat-form-field *ngIf="restaurantForm.get('role')?.value === 'member'" id="fullAddress" subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="grid-item">
                        <mat-label>Address</mat-label>
                        <textarea matInput placeholder="Enter address" formControlName="full_address"></textarea>
                        <mat-error *ngIf="restaurantForm.get('full_address').hasError('required') && restaurantForm.get('full_address').touched">Required</mat-error>
                        <mat-error *ngIf="restaurantForm.get('full_address').hasError('pattern')">Enter correct address</mat-error>
                    </mat-form-field>
                    <!-- Cuisines -->
                    <div class="mentions">
                        <mat-form-field class="w-100" id="cuisines" subscriptSizing="dynamic" appearance="outline" floatLabel="never">
                            <mat-label>Cuisines</mat-label>
                            <mat-select formControlName="cuisines" multiple>
                                <mat-option> 
                                    <ngx-mat-select-search 
                                    placeholderLabel="Search" 
                                    noEntriesFoundLabel="Cuisine not found" 
                                    ngDefaultControl 
                                    [formControl]="searchCuisine">
                                </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let type of cuisines | datafilter : searchCuisine.value :['name']" [value]="type.name">{{ type.name }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="restaurantForm.get('restaurantForm.cuisines')?.invalid">Required</mat-error>
                        </mat-form-field>
                        <!-- mentions -->
                        <div class="thrive-toast_info" *ngIf="dataCuisine.length">
                            User inputs available for approval. <a class="link" (click)="openCuisine = !openCuisine"> {{openCuisine?'Hide':'Show'}} details.</a> 
                        </div>
                        <div class="show-table" *ngIf="openCuisine">
                            <tn-table 
                            [title]="''"
                            [data]="dataCuisine"
                            [columns]="columnsCuisine"
                            [desktopDisplayedColumns]="desktopDisplayedColumnsCuisine"
                            [mobileDisplayedColumns]="mobileDisplayedColumnsCuisine"
                           >
                            </tn-table>
                        </div>
                    </div>
                    <!-- Cost for 2 -->
                    <div class="mentions">
                        <mat-form-field id="costForTwo" subscriptSizing="dynamic" appearance="outline" floatLabel="never">
                            <mat-label>Average cost for 2</mat-label>
                            <input matInput placeholder="Enter average cost for 2" formControlName="cost_for_two" />
                            <mat-error *ngIf="restaurantForm.get('cost_for_two')?.errors?.pattern">Digits only</mat-error>
                        </mat-form-field>
                        <!-- mentions -->
                        <div class="thrive-toast_info" *ngIf="dataAvg.length">
                            User inputs available for approval. <a class="link" (click)="openAvg = !openAvg"> {{openAvg?'Hide':'Show'}} details.</a> 
                        </div>
                        <div class="show-table" *ngIf="openAvg">
                            <tn-table 
                                    [title]="''"
                                    [data]="dataAvg"
                                    [columns]="columnsAvg"
                                    [desktopDisplayedColumns]="desktopDisplayedColumnsAvg"
                                    [mobileDisplayedColumns]="mobileDisplayedColumnsAvg">
                            </tn-table>
                        </div>
                    </div>
                    <!-- Known for start -->
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" >
                        <mat-label>Known for</mat-label>
                        <mat-chip-grid #knownFor aria-label="known for tag selection">
                            <mat-chip-row *ngFor="let knownForTag of selectedKnownForTags" (removed)="removeKnownForTag(knownForTag)">
                                {{ knownForTag }}
                                <button matChipRemove [attr.aria-label]="removeknownForTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Search tags start typing..." 
                            #known 
                            [formControl]="searchForKnownForTag" 
                            [matChipInputFor]="knownFor" 
                            [matAutocomplete]="autoKnow" 
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                            (matChipInputTokenEnd)="addknownForTag($event)" />
                        <mat-autocomplete #autoKnow="matAutocomplete" 
                            (optionSelected)="selectKnownForTag($event)">
                            <mat-option *ngFor="let tag of knownForTags" [value]="tag">
                                {{ tag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                     <!-- Ad hoc tags -->
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" >
                        <mat-label>Ad hoc tags</mat-label>
                        <mat-chip-grid #chipGrid aria-label="adhoc tag selection">
                            <mat-chip-row *ngFor="let adhocTag of selectedAdhocTags" (removed)="removeAdhocTag(adhocTag)">
                                {{ adhocTag }}
                                <button matChipRemove [attr.aria-label]="'remove ' + adhocTag">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                        </mat-chip-grid>
                        <input placeholder="Search tags start typing..." 
                            #adhoc 
                            [formControl]="searchForAdhocTag" 
                            [matChipInputFor]="chipGrid" 
                            [matAutocomplete]="auto" 
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                            (matChipInputTokenEnd)="addAdhocTag($event)" />
                        <mat-autocomplete #auto="matAutocomplete" 
                            (optionSelected)="selectAdhocTag($event)">
                            <mat-option *ngFor="let addTag of adhocTags" [value]="addTag">
                                {{ addTag }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" *ngIf="restaurantForm.get('role')?.value === 'owner'">
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Outlets</mat-panel-title>
                </mat-expansion-panel-header>
                <app-outlets [outlets]="outlets" [cuisines]="cuisines" (selectOutlet)="selectOutlet($event)"></app-outlets>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="dataContent.length" [expanded]="step === 3" (opened)="setStep(3)" >
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Restaurant content</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="grid-item show-table">
                    <tn-table 
                            [title]="''"
                            [data]="dataContent"
                            [columns]="columnsContent"
                            [desktopDisplayedColumns]="desktopDisplayedColumnsContent"
                            [mobileDisplayedColumns]="mobileDisplayedColumnsContent"
                            [specialHTML]="openLink"
                            [mobileTemplate]="mobileTemplate"
                            >
                    </tn-table>
                </div>
                <ng-template #openLink let-option>
                    <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openLinkFn(option.row); $event.stopPropagation()">
                        <mat-icon class="mr-0">link</mat-icon>
                    </button>
                </ng-template>
                <ng-template #mobileTemplate let-option>
                    <table class="thrive-table_mobile">
                        <tr>
                            <td>Type</td>
                            <td>
                                {{option.row.post_type}}
                            </td>
                        </tr>
                        <tr>
                            <td>Link</td>
                            <td>
                                <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openLinkFn(option.row);$event.stopPropagation()">
                                    <mat-icon class="mr-0">link</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" *ngIf="restaurantId">
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Menu images</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="multiple-image-row" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                    <div class="multiple-image-size" cdkDrag *ngFor="let menu of menuCards">
                        <mat-icon (click)="removeMenuCard(menu)">cancel</mat-icon>
                        <div class="total-menu pretitle">+{{menu?.menu_count}}</div>
                        <div class="menu-status pretitle {{menu?.status === 'available' ? 'published' : menu?.status}}">{{menu?.status === 'available' ? 'published' : menu?.status}}</div>
                        <div class="image-size" (click)="addMenuCard(menu)">
                            <img width="120" height="165" [src]="menu?.menu_image" />
                        </div>
                        <p>{{menu?.name}}</p>
                        <button (click)="publish(menu?.restaurant_menu_category_id)" *ngIf="menu.status !== 'available'" class="puslish-btn">{{menu.status === 'draft' && menu.publish_on_app ? 'Republish' : 'Publish'}}</button>
                    </div>
                    <div class="upload-card" (click)="addMenuCard()">
                        <mat-icon color="primary">add</mat-icon>
                        <p class="pretitle">ADD MENU SECTION</p>
                    </div>
                </div>
                <div class="thrive-toast_info mt-20" *ngIf="menuCards.length">
                    <span>Info:</span>
                    <span> You can <span class="bold">click and hold to rearrange the menu sections.</span> The same order of menus will be published on consumer app.</span>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="userUploadedMedias.length &&  restaurantId" [expanded]="step === 5" (opened)="setStep(5)" >
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Restaurant media</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="grid-item">
                    <div class="image-container">
                        <div class="image-box" *ngFor="let media of userUploadedMedias;" (click)="openImageDetails(media)">
                            <div *ngIf="media.user_restaurant.approved && !media.user_restaurant.is_deleted" class="status approved">Approved</div>
                            <div *ngIf="!media.user_restaurant.approved && !media.user_restaurant.is_deleted" class="status pending">Pending</div>
                            <div *ngIf="!media.user_restaurant.approved && media.user_restaurant.is_deleted" class="status rejected">Rejected</div>
                            <img *ngIf="media.user_restaurant.attribute_name === 'photos'" [src]="media.user_restaurant.attribute_value[0]">
                            <video controls [poster]="media.user_restaurant.attribute_value.thumbnail_url" *ngIf="media.user_restaurant.attribute_name === 'video'" [src]="media.user_restaurant.attribute_value.video_url" width="155" height="120"></video>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" >
                <mat-expansion-panel-header>
                    <mat-panel-title class="basetitle">Upload restaurant image</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="grid-item">
                    <image-upload [aspectRatio]="'4:3'" [imageUrl]="restaurantForm.get('image_url')?.value" (uploadImg)="onImageUpload($event)"></image-upload>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button (click)="submit()" accessControl moduleType="moderation_tool" [disabled]="submitted">
                <mat-icon>add</mat-icon>
                <span>{{ restaurantId ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>