import { Component } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ColumnData, TableComponent } from '../shared/table/table.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
import { OnInit } from '@angular/core';
import { FiltersComponent } from './filters/filters.component';
import { TagsService } from './tags.service';
@Component({
  selector: 'app-tags',
  standalone: true,
  imports: [CommonModule, 
    MatDialogModule, 
    MatIconModule, 
    TableComponent, 
    MatButtonModule, 
    RouterModule, 
    MatChipsModule, 
    AccessControlDirective],
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  providers: [DatePipe]
})
export class TagsComponent implements OnInit{
  data: any=[];
  pageSize: number = 10;
  resultsLength: number = 0;
  pageIndex: number = 0;
  columns: ColumnData[] = [];
  desktopDisplayedColumns: string[] = [];
  mobileDisplayedColumns: string[] = [];

  showFilters:boolean = true;
  filters = {
    show_deleted: null,
    tag_type: null,
    name: null,
    tag_category_id: null
  };
  filter_enable: boolean = false;
  selection:any;
  constructor(private router: Router, public dialog: MatDialog,private tagsService:TagsService){}
  ngOnInit() {
    this.getTagList(0);
    this.columns = [
        {
            columnDef: 'name',
            header: 'Name',
            cell: 'name'
        },
        {
            columnDef: 'tag_type',
            header: 'Type',
            cell: 'tag_type'
        },
        {
            columnDef: 'tag_category_name',
            header: 'Category',
            cell: 'tag_category_name'
        }
    ];
    this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
    this.mobileDisplayedColumns = ['name'];
  }
  /**
     * GET API call for fetching all Posts basis some filters
     * @param {number} page Page number for pagination support
     * @param {string} filterType Which type of filter to be applied
     * @param {string} filterValue Value of the filter to apply the filter
     * @param {string} sortType Which type of sort to be applied
     * @param {string} sortBy Order of the sort to apply
     */
   getTagList(page: number) {
    this.tagsService.getTags(page, this.filters).subscribe((response: any) => {
        this.data = response.tags;
        this.resultsLength = response.total;
        this.pageIndex = response.current_page - 1;
    });
  }

  removeFilter(param) {
      this.filters[param] = null;
      this.getTagList(0);
  }

  clearAll() {
      this.filters = {
        show_deleted: null,
        tag_type: null,
        name: null,
        tag_category_id: null
      };
      this.getTagList(0);
  }

  showClearFilter() {
      for (const property in this.filters) {
          if (this.filters[property] !== null) return true;
      }
      return false;
  }

  /**
   * To change the page and get the next array of Posts
   * @param event Event to change the page where you get pageNumber, pageSize and other pagination properties from table.component
   */
  changePage(event) {
      this.getTagList(event.pageIndex);
  }

  /**
   * Apply the selected filter values and get the filtered data from API
   */
  applyFilter() {
      this.getTagList(0);
  }

  /**
   * Navigate to a different page on click
   * @param event Row details from the table
   */
  navigateTo(event) {
    this.router.navigateByUrl('/tags/'+event.id);
  }

  openFilters() {
    const dialogRef = this.dialog.open(FiltersComponent, {
        width: window.innerWidth < 768 ? '80vw' : '40vw',
        panelClass: 'thrive-dialog',
        data: this.filters
    });

    dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
            this.filters = result;
            this.getTagList(0);
        }
    });
  }
}
