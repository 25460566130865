import { Component, OnInit } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon'
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatCheckboxModule} from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import {TagsService } from '../tags.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { DataFilterPipe } from '../../shared/pipes/data-filter.pipe';
export interface categoryIf{
  id:number,
  name:string,
  description:string|null,
  tag_type:string,
  background_image_color: string | null,
  image_url:string
}
@Component({
  selector: 'app-create-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    DataFilterPipe,
    AccessControlDirective
],
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss']
})
export class CreateEditComponent implements OnInit{
  tagForm: FormGroup;
  editId: string;
  public search = new FormControl();
  tagCategoryList:Array<categoryIf> = [];
  tagCategories:Array<categoryIf> = [];
  tagTypes:Array<any> = [];
  constructor(private route: ActivatedRoute, private fb: FormBuilder, private tagService: TagsService, private router: Router) {
      this.editId = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    this.getTagCategory();
    this.createForm();
    this.getTagType();
    if (this.editId !== 'new') {
      this.getTagInfo();
    }
  }

  getTagCategory(){
    this.tagService.getTagCategory().subscribe((response:Array<categoryIf>)=>{
      this.tagCategoryList = response
    })
  }

  getTagType(){
    this.tagService.getTagType().subscribe((response:any)=>{
      this.tagTypes = this.convertObjectToArray(response);
    });
  }

  /**
   * set tag_type value from selected category 
   * @param categoryId selected category id
   */
  categoryChange(categoryId:number){
    const selectedCategory = this.getCategoryById(categoryId);
    this.tagForm.get('tag_type').setValue(selectedCategory.tag_type);
  }

  tagTypeChange(tagType:string){
    this.tagCategories = [...this.tagCategoryList.filter(category => category.tag_type === tagType)];
    this.tagForm.get('tag_category_id').setValue('');
  }

  /**
   * get category object by id
   * @param id selected category id 
   * @returns category object
   */
  
  getCategoryById(id:number) {
    return this.tagCategoryList.find(category => category.id === id);
  }

  // Method to convert object to array of objects
  convertObjectToArray(obj: { [key: string]: number }): Array<{ key: string, value: number }> {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  getTagInfo(){
    this.tagService.getTagInfo(this.editId).subscribe((response:any)=>{
      this.tagCategories = [...this.tagCategoryList.filter(category => category.tag_type === response.tag_type)];
      this.tagForm.patchValue(response);
    });
  }
  createForm() {
      this.tagForm = this.fb.group({
          name:new FormControl('',[Validators.required]),
          status:new FormControl(''),
          tag_type:new FormControl('',[Validators.required]),
          tag_category_id:new FormControl('',[Validators.required])
      });
  }

  submit() {
      if (this.editId !== 'new') {
        this.tagService.updateTag(this.editId,{tag:this.tagForm.value}).subscribe((response:any)=>{
          Swal.fire({title: 'Success', text: 'Tag updated successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
          this.router.navigateByUrl('/tags');
        });
      } else {
        this.tagService.createTag({tag:this.tagForm.value}).subscribe(() => {
            Swal.fire({title: 'Success', text: 'Tag created successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
            this.router.navigateByUrl('/tags');
        },(error) => {});
      }
  }
}
