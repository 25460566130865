import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatIconModule} from '@angular/material/icon';
import {BannerService} from './banner.service';
import {Router, RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {FormControl, FormsModule} from '@angular/forms';
import Swal from 'sweetalert2';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss'],
    standalone: true,
    imports: [CommonModule, 
                MatButtonModule, 
                TableComponent, 
                MatIconModule, 
                RouterModule, 
                MatFormFieldModule, 
                MatSelectModule, 
                FormsModule, 
                MatSlideToggleModule,
                AccessControlDirective],
    providers: [BannerService]
})
export class BannersComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    filter: FormControl = new FormControl('');
    status: string = 'all';

    constructor(private bannerService: BannerService, private router: Router) {}

    ngOnInit() {
        this.getBanners(0, 'all');
        this.columns = [
            {
                columnDef: 'image',
                header: 'Image',
                cell: 'image_url',
                isImage: true
            },
            {
                columnDef: 'title',
                header: 'Title',
                cell: 'title',
                pipe: 'titlecase',
                sticky: true
            },
            {
                columnDef: 'sortOrder',
                header: 'Order',
                cell: 'sort_order'
            },
            {
                columnDef: 'desc',
                header: 'Description',
                cell: 'description'
            },
            {
                columnDef: 'status',
                header: 'Status',
                cell: 'status',
                isToggle: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['image', 'title'];
    }

    getBanners(page, filter) {
        this.bannerService.getBanners(page, filter).subscribe((banners: any) => {
            this.data = banners.banners;
            this.resultsLength = banners.total_results;
        });
    }

    changePage(event) {
        this.getBanners(event.pageIndex, this.status);
    }

    actionClicked(event) {
        const formData = new FormData();
        formData.append('title', event.row.title);
        formData.append('description', event.row.description);
        formData.append('filters', JSON.stringify(event.row.filters));
        formData.append('image', event.row.image_url);
        formData.append('is_clickable', event.row.is_clickable);
        formData.append('sort_order', event.row.sort_order);
        formData.append('status', event.checked);
        if (event.action == 'status') {
            this.bannerService.updateBanner(event.row.id, formData).subscribe(() => {
                Swal.fire({title: 'Status Updated', text: 'Status updated successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.getBanners(0, this.status);
            });
        }
    }

    changeStatus(event) {
        this.getBanners(0, event.value);
    }

    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('app-banner/' + event.id);
    }
}
