<div id="adminUserEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/admin-user'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Admin User</h2>
        </div>
    </div>
    <form [formGroup]="adminForm">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter your name" formControlName="name" />
            <mat-error *ngIf="adminForm.get('name').hasError('required') && adminForm.get('name').touched">Required*</mat-error>
            <mat-error *ngIf="adminForm.get('name').hasError('pattern')">Enter name correctly</mat-error>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Email address</mat-label>
            <input matInput placeholder="Enter your email" formControlName="email" />
            <mat-error *ngIf="adminForm.get('email').hasError('required') && adminForm.get('email').touched">Required*</mat-error>
            <mat-error *ngIf="adminForm.get('email').hasError('pattern')">Enter correct email address</mat-error>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Mobile number</mat-label>
            <input matInput placeholder="Enter your mobile number" formControlName="mobile" />
            <mat-error *ngIf="adminForm.get('mobile').hasError('required') && adminForm.get('mobile').touched">Required*</mat-error>
            <mat-error *ngIf="adminForm.get('mobile').hasError('pattern')">Enter 10 digit phone number</mat-error>
        </mat-form-field>

        <div class="pwd">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 password">
                <mat-label>Password</mat-label>
                <input type="password" matInput placeholder="Enter strong password" formControlName="password_digest" />

                <mat-error *ngIf="adminForm.get('password_digest').hasError('required') && adminForm.get('password_digest').touched">Required*</mat-error>
            </mat-form-field>

            <button *ngIf="editId !== 'new'" class="btn-thrive_icon inputbox" mat-icon-button [matTooltip]="'Edit Password'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="editPassword()">
                <mat-icon class="mr-0">edit</mat-icon>
            </button>
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose role</mat-label>
            <mat-select placeholder="Choose Role" formControlName="role">
                <mat-option value="admin">Admin</mat-option>
                <mat-option value="manager">Manager</mat-option>
                <mat-option value="employee">Employee</mat-option>
                <mat-option value="intern">Intern</mat-option>
            </mat-select>
            <mat-error *ngIf="adminForm.get('role').hasError('required')">Required*</mat-error>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose team</mat-label>
            <mat-select placeholder="Choose Role" formControlName="team">
                <mat-option value="sales">Sales</mat-option>
                <mat-option value="onboarding">On-boarding</mat-option>
                <mat-option value="am">Account Management</mat-option>
                <mat-option value="kam">Key Account Management</mat-option>
                <mat-option value="support">Support</mat-option>
                <mat-option value="product">Product</mat-option>
                <mat-option value="engineering">Engineering</mat-option>
                <mat-option value="qa">QA</mat-option>
                <mat-option value="finance">Finance</mat-option>
                <mat-option value="marketing">Marketing</mat-option>
                <mat-option value="partnerships">Partnerships</mat-option>
                <mat-option value="founder">Founder</mat-option>
            </mat-select>
            <mat-error *ngIf="adminForm.get('team').hasError('required')">Required*</mat-error>
        </mat-form-field>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button (click)="submitForm()" [disabled]="!adminForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
