import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getCategories(page, filter) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('page_no', page + 1);
        if (filter !== 'all') httpParams = httpParams.append('status', filter ? 1 : 0);
        return this.http.get(this.url.consumerUrl + '/v1/thrive_categories/all', {params: httpParams});
    }

    getCategoryById(id) {
        return this.http.get(this.url.consumerUrl + '/v1/thrive_categories/' + id);
    }

    createCategory(body) {
        return this.http.post(this.url.consumerUrl + '/v1/thrive_categories', body);
    }

    updateCategory(id, body) {
        return this.http.put(this.url.consumerUrl + '/v1/thrive_categories/' + id, body);
    }
}
