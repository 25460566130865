import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RbacService } from '../../shared/rbac/rbac.service';
@Injectable({
  providedIn: 'root'
})

export class  accessGuard implements CanActivate {
  constructor(private router: Router,private rbacService:RbacService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      let access_control:any = this.rbacService.getDataFromStore('access_control');
      const module: any = access_control.find(access => access.feature === next.data[0]);
      if(module && !module.write && !module.read ){
        this.router.navigateByUrl('/merchant')
        return false;
      }
      return true;
    }
  }