import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getBanners(page, filter) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('page_no', page + 1);
        if (filter !== 'all') httpParams = httpParams.append('status', filter ? 1 : 0);
        return this.http.get(this.url.consumerUrl + '/v1/thrive_banners/all', {params: httpParams});
    }

    getBannerById(id) {
        return this.http.get(this.url.consumerUrl + '/v1/thrive_banners/' + id);
    }

    createBanner(body) {
        let headers = new HttpHeaders();
        headers.delete('Content-Type');
        headers.append('Accept', 'multipart/form-data');
        return this.http.post(this.url.consumerUrl + '/v1/thrive_banners', body, {headers: headers});
    }

    updateBanner(id, body) {
        let headers = new HttpHeaders();
        headers.delete('Content-Type');
        headers.append('Accept', 'multipart/form-data');
        return this.http.put(this.url.consumerUrl + '/v1/thrive_banners/' + id, body, {headers: headers});
    }
}
