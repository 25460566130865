<div class="dialog-header">
    <h3>Create new filter</h3>
    <button class="btn-thrive_icon" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <form [formGroup]="customeFilter">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Name of the filter</mat-label>
            <input matInput formControlName="filter_name" />
            <mat-error *ngIf="customeFilter.get('filter_name').hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Enter filter key</mat-label>
            <input matInput placeholder="filter_key" formControlName="filter_key" />
            <mat-error *ngIf="customeFilter.get('filter_key').hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20 disabled">
            <mat-label>Type of filter</mat-label>
            <input readonly matInput formControlName="filter_type" />
            <mat-error *ngIf="customeFilter.get('filter_type').hasError('required')">Required</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Enter comma separated merchant id</mat-label>
            <input matInput formControlName="filter_value" />
            <mat-error *ngIf="customeFilter.get('filter_value').hasError('required')">Required</mat-error>
            <mat-error *ngIf="customeFilter.get('filter_value').hasError('pattern')">Please enter a valid input: a comma-separated list of numeric values without spaces (e.g., 1,2,3,4).</mat-error>
        </mat-form-field>
    </form>
</div>
<div class="dialog-footer">
    <button class="btn-thrive_secondary ml-20" (click)="dialogRef.close()">Cancel</button>
    <button class="btn-thrive_primary ml-20" [disabled]="formSubmit" (click)="submit()">Create new filter</button>
</div>

