import {Component, OnInit} from '@angular/core';
import {CommonModule,formatDate} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {MatIconModule} from '@angular/material/icon';
import {ThrivePricingService} from '../thrive-pricing.service';
import {MatSelectModule} from '@angular/material/select';
import {AccessControlDirective } from '../../shared/rbac/rbac.directive';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
export interface temporaryCommissionIf{
        id: number,
        merchant_id:number,
        consumer_app_new_user_fee:number,
        consumer_app_repeat_user_fee: number,
        is_active: boolean,
        start_date:string,
        end_date:string,
        created_at:string,
        updated_at:string,
        ondc_max_fee: {
            dine_in:number,
            delivery: number,
            take_away: number
        },
        direct_fee_value: {
            dine_in:number,
            delivery: number,
            take_away: number
        }
}

export interface commissionIf{
    id:number,
    name:string,
    location:string,
    pincode:string,
    city:string,
    created_at:string,
    contact_number:string,
    manual_popularity:number,
    payment_gateway_plan_id?: number | null,
    platform: string,
    chain_id: null | number,
    thrive_promise:number,
    status:number,
    commission_for_new_customer?:string | null,
    commission_for_repeat_customer?:string | null,
    commission_for_direct_platform?:string | null,
    pg_plan:number,
    temporary:boolean | null,
    direct?: {
        delivery:number | null,
        take_away: number | null,
        dine_in: number | null
    },
    ondc?: {
        delivery:number | null,
        take_away: number | null,
        dine_in: number | null
    }
}
@Component({
    selector: 'app-thrive-pricing-create',
    templateUrl: './thrive-pricing-create.component.html',
    styleUrls: ['./thrive-pricing-create.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule,AccessControlDirective,MatSlideToggleModule,MatDatepickerModule,MatNativeDateModule],
})

export class ThrivePricingCreateComponent implements OnInit{
    pricingForm: FormGroup;
    editId: string;
    pricingDetails:commissionIf;
    submitted:boolean = false;
    minDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));;
    showDirect:boolean = false;
    showConsumer:boolean = false;
    showOndc:boolean = false;
    temporaryCommissionData:temporaryCommissionIf;
    constructor(private thrivePricingService: ThrivePricingService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}
    temporary_data:FormGroup;
    ngOnInit() {
        this.editId = this.route.snapshot.params.id;
        this.createForm();
    }

    /**
     * To edit pricing 
     */
    createForm() {
        this.pricingForm = this.fb.group<any>({
            id: this.editId,
            thrive_exclusive_status: new FormControl('', Validators.required),
            popularity: new FormControl('', [Validators.required,Validators.min(0), Validators.max(1000)]),
            pg_plan: new FormControl('', [Validators.required]),
            //consumer app
            new_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            repeat_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            //direct ordering
            direct:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(1)]),
                take_away:new FormControl('', [Validators.required,Validators.min(1)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(1)])
            }),
            //ondc ordering
            ondc:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(1)]),
                take_away:new FormControl('', [Validators.required,Validators.min(1)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(1)])  
            }),
            temporary: new FormControl(false),
        });

        this.temporary_data = this.fb.group({
            id: this.editId,
            start_date: new FormControl(null,[Validators.required]),
            end_date: new FormControl(null,[Validators.required]),
           //consumer app
            new_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            repeat_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            //direct ordering
            direct:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(1)]),
                take_away:new FormControl('', [Validators.required,Validators.min(1)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(1)])
            }),
            //ondc ordering
            ondc:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(1)]),
                take_away:new FormControl('', [Validators.required,Validators.min(1)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(1)])  
            }),
        })

        //in case of editing a pricing
        this.thrivePricingService.getPricingById(this.editId).subscribe((pricing: commissionIf) => {
            this.pricingDetails = pricing;
            this.pricingForm.patchValue({
                thrive_exclusive_status: pricing.thrive_promise,
                popularity: pricing.manual_popularity,
                pg_plan: pricing.pg_plan,
                //consumer app
                new_customer_price: pricing.commission_for_new_customer,
                repeat_customer_price: pricing.commission_for_repeat_customer,
                //direct ordering
                direct:{
                    delivery: pricing?.direct?.delivery,
                    take_away: pricing?.direct?.take_away,
                    dine_in: pricing?.direct?.dine_in
                },
                // //ondc ordering
                ondc:{
                    delivery: pricing?.ondc?.delivery,
                    take_away: pricing?.ondc?.take_away,
                    dine_in: pricing?.ondc?.dine_in
                }, 
                temporary:pricing.temporary 
            });

            switch (pricing.platform) {
                case 'consumer_app':
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                    this.removeValidation('ondc.delivery');
                    this.removeValidation('ondc.take_away');
                    this.removeValidation('ondc.dine_in'); 
                    this.showConsumer = true;
                break;
                case 'direct':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.removeValidation('ondc.delivery');
                    this.removeValidation('ondc.take_away');
                    this.removeValidation('ondc.dine_in'); 
                    this.showDirect = true;
                break;
                case 'crm_only':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.removeValidation('ondc.delivery');
                    this.removeValidation('ondc.take_away');
                    this.removeValidation('ondc.dine_in'); 
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                break;
                case 'ondc_only':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                    this.showOndc = true;
                break;
                case 'both':
                    this.removeValidation('ondc.delivery');
                    this.removeValidation('ondc.take_away');
                    this.removeValidation('ondc.dine_in'); 
                    this.showConsumer = true;
                    this.showDirect = true;
                break;
                case 'microsite_and_ondc':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.showDirect = true;
                    this.showOndc = true;
                break;
                case 'consumer_app_and_ondc':
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                    this.showConsumer = true;
                    this.showOndc = true;
                break;
                case 'all_platforms':
                    this.showConsumer = true;
                    this.showOndc = true;
                    this.showDirect = true;
                break;
            }

            if(this.pricingDetails.temporary){
                this.thrivePricingService.getTemporaryCommissionById(this.editId).subscribe((response:temporaryCommissionIf)=>{
                    this.temporaryCommissionData = response;
                    this.temporary_data.patchValue(response);
                    this.pricingForm.addControl('temporary_data',this.temporary_data);
                })
            }
        });
    }

    removeValidation(constrolName:string){
        this.pricingForm.get(constrolName).clearValidators();
        this.pricingForm.get(constrolName).updateValueAndValidity();
        this.temporary_data.get(constrolName).clearValidators();
        this.temporary_data.get(constrolName).updateValueAndValidity();
    }

    onToggleTemporary({checked}){
        if(checked){
            this.temporary_data.patchValue(this.pricingForm.value);
            this.pricingForm.addControl('temporary_data',this.temporary_data);
        } else {
            this.pricingForm.removeControl('temporary_data');  
        }
    }

    // Converts a date to ISO string in UTC
    toUTCString(date: Date): string {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }

    disabledTemporaryCommission(){
        Swal.fire({
            title: 'Are you sure you want to delete?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
              this.thrivePricingService.deleteTemporaryCommission(this.temporaryCommissionData.id).subscribe((response:any)=>{
                this.createForm();  
              });
            }
        });
    }

    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if(this.pricingForm.valid){
            this.submitted = true;
            let temporary_data:any = {};
            if(this.pricingForm.get('temporary_data')){
                temporary_data = this.pricingForm.get('temporary_data').value;
                delete this.pricingForm.value.temporary_data;
            }
            this.thrivePricingService.updatePricingMerchants(this.pricingForm.value).subscribe(() => {
                if(this.pricingForm.get('temporary_data')){
                    temporary_data.start_date = this.toUTCString(temporary_data.start_date);
                    temporary_data.end_date = this.toUTCString(temporary_data.end_date);
                    this.thrivePricingService.createTemporaryCommission(temporary_data).subscribe((response:any)=>{
                        Swal.fire('Updated!', 'Price has been updated!', 'success');
                        this.router.navigateByUrl('/thrive-pricing');
                    });
                } else {
                    Swal.fire('Updated!', 'Price has been updated!', 'success');
                    this.router.navigateByUrl('/thrive-pricing');
                }
            });
        } else {
            this.pricingForm.markAllAsTouched();  
        }
    }
}
