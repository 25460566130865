import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {BrandCollaborationService} from './brand-collaboration.service';
import {environment} from '../../environments/environment';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-brand-collaboration',
    templateUrl: './brand-collaboration.component.html',
    styleUrls: ['./brand-collaboration.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, TableComponent, MatButtonModule, RouterModule, MatTooltipModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, FormsModule,AccessControlDirective],
    providers: [BrandCollaborationService]
})
export class BrandCollaborationComponent {
    data: Array<any>;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    page: number;
    perPage: number;

    constructor(private brandCollaborationService: BrandCollaborationService, private router: Router, private route: ActivatedRoute) {
        this.getBrandscol(this.page, this.perPage);
    }

    /**
     * To get brand collaborations to be shown in a table
     * @param page To Paginate the recieved data accordingly
     * @param perPage To get specified number of rows
     */
    getBrandscol(page: number, perPage: number) {
        this.brandCollaborationService.getBrands().subscribe((brands: Array<any>) => {
            this.data = brands;
        });
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'listing_slug',
                header: 'Brand slug',
                cell: 'listing_slug'
            },
            {
                columnDef: 'page',
                header: 'Open page',
                cell: 'page',
                isSpecialHTML: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'name', 'listing_slug'];
    }

    /**
     * To navigate to update page of row click
     * @param event To get row id of row click
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('brand-collaboration/' + event.id);
    }

    /**
     * To open order page
     * @param row To get row id of clicked row
     */
    actionClicked(row) {
        window.open(environment.micrositeWebsiteUrl + row.listing_slug, '_blank');
    }
}
