import { Component, ElementRef, Inject, ViewChild, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatChipInputEvent,MatChipsModule } from '@angular/material/chips';
import Swal from 'sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { RestaurantsService } from '../../../restaurants.service';
import { DataFilterPipe } from '../../../../shared/pipes/data-filter.pipe';
import {NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {cuisineIf} from '../../reataurant';

@Component({
  selector: 'app-create-restaurant',
  standalone: true,
  imports: [CommonModule, 
    MatDialogModule, 
    MatButtonModule, 
    MatIconModule, 
    MatFormFieldModule, 
    MatInputModule, 
    ReactiveFormsModule,
    MatAutocompleteModule, 
    MatSelectModule,
    DataFilterPipe,
    MatChipsModule,
    NgxMatSelectSearchModule],
  templateUrl: './create-restaurant.component.html',
  styleUrls: ['./create-restaurant.component.scss']
})
export class CreateRestaurantComponent implements OnInit{

  restaurantForm: FormGroup = this.fb.group({
    role:'member',
    part_of_chain:'no',
    merchant_id: new FormControl('',[Validators.pattern(/^\d+$/)]),
    google_place_id:new FormControl(''),
    name: new FormControl('',Validators.required),
    lat: new FormControl('',[Validators.required, Validators.pattern( /^(-?\d+(\.\d+)?),?\s*$/)]),
    lon: new FormControl('',[Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*$/)]),
    locality:new FormControl('',Validators.required),
    full_address:new FormControl('',Validators.required),
    image_url:new FormControl(''),
    image_blob:new FormControl(''),
    cuisines:new FormControl(''),
    cost_for_two:new FormControl('',[Validators.pattern(/^\d+$/)]),
    ad_hoc_tag:new FormControl(''),
    restaurant_known_for_tag:new FormControl('')
  });

  cuisines: Array<cuisineIf> = [];
  searchCuisine = new FormControl();
  // adhoc tags
  searchForAdhocTag:FormControl=new FormControl('');
  separatorKeysCodes: number[] = [ENTER, COMMA];
  maxTagCharLimit: number = 30;
  adhocTagKeyWord = new FormControl('');
  adhocTags:Array<string>=[];
  selectedAdhocTags:Array<string>=[];
  @ViewChild('adhoc') adhoc: ElementRef<HTMLInputElement>;

  //Known for tags
  knownForTags:Array<string>=[];
  selectedKnownForTags:Array<string>=[];
  knowForTagKeyWord=new FormControl('');
  searchForKnownForTag:FormControl=new FormControl('');
  @ViewChild('known') knownFor: ElementRef<HTMLInputElement>;
  submitted: boolean;
  constructor(public dialogRef: MatDialogRef<CreateRestaurantComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private restaurantsService: RestaurantsService){}
  ngOnInit(): void {
      this.cuisines = this.data.cuisines;
      //autocomplete for ad hoc tags
      this.searchForAdhocTag.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged())
        .subscribe((keyword:string) => {
        if(keyword && keyword.trim().length>1){
            this.getAdhocTagsList(keyword);
        } else {
            this.adhocTags = [];
        }
    });
    // auto complete known for 
    this.searchForKnownForTag.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged())
        .subscribe((keyword:string) => {
        if(keyword && keyword.trim().length>1){
            this.getKnownForTagsList(keyword);
        } else {
            this.knownForTags = [];
        }
    }); 
  }
  // ad hoc tags
    /**
     * search tags
     * @param searchQuery search keyword
     */
     getAdhocTagsList(searchQuery:string){
      this.restaurantsService.getTagsList({'search_query':searchQuery,'tag_type':'restaurant_ad_hoc'}).subscribe((tags:any) => {
          this.adhocTags = tags;
      });
  }

  /**
   * Add a tag
   * @param event Represents an input event on a matChipInput.
   */
  addAdhocTag(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();
      // Trim the input text to the character limit
      const trimmedInput = value.substr(0, this.maxTagCharLimit);

      if (value.length > this.maxTagCharLimit)
      Swal.fire('Note!', `Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

      if (value) this.selectedAdhocTags.push(trimmedInput);

      event.chipInput!.clear();
      this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
  }

  /**
   * Select a tag
   * @param event Event object that is emitted when an autocomplete option is selected
   */
  selectAdhocTag(event: MatAutocompleteSelectedEvent): void {
      this.selectedAdhocTags.push(event.option.viewValue);
      this.adhoc.nativeElement.value = '';
      this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
  }

  /**
   * Remove the tag
   * @param tag tag to be removed
   */
  removeAdhocTag(tag: string): void {
      const index:number = this.selectedAdhocTags.indexOf(tag);
      if (index >-1) {
          this.selectedAdhocTags.splice(index, 1);
      }
      this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
  }

  // Known for tags

  /**
  * search tags
  * @param searchQuery search keyword
  */
  getKnownForTagsList(searchQuery:string){
      this.restaurantsService.getTagsList({'search_query':searchQuery,'tag_type':'restaurant_known_for'}).subscribe((tags:any) => {
          this.knownForTags = tags;
      });
  }

  /**
   * Add a tag
   * @param event Represents an input event on a matChipInput.
   */
  addknownForTag(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();
      // Trim the input text to the character limit
      const trimmedInput = value.substr(0, this.maxTagCharLimit);

      if (value.length > this.maxTagCharLimit)
      Swal.fire('Note!', `Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

      if (value) this.selectedKnownForTags.push(trimmedInput);

      event.chipInput!.clear();
      this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
  }

  /**
   * Select a tag
   * @param event Event object that is emitted when an autocomplete option is selected
   */
  selectKnownForTag(event: MatAutocompleteSelectedEvent): void {
      this.selectedKnownForTags.push(event.option.viewValue);
      this.knownFor.nativeElement.value = '';
      this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
  }

  /**
   * Remove the tag
   * @param tag tag to be removed
   */
  removeKnownForTag(tag: string): void {
      const index:number = this.selectedKnownForTags.indexOf(tag);
      if (index >-1) {
          this.selectedKnownForTags.splice(index, 1);
      }
      this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
  }
  /**
     * submit restaurant form value
     */
   submit() {
    if (this.restaurantForm.valid) {
      this.submitted = true;
      let data = JSON.parse(JSON.stringify(this.restaurantForm.value));
      delete data.image_blob;
      const formData = new FormData();
      for (const key in data) {
          if (data.hasOwnProperty(key)) {
            switch(key){
              case 'cuisines':
              case 'ad_hoc_tag':
              case 'restaurant_known_for_tag':
                  if(data[key] && data[key].length){
                      for (let i = 0; i < data[key].length; i++) {
                          formData.append(`${key}[]`, data[key][i]);
                      }
                  } else {
                      formData.append(`${key}[]`,data[key]);
                  }
              break;
              default:
                  data[key]?formData.append(key, data[key]):formData.append(key,'');
            }
          }
      }
      this.createRestaurant(formData);
    }else{
      this.restaurantForm.markAllAsTouched();
    }
  }

  /**
   * Create a new restaurant 
   */
  createRestaurant(formData:any){
      this.restaurantsService.createRestaurant(formData).subscribe(
          (response:any) => {
              this.submitted = false;
              this.dialogRef.close(response);
          },
          (err) => {
              this.submitted = false;
              let errMsg:string='Something went wrong. Please try agaian later.';
              if(err?.error?.errors){
                  errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
              }
              Swal.fire('Error', errMsg, 'error');
          }
      );
  }
 
  onClose(){
    this.dialogRef.close();
  }
}
