<div id="banner-edit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/custom-menu'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Custom Menu</h2>
        </div>
    </div>
    <div class="thrive-card no-hover form-card" style="margin-bottom: 60px">
        <form [formGroup]="customMenuForm">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Enter Item Name</mat-label>
                <input matInput placeholder="Enter Menu Name" formControlName="item_name" />
                <mat-error *ngIf="customMenuForm.get('item_name').invalid">Name is Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20" >
                <mat-label>Select Restaurant</mat-label>
                <input [readonly]="edit && edit.id" [ngClass]="{'disabled':edit && edit.id}" type="text" placeholder="Enter 3 digits to see results..." matInput formControlName="restaurant_id" [matAutocomplete]="auto" required />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptioResto">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let option of filteredOptions" [value]="option">
                        {{ option.name }}
                      </mat-option>
                    </ng-container>
                </mat-autocomplete>
                <mat-error *ngIf="customMenuForm.get('restaurant_id').invalid">Choose value from suggested one only.</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Status</mat-label>
                <mat-select placeholder="Choose Merchant" formControlName="item_status">
                    <mat-option [value]="'to_review'" [disabled]="customMenuForm.get('item_status').value !== 'to_review' && (edit && edit.id)">To Review</mat-option>
                    <mat-option [value]="'approved'">Approved</mat-option>
                    <mat-option [value]="'reviewed'">Reviewed</mat-option>
                </mat-select>
                <mat-error *ngIf="customMenuForm.get('item_status').invalid">Status is Required</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button (click)="submitCustomMenu()" [disabled]="submitted">
                <mat-icon>add</mat-icon>
                <span>{{ edit && edit.id ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
