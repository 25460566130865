<div class="dialog-header">
    <span>Filter & Sort</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClick(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-body">

    <mat-selection-list [multiple]="false" #filter color="primary" [hideSingleSelectionIndicator]="true" (selectionChange)="changeFilter($event.source._value)">
        <p mat-subheader class="filter-header">Filters</p>
        <mat-list-option *ngFor="let filter of filters" [class.selected]="filterOption == filter.name" [value]="filter.name" [selected]="filterOption === filter.name">{{ filter.name }}</mat-list-option>
    </mat-selection-list>

    <div class="filter-area">

        <p class="filter-header">{{getName(filterOption) | titlecase}}</p>

        <mat-radio-group color="primary" *ngIf="filterOption == 'Status'" labelPosition="before" [(ngModel)]="statusValue">
            <mat-radio-button class="radio" value="true">On</mat-radio-button>
            <mat-radio-button class="radio" value="false">Off</mat-radio-button>
        </mat-radio-group>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" *ngIf="filterOption == 'Name'">
            <input matInput placeholder="Enter tag name" [(ngModel)]="nameValue" />
        </mat-form-field>

        <mat-form-field *ngIf="filterOption == 'Category'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" >
            <mat-label>Select tag category</mat-label>
            <mat-select [(ngModel)]="categoryValue" multiple>
                <mat-option>
                    <ngx-mat-select-search
                                           placeholderLabel="Search"
                                           noEntriesFoundLabel="Category not found"
                                           ngDefaultControl
                                           [(ngModel)]="search">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="category" *ngFor="let category of tagCategoryList| datafilter : search : ['name','id']">{{category.name}} ({{category.id}})</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="filterOption == 'Type'" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" >
            <mat-label>Select tag type</mat-label>
            <mat-select [(ngModel)]="typeValue" multiple>
                <mat-option *ngFor="let type of tagType" [value]="type">{{type.key}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-icon-button class="btn-thrive_icon delete-btn m-10-l-auto" (click)="clearValue()" matTooltip="Clear Value" matTooltipClass="thrive-tooltip">
            <mat-icon>delete</mat-icon>
        </button>

    </div>
</div>
<div class="dialog-footer">
    <button mat-icon-button class="btn-thrive_icon delete-btn" (click)="clearAll()" matTooltip="Reset Filter" matTooltipClass="thrive-tooltip">
        <mat-icon>delete</mat-icon>
    </button>
    <div class="buttons">
        <button (click)="onClick(false)" class="btn-thrive_secondary" mat-button>Cancel</button>
        <button (click)="onClick(true)" class="btn-thrive_primary ml-20" mat-button>Apply Filter</button>
    </div>
</div>