import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminuserService{

  private url = environment;

    constructor(public http: HttpClient) { }

    getAdmins() {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('all_user', true);
      return this.http.get(this.url.adminUrl + '/admins',{params: httpParams});
    }
    createAdminUser(body){
      return this.http.post(this.url.adminUrl+'/admins',body);
    }
    getAdminUserById(id:string){
      return this.http.get(this.url.adminUrl+'/admins/'+id);
    }
    updateUser(id: string, body: any) {
      return this.http.put(this.url.adminUrl + '/admins/' + id,body);
    }
    getAccessControlData(){
      return this.http.get(this.url.adminUrl+'/admin_access_control');
    }

}

