<div id="merchantUsersCreate" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/merchant-users'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>{{ editId !== 'new' ? 'Update' : 'Create' }} User</h2>
        </div>
    </div>
    <ng-container >
        <form [formGroup]="merchantForm">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>First Name</mat-label>
                <input matInput placeholder="Enter first name" formControlName="fname" />
                <mat-error *ngIf="merchantForm.get('fname').hasError('required') && merchantForm.get('fname').touched">Required*</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Last name</mat-label>
                <input matInput placeholder="Enter last name" formControlName="lname" />
                <mat-error *ngIf="merchantForm.get('lname').hasError('required') && merchantForm.get('lname').touched">Required*</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Email address</mat-label>
                <input matInput placeholder="Enter your email" formControlName="email" (change)="checkEmail(merchantForm.get('email').value)" />
                <mat-error *ngIf="merchantForm.get('email').hasError('required')  && merchantForm.get('email').touched">Required*</mat-error>
                <mat-error *ngIf="merchantForm.get('email').hasError('pattern')  && merchantForm.get('email').touched">Enter correct email address</mat-error>
                <mat-error *ngIf="merchantForm.get('email').hasError('emailPresent')  && merchantForm.get('email').touched">Email is already present</mat-error>
                <mat-error *ngIf="merchantForm.get('email').hasError('inValid')  && merchantForm.get('email').touched">Enter a hashtagloyalty email address</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" (change)="checkMobile(merchantForm.get('mobile').value)">
                <mat-label>Mobile number</mat-label>
                <input matInput placeholder="Enter your mobile number" formControlName="mobile" />
                <mat-error *ngIf="merchantForm.get('mobile').hasError('required') && merchantForm.get('mobile').touched">Required*</mat-error>
                <mat-error *ngIf="merchantForm.get('mobile').hasError('pattern') && merchantForm.get('mobile').touched">Enter 10 digit phone number</mat-error>
                <mat-error *ngIf="merchantForm.get('mobile').hasError('mobilePresent')  && merchantForm.get('mobile').touched">Mobile number is already present</mat-error>
            </mat-form-field>

            <mat-slide-toggle class="w-100" color="primary" formControlName="is_static_pin">Static Pin</mat-slide-toggle>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="merchantForm.value.is_static_pin">
                <mat-label>Otp</mat-label>
                <input matInput placeholder="Enter static otp" formControlName="otp" />
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Choose Role</mat-label>
                <mat-select placeholder="Choose Role" formControlName="role" (selectionChange)="onRoleSelect()">
                    <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="merchantForm.get('role').hasError('required') && merchantForm.get('role').touched ">Required*</mat-error>
            </mat-form-field>
            
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="merchantForm.value.role === 1">
                <mat-label>Select Merchant</mat-label>
                <mat-select placeholder="Select Merchants" formControlName="merchant_id" (selectionChange)="onSelect($event,'merchant')">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search Merchants" noEntriesFoundLabel="Merchant not found" [formControl]="searchMerchantControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let merchant of getMerchantList" [value]="merchant?.id">{{ merchant?.name }} - {{ merchant.location }}</mat-option>
                </mat-select>
                <mat-error *ngIf="merchantForm.get('merchant_id').hasError('required') && merchantForm.get('merchant_id').touched">Required*</mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="merchantForm.value.role === 2">
                <mat-label>Select chain</mat-label>
                <mat-select placeholder="Select Chain" formControlName="chain_id">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search Chains" noEntriesFoundLabel="Chain not found" [formControl]="searchMerchantControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let chain of filterChains" [value]="chain.id">{{ chain.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="merchantForm.get('chain_id').hasError('required') && merchantForm.get('chain_id').touched">Required*</mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic"  floatLabel="always" appearance="outline" class="w-100" *ngIf="merchantForm.value.role === 3">
                <mat-label>Select merchants</mat-label>
                <mat-select multiple (selectionChange)="onSelect($event,'subchain')" formControlName="subchains" placeholder="Search and select merchant.">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="searchMerchantControl" placeholderLabel="Search merchant" noEntriesFoundLabel="'No matching merchant found!'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let merchant of getMerchantList" [value]="merchant">{{ merchant.name }} - {{ merchant.location }}</mat-option>
                </mat-select>
                <mat-error *ngIf="merchantForm.get('subchains').hasError('required') && merchantForm.get('subchains').touched">Required*</mat-error>
            </mat-form-field>
        </form>
        <div class="action-footer">
            <div class="footer-container">
                <button class="btn-thrive_primary"
                accessControl
                moduleType="merchant_users"  
                mat-button 
                (click)="submitForm()" 
                >
                    <mat-icon>add</mat-icon>
                    <span>{{ editId !== 'new' ? 'Update' : 'Create' }}</span>
                </button>
            </div>
        </div>
    </ng-container>
</div>
