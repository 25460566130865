<div id="reporting" class="thrive-container ">
    
    <div>
        <h2>Generate Report <span class="basetitle">(Operational hours)</span></h2>
    </div>
        
   
    <form class="mt-30" [formGroup]="reporting">
        <div class="flex-row">
            <div class="flex-inner-row">
                <div class="subtitle normal">Select merchant role</div>
                <mat-radio-group formControlName="role" color="primary" (change)="onChangeRole($event)">
                    <mat-radio-button value="merchant">Merchant</mat-radio-button>
                    <mat-radio-button value="chain_owner">Chain</mat-radio-button>
                </mat-radio-group>
            </div>
        <!-- </div>
        <div class="flex-row"> -->

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mt-20" *ngIf="reporting.get('role').value === 'merchant'">
                <mat-label>Select Merchant</mat-label>
                <mat-select multiple placeholder="Select Merchant" formControlName="id" (selectionChange)="onSelect($event)">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search Merchants" noEntriesFoundLabel="Merchant not found" [formControl]="searchMerchantControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let merchant of getMerchantList" [value]="merchant">{{ merchant?.name }} - {{ merchant.location }}</mat-option>
                </mat-select>
                <mat-error *ngIf="reporting.get('id').hasError('required') && reporting.get('id').touched">Required*</mat-error>
            </mat-form-field>
            
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mt-20" *ngIf="reporting.get('role').value === 'chain_owner'">
                <mat-label>Select chain</mat-label>
                <mat-select placeholder="Select Chain" formControlName="chain_id">
                    <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search Chains" noEntriesFoundLabel="Chain not found" [formControl]="searchMerchantControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let chain of filterChains" [value]="chain.id">{{ chain.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="reporting.get('chain_id').hasError('required') && reporting.get('chain_id').touched">Required*</mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mt-20">
                <mat-label>Select order type</mat-label>
                <mat-select placeholder="Select order type" formControlName="ordering_type">
                    <mat-option value="delivery">Delivery</mat-option>
                    <mat-option value="take_away">Pick up</mat-option>
                    <mat-option value="dine_in">Dine in</mat-option>
                </mat-select>
                <mat-error *ngIf="reporting.get('ordering_type').hasError('required') && reporting.get('ordering_type').touched">Required*</mat-error>
            </mat-form-field>

            <div>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20">
                    <mat-label>Select date range</mat-label>
                    <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
                        <input readonly matStartDate formControlName="start_date" placeholder="From">
                        <input readonly matEndDate formControlName="end_date" placeholder="To">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker ></mat-date-range-picker>
                </mat-form-field>
                <mat-error *ngIf="reporting.get('start_date').hasError('required') && reporting.get('start_date').touched || 
                reporting.get('end_date').hasError('required') && reporting.get('end_date').touched">Start date and end date is required.</mat-error>
                <mat-error *ngIf="reporting.get('start_date').hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="reporting.get('end_date').hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </div>

        </div>
    </form>
    
    <div class="action-footer">
        <div class="footer-container">
            <button [disabled]="this.reporting.invalid" class="btn-thrive_primary" mat-button (click)="submit()">
                Generate Report
            </button>
        </div>
    </div>
</div>
