<div id="cuisine" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections cuisine-header">
            <h2>Cuisines</h2>

            <div class="search">
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="search-width">
                    <input matInput #searchInput placeholder="Search here" [formControl]="searchQuery">
                    <mat-icon matSuffix *ngIf="!searchQuery.value">search</mat-icon>
                    <mat-icon matSuffix *ngIf="searchQuery.value" (click)="searchQuery.setValue('')">cancel</mat-icon>
                </mat-form-field>
            </div>
            <button class="btn-thrive_primary" 
                mat-button 
                routerLink="/cuisine/new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>

    <tn-table
        [title]="'Sources'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        (rowClick)="navigateTo($event)"
        (changePage)="changePage($event)"
    >
    </tn-table>
</div>
