import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import * as XLSX from 'xlsx';
import {checkMandatory, checkOptional} from './bulk-offer-upload-array';
import {BulkOfferUploadService} from './bulk-offer-upload.service';
import Swal from 'sweetalert2';
import {MatIconModule} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bulk-offer-upload',
  standalone: true,
  imports: [CommonModule,MatButtonModule, MatTooltipModule, MatCardModule, MatIconModule, RouterLink],
  templateUrl: './bulk-offer-upload.component.html',
  styleUrls: ['./bulk-offer-upload.component.scss']
})
export class BulkOfferUploadComponent {
  headers: any;
  excelData: any;
  file: any;
  stopLoop: boolean = false;
  @ViewChild('bulkUploadInput') bulkUploadVariable: ElementRef;

  constructor(private bulkOfferUploadService: BulkOfferUploadService, private router: Router, private route: ActivatedRoute) {}

  /**
   * To read the excel file, validate headers and data
   * @param event To get the uploaded CSV file
   * @returns To break out of the function if there is an error
   */
  readExcel(event: any) {
      let broke: boolean = false;
      let checkMandatoryLength: number = 0;
      let checkOptionalLength: number = 0;
      let ptr: number = 0;
      let file: any = event.target.files[0];
      this.file = event.target.files[0];
      if (this.file.type != 'text/csv') {
          Swal.fire('File extension error!', 'Please upload file in .csv format only', 'error');
          return;
      }

      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = () => {
          let workBook = XLSX.read(fileReader.result, {type: 'binary'});
          let sheetNames = workBook.SheetNames;
          this.excelData = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);

          this.headers = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]], {
              header: 1
          })[0];

          //to check mandatory headers
          for (let i = 0; i < this.headers.length; i++) {
              if (this.headers.includes(checkMandatory[i])) {
                  checkMandatoryLength++;
              } else {
                  i++;
              }
          }

          //to check optional headers
          for (let j = 0; j < checkOptional.length; j++) {
              if (this.headers.includes(checkOptional[j])) {
                  checkOptionalLength++;
              } else {
                  j++;
              }
          }

          //to check if headers test is not changed
          if (checkMandatoryLength == checkMandatory.length && checkOptionalLength == checkOptional.length) {
              this.stopLoop = false;
          } else {
              Swal.fire('Error!', 'Enter Valid Headers!', 'error');
              this.stopLoop = true;
              this.clearInput();
          }

          if (checkMandatoryLength === checkMandatory.length && checkOptionalLength === checkOptional.length) {
              for (let i = 0; i < this.excelData.length; i++) {
                  for (let j = 0; j < checkMandatory.length; j++) {
                      if (checkMandatory[j] in this.excelData[i]) {
                          ptr++;
                      } else {
                          broke = true;
                      }
                  }
                  if (broke) {
                      break;
                  }
              }
          } else {
              // console.log('Not Verified');
          }

          //To confirm if all the mandatory fields values are present
          if (this.excelData.length * checkMandatoryLength === ptr) {
              if (checkMandatoryLength == checkMandatory.length && checkOptionalLength == checkOptional.length) {
                  this.bulkUploadMerchants();
                  this.clearInput();
              }
          } else {
              if (!this.stopLoop) {
                  Swal.fire('Error!', 'Please fill all the mandatory fields! Some are missing', 'error');
                  this.clearInput();
              }
          }
      };
  }

  /**
   * To upload the data of CSV and show alert accordingly
   */
  bulkUploadMerchants() {
      const formData = new FormData();
      formData.append('csv_file', this.file);
      formData.append('email', localStorage.getItem('email'));
      this.bulkOfferUploadService.bulkUpload(formData).subscribe(
          (success) => {
              Swal.fire('Uploaded!', 'File has been uploaded successfully!', 'success');
          },
          (err) => {
              Swal.fire('Error!', 'File was not uploaded', 'error');
          }
      );
  }

  /**
   * To clear the uploaded file
   */
  clearInput() {
      this.bulkUploadVariable.nativeElement.value = '';
  }
}
