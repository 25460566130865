<div class="dialog-header">
    <span>Filter & Sort</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClick(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-body">
    <mat-selection-list [multiple]="false" #filter color="primary" [hideSingleSelectionIndicator]="true" (selectionChange)="changeFilter($event.source._value)">
        <p mat-subheader class="filter-header">Filters</p>
        <mat-list-option *ngFor="let filter of filters" [class.selected]="filterOption == filter.id" [value]="filter.id" [selected]="filterOption === filter.id">{{ filter.name }}</mat-list-option>
        <p mat-subheader class="filter-header">Sort</p>
        <mat-list-option *ngFor="let sort of sorts" [class.selected]="filterOption == sort.id" [value]="sort.id" [selected]="filterOption === sort.id">{{ sort.name }}</mat-list-option>
    </mat-selection-list>
    <div class="filter-area">
        <p class="filter-header">{{getName(filterOption) | titlecase}}</p>

        <mat-radio-group color="primary" *ngIf="filterOption == 'status'" labelPosition="before" [(ngModel)]="statusValue">
            <mat-radio-button class="radio" value="approved">Approved</mat-radio-button>
            <mat-radio-button class="radio" value="rejected">Rejected</mat-radio-button>
            <mat-radio-button class="radio" value="to_review">To review</mat-radio-button>
            <mat-radio-button class="radio" value="deleted">Deleted</mat-radio-button>
        </mat-radio-group>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" *ngIf="filterOption == 'last_updated'" floatLabel="never" class="field">
            <mat-label>Select date range</mat-label>
            <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
            <input readonly matStartDate [(ngModel)]="dateValue.start_date" placeholder="From">
            <input readonly matEndDate [(ngModel)]="dateValue.end_date" placeholder="To">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker ></mat-date-range-picker>
        </mat-form-field>

        <mat-radio-group color="primary" *ngIf="filterOption == 'post_type'" labelPosition="before" [(ngModel)]="postTypeValue">
            <mat-radio-button class="radio" value="question">Question</mat-radio-button>
            <mat-radio-button class="radio" value="simple_post">Thread(Post)</mat-radio-button>
            <mat-radio-button class="radio" value="list">List</mat-radio-button>
        </mat-radio-group>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" *ngIf="filterOption == 'user_id'">
            <mat-label>Select internal user</mat-label>
            <mat-select [(ngModel)]="userIdValue" multiple>
                <mat-option *ngFor="let user of botUsers" [value]="user.id">{{ user.fname + ' ' + user.lname }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="filterOption == 'user_details'">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" >
                <mat-label>Select filter category</mat-label>
                <mat-select [(ngModel)]="userType" (selectionChange)="onSelectionChange($event)">
                    <mat-option [value]="'id'">User id</mat-option>
                    <mat-option [value]="'user_name'">User name</mat-option>
                    <mat-option [value]="'display_name'">Display name</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" *ngIf="userType">
                <input matInput placeholder="Enter {{userType === 'user_name' ? 'User name' :userType === 'display_name' ? 'Display name' : 'user id' }}" [(ngModel)]="userDetails[userType]" />
            </mat-form-field>
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" *ngIf="filterOption == 'user_name'">
            <input matInput placeholder="Enter User Id" [(ngModel)]="userNameValue" />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="field" *ngIf="filterOption == 'search_query'">
            <input matInput placeholder="Enter Title" [(ngModel)]="titleValue" />
        </mat-form-field>

        <mat-radio-group color="primary" *ngIf="filterOption == 'is_pinned'" labelPosition="before" [(ngModel)]="isPinnedValue">
            <mat-radio-button class="radio" value="true">True</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group color="primary" *ngIf="filterOption == 'recently_updated'" labelPosition="before" [(ngModel)]="sortUpdatedValue">
            <mat-radio-button class="radio" value="asc">Ascending</mat-radio-button>
            <mat-radio-button class="radio" value="desc">Descending</mat-radio-button>
        </mat-radio-group>

        <button mat-icon-button class="btn-thrive_icon delete-btn" style="margin: 10px 10px 10px auto;" (click)="clearValue()" matTooltip="Clear Value" matTooltipClass="thrive-tooltip">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
<div class="dialog-footer" style="border-top: 1px solid #c9c9c9; justify-content: space-between;">
    <button mat-icon-button class="btn-thrive_icon delete-btn" (click)="clearAll()" matTooltip="Reset Filter" matTooltipClass="thrive-tooltip">
        <mat-icon>delete</mat-icon>
    </button>
    <div style="display: flex; align-items: center;">
        <button (click)="onClick(false)" class="btn-thrive_secondary" mat-button>Cancel</button>
        <button (click)="onClick(true)" class="btn-thrive_primary ml-20" mat-button>Apply Filter</button>
    </div>
</div>
