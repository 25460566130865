import { Component, Inject } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectChange,MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-filter-model',
  standalone: true,
  imports: [CommonModule, MatDialogModule,MatSelectModule, MatButtonModule, MatIconModule, MatListModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatRadioModule, FormsModule, MatTooltipModule],
  templateUrl: './filter-model.component.html',
  styleUrls: ['../../filter-dialog.scss'],
  providers: [DatePipe]
})

export class FilterModelComponent {

  filterOption = 'post_id';

  filterCategory = [
      { name: 'Post id', id: 'post_id' },
      { name: 'Title', id: 'search_query' },
      { name: 'Status', id: 'status' },
      { name: 'User details', id: 'user_details' },
      { name: 'Post type', id: 'post_type' },
      { name: 'Last updated', id: 'last_updated' },
  ];

  sorts = [
      { name: 'Recently Updated', id: 'recently_updated' }
  ];

  filters = {
    post_id: null,
    search_query: null,
    status: null,
    post_type: null,
    last_updated: {
      start_date:null,
      end_date:null
    },
    user_type:null,
    user_details:{
      id:null,
      user_name:null,
      display_name:null
    },
    recently_updated: null
  };

  maxDate:Date = new Date();

  constructor(public dialogRef: MatDialogRef<FilterModelComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any, 
              private datePipe: DatePipe){
                this.filters = {...this.data};
              }

  changeFilter(filter) {
    this.filterOption = filter[0];
  }

  getName(filterOption:string) {
    return filterOption.split('_').join(' ');
  }

  clearValue() {
    switch (this.filterOption) {
      case 'user_details':
        this.filters.user_details={
          id:null,
          user_name:null,
          display_name:null
        },
        this.filters.user_type = null;
      break;
      case 'last_updated':
        this.filters.last_updated = {
          start_date:null,
          end_date:null
        }
      break;
      default:
        this.filters[this.filterOption] = null; 
    }
  }

  clearAll(){
    this.filters = {
      post_id: null,
      search_query: null,
      status: null,
      post_type: null,
      last_updated: {
        start_date:null,
        end_date:null
      },
      user_type:null,
      user_details:{
        id:null,
        user_name:null,
        display_name:null
      },
      recently_updated: null
    };
  } 

  onSelectionChange(event:MatSelectChange){
    this.filters.user_details = {
        id:null,
        user_name:null,
        display_name:null
    };
  }

  onClick(): void {
    if(this.filters.last_updated.start_date && this.filters.last_updated.end_date){
      this.filters.last_updated.start_date = this.datePipe.transform(this.filters.last_updated.start_date, 'yyyy-MM-dd');
      this.filters.last_updated.end_date = this.datePipe.transform(this.filters.last_updated.end_date, 'yyyy-MM-dd');
    }
    this.dialogRef.close(this.filters);
  }

}
