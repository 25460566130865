import { Component,Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormControl, FormGroup,Validators,  ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FilterService} from '../filters.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-modal',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule],
  templateUrl: './create-modal.component.html',
})
export class CreateModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public filterApi:FilterService
) { }
  customeFilter: FormGroup = this.fb.group({
    filter_name: new FormControl('',Validators.required),
    filter_key: new FormControl('',Validators.required),
    filter_type: new FormControl('merchant_id'),
    filter_value: new FormControl('',[Validators.required,Validators.pattern(/^\d+(?:[,]\d+)*$/)]),
    show_on_app_filter_list: new FormControl(false),
    status: new FormControl(true) 
  });
  formSubmit:boolean = false;
  submit(){
      if(this.customeFilter.valid){
        this.formSubmit = true;
        this.filterApi.createFilterAPI(this.customeFilter.value).subscribe((response:any)=>{
          this.dialogRef.close(true)
        },(error:any)=>{
          this.formSubmit = false;
            if(error.status === 422){
              Swal.fire('Failure', error.error.errors[0]+'. Please enter unique key.', 'error');
            } else {
              Swal.fire('Failure','Something went wrong please try again!', 'error');
            }
        
        });
      }
  }
}
