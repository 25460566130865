<div id="moderation" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Comments</h2>
            <button class="btn-thrive_icon" mat-icon-button (click)="openFilters()">
                <mat-icon>filter_list</mat-icon>
            </button>
        </div>
        <div class="chip-container" *ngIf="hasActiveFilters()">
            <mat-chip-listbox>
                <mat-chip *ngIf="filters?.post_id">
                    Post id: {{ filters?.post_id }}
                    <button matChipRemove (click)="removeFilter('post_id')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters?.status">
                    Status: {{ filters?.status | titlecase }}
                    <button matChipRemove (click)="removeFilter('status')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters?.last_updated?.start_date && filters?.last_updated?.end_date">
                    Last updated: <span>Form: </span>{{ filters?.last_updated?.start_date }}<span> To: </span>{{ filters?.last_updated?.end_date }}
                    <button matChipRemove (click)="removeFilter('last_updated')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters?.post_type">
                    Post Type: {{ filters?.post_type | titlecase }}
                    <button matChipRemove (click)="removeFilter('post_type')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters?.user_details?.id">
                    User ID:{{ filters?.user_details?.id }}
                    <button matChipRemove (click)="removeFilter('id')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>

                <mat-chip *ngIf="filters?.user_details?.user_name">
                    User name:{{filters?.user_details?.user_name}} 
                    <button matChipRemove (click)="removeFilter('user_name')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>

                <mat-chip *ngIf="filters?.user_details?.display_name">
                    Display name:{{filters?.user_details?.display_name}}
                    <button matChipRemove (click)="removeFilter('display_name')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>

                <mat-chip *ngIf="filters?.search_query">
                    Title: {{ filters?.search_query | titlecase }}
                    <button matChipRemove (click)="removeFilter('search_query')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters?.recently_updated">
                    Sort By Recently Updated: {{ filters?.recently_updated | titlecase }}
                    <button matChipRemove (click)="removeFilter('recently_updated')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
            </mat-chip-listbox>
            <button mat-icon-button class="btn-thrive_icon delete-btn" (click)="clearAllFilters()" matTooltip="Clear All Filter" matTooltipClass="thrive-tooltip">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div style="overflow-x: auto">
        <tn-table
                  [title]="'Comments'"
                  [data]="data"
                  [columns]="columns"
                  [desktopDisplayedColumns]="desktopDisplayedColumns"
                  [mobileDisplayedColumns]="mobileDisplayedColumns"
                  [resultsLength]="resultsLength"
                  [pageSize]="pageSize"
                  [hidePageSize]="true"
                  [specialHTML]="{'body':comment,'page':openFeed}"
                  [specialButton]="approveButton"
                  [mobileTemplate]="mobileTemplate"
                  [pageIndex]="pageIndex"
                  (changePage)="changePage($event)"
                  (rowClick)="updateComment($event)">
        </tn-table>
    </div>
</div>

<ng-template #comment let-option>
    <span [innerHTML]="((option.row['showMore']) ? option.row.body : (option.row.body | slice:0:30)) | mentionReplace:(option.row?.mentions_data?.['Restaurant'] || {})"></span>
    <span *ngIf="!option.row['showMore'] && option.row.body.length > 30">...</span> 
    <span *ngIf="!option.row['showMore'] && option.row.body.length > 30">see more</span>
</ng-template>

<ng-template #openFeed let-option>
    <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openFeedFn(option.row); $event.stopPropagation()">
        <mat-icon class="mr-0">open_in_new</mat-icon>
    </button>
</ng-template>

<ng-template #approveButton let-option>
    <button *ngIf="option.row.status !== 'approved'" class="btn-thrive_icon mr-10" mat-icon-button  (click)="changeStatus(option.row,'approved');$event.stopPropagation()" [matTooltip]="'Approve'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon >check</mat-icon>
    </button>
    <button  *ngIf="option.row.status !== 'rejected'" class="btn-thrive_icon" mat-icon-button (click)="changeStatus(option.row,'rejected');$event.stopPropagation()" [matTooltip]="'Reject'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon>close</mat-icon>
    </button>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Post type</td>
            <td>{{ option.row.post_type }}</td>
        </tr>
        <tr>
            <td>Last updated</td>
            <td>{{ option.row.updated_at }}</td>
        </tr>
        <tr>
            <td>Open feed</td>
            <td>
                <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openFeed(option.row); $event.stopPropagation()">
                    <mat-icon class="mr-0">open_in_new</mat-icon>
                </button>
            </td>
        </tr>
        <tr>
            <td>Change status</td>
            <td>
                <button *ngIf="option.row.status !== 'approved'" class="btn-thrive_icon mr-10" mat-icon-button  (click)="changeStatus(option.row,'approved');$event.stopPropagation()" [matTooltip]="'Approve'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon >check</mat-icon>
                </button>
                <button  *ngIf="option.row.status !== 'rejected'" class="btn-thrive_icon" mat-icon-button (click)="changeStatus(option.row,'rejected');$event.stopPropagation()" [matTooltip]="'Reject'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon>close</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</ng-template>
