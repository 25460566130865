import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {RestaurantsService} from './restaurants.service';
import {FormControl,FormGroup,FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-custome-menu',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    ReactiveFormsModule],
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.scss']
})
export class RestaurantsComponent {
    data: any;
    page: number=0;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    showFilters: boolean = false;
    filterCategory: string;
    search: string;
    filterOptions: any;
    isLoading: boolean;
    range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null)
    });
    constructor(
        private restaurantsService: RestaurantsService, 
        private router: Router) {}

    ngOnInit() {
        this.initialise();
    }

    initialise(){
        this.columns = [
            {
                columnDef: 'restaurant_id',
                header: 'Id',
                cell: 'restaurant_id',
                sticky: true
            },
            {
                columnDef: 'merchant_id',
                header: 'Merchant id',
                cell: 'merchant_id',
            },
            {
                columnDef: 'google_place_id',
                header: 'Place id',
                cell: 'google_place_id',
                sticky: true
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'lat',
                header: 'Latitude',
                cell: 'lat',
            },
            {
                columnDef: 'lon',
                header: 'Longitude',
                cell: 'lon',
            },
            {
                columnDef: 'locality',
                header: 'Locality',
                cell: 'locality',
            },
            {
                columnDef: 'full_address',
                header: 'Address',
                cell: 'full_address',
            },
            {
                columnDef: 'image_url',
                header: 'Image',
                cell: 'image_url',
            },
            {
                columnDef: 'image_file_name',
                header: 'Image name',
                cell: 'image_file_name',
            },
            {
                columnDef: 'image_content_type',
                header: 'Image type',
                cell: 'image_content_type',
            },
            {
                columnDef: 'cuisines',
                header: 'Cuisines',
                cell: 'cuisines',
            },
            {
                columnDef: 'cost_for_two',
                header: 'Cost for two',
                cell: 'cost_for_two',
            },
            {
                columnDef: 'ad_hoc_tag',
                header: 'Ad hoc tags',
                cell: 'ad_hoc_tag',
            },
            {
                columnDef: 'created_at',
                header: 'Created at',
                cell: 'created_at'
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['restaurant_id','name'];
        this.getRestaurantsList(this.page, this.pageSize);
    }

    /**
     * get restaurants list
     * @param page page number
     * @param pageSize number of merchants in one page i.e. page size
     */
    getRestaurantsList(page:number,pageSize:number) {
        this.restaurantsService.getRestaurantsList(page,pageSize).subscribe((response: any) => {
            this.data = response.restaurants;
            this.resultsLength = response.total_results;
        });
    }

    clearText() {
        this.search = null;
    }

    /**
     * update custom item list on page change
     * @param event table event containing page index and size
     */
    changePage(event: any) {
        if (this.filterCategory && this.search) {
            this.applyFilters(event.pageIndex,event.pageSize);
        } else {
            this.getRestaurantsList(event.pageIndex, event.pageSize);
        }
    }

    /**
     * fetch restaurant list as per the applied filters
     * @param page  page number
     */
    applyFilters(page: number,pageSize:number) {
        let filterObj:any={};
        switch(this.filterCategory){
            case 'created_at':
                let createdStartDate=new Date(this.range.get('start').value);
                let startDate=`${createdStartDate.getFullYear()}-${('0' + (createdStartDate.getMonth() + 1)).slice(-2)}-${('0' + createdStartDate.getDate()).slice(-2)}`;
                let createdEndDate=new Date(this.range.get('end').value);
                let endDate=`${createdEndDate.getFullYear()}-${('0' + (createdEndDate.getMonth() + 1)).slice(-2)}-${('0' + createdEndDate.getDate()).slice(-2)}`;
                filterObj['created_at_from'] = startDate;
                filterObj['created_at_to'] = endDate;
                break;
            default:
                if(this.search){
                    filterObj[this.filterCategory] = this.search;
                }
        }
        let tempData=this.data;
        this.data=null;
        this.restaurantsService.getRestaurantsList(page,pageSize,filterObj).subscribe((response: any) => {
            this.data = response.restaurants;
            this.resultsLength = response.total_results;
        },(err)=>{
            this.data=tempData;
            Swal.fire({title: 'Error', text: 'Error in applyingthe filter', icon: 'error', timer: 4000, timerProgressBar: true});
        });
    }

    clearFilter(){
        this.filterCategory=null;
        this.search=null;
        this.getRestaurantsList(0,this.pageSize);
    }

    /**
     * go to restaurant create/edit page
     * @param event 
     */
    navigateTo(event: any) {
        this.router.navigateByUrl('restaurants/' + event.restaurant_id);
    }
}