// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    adminUrl: 'https://adminapi.hashtagloyalty.net',
    businessURL: 'https://dashboardapi.hashtagloyalty.net',
    consumerUrl: 'https://massiveapi.hashtagloyalty.net/mobile/api',
    micrositeWebsiteUrl:'https://order.hashtagloyalty.net/order/',
    socialWebsiteUrl:'https://social.hashtagloyalty.net',
    xApiKey:'c862759292123471f04301753c9604ce77a43ae905eb09f9bb395dc8db3caaf381635d22f32c2dd19c2696b2950b853e317ca0e7578b1eadf07daa68416d63e4',
    s3Url:'https://hashtagloyaltydev.s3.ap-southeast-1.amazonaws.com'
};