<div id="brandCollaborationEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/brand-collaboration'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2 class="mainHeading">{{ editId != 'new' ? 'Update' : 'Create' }} Collaboration</h2>
        </div>
    </div>
    <form [formGroup]="brandForm" class="mt-30">
        <div class="formgrid">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" formControlName="name" />
                <mat-error *ngIf="brandForm.get('name').hasError('required') && brandForm.get('name').touched">Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Listing slug</mat-label>
                <span class="slug-prefix" matTextPrefix>https://thrivenow.in/order/</span>
                <input class="slug-input" matInput placeholder="Enter brand slug" formControlName="listing_slug" />
                <mat-error *ngIf="brandForm.get('listing_slug').hasError('required') && brandForm.get('listing_slug').touched">Required</mat-error>
            </mat-form-field>
        </div>

        <div>
            <p class="basetitle mt-20">Upload banner image (4:1)</p>
            <br />
            <image-upload [aspectRatio]="'4:1'" [imageUrl]="brandForm.get('banner_url')?.value" (uploadImg)="onImageUpload($event, 'background')"></image-upload>
        </div>

        <div class="formgrid mt-20">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Heading</mat-label>
                <input matInput placeholder="Enter heading" formControlName="heading" />
                <mat-error *ngIf="brandForm.get('heading').hasError('required') && brandForm.get('heading').touched">Required</mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                <mat-label>Sub heading</mat-label>
                <input matInput placeholder="Enter sub heading" formControlName="sub_heading" />
                <mat-error *ngIf="brandForm.get('sub_heading').hasError('required') && brandForm.get('sub_heading').touched">Required</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mt-20">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Enter description" formControlName="description"></textarea>
            <mat-error *ngIf="brandForm.get('description').hasError('required')">Required</mat-error>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mt-20">
            <mat-label>Merchants</mat-label>
            <mat-select placeholder="Select Merchants" multiple formControlName="merchant_ids">
                <mat-option>
                    <ngx-mat-select-search placeholderLabel="Find Merchants" noEntriesFoundLabel="Merchant not found" [formControl]="searchMerchantControl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let merchant of filterOptions" [value]="merchant.id">{{ merchant.id }} - {{ merchant.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="brandForm.get('merchant_ids').hasError('required') && brandForm.get('merchant_ids').touched">Required</mat-error>
        </mat-form-field>
        <div class="formgrid mt-20">
            <div [class.color-picker-focus]="colorPickerId == 'header'">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Header color*</mat-label>
                    <input matInput formControlName="heading_color" [(colorPicker)]="colorHeader" (colorPickerChange)="brandForm.get('heading_color').setValue($event)" (focus)="(colorPickerId == 'header')" />
                    <span matSuffix class="color-circle" [style.background-color]="colorHeader"></span>
                </mat-form-field>
            </div>
            <div [class.color-picker-focus]="colorPickerId == 'footer'">
                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Footer color*</mat-label>
                    <input matInput formControlName="footer_color" [(colorPicker)]="colorFooter" (colorPickerChange)="brandForm.get('footer_color').setValue($event)" (focus)="(colorPickerId == 'footer')" />
                    <span matSuffix class="color-circle" [style.background-color]="colorFooter"></span>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" 
                mat-button 
                accessControl
                moduleType="brand_collaboration" 
                (click)="submitForm()" 
                [disabled]="!brandForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
