import {CommonModule} from '@angular/common';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {MatSliderModule} from '@angular/material/slider';
import {ImageCroppedEvent, ImageCropperModule, ImageTransform} from 'ngx-image-cropper';
import {HttpClient} from '@angular/common/http';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import Swal from 'sweetalert2';

export interface ImageEditorParams {
    image: string;
    aspectRatio: number;
    type?: string;
    message?: string;
}

@Component({
    selector: 'app-image-editor',
    templateUrl: './image-editor.component.html',
    styleUrls: ['./image-editor.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, ImageCropperModule, MatSliderModule, FormsModule, ReactiveFormsModule, RouterModule]
})
export class ImageEditorComponent implements OnInit {
    @ViewChild('bannerImageInput', {static: false})
    bannerImageInput: ElementRef;
    image_changed_event: any = '';
    cropped_image: any = '';
    canvas_rotation = 0;
    rotation: number = 0;
    scale: number = 1;
    show_cropper: boolean = false;
    contain_within_aspect_ratio: boolean = false;
    transform: ImageTransform = {};
    image_zoom: number = 10;
    image_straighten: number = 0;
    show_delete: boolean = false;
    image_base64: any;
    image_blob: any = null;

    constructor(public dialogRef: MatDialogRef<ImageEditorComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageEditorParams, private http: HttpClient) {}

    ngOnInit() {
        setTimeout(() => {
            this.getBase64();
        }, 0);
    }
    // get base 64 form image url
    getBase64() {
        this.http
            .get(this.data.image, {
                responseType: 'blob',
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
                    Pragma: 'no-cache',
                    Expires: '0'
                }
            })
            .subscribe((response: any) => {
                let reader = new FileReader();
                reader.readAsDataURL(response);
                reader.onload = (e: any) => {
                    this.image_base64 = reader.result;
                };
            });
    }
    // upload new image
    fileChangeEvent(event: any): void {
        let mb = Math.round(event.target.files[0].size / Math.pow(1024, 2)).toFixed(2);
        if (Number(mb) <= 5) {
            this.image_changed_event = event;
            this.data.type = 'edit';
        } else {
            Swal.fire('Error', 'Maximum size allowed is 5 MB.', 'error');
            this.bannerImageInput.nativeElement.value = '';
        }
    }
    // final croped image we get in imageCropper
    imageCropped(event: ImageCroppedEvent) {
        this.cropped_image = event.base64;
        const croppedImageBlob: Blob = this.dataURItoBlob(event.base64);
        this.image_blob = croppedImageBlob;
    }
    // this method invoke when image is loaded finally
    imageLoaded() {
        this.show_cropper = true;
    }
    dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    }
    // this method is use for transform image
    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }
    // rotate the image
    rotateRight() {
        this.canvas_rotation++;
        this.flipAfterRotate();
    }
    // straigten the image
    straighten(event: any) {
        this.canvas_rotation = this.image_straighten / 10;
        // this.flipAfterRotate();
    }
    // zoomin and zoomout uploaded image
    zoom(event: any) {
        this.scale = event.value / 10 === 0 ? 0.0 : event.value / 10;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }
    // delete image
    deleteImage() {
        this.dialogRef.close({type: 'delete', base64: null, image_blob: null});
    }
    // apply image
    submitCropImage() {
        this.dialogRef.close({type: 'apply', base64: this.cropped_image, image_blob: this.image_blob});
    }
    // close modal
    onClick() {
        this.dialogRef.close();
    }
}
