import {CommonModule} from '@angular/common';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {BannerService} from '../banner.service';
import {FiltersComponent} from '../../shared/filters/filters.component';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
@Component({
    selector: 'app-banner-edit',
    templateUrl: './banner-edit.component.html',
    styleUrls: ['./banner-edit.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatSlideToggleModule, MatCheckboxModule, FiltersComponent,AccessControlDirective],
    providers: [BannerService]
})
export class BannerEditComponent {
    edit_id: string;
    bannerForm: FormGroup;
    filters: any;
    uploadedImg: any;
    showForm:boolean = false;
    @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;

    constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private bannerService: BannerService) {
        this.createForm();
        this.edit_id = this.route.snapshot.params.id;
        if (this.edit_id !== 'new') {
            this.bannerService.getBannerById(this.edit_id).subscribe((banner: any) => {
                this.showForm = true;
                this.bannerForm.patchValue(banner);
                this.filters = banner.filters;
                this.uploadedImg = banner.image_url;
            });
        }  else {
            this.filters = {order_type: "delivery"}; 
            this.showForm = true; 
        }
       
    }

    ngOnInit() {}

    createForm(formValue?) {
        this.bannerForm = this.fb.group({
            title: [formValue ? formValue.title : ''],
            description: [formValue ? formValue.description : ''],
            sort_order: [formValue ? formValue.sort_order : 0, Validators.required],
            image_url: [formValue ? formValue.image_url : '', Validators.required],
            status: [formValue ? formValue.status : true, Validators.required],
            is_clickable: [formValue ? formValue.is_clickable : false, Validators.required]
        });
    }

    onImageUpload(event: any) {
        let mb = Math.round(event.target.files[0].size / Math.pow(1024, 2)).toFixed(2);
        if (Number(mb) <= 5) {
            this.bannerForm.get('image_url').setValue(event.target.files[0]);
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (e: any) => {
                this.uploadedImg = reader.result;
            };
        } else {
            Swal.fire('Error!', 'Maximum size allowed is 5 MB!', 'error');
            this.fileUpload.nativeElement.value = '';
        }
    }

    removeImage() {
        this.fileUpload.nativeElement.value = '';
        this.bannerForm.get('image_url').setValue('');
    }

    fetchFilters(event) {
        this.filters = event;
    }

    submitBanner() {
        let data = this.bannerForm.value;
        data.filters = this.filters;
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('filters', JSON.stringify(data.filters));
        formData.append('image', data.image_url);
        formData.append('is_clickable', data.is_clickable);
        formData.append('sort_order', data.sort_order);
        if (this.bannerForm.valid) {
            if (this.edit_id !== 'new') {
                formData.append('status', data.status);
                formData.delete('image');
                this.bannerService.updateBanner(this.edit_id, formData).subscribe(
                    () => {
                        Swal.fire('Updated!', 'Banner has been updated created!', 'success');
                        this.router.navigateByUrl('/app-banner');
                    },
                    (err) => {
                        if (err.error.errors.filters) Swal.fire('Error!', 'Filters cannot be blank!', 'error');
                    }
                );
            } else {
                this.bannerService.createBanner(formData).subscribe(
                    () => {
                        Swal.fire('Created!', 'Banner has been successfully created!', 'success');
                        this.router.navigateByUrl('/app-banner');
                    },
                    (err) => {
                        if (err.error.errors.filters) Swal.fire('Error!', 'Filters cannot be blank!', 'error');
                    }
                );
            }
        }
    }
}
