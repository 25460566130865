import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import { RbacService } from '../shared/rbac/rbac.service';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, FormsModule, MatButtonModule]
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loginError = false;
    url = environment.adminUrl;

    constructor(public fb: FormBuilder, 
                private http: HttpClient, 
                private rbacService:RbacService,
                private router: Router) {}

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    onSubmit() {
        if (this.loginForm.valid) {
            this.http.post(this.url + '/admin_auth', this.loginForm.value).subscribe(
                (res: any) => {
                    localStorage.setItem('token', res.authentication_token);
                    localStorage.setItem('name', res.name);
                    localStorage.setItem('email', res.email);
                    this.getAccessFeatures();
                },
                (err) => {
                    this.loginError = err?.error?.errors;
                }
            );
        }
    }
    getAccessFeatures(){
        this.http.get(this.url+'/admin_access_control').subscribe((res:any)=>{
            this.rbacService.setDataInStore('access_control',res.access_control);
            this.router.navigateByUrl('/merchant');
        });
    }
}
