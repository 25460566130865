import {Component, OnInit} from '@angular/core';
import {CommonModule,formatDate} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
    AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import Swal from 'sweetalert2';
import {MatIconModule} from '@angular/material/icon';
import {ThrivePricingService} from '../thrive-pricing.service';
import {MatSelectModule} from '@angular/material/select';
import {AccessControlDirective } from '../../shared/rbac/rbac.directive';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
export interface temporaryCommissionIf{
        id: number,
        merchant_id:number,
        consumer_app_new_user_fee:number,
        consumer_app_repeat_user_fee: number,
        is_active: boolean,
        start_date:string,
        end_date:string,
        created_at:string,
        updated_at:string,
        direct_fee_value: {
            dine_in:number,
            delivery: number,
            take_away: number
        }
}

export interface commissionIf{
    id:number,
    name:string,
    location:string,
    pincode:string,
    city:string,
    created_at:string,
    contact_number:string,
    manual_popularity:number,
    payment_gateway_plan_id?: number | null,
    platform: string,
    chain_id: null | number,
    thrive_promise:number,
    status:number,
    commission_for_new_customer?:string | null,
    commission_for_repeat_customer?:string | null,
    commission_for_direct_platform?:string | null,
    pg_plan:number,
    temporary:boolean | null,
    direct?: {
        delivery:number | null,
        take_away: number | null,
        dine_in: number | null
    },
    ordering_mode:Array<string>
    ondc_max_fee:number | null
}
// Custom validator to check if end date is greater than start date
export function dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('start_date')?.value;
      const endDate = control.get('end_date')?.value;
      // If both start and end dates are present, check if end date is greater
      if (startDate && endDate) {
        return new Date(startDate) < new Date(endDate) ? null : { dateRangeInvalid: true };
      }
      return null; // Return no error if dates are missing
    };
  }
@Component({
    selector: 'app-thrive-pricing-create',
    templateUrl: './thrive-pricing-create.component.html',
    styleUrls: ['./thrive-pricing-create.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatSelectModule,AccessControlDirective,MatSlideToggleModule,MatDatepickerModule,MatNativeDateModule],
})

export class ThrivePricingCreateComponent implements OnInit{
    pricingForm: FormGroup;
    editId: string;
    pricingDetails:commissionIf;
    submitted:boolean = false;
    minDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));;
    showDirect:boolean = false;
    showConsumer:boolean = false;
    showOndc:boolean = false;
    temporaryCommissionData:temporaryCommissionIf;
    showTemporary:boolean = false;
    constructor(private thrivePricingService: ThrivePricingService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}
    temporary_data:FormGroup;
    ngOnInit() {
        this.editId = this.route.snapshot.params.id;
        this.createForm();
    }

    /**
     * To edit pricing 
     */
    createForm() {
        this.pricingForm = this.fb.group<any>({
            id: this.editId,
            thrive_exclusive_status: new FormControl('', Validators.required),
            popularity: new FormControl('', [Validators.required,Validators.min(0), Validators.max(1000)]),
            pg_plan: new FormControl('', [Validators.required]),
            //consumer app
            new_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            repeat_customer_price: new FormControl('', [Validators.required,Validators.min(1)]),
            //direct ordering
            direct:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(1)]),
                take_away:new FormControl('', [Validators.required,Validators.min(1)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(1)])
            }),
            ondc_max_fee:new FormControl('', [Validators.required,Validators.min(0),Validators.max(10)]),
            temporary: new FormControl(false),
        });

        this.temporary_data = this.fb.group({
            id: this.editId,
            start_date: new FormControl(null,[Validators.required]),
            end_date: new FormControl(null,[Validators.required]),
           //consumer app
            new_customer_price: new FormControl('', [Validators.required,Validators.min(0)]),
            repeat_customer_price: new FormControl('', [Validators.required,Validators.min(0)]),
            //direct ordering
            direct:this.fb.group({
                delivery:new FormControl('', [Validators.required,Validators.min(0)]),
                take_away:new FormControl('', [Validators.required,Validators.min(0)]),
                dine_in:new FormControl('', [Validators.required,Validators.min(0)])
            })
        },{ validators: dateRangeValidator() });

        //in case of editing a pricing
        this.thrivePricingService.getPricingById(this.editId).subscribe((pricing: commissionIf) => {
            this.pricingDetails = pricing;
            let remainingElements:Array<string> = []
            if(this.pricingDetails?.ordering_mode?.length){
                remainingElements = ['delivery','take_away','dine_in'].reduce((acc, element) => {
                    if (!this.pricingDetails.ordering_mode.includes(element)) {
                    acc.push(element);
                    }
                    return acc;
                }, []);
            }
            this.pricingForm.patchValue({
                thrive_exclusive_status: pricing.thrive_promise,
                popularity: pricing.manual_popularity,
                pg_plan: pricing.pg_plan,
                //consumer app
                new_customer_price: pricing.commission_for_new_customer,
                repeat_customer_price: pricing.commission_for_repeat_customer,
                //direct ordering
                direct:{
                    delivery: pricing?.direct?.delivery,
                    take_away: pricing?.direct?.take_away,
                    dine_in: pricing?.direct?.dine_in
                },
                //ondc ordering
                ondc_max_fee:pricing?.ondc_max_fee ? pricing?.ondc_max_fee : 0, 
                temporary:pricing.temporary 
            });

            switch (pricing.platform) {
                case 'consumer_app':
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                    this.removeValidation('ondc_max_fee');
                    this.showConsumer = true;
                break;
                case 'microsite':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.removeValidation('ondc_max_fee');
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                    this.showDirect = true;
                break;
                case 'crm_only':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    this.removeValidation('direct.delivery');
                    this.removeValidation('direct.take_away');
                    this.removeValidation('direct.dine_in');
                break;
                case 'ondc_only':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                    this.showOndc = true;
                    this.showDirect = true;
                break;
                case 'both':
                    this.removeValidation('ondc_max_fee');
                    this.showConsumer = true;
                    this.showDirect = true;
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                break;
                case 'microsite_and_ondc':
                    this.removeValidation('new_customer_price');
                    this.removeValidation('repeat_customer_price');
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                    this.showDirect = true;
                    this.showOndc = true;
                break;
                case 'consumer_app_and_ondc':
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                    this.showConsumer = true;
                    this.showOndc = true;
                    this.showDirect = true;
                break;
                case 'all_platforms':
                    remainingElements.forEach(mode => {
                        this.removeValidation('direct.'+[mode]);       
                    });
                    this.showConsumer = true;
                    this.showOndc = true;
                    this.showDirect = true;
                break;
            }

            if(this.pricingDetails.temporary){
                this.thrivePricingService.getTemporaryCommissionById(this.editId).subscribe((response:temporaryCommissionIf)=>{
                    this.temporaryCommissionData = response;
                    let startDate = new Date(response.start_date);
                    let currentDate = new Date();
                    if(startDate)
                    this.showTemporary = (currentDate < startDate);
                    this.temporary_data.patchValue({
                         //consumer app
                        new_customer_price: response.consumer_app_new_user_fee,
                        repeat_customer_price: response.consumer_app_repeat_user_fee,
                        //direct ordering
                        direct:{
                            delivery: response?.direct_fee_value?.delivery,
                            take_away: response?.direct_fee_value?.take_away,
                            dine_in: response?.direct_fee_value?.dine_in
                        },
                        start_date:response?.start_date,
                        end_date:response?.end_date,
                    });
                    this.pricingForm.addControl('temporary_data',this.temporary_data);
                })
            }
        });
    }

    removeValidation(controlName:string){
        this.pricingForm.get(controlName).clearValidators();
        this.pricingForm.get(controlName).updateValueAndValidity();
        if(controlName !== 'ondc_max_fee'){
            this.temporary_data.get(controlName).clearValidators();
            this.temporary_data.get(controlName).updateValueAndValidity();
        }
    }

    onToggleTemporary({checked}){
        if(checked){
            this.temporary_data.patchValue(this.pricingForm.value);
            this.pricingForm.addControl('temporary_data',this.temporary_data);
            this.showTemporary = true;
        } else {
            this.pricingForm.removeControl('temporary_data');
            this.showTemporary = false;  
        }
    }

    // Converts a date to ISO string in UTC
    toUTCString(date: Date): string {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }

    disabledTemporaryCommission(){
        Swal.fire({
            title: 'Are you sure you want to delete?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
              this.thrivePricingService.deleteTemporaryCommission(this.temporaryCommissionData.id).subscribe((response:any)=>{
                this.createForm();  
              });
            }
        });
    }

    onDateChange($event){
        // Trigger validation when date changes
        this.temporary_data.updateValueAndValidity();
    }

    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if(this.pricingForm.valid){
            this.submitted = true;
            let temporary_data:any = {};
            if(this.pricingForm.get('temporary_data')){
                temporary_data = this.pricingForm.get('temporary_data').value;
                delete this.pricingForm.value.temporary_data;
            }
            this.thrivePricingService.updatePricingMerchants(this.pricingForm.value).subscribe(() => {
                if(this.pricingForm.get('temporary_data')){
                    temporary_data.start_date = this.toUTCString(temporary_data.start_date);
                    temporary_data.end_date = this.toUTCString(temporary_data.end_date);
                    this.thrivePricingService.createTemporaryCommission(temporary_data).subscribe((response:any)=>{
                        Swal.fire('Updated!', 'Price has been updated!', 'success');
                        this.router.navigateByUrl('/thrive-pricing');
                    });
                } else {
                    Swal.fire('Updated!', 'Price has been updated!', 'success');
                    this.router.navigateByUrl('/thrive-pricing');
                }
            });
        } else {
            this.pricingForm.markAllAsTouched();  
        }
    }
}
