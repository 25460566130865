import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {VersionService} from './version.service';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-app-version',
    templateUrl: './app-version.component.html',
    styleUrls: ['./app-version.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatRadioModule,AccessControlDirective],
    providers: [VersionService]
})
export class AppVersionComponent {
    iosVersion: any;
    androidVersion: any;
    versionForm: FormGroup;
    showForm = false;

    constructor(private versionService: VersionService, private fb: FormBuilder) {
        this.getVersions();
        this.versionForm = this.fb.group({
            current_version: ['', Validators.required],
            minimum_supported_version: ['', Validators.required],
            platform: ['', Validators.required]
        });
    }

    getVersions() {
        this.versionService.getVersion().subscribe((versions: any) => {
            let iosVersions = versions.filter((version) => version.platform === 'ios');
            let androidVersions = versions.filter((version) => version.platform === 'android');
            this.iosVersion = iosVersions[iosVersions.length - 1];
            this.androidVersion = androidVersions[androidVersions.length - 1];
        });
    }

    saveVersion() {
        this.versionForm.markAllAsTouched();
        if (this.versionForm.valid) {
            this.versionService.createVersion(this.versionForm.value).subscribe(() => {
                Swal.fire({title: 'Success', text: 'App Version created successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.showForm = false;
                this.getVersions();
            });
        }
    }
}
