import {Component} from '@angular/core';
import {CategoryService} from './category.service';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {Router, RouterModule} from '@angular/router';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {FormControl, FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import Swal from 'sweetalert2';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, TableComponent, MatIconModule, RouterModule, MatFormFieldModule, MatSelectModule, FormsModule, MatSlideToggleModule,AccessControlDirective],
    providers: [CategoryService]
})
export class CategoryComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    filter: FormControl = new FormControl('');
    status: string = 'all';

    constructor(private categoryService: CategoryService, private router: Router) {}

    ngOnInit() {
        this.getCategories(0, 'all');
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'title',
                header: 'Title',
                cell: 'title',
                pipe: 'titlecase',
                sticky: true
            },
            {
                columnDef: 'category_type',
                header: 'Category type',
                cell: 'category_type'
            },
            {
                columnDef: 'sort_order',
                header: 'Sort order',
                cell: 'sort_order'
            },
            {
                columnDef: 'status',
                header: 'Status',
                cell: 'status',
                isToggle: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id','title'];
        this.filter.valueChanges.subscribe((e) => {
            this.getCategories(0, e);
        });
    }

    getCategories(page, filter) {
        this.categoryService.getCategories(page, filter).subscribe((categories: any) => {
            this.data = categories.categories;
            this.resultsLength = categories.total_results;
        });
    }

    changePage(event) {
        this.getCategories(event.pageIndex, this.status);
    }

    actionClicked(event) {
        if (event.action == 'status') {
            const formData = new FormData();
            formData.append('status',event.checked);
            this.categoryService.updateCategory(event.row.id,formData).subscribe(() => {
                Swal.fire({title: 'Status Updated', text: 'Status updated successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.getCategories(0, this.status);
            });
        }
    }

    changeStatus(event) {
        this.getCategories(0, event.value);
    }

    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('app-category/' + event.id);
    }
}
