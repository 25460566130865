import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SourceManagementService {
    private url = environment;

    constructor(public http: HttpClient) {}

    /**
     * To Get Sources from API
     * @returns API url for getting Sources
     */
    getSources() {
        return this.http.get(this.url.adminUrl + '/diy_sources');
    }
    /**
     * To create new source
     * @param body To add new data
     * @returns To get API url to POST Sources
     */
    createSources(body) {
        return this.http.post(this.url.adminUrl + '/diy_sources', body);
    }
    /**
     * To fetch Source by Id
     * @param id To get id by which the source needs to be searched
     * @returns To get API url to GET Sources by id
     */
    getSourcesById(id: string) {
        return this.http.get(this.url.adminUrl + '/diy_sources/' + id);
    }
    /**
     * To Update already present Source
     * @param id To get Id of Source to be updated
     * @param body To edit the Data of a Source
     * @returns To get API url to PUT Sources by id
     */
    updateSources(id: string, body: any) {
        return this.http.put(this.url.adminUrl + '/diy_sources/' + id, body);
    }
    /**
     * To delete source from API
     * @param id To get row id to be deleted
     * @returns To get API to DELETE sources by id
     */
    deleteSource(id: string) {
        return this.http.delete(this.url.adminUrl + '/diy_sources/' + id);
    }
}
