import { CommonModule,Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ColumnData, TableComponent } from '../../shared/table/table.component';
import { ModerationService } from '../moderation.service';
import Swal from 'sweetalert2';
import { MentionModule } from 'angular-mentions';
import { BehaviorSubject, debounceTime, distinctUntilChanged, forkJoin } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusDialog } from '../moderation-tool.component';
import { MatListModule } from '@angular/material/list';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar, MatSnackBarRef, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import { columnsComments,columnsRecommendations,listTag,selectedTags,uploadFiles} from './moderation-tool';
import { CommentDialog } from '../comment-dialog/comment-dialog';
import { ImageDialog } from './image-dialog/image-dialog';
import { MentionReplacePipe } from '../../shared/pipes/mention-replace.pipe';
/**
 *
 * @param control form control value
 * @returns valid or invalid
 */
export function tagLimit(control: AbstractControl) {
    if (control.value && control.value.length > 5) {
        return { maxTag: true };
    } else {
        return null;
    }
}

@Component({
    selector: 'app-moderation-tool-edit',
    templateUrl: './moderation-tool-edit.component.html',
    styleUrls: ['./moderation-tool-edit.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        RouterModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        TableComponent,
        MatTooltipModule,
        NgxMatSelectSearchModule,
        MatCheckboxModule,
        MatListModule,
        MentionModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        AccessControlDirective
    ],
    providers: [ModerationService,MentionReplacePipe]
})
export class ModerationToolEditComponent implements OnInit {
    postForm: FormGroup;
    restaurantAdHocTags = this.fb.group({})
    editId: any;
    userId: any;
    postDetails:any;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    allTags: string[] = [];
    @ViewChild('file') fileElement: ElementRef<HTMLInputElement>;
    @ViewChild('tags') tags: ElementRef<HTMLInputElement>;
    @ViewChild('cohort') cohort: ElementRef<HTMLInputElement>;
    @ViewChild('cuisine') cuisine: ElementRef<HTMLInputElement>;
    @ViewChild('adhoc') adhoc: ElementRef<HTMLInputElement>;
    @ViewChild('restaurantAdHocTags') restoAdHocTags:ElementRef<HTMLInputElement>;
    @ViewChild('mentionDiv', { static: false }) mentionDiv: ElementRef;
    dataComments: any;
    dataRecommendations: any;
    pageSizeComments: number = 10;
    pageSizeRecommendations: number = 10;
    resultsLengthComments = 0;
    resultsLengthRecommendations: number = 0;
    columnsComments: ColumnData[] = [];
    columnsRecommendations: ColumnData[] = [];
    desktopDisplayedColumnsComments: string[] = [];
    desktopDisplayedColumnsRecommendations: string[] = [];
    mobileDisplayedColumnsComments: string[] = [];
    mobileDisplayedColumnsRecommendations: string[] = [];
    selectedIndex = 0;
    botUsers: any;
    myFiles: any[] = [];
    showFiles: any = [];
    searchResto = new FormControl('');
    restoList: any[] = [];
    selectedResto: any[] = [];
    restoNotFound: boolean = false;
    selectedMentions = [];
    mentions = new BehaviorSubject<any[]>([]);
    mentionConfig:any = {};
    uploadFiles: any = uploadFiles;
    submitted: boolean = false;
    maxTagCharLimit: number = 30;
    selectedTags:any=selectedTags;
    listTag:any=listTag;
    constructor(private location: Location,
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private moderationService: ModerationService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private renderer:Renderer2,
        private mentionReplacePipe: MentionReplacePipe) {
        this.mentions = new BehaviorSubject<any[]>([]);
    }

    ngOnInit() {
        this.moderationService.getBotUsers().subscribe((users) => (this.botUsers = users));
        this.editId = this.route.snapshot.params.id;
        this.userId = this.route.snapshot.queryParams.user_id;
        if (!this.route.snapshot.params.module) {
            this.changeTab({ index: 0 });
        }
        if (this.route.snapshot.params.module === 'comments') {
            this.changeTab({ index: 1 });
        }
        if (this.route.snapshot.params.module === 'recommendations') {
            this.changeTab({ index: 2 });
        }
        if (this.route.snapshot.params.moduleId) {
            this.changeTab({ index: 1 }, this.route.snapshot.params.moduleId);
        }
        this.createForm();
        this.columnsComments = columnsComments ;
        this.columnsRecommendations = columnsRecommendations;
        this.desktopDisplayedColumnsComments = this.columnsComments.map((c) => c.columnDef);
        this.desktopDisplayedColumnsRecommendations = this.columnsRecommendations.map((c) => c.columnDef);
        this.mobileDisplayedColumnsComments = ['body', 'user'];
        this.mobileDisplayedColumnsRecommendations = ['name', 'mid'];
        if (this.editId !== 'new') {
            this.getFeedDetails();
            this.getComments(0);
            this.getRecommendations();
        }

        this.searchResto.valueChanges.pipe(
            debounceTime(700),
            distinctUntilChanged()).subscribe(value => {
                if (value && value.length) {
                    this.getResto(value);
                    this.restoNotFound = false;
                } else {
                    this.restoNotFound = false;
                }
            });

           
            this.postForm.get('category_tag_names').valueChanges.subscribe((e) => { 
                if(this.postForm.get('category_tag_names').value.length > 1)
                this.getAllTypeOftagsList('post_category',e,'cohort'); 
            });
            
            
            this.postForm.get('cuisines_tag_names').valueChanges.subscribe((e) => {
                if(this.postForm.get('cuisines_tag_names').value.length > 1) 
                this.getAllTypeOftagsList('post_cuisines',e,'cuisine'); 
            });
            
           
            this.postForm.get('ad_hoc_tag_names').valueChanges.subscribe((e) => { 
                if(this.postForm.get('ad_hoc_tag_names').value.length > 1)
                this.getAllTypeOftagsList('post_ad_hoc',e,'adhoc'); 
            });
    }

    configureMentions(){
        if(this.mentionDiv?.nativeElement){
            this.mentionConfig = this.moderationService.getMentionConfig(
                this.mentionDiv.nativeElement,
                this.postForm.get('body'),
                true,
                this.selectedMentions,
                this.restaurantAdHocTags,
                this.listTag
            );
        } 
    }

    getFeedDetails() {
        this.moderationService.getFeed(this.editId, this.userId).subscribe((post: any) => {
            this.postDetails = post;
            this.postTypeChange({ value: post.post_type });
            this.createForm(post);
            this.selectedTags.tags = post.tag_names;
            this.selectedTags.cuisine = post.cuisines_tag_names;
            this.selectedTags.cohort = post.category_tag_names;
            this.selectedTags.adhoc = post.ad_hoc_tag_names;
            if (post.post_type === 'list') {
                if (post.mentions_data.Restaurant) {
                    for (const key in post.mentions_data.Restaurant) {
                        if (post.mentions_data.Restaurant.hasOwnProperty(key)) {
                            post.mentions_data.Restaurant[key].checked = true;
                            post.mentions_data.Restaurant[key].restaurant_id = post.mentions_data.Restaurant[key].id;
                            post.mentions_data.Restaurant[key]?.items.forEach(element => {
                                element['item_name'] = element.name;
                                element['item_configured_for'] = 'menu_items'
                            });
                            if(post.mentions_data.Restaurant[key]?.custom_items){
                                post.mentions_data.Restaurant[key].custom_items?.forEach(element => {
                                    element['item_name'] = element.name;
                                    element['item_configured_for'] = 'custom_items'
                                });
                                post.mentions_data.Restaurant[key].selected_items = [...post.mentions_data.Restaurant[key].items, ...post.mentions_data.Restaurant[key]?.custom_items];
                            } else {
                                post.mentions_data.Restaurant[key].selected_items = [...post.mentions_data.Restaurant[key].items];
                            }
                            post.mentions_data.Restaurant[key]['items_search'] = new FormControl('');
                            post.mentions_data.Restaurant[key]['items_search'].valueChanges.subscribe((e) => {
                                post.mentions_data.Restaurant[key].items = this.loadItems(post.mentions_data.Restaurant[key],e);
                            });
                            if(post.mentions_data.Restaurant[key].merchant_id)
                            post.mentions_data.Restaurant[key].items = this.loadItems(post.mentions_data.Restaurant[key]);
                            this.addToArray(post.mentions_data.Restaurant[key]);
                            this.restoList.push(post.mentions_data.Restaurant[key]);
                        }
                    }
                }
            } else if (post.post_type === 'simple_post') {
                let mentions = [];
                if (post?.mentions_data['Restaurant']) {
                    for (const key in post.mentions_data['Restaurant']) {
                        mentions.push(post.mentions_data['Restaurant'][key]);
                    }
                    this.selectedMentions = mentions.map((mention) => {
                        mention.restaurant_id = mention.id;
                        this.restaurantAdHocTags.addControl(mention.restaurant_id,new FormControl(''))
                        this.restaurantAdHocTags.get([mention.restaurant_id]).valueChanges.subscribe((e) => { this.getAllTypeOftagsList('restaurant_ad_hoc',e,'restaurant_ad_hoc'); });
                        this.selectedTags[mention.restaurant_id] = [];
                        this.selectedTags[mention.restaurant_id] = post.restaurant_ad_hoc_tags[mention.restaurant_id]
                        this.restaurantAdHocTags.get([mention.restaurant_id]).setValue(post.restaurant_ad_hoc_tags[mention.restaurant_id]);
                        return mention;
                    });
                } 
                let body = this.mentionReplacePipe.transform(this.postForm.get('body').value,post?.mentions_data['Restaurant']);
                this.renderer.setProperty(this.mentionDiv.nativeElement, 'innerHTML', body); 
                this.uploadFiles.uploadedData = post.media_data;
                this.configureMentions();
            }
        });
    }

    /**
     * show form according to the post type
     * @param {any} event Post type
     */
    postTypeChange(event: any) {
        switch (event.value) {
            case 'question':
                this.postForm.addControl('body', new FormControl('', [Validators.required, Validators.maxLength(1000)]));
                this.postForm.addControl('tag_names', new FormControl('', tagLimit));
                this.postForm.removeControl('file');
                this.postForm.get('tag_names').valueChanges.subscribe((e) => { this.getTags(e); });
                this.postForm.removeControl('selected_resto');
                break;
            case 'simple_post':
                this.postForm.addControl('body', new FormControl('', [Validators.required, Validators.maxLength(1000)]));
                this.postForm.removeControl('tag_names');
                this.postForm.addControl('file', new FormControl(''));
                this.postForm.removeControl('selected_resto');
                this.postForm.addControl('restaurant_ad_hoc_tags',this.restaurantAdHocTags);
                this.configureMentions();
                break;
            case 'list':
                this.postForm.addControl('body', new FormControl('', [Validators.required, Validators.maxLength(1000)]));
                this.postForm.addControl('tag_names', new FormControl('', tagLimit));
                this.postForm.removeControl('file');
                this.postForm.get('tag_names').valueChanges.subscribe((e) => { this.getTags(e); });
                this.postForm.addControl('selected_resto', new FormControl([], Validators.required));
                break;
        }
    }

    get selectedRestoControl() {
        return this.postForm.get('selected_resto');
    }

    /**
     * add array as a value to form group
     * @param value selected restaurents
     */

    addToArray(value: any) {
        // Get the current value of the array from the form control
        const currentValue: any[] = this.selectedRestoControl.value;
        // Push the new value to the array
        currentValue.push(value);
        // Update the form control with the new array value
        this.selectedRestoControl.setValue(currentValue);
    }

    /**
     * Get resto method get search resto form backend
     * @param {string} value search value
     */

    getResto(value: string) {
        this.moderationService.searchRestaurant(value).subscribe((restaurants: any) => {
            this.restoNotFound = restaurants.length ? false : true;
            for (const oldObj of this.selectedRestoControl.value) {
                const existingObjIndex = restaurants.findIndex((newObj) => this.compareById(newObj, oldObj));
                if (existingObjIndex !== -1) {
                    // If object already exists, replace it with the old one
                    restaurants[existingObjIndex] = oldObj;
                } else {
                    // If the object doesn't exist, add it to the new array
                    restaurants.unshift(oldObj);
                }
            }
            this.restoList = restaurants;
        });
    }

    /**
     * Function to compare objects by their restaurant id property
     * @param obj1 new object
     * @param obj2 old object
     * @returns equal value
     */
    compareById(obj1, obj2): boolean {
        return obj1.restaurant_id === obj2.restaurant_id;
    }

    /**
     * get items list when restaurant is selected
     * if unselected then remove form selectedResto
     * @param event for checking restaurant selected or not
     * @param resto restaurant details
     */
    selectResto(event,resto){
        if(event.checked){
            if(resto.merchant_id){
                resto.selected_items=[];
                resto['items_search'] = new FormControl('');
                resto['items_search'].valueChanges.subscribe((e) => {
                    resto.items = this.loadItems(resto,e);
                }); 
            }
            this.addToArray(resto);
        } else {
            this.selectedRestoControl.setValue(this.selectedRestoControl.value.filter((sresto => {
                if (sresto.checked) {
                    return sresto;
                }
            })));
        }
    }

    /**
     * get item list when edit
     * @param resto restaurant details
     */
    loadItems(resto,searchQuery?:string){
        this.moderationService.getItems(resto.restaurant_id,searchQuery).subscribe((response:any)=>{
            return resto.items = response.items;
        }) 
    }

    /**
     * Use for upload files
     * @param event get list of uploaded file
     */
    onFileChange(event) {
        if ((event.target.files.length + this.uploadFiles.uploadedData.length + this.uploadFiles.filesBase64.length) <= 5) {
            // for (var i = 0; i < event.target.files.length; i++) {
            const fileObj = event.target.files[0];
            const fileSizeMB = Number(((fileObj.size / 1024) / 1024).toFixed(4));
            if (fileSizeMB < 25) {
                let reader = new FileReader();

                this.uploadFiles.mediaFiles.push(event.target.files[0].name);
                let file: File = event.target.files[0];
                this.uploadFiles.filesObj.push(file);
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.uploadFiles.filesBase64.push(reader.result);
                };
            } else {
                event.preventDefault();
                event.value = "";
                this.fileElement.nativeElement.value = '';
                Swal.fire('Maximum 25MB file allowed.', '', 'error');
            }
            // }
        } else {
            event.preventDefault();
            event.value = "";
            this.fileElement.nativeElement.value = '';
            Swal.fire('Maximum 5 files allowed.', '', 'error');
        }
    }

    /**
     * Generate pre signd url from s3
     * Attach files to the corresponding url
     */
    getSignMediaUrl() {
        let params = { 'media_files': this.uploadFiles.mediaFiles };
        this.moderationService.getSignUrl(params).subscribe((signUrl: any) => {
            const innerApiCalls = [];
            for (let i = 0; i < signUrl.presigned_urls.length; i++) {

                if (this.uploadFiles.mediaFiles.includes(signUrl.presigned_urls[i].original_file_name)) {
                    // attach file to the corresponding url
                    const headers = { 'Content-Type': this.uploadFiles.filesObj[i].type };
                    innerApiCalls.push(this.moderationService.getS3Url(signUrl.presigned_urls[i].presigned_url, this.uploadFiles.filesObj[i], { headers }));
                    // get s3 url
                    const url = signUrl.presigned_urls[i].presigned_url.substring(0, signUrl.presigned_urls[i].presigned_url.indexOf("?"));
                    // get image type without extension
                    const type = this.uploadFiles.filesObj[i].type.substring(0, this.uploadFiles.filesObj[i].type.indexOf("/"));
                    let media_data = {
                        "media_name": signUrl.presigned_urls[i].original_file_name,
                        "media_type": type,
                        "media_url": url
                    };
                    this.uploadFiles.mediaData.push(media_data);
                }
            }
            forkJoin(innerApiCalls).subscribe(responses => {
                // Handle the responses of all the inner API calls if needed
                let media = this.uploadFiles.uploadedData.concat(this.uploadFiles.mediaData);
                this.postForm.value['media_data'] = media;
                this.submitFormData();
            }, error => {
                // Handle error if needed
            });
        });
    }

    /**
     *
     * @param type type of data old,new
     * @param index file index in array
     */
    deleteMedia(type, index) {
        if (type === 'new') {
            this.uploadFiles.filesBase64.splice(index, 1);
            this.uploadFiles.filesObj.splice(index, 1);
        } else {
            this.uploadFiles.uploadedData.splice(index, 1);
        }
    }
    /**
         * Trigger search of restaurants when @ is typed
         * @param event get the keystrokes being typed
         */
    triggerSearch(event:string) {
        this.moderationService.getRestaurants(event).subscribe((restaurants:any) => {
            this.mentions.next(restaurants.restaurants.map((restaurant: any) => restaurant));
        });
    }

    editComment(){
        this.moderationService.editComment(this.mentionDiv.nativeElement,this.postForm.get('body'),this.selectedMentions,this.restaurantAdHocTags);
    }
    /**
     * Change the status of post to approved/rejected
     * @param event get current status
     */
    changeStatus(event) {
        this.moderationService.updatePost(this.editId, { status: event.value }).subscribe(() => {
            Swal.fire(event.value.toUpperCase() + '!', 'Post has been ' + event.value + '!', 'success');
        });
    }

    /**
     * Make API call to fetch Comments
     */
    getComments(page) {
        this.moderationService.getComments(this.editId, this.userId, page).subscribe((comments: any) => {
            this.dataComments = comments.comments;
            this.resultsLengthComments = comments.total_results;
        });
    }

    /**
     * Make API call to fetch Recommendations
     */
    getRecommendations() {
        this.moderationService.getRecommendations(this.editId, this.userId, 0).subscribe((recommendations: any) => {
            this.dataRecommendations = recommendations.recommendations;
            this.resultsLengthRecommendations = recommendations.total_results;
        });
    }

    /**
     * Create post form
     * @param post If post is present, prepolutate the values
     */
    createForm(post?) {
        if (!post) {
            this.postForm = this.fb.group({
                post_type: new FormControl('', Validators.required),
                status: new FormControl('to_review',Validators.required),
                user_id: new FormControl('', Validators.required),
                created_at:new FormControl(''),
                category_tag_names:new FormControl(),
                cuisines_tag_names:new FormControl(),
                ad_hoc_tag_names:new FormControl(),
                is_public: true,
                lat: 19,
                lon: 72,
                is_pinned: new FormControl(false, Validators.required)
            });
        } else {
            this.postForm.patchValue({
                post_type: post.post_type,//newly added field
                body: post.body,
                user_id: post.user_id,
                created_at: this.getDate(post.created_at),
                tag_names: post.tag_names,
                category_tag_names:post.category_tag_names,
                cuisines_tag_names:post.cuisines_tag_names,
                ad_hoc_tag_names:post.ad_hoc_tag_names,
                status: post.status,
                is_public: true,
                lat: 19,
                lon: 72,
                is_pinned: post.is_pinned
            });
        }
    }

    getDate(date) {
        let dateTime = new Date(date);
        var isoDateTime = new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000).toISOString();
        isoDateTime = isoDateTime.split('.')[0];
        isoDateTime = isoDateTime.slice(0, -3);
        return isoDateTime;
    }
    /**
     * change the current selected tab
     * @param tab tab number to be selected
     * @param commentId Open dialog if comment id is present
     */
    changeTab(tab, commentId?) {
        this.selectedIndex = tab.index;
        if (tab.index == 0) this.location.go('/moderation-tool/' + this.editId + '/question?user_id=' + this.userId);
        if (tab.index == 1) {
            if (commentId) {
                this.location.go('/moderation-tool/' + this.editId + '/comments?user_id=' + this.userId);
                this.moderationService.getCommentsById(commentId, this.userId).subscribe((comment) => this.commentEdit(comment));
            } else this.location.go('/moderation-tool/' + this.editId + '/comments?user_id=' + this.userId);
        }
        if (tab.index == 2) this.location.go('/moderation-tool/' + this.editId + '/recommendations?user_id=' + this.userId);
    }

    /**
     * Search for tags from a list
     * @param search keystrokes to search tags
     */
    getTags(search: any) {
        this.moderationService.getTags(search).subscribe((response: any) => {
            this.listTag.tags = response.tags;
        });
    }

    /**
     * search tags
     * @param tagType type of tag you want to search from
     * @param searchQuery search character
     * @param tagList reponse store in given key  
     */
    getAllTypeOftagsList(tagType:string,searchQuery:string,tagList:any){
        this.moderationService.getTypeOfTags(tagType,searchQuery).subscribe((tags:any) => {
            this.listTag[tagList] = tags;
        });
    }

    /**
     * Add a tag
     * @param event Represents an input event on a matChipInput.
     */
    add(event: MatChipInputEvent,tagType:any,formControlName:any): void {
        const value = (event.value || '').trim();
        // Trim the input text to the character limit
        const trimmedInput = value.substr(0, this.maxTagCharLimit);

        if (value.length > this.maxTagCharLimit)
            this.openSnackBar(`Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

        if (value)
            this.selectedTags[tagType].push(trimmedInput);

        event.chipInput!.clear();
        this.postForm.controls[formControlName].setValue(this.selectedTags[tagType]);
    }

    /**
     *
     * @param message text that you want to show in snackbar
     * @param action type of action success/warning/error
     */
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action);
    }

    /**
     * Remove the tag
     * @param tag tag to be removed
     */
    remove(tag: string,typeTags:any,formControlName:any): void {
        const index = this.selectedTags[typeTags].indexOf(tag);
        if (index >= 0) {
            this.selectedTags[typeTags].splice(index, 1);
        }
        this.postForm.controls[formControlName].setValue(this.selectedTags[typeTags]);
    }
    /**
     * add resto tag
     * @param event Represents an input event on a matChipInput.
     * @param formControl from control name where you want to store
     */
    addRestoTag(event: MatChipInputEvent,formControl:string): void {
        const value = (event.value || '').trim();
        // Trim the input text to the character limit
        const trimmedInput = value.substr(0, this.maxTagCharLimit);

        if (value.length > this.maxTagCharLimit)
            this.openSnackBar(`Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

        if (value){
            this.selectedTags[formControl]=[];
            this.selectedTags[formControl].push(trimmedInput);
        }

        event.chipInput!.clear();
        this.restaurantAdHocTags.controls[formControl].setValue(this.selectedTags[formControl]);
    }

     /**
     * Select a tag
     * @param event Event object that is emitted when an autocomplete option is selected
     */
      selectedRestoTag(event: MatAutocompleteSelectedEvent,formControl:string): void {
          if(!this.selectedTags[formControl]){
            this.selectedTags[formControl] = []; 
          }
        this.selectedTags[formControl].push(event.option.viewValue);
        this.restoAdHocTags.nativeElement.value = '';
        this.restaurantAdHocTags.controls[formControl].setValue(this.selectedTags[formControl]);
    }
    /**
     * remove reto tags
     * @param tag tag name
     * @param formControl form control name
     */
    removeRestoTag(tag:string,formControl:string){
        const index = this.selectedTags[formControl].indexOf(tag);
        if (index >= 0) {
            this.selectedTags[formControl].splice(index, 1);
        }
        this.restaurantAdHocTags.controls[formControl].setValue(this.selectedTags[formControl]);
    }
    /**
     * Approve/Reject the comment basis the content
     * @param comment Row details from the table
     */
    approve(comment) {
        const dialogRef = this.dialog.open(StatusDialog, {
            width: window.innerWidth < 768 ? '80vw' : '40vw',
            data: event,
            panelClass: 'thrive-dialog'
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                this.moderationService.updateComment(comment.id, { ...comment, user_id: comment.user_id, status: result }).subscribe(() => {
                    Swal.fire(result.toUpperCase() + '!', 'Comment has been ' + result + '!', 'success');
                    setTimeout(() => this.getComments(0), 500);
                });
            }
        });
    }

    /**
     * Select a tag
     * @param event Event object that is emitted when an autocomplete option is selected
     */
    selected(event: MatAutocompleteSelectedEvent,tagType:any,eleId:any,formControlName:any): void {
        this.selectedTags[tagType].push(event.option.viewValue);
        this[eleId].nativeElement.value = '';
        this.postForm.controls[formControlName].setValue(this.selectedTags[tagType]);
    }

    // Add menu form particular resto 
    /**
     * 
     * @param event Selected value from drop down
     * @param resto selected resto from list
     * @param input input control value which user entered
     */
    public selectedItemsFn(event: MatAutocompleteSelectedEvent,resto:any,input:HTMLInputElement):void{
        resto.selected_items.push(event.option.value);
        input.value = ''; 
    }
    /**
     * 
     * @param item Menu which user want to remove
     * @param resto Resto details from menu
     */
    removeItem(item:any,resto:any):void{
        const index = resto['selected_items'].findIndex(i=>item?.id === i?.id);
        if (index >= 0) {
            resto['selected_items'].splice(index, 1);
        }
    }

    viewImage(index, type, image) {
        this.dialog.open(ImageDialog, {
            width: window.innerWidth < 768 ? '80vw' : '70vw',
            data: { image, type},
            panelClass: 'thrive-dialog'
        });
    }

    /**
     * Show only non selected option in auto complete
     * @param items Mat options
     * @param resto selacted resto details
     * @returns return item name which is visible on front end
     */
    showValue(items:any,resto:any){
        let index = resto.selected_items.findIndex(i=>i.id === items.id)
        if(index === -1){
            return items.item_name;
        }   
    }
    /**
     * Open the dialog to add a comment
     */
    addComment() {
        const dialogRef = this.dialog.open(CommentDialog, {
            width: window.innerWidth < 768 ? '80vw' : '70vw',
            panelClass: 'thrive-dialog',
            data:{post_details:this.postDetails,comment:null}
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let obj = {
                    slug: this.editId,
                    entity: 'Question',
                    entity_id: this.editId
                };
                result = { ...result, ...obj };
                this.moderationService.createComment(result).subscribe(() => {
                    Swal.fire('Created!', 'Comment has been successfully created!', 'success');
                    this.getComments(0);
                });
                this.changeTab({ index: 1 });
            }
        });
    }

    /**
     * Open the dialog to edit the comment
     * @param event Row details from the table
     */
    commentEdit(event) {
        this.selectedIndex = 1;
        this.location.go('/moderation-tool/' + this.editId + '/comments/' + event.id + '?user_id=' + this.userId);
        const dialogRef = this.dialog.open(CommentDialog, {
            width: window.innerWidth < 768 ? '80vw' : '70vw',
            data:{comment:event,post_details:this.postDetails},
            panelClass: 'thrive-dialog'
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.changeTab({ index: 1 });
            if (result) {
                let obj = {
                    slug: this.editId,
                    entity: 'Question',
                    entity_id: this.editId
                };
                result = { ...result, ...obj };
                this.moderationService.updateComment(event.id, result).subscribe(() => {
                    Swal.fire('Updated!', 'Comment has been successfully updated!', 'success');
                    this.getComments(0);
                });
                this.changeTab({ index: 1 });
            }
        });
    }

    /**
     * To change the page and get the next array of Posts
     * @param event Event to change the page where you get pageNumber, pageSize and other pagination properties from table.component
     */
    changePage(event) {
        this.getComments(event.pageIndex);
    }

    /**
     * Create or Update the Post and then navigate back to overview page
     */
    submitPost() {
        const { post_type } = this.postForm.value;
        if (post_type === 'list') {
          const restaurants = this.selectedRestoControl.value.reduce((acc, resto) => {
            const { restaurant_id, name, selected_items } = resto;
      
            if (!restaurant_id) return acc;
      
            const items = [];
            const custom_items = [];
      
            if (selected_items?.length) {
              selected_items.forEach(item => {
                const { id, item_name, item_configured_for } = item;
                const formattedItem = { id, name: item_name };
      
                item_configured_for === 'menu_items' ? items.push(formattedItem) : custom_items.push(formattedItem);
              });
            }
      
            acc[restaurant_id] = {
              id: restaurant_id,
              type: 'Restaurant',
              name,
              items,
              custom_items,
            };
      
            return acc;
          }, {});

        this.postForm.value['list_mentions'] = {'Restaurant': restaurants};

        } else if (post_type === 'simple_post') {
          this.postForm.get('body').setValue(
            this.moderationService.convertSpanTagsToPlaceholders(this.mentionDiv.nativeElement)
          );
      
          if (this.uploadFiles.filesObj?.length) {
            this.submitted = true;
            this.getSignMediaUrl();
            return;
          } else {
            this.postForm.patchValue({
              media_data: this.uploadFiles.uploadedData
            });
          }
        }
      
        this.submitted = true;
        this.submitFormData();
    }

    submitFormData() {
        if (this.editId !== 'new') {
            this.moderationService.updatePost(this.editId, this.postForm.value).subscribe(() => {
                let currentDate = new Date(this.postForm.get('created_at').value);
                let oldDate = new Date(this.postDetails.created_at);
                if (currentDate.getTime() !== oldDate.getTime()) this.updateCreateDate(); 
                Swal.fire('Updated', 'Post has been successfully updated!', 'success').then(() => {
                    setTimeout(() => this.router.navigateByUrl('/moderation-tool'), 100);
                });
            },(err:any)=>{
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                if (err.status === 422) {
                    window.location.reload();
                }
                Swal.fire('Error', errMsg, 'error');
            });
        } else {
            this.moderationService.createPost(this.postForm.value).subscribe(() => {
                Swal.fire('Created', 'Post has been successfully created!', 'success');
                setTimeout(() => this.router.navigateByUrl('/moderation-tool'), 100);
            }, (err) => {
                let errMsg: string = 'Something went wrong. Please try agaian later.';
                if (err?.error?.errors) {
                    errMsg = typeof err.error.errors === 'string' ? err.error.errors : err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            });
        }
    }
    updateCreateDate(){
        let body = {
            "posts": [
                {
                    "post_id": this.postDetails.id,
                    "created_date":this.postForm.get('created_at').value, 
                }
            ]
        }
       this.moderationService.updateCreatedDate(this.postDetails.user_id,body).subscribe((response)=>{}) 
    }
}