import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

// Validation to validate ordering type is selected or not
function atLeastOneChecked(min_required = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
        let checked = 0;
        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.controls[key];
            if (control.value) {
                checked++;
            }
        });
        if (checked < min_required) {
            return {
                notchecked: true,
            };
        }
        return null;
    };
}

@Component({
    selector: 'app-niu-popup',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule
    ],
    templateUrl: './niu-popup.component.html',
    styleUrls: ['./niu-popup.component.scss']
})

export class NiuPopupComponent {
    modelView: boolean = false;
    reason: FormGroup = this.fb.group({
        'Shut down': new FormControl(false),
        'Lost to competitor': new FormControl(false),
        'Money issues': new FormControl(false),
        'Missing features': new FormControl(false),
        'In-house loyalty': new FormControl(false),
        'Performance issues': new FormControl(false),
        'Duplicate': new FormControl(false),
        'Other': new FormControl(false)
    }, {
        validators: atLeastOneChecked()
    });

    constructor(
        public dialogRef: MatDialogRef<NiuPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public fb: FormBuilder
    ) { }

    missignFeature(event: any) {
        if (event.checked) {
            this.reason.addControl('missing_features', new FormControl('', Validators.required));
        } else {
            this.reason.removeControl('missing_features');
        }
    }

    others(event: any) {
        if (event.checked) {
            this.reason.addControl('others_reason', new FormControl('', Validators.required));
        } else {
            this.reason.removeControl('others_reason');
        }
    }

    submit() {
        if (this.reason.valid) {
            let reason = {};
            Object.keys(this.reason.value).forEach((key) => {
                if (this.reason.value[key] === true && key === 'Missing features') {
                    reason[key] = this.reason.value['missing_features'];
                } else if (this.reason.value[key] === true && key === 'Other') {
                    reason[key] = this.reason.value['others_reason'];
                } else if (this.reason.value[key] === true) {
                    reason[key] = key;
                }
            });
            this.dialogRef.close(reason);
        } else {
            if (this.reason.get('missing_features'))
                this.reason.get('missing_features').markAsTouched();

            if (this.reason.get('others_reason'))
                this.reason.get('others_reason').markAsTouched();
        }
    }
}
