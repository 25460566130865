import { Component } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColumnData, TableComponent } from '../../shared/table/table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModerationService } from '../moderation.service';
import { FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { CommentDialog } from '../comment-dialog/comment-dialog';
import { MentionReplacePipe } from '../../shared/pipes/mention-replace.pipe';
import { FilterModelComponent } from './filter-model/filter-model.component';
import { MatChipsModule } from '@angular/material/chips';
import { fileURLToPath } from 'url';
@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [CommonModule,
                 MatDialogModule, 
                 MatIconModule, 
                 TableComponent, 
                 FormsModule, 
                 MatButtonModule, 
                 RouterModule, 
                 MatTooltipModule, 
                 MatSelectModule, 
                 MatDatepickerModule, 
                 MatNativeDateModule, 
                 MatFormFieldModule, 
                 MatInputModule,
                 FilterModelComponent,
                 MatChipsModule,
                 MentionReplacePipe],
  providers: [ModerationService, DatePipe],
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    pageIndex: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    filters = {
        post_id: null,
        search_query: null,
        status: null,
        post_type: null,
        last_updated: {
          start_date:null,
          end_date:null
        },
        user_type:null,
        user_details:{
          id:null,
          user_name:null,
          display_name:null
        },
        recently_updated: null
    };

    //comments
    // filter:any={
    //     page_no:1,
    // }

  constructor(private moderationService: ModerationService, 
            private router: Router,
            public dialog: MatDialog) {}
  ngOnInit() {
    this.getAllComment(this.pageIndex,this.filters);
    this.columns = [
        {
            columnDef: 'body',
            header: 'Comment',
            cell: 'body',
            isSpecialHTML: true
        },
        {
            columnDef: 'post_type',
            header: 'Post type',
            cell: 'post_type',
            pipe: 'titlecase'
        },
        {
            columnDef: 'updated_at',
            header: 'Last updated',
            cell: 'updated_at'
        },
        {
            columnDef: 'status',
            header: 'Status',
            cell: 'status',
            pipe: 'titlecase',
            isChip: true
        },
        {
            columnDef: 'page',
            header: 'Open feed',
            cell: 'page',
            isSpecialHTML: true
        },
        {
            columnDef: 'action',
            header: 'Change status',
            cell: '',
            isSpecialAction: true
        }
    ];
    this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
    this.mobileDisplayedColumns = ['body'];
}

    /**
     * GET API call for fetching all Posts basis some filters
     * @param {number} page Page number for pagination support
     * @param {string} filterType Which type of filter to be applied
     * @param {string} filterValue Value of the filter to apply the filter
     * @param {string} sortType Which type of sort to be applied
     * @param {string} sortBy Order of the sort to apply
     */
    getAllComment(pageNo,filters) {
        this.moderationService.getAllComment(pageNo,filters).subscribe((response:any)=>{
            this.data = response.comments;
            this.resultsLength = response.total;
            this.pageIndex = response.current_page - 1;
        });
    }
    //Open feed 
    openFeedFn(event){
        if (!event.action) this.router.navigate([]).then(result => { window.open('moderation-tool/' + event.post_slug + '/question?user_id=' + event.user_id, '_blank'); });
    }

    /**
     * To change the page and get the next array of Posts
     * @param event Event to change the page where you get pageNumber, pageSize and other pagination properties from table.component
     */
    changePage(event) {
        this.pageIndex = event.pageIndex;
        this.getAllComment(this.pageIndex,this.filters);
    }

    /**
     * Approve/Reject the post basis the content
     * @param comment details Row details from the table
     * @param status contain Approve/Reject
     */
    changeStatus(comment:any,status:string) {
        this.moderationService.updateComment(comment.id, { ...comment, user_id: comment.user_id, status: status }).subscribe(() => {
            Swal.fire(status.toUpperCase() + '!', 'Comment has been ' + status + '!', 'success').then(()=>{
                this.getAllComment(this.pageIndex,this.filters)
            });
        });
    }

    removeFilter(param:string) {
        switch (param) {
            case 'last_updated':
                this.filters[param].start_date=null;
                this.filters[param].end_date=null;
            break;
            case 'id':
            case 'user_name':
            case 'display_name':
                this.filters.user_details[param]=null;
            break;
            default:
                this.filters[param] = null;
            break;
        }
        this.getAllComment(this.pageIndex,this.filters);
    }
    // Check if any filter value is non-null
    hasActiveFilters(): boolean {
        return Object.keys(this.filters).some(key => {
        const value = this.filters[key];
    
        if (typeof value === 'object' && value !== null) {
            return Object.keys(value).some(subKey => value[subKey] !== null);
        }
    
        return value !== null;
        });
    }

    clearAllFilters() {
        // Resetting the filters object to its initial state
        this.filters = {
          post_id: null,
          search_query: null,
          status: null,
          post_type: null,
          last_updated: {
            start_date: null,
            end_date: null
          },
          user_type: null,
          user_details: {
            id: null,
            user_name: null,
            display_name: null
          },
          recently_updated: null
        };
        this.getAllComment(this.pageIndex,this.filters); 
      }

    /**
     * Update comment on row click
     * @param event Row details from the table
     */
    updateComment(event) {
        const dialogRef = this.dialog.open(CommentDialog, {
            width: window.innerWidth < 768 ? '80vw' : '70vw',
            data:{comment:event,post_details:null},
            panelClass: 'thrive-dialog'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let obj = {
                    slug: event.post_slug,
                    entity: 'Question',
                    entity_id: event.post_slug
                };
                result = { ...result, ...obj };
                this.moderationService.updateComment(event.id, result).subscribe(() => {
                    Swal.fire('Updated!', 'Comment has been successfully updated!', 'success').then(()=>{
                        this.getAllComment(this.pageIndex,this.filters);
                    });
                });
            }
        })
    }

    openFilters() {
        const dialogRef = this.dialog.open(FilterModelComponent, {
            width: window.innerWidth < 768 ? '80vw' : '40vw',
            panelClass: 'thrive-dialog',
            data: this.filters
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.filters = result;
                this.getAllComment(this.pageIndex,this.filters);
            }
        });
    }

}