import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-change-permission',
  standalone: true,
  imports: [CommonModule,
            MatDialogModule, 
            MatButtonModule, 
            MatIconModule, 
            MatFormFieldModule, 
            MatInputModule, 
            FormsModule, 
            MatSelectModule],
  templateUrl: './change-permission.component.html',
})
export class ChangePermissionComponent implements OnInit{
  correntPermission: string;
  status:Array<{id:string,name:string}> = [
    {
      id:'default',
      name:'Default'
    },
    {
      id:'wait_list',
      name:'Wait list'
    },
    {
      id:'invite_only',
      name:'Invite only'
    },
    {
      id:'cool_off',
      name:'Cool off'
    },
    {
      id:'blacklist',
      name:'Blacklist'
    }
  ];
  verificationType:Array<{id:string,name:string}> =[
    {
      id:'unverified',
      name:'Unverified'
    },
    { 
      id:'influencer',
      name:'Influencer'
    },
    {
      id:'merchant',
      name:'merchant'
    },
    {
      id:'internal_user',
      name:'Internal user'
    },
    {
      id:'verified',
      name:'Verified'
    } 
  ];
  dropDownArray:Array<{id:string,name:string}> = [];
  constructor(public dialogRef: MatDialogRef<ChangePermissionComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any) {
                console.log(this.data);
              }
  ngOnInit(): void {
    const isStatusType = this.data.model_type === 'status';
    this.correntPermission = isStatusType 
    ? this.data.user.profile.status 
    : this.data.user.profile.verification_type;
    this.dropDownArray = isStatusType 
    ? [...this.status] 
    : [...this.verificationType];
  }  

  submit(){
    this.data.user['user_selection']=this.correntPermission;
    this.dialogRef.close(this.data); 
  }
}
