<div id="moderation" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections">
            <h2>Moderation Tool</h2>
            <div>
                <mat-form-field *ngIf="selection && (selection.selected.length > 0)" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                    <mat-label>Build Status update</mat-label>
                    <mat-select (selectionChange)="bulkUpdateStatus($event.value)">
                        <mat-option value="approved">Approved</mat-option>
                        <mat-option value="rejected">Rejected</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button class="btn-thrive_icon" mat-icon-button (click)="openFilters()">
                <mat-icon>filter_list</mat-icon>
            </button>
            <button class="btn-thrive_primary"
                    accessControl
                    moduleType="moderation_tool"
                    mat-button
                    routerLink="new/question">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>

        </div>
        <div class="chip-container">
            <mat-chip-listbox>
                <mat-chip *ngIf="filters.status">
                    Status: {{ filters.status | titlecase }}
                    <button matChipRemove (click)="removeFilter('status')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.created_date">
                    Created Date: {{ filters.created_date }}
                    <button matChipRemove (click)="removeFilter('created_date')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.post_type">
                    Post Type: {{ filters.post_type | titlecase }}
                    <button matChipRemove (click)="removeFilter('post_type')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.user_id">
                    User Id: {{ filters.user_id }}
                    <button matChipRemove (click)="removeFilter('user_id')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.user_name">
                    User Name: {{ filters.user_name | titlecase }}
                    <button matChipRemove (click)="removeFilter('user_name')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.search_query">
                    Title: {{ filters.search_query | titlecase }}
                    <button matChipRemove (click)="removeFilter('search_query')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.is_pinned">
                    Is Pinned: {{ filters.is_pinned }}
                    <button matChipRemove (click)="removeFilter('is_pinned')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.created_at">
                    Sort By Created Date: {{ filters.created_at | titlecase }}
                    <button matChipRemove (click)="removeFilter('created_at')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.recently_updated">
                    Sort By Recently Updated: {{ filters.recently_updated | titlecase }}
                    <button matChipRemove (click)="removeFilter('recently_updated')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
            </mat-chip-listbox>
            <button mat-icon-button class="btn-thrive_icon delete-btn" (click)="clearAll()" matTooltip="Clear All Filter" matTooltipClass="thrive-tooltip" *ngIf="showClearFilter()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div style="overflow-x: auto">
        <tn-table
                  [title]="'Questions'"
                  [data]="data"
                  [columns]="columns"
                  [desktopDisplayedColumns]="desktopDisplayedColumns"
                  [mobileDisplayedColumns]="mobileDisplayedColumns"
                  [resultsLength]="resultsLength"
                  [pageSize]="pageSize"
                  [hidePageSize]="true"
                  [specialHTML]="{'title':titleId,'user':scheduleColumn,'tag':tag}"
                  [specialButton]="approveButton"
                  [mobileTemplate]="mobileTemplate"
                  [pageIndex]="pageIndex"
                  (changePage)="changePage($event)"
                  (rowClick)="navigateTo($event)"
                  (actionClick)="actionClicked($event)"
                  [featureAccessControl]="'moderation_tool'"
                  (getSelectedRow)="getSelectedRow($event)">
        </tn-table>
    </div>
</div>
<ng-template #titleId let-option>
    <span [innerHTML]="((option.row['showMore']) ? option.row.title : (option.row.title | slice:0:30)) | mentionReplace:(option.row?.mentions_data?.['Restaurant'] || {})"></span>
    <span *ngIf="!option.row['showMore'] && option.row.title.length > 30">...</span> 
    <span *ngIf="!option.row['showMore'] && option.row.title.length > 30">see more</span>
</ng-template>
<ng-template #scheduleColumn let-option>
    <span>{{ option.row.user.name }} ({{ option.row.user.id }})</span>
</ng-template>
<ng-template #tag let-option>
    <span>{{ option.row.mentions_data | tags }}</span>
</ng-template> 
<ng-template #approveButton let-option>
    <button class="btn-thrive_icon p-0"
            accessControl
            moduleType="moderation_tool"
            mat-icon-button
            (click)="approve(option.row); $event.stopPropagation()"
            [matTooltip]="'Change Status'"
            matTooltipPosition="below"
            matTooltipClass="thrive-tooltip"
            style="z-index: 999">
        <mat-icon class="mr-0">change_circle</mat-icon>
    </button>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>User</td>
            <td>{{ option.row.user.name }} ({{ option.row.user.id }})</td>
        </tr>
        <tr>
            <td>Total Comment</td>
            <td>{{ option.row.total_comments }}</td>
        </tr>
        <tr>
            <td>Total Recommendations</td>
            <td>{{ option.row.total_recommendations }}</td>
        </tr>
        <tr>
            <td>Tag</td>
            <td>{{ option.row.mentions_data | tags }}</td>
        </tr>
        <tr>
            <td>Last Updated</td>
            <td>{{ option.row.updated_date_and_time }}</td>
        </tr>
        <tr>
            <td>Created At</td>
            <td>{{ option.row.created_date }}</td>
        </tr>
        <tr>
            <td>Status</td>
            <td>{{ option.row.status }}</td>
        </tr>
        <tr>
            <td>Pinned</td>
            <td>
                <mat-slide-toggle accessControl
                                  moduleType="moderation_tool" color="primary" (click)="$event.stopPropagation()" (change)="actionClicked({row: option.row, checked: $event.checked, action: 'is_pinned'})" [checked]="option.row.is_pinned"></mat-slide-toggle>
            </td>
        </tr>
        <tr>
            <td></td>
            <td>
                <button accessControl
                        moduleType="moderation_tool" class="btn-thrive_icon p-0" mat-icon-button (click)="approve(option.row); $event.stopPropagation()" [matTooltip]="'Change Status'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon class="mr-0">change_circle</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</ng-template>