<div id="offerBulkUpload" class="thrive-container table-grid-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="['/merchant']">
                <mat-icon class="arrow-back">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Bulk offer upload</h2>
        </div>
    </div>

    <div class="thrive-card mt-0">
        <div class="thrive-toast_info mb-16">
            <span>Info:</span>
            Upload in .csv format only
        </div>
        <div class="buttons-row">
            <a class="btn-thrive_secondary" mat-button href="https://docs.google.com/spreadsheets/d/1mPKfEuvyDt2kFQ1EY2jw1nZc4-IT67v_evPR2phWSpM/edit?gid=670767184#gid=670767184" target="_blank">VIEW FORMAT</a>
            <button class="btn-thrive_primary ml-20" (click)="bulkUploadInput.click()" mat-button>UPLOAD</button>
            <input type="file" #bulkUploadInput (change)="readExcel($event)" style="display: none" name="blukUpload" accept=".csv" value="upload" />
        </div>
    </div>
</div>
