import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CustomMenuService} from './custom-menu.service';
import {FormControl,FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive'
@Component({
  selector: 'app-custome-menu',
  standalone: true,
  imports: [CommonModule,
    MatIconModule,
    TableComponent,
    MatButtonModule,
    RouterModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    AccessControlDirective
],
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    showFilters: boolean = false;
    filterCategory: string;
    route: any;
    search: string;
    allCity: any = [];
    page: any;
    perPage: number;
    filterOptions: any;
    formControl = new FormControl('');
    isLoading: boolean;
    constructor(
        private customMenuService: CustomMenuService, 
        private router: Router,
        public dialog: MatDialog) {
        this.getCustomItemList(this.page, this.perPage);
    }
    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'item_name',
                header: 'Name',
                cell: 'item_name'
            },
            {
                columnDef: 'restaurant_name',
                header: 'Restaurant',
                cell: 'restaurant_name',
                isSpecialHTML: true
            },
            {
                columnDef: 'created_date',
                header: 'Created At',
                cell: 'created_date'
            },
            {
                columnDef: 'updated_date',
                header: 'Last updated At',
                cell: 'updated_date'
            },
            {
                columnDef: 'item_status',
                header: 'Status',
                cell: 'item_status',
                pipe: 'titlecase',
                isChip: true
            },
            {
                columnDef: 'action',
                header: '',
                cell: '',
                isSpecialAction: true,
                isDeletable: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['item_name'];

    this.formControl.valueChanges.pipe(
        filter(res => {
            if(res !== null && !res?.length && this.filterCategory){
                this.applyFilters(0);
            }
            
            return res !== null && res.length >= 2
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.filterOptions = [];
          this.isLoading = true;
        }),
        switchMap(value => this.customMenuService.getRestaurant(1,value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
        )
        .subscribe((data: any) => {
            if (!data.restaurants.length) {
            this.filterOptions = [];
            } else {
            this.filterOptions = data.restaurants;
            }
    });
}

getOptioResto(option) {
    if(option)
    return option.name;
}

/**
 * get merchants list
 * @param page page number
 * @param perPage number of merchants in one page i.e. page size
 */
 getCustomItemList(page: number, perPage: number,searchQuery?:string,restaurantId?:any) {
    this.customMenuService.getCustomItemList(page,perPage,searchQuery,restaurantId).subscribe((response: any) => {
        this.data = response.items;
        this.resultsLength = response.total_results;
    });
}

clearText() {
    this.search = null;
    this.formControl.reset();
}

/**
 * update custom item list on page change
 * @param event table event containing page index and size
 */
changePage(event: any) {
    if (this.filterCategory && this.search) {
        this.applyFilters(event.pageIndex);
    } else {
        this.getCustomItemList(event.pageIndex, event.pageSize);
    }
}

/**
 * fetch restaurant list as per the applied filters
 * @param page  page number
 */
applyFilters(page: any) {
    this.customMenuService.getCustomItemList(page,this.perPage,this.search,this.formControl.value).subscribe((response: any) => {
        this.data = response.items;
        this.resultsLength = response.total_results;
    });
}

/**
     * Approved/Reviewed the custom item 
     * @param itemDetails Row details from the table
     */
approve(itemDetails:any) {
    const dialogRef = this.dialog.open(StatusDialog, {
        width: window.innerWidth < 768 ? '80vw' : '40vw',
        data: itemDetails,
        panelClass: 'thrive-dialog'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
        if (result) {
            let body = {
                "restaurant_id": itemDetails.restaurant_details.id,
                "item_status": result
            }
            this.customMenuService.updateItemStatus(itemDetails.id,body).subscribe((response:any)=>{
                Swal.fire(result.toUpperCase() + '!', 'Item has been ' + result + '!', 'success'); 
                setTimeout(() => this.getCustomItemList(this.page, this.perPage), 1000);
            })
        }
    });
}
 /**
     * To delete sources from API on pressing delete button
     * @param event To fetch the id of the clicked row
     */
deleteItem(event) {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You want to delete this Item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6236FF',
        cancelButtonColor: '#B95000',
        confirmButtonText: 'Confirm'
    }).then((result) => {
        if (result.isConfirmed === true) {
            let body = {
                "restaurant_id": event.row.restaurant_details.id,
                "deleted": true
            }
            this.customMenuService.deleteItem(event.row.id,body).subscribe(() => 
                setTimeout(() => this.getCustomItemList(this.page, this.perPage), 1000)
            );
        }
    });
}

/**
 * go to merchant create/edit page
 * @param event 
 */
navigateTo(event: any) {
    if(!event.action) this.router.navigateByUrl('custom-menu/' + event.id + '/' + event.restaurant_details.id);
}
}
@Component({
    selector: 'comment-dialog',
    templateUrl: './status-dialog.html',
    styles: ['::ng-deep mention-list .mention-active > a { background: #6236FF !important }'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule]
})
export class StatusDialog {
    status: string;

    constructor(public dialogRef: MatDialogRef<StatusDialog>, 
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.status = data.item_status;
    }

    /**
     * To close the dialog with or without any submission
     * @param {string | boolean} action close the dialog or not
     */
    onClick(action: string | boolean): void {
        this.dialogRef.close(action ? this.status : false);
    }
}
