<div class="dialog-header">
    <span>Comments </span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClick(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <div  class="comment-container">
        <form [formGroup]="commentForm">
            <div 
                class="editable-div"
                contenteditable="true"
                placeholder="Use @ to trigger mention"
                [mention]="mentions | async" 
                [mentionConfig]="mentionConfig"
                [mentionListTemplate]="mentionListTemplate" 
                (searchTerm)="triggerSearch($event)"
                (input)="editComment()"
                #mentionDiv
            >
            </div>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20" *ngIf="!data.comment">
                <mat-label>Select User</mat-label>
                <mat-select placeholder="Select User" formControlName="user_id">
                    <mat-option *ngFor="let user of botUsers" [value]="user?.id">{{ user?.fname + ' ' + user?.lname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20" *ngIf="data.comment">
                <mat-label>Change Status</mat-label>
                <mat-select placeholder="Select User" formControlName="status">
                    <mat-option value="to_review" disabled>To Review</mat-option>
                    <mat-option value="approved">Approved</mat-option>
                    <mat-option value="rejected">Rejected</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        
        <div class="info-container">
            <div class="basetitle">Post body:</div>
            <div>{{postDetails?.body}}</div>
            <div class="basetitle">Post type:</div>
            <div>{{postDetails?.post_type}}</div>
            <div class="basetitle">Post status:</div>
            <div>{{postDetails?.status}}</div>
            <div class="basetitle">Created at:</div>
            <div>{{postDetails?.created_at  | date}}</div>
            <div class="basetitle">User:</div>
            <div>{{postDetails?.user_data?.name}} ({{postDetails?.user_data?.username}})</div>
            <div class="basetitle">Post link:</div>
            <a class="link" (click)="openLinkFn()">Open post</a>
        </div>
    </div>
</div>
<div class="dialog-footer">
    <button (click)="onClick(false)" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="onClick(true)" accessControl moduleType="moderation_tool" class="btn-thrive_primary ml-20" mat-button [innerHTML]="data.comment ? 'Update' : 'Save'" [disabled]="!commentForm.valid"></button>
</div>

<ng-template #mentionListTemplate let-item="item">
    <span>{{item.name + ', ' + item.locality}}</span>
</ng-template>

