import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VersionService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getVersion() {
        return this.http.get(this.url.consumerUrl + '/v1/app_versions');
    }

    createVersion(body) {
        return this.http.post(this.url.consumerUrl + '/v1/app_versions', body);
    }
}
