<div id="menuCard" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/restaurants/'+restaurantInfo?.id">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Upload Menu Images - {{restaurantInfo?.restaurant_name}} (MID: {{restaurantInfo?.id}})</h2>
        </div>
    </div>
    <form [formGroup]="menuCard">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Name of the menu</mat-label>
            <input matInput placeholder="Enter menu name" formControlName="name" />
            <mat-error *ngIf="menuCard.get('name').hasError('required')">Required.</mat-error>
        </mat-form-field>

        <p class="basetitle normal mt-20 mb-10">Upload menu images</p>
        <input #itemImageUpload  accept="image/*" multiple style="display: none" type="file" (change)="onImageUpload($event)" />
        <div class="container-img w-100" *ngIf="!menuCard.get('images').value?.length">
            <div (click)="itemImageUpload.click()" class="property-container mt-30">
                <img src="assets/image-uploader.svg" alt="upload image" />
                <div class="subtitle mt-10">Upload multiple image </div>
                <button class="btn-thrive_primary mt-10" >Browse</button>
                <div class="property-row mt-30" >
                    <div class="property-column" *ngFor="let property of imageProperty;">
                        <span class="property-name">{{property.name}}</span>
                        <span class="property-value subbase">{{property.value}}</span>
                    </div>
                </div>
            </div>
        </div>
        <mat-error *ngIf="menuCard.get('images').hasError('required')">Required.</mat-error>
        <div class="multiple-image-row" cdkDropList cdkDropListOrientation="horizontal" *ngIf="menuCard.get('images').value?.length" (cdkDropListDropped)="drop($event)">
            <div class="multiple-image-size" cdkDrag *ngFor="let image of menuCard.get('images')?.value;let i = index;">
                <mat-icon (click)="removeImagefromArray(i,image)">cancel</mat-icon>
                <div class="image-size" (click)="openCarousal()">
                    <img width="120" height="120" (error)="showUploadedImage($event,image)" [src]="image.link" />
                </div>
                <p class="image-name">{{getName(image.link)}}</p>
            </div>
            
            <div class="upload-card" (click)="itemImageUpload.click()">
                <mat-icon color="primary">cloud_upload</mat-icon>
                <p class="pretitle">UPLOAD MORE</p>
            </div>
        </div>


        <div class="thrive-toast_info mt-20">
            <span>Info:</span>
            <span> <span class="bold">You can click and hold to rearrange the images.</span> The same order of images will be published on consumer app.</span>
        </div>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button [routerLink]="'/restaurants/'+restaurantInfo?.id" mat-button class="btn-thrive_secondary mr-10">
                Cancel
            </button>
            <button class="btn-thrive_primary" 
                    mat-button 
                    (click)="submit()"
                    accessControl
                    moduleType="moderation_tool" 
                    [disabled]="submitted">
                <mat-icon>add</mat-icon>
                <span>Save Changes</span>
            </button>
        </div>
    </div>
</div>
