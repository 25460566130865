import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {MatTable, MatTableModule} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BreakpointObserver} from '@angular/cdk/layout';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {LoaderComponent} from '../loader/loader.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import { AccessControlDirective } from '../rbac/rbac.directive';
import { RbacService } from '../rbac/rbac.service'
import {MatSnackBar,MatSnackBarModule} from '@angular/material/snack-bar';
export interface ColumnData {
    columnDef: string;
    header: string;
    subHeader?: string;
    cell: string;
    pipe?: string;
    class?: string;
    isChip?: boolean;
    chipColor?: string;
    align?: 'left' | 'right' | 'center';
    sticky?: boolean;
    isSpecialHTML?: boolean;
    isDraggable?: boolean;
    isToggle?: boolean;
    isEditable?: boolean;
    isDeletable?: boolean;
    isSpecialAction?: boolean;
    isImage?: boolean;
    isDate?: boolean;
    isBool?: boolean;
    isDateMedium?: boolean;
    hidingCondition?: {
        //show or hide data in column
        hidingParam: string; //parameter on which to show or hide
        hidingOperator: 'equals' | 'greaterthan' | 'lessthan' | 'greaterthanequals' | 'lessthanequals' | 'notequals'; //operator to decide the visibility
        hidingString: string; //condition to match for visibility
    };
}

@Component({
    selector: 'tn-table',
    templateUrl: './table.component.html',
    animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
    styleUrls: ['./table.component.scss'],
    standalone: true,
    imports: [CommonModule, MatTableModule, MatPaginatorModule, MatChipsModule, DragDropModule, MatSlideToggleModule, MatButtonModule, MatIconModule, MatTooltipModule, LoaderComponent,MatCheckboxModule,AccessControlDirective],
})
export class TableComponent implements OnInit {
    @Input() title: any;
    @Input() data: any;
    @Input() columns: ColumnData[];
    @Input() desktopDisplayedColumns: string[];
    @Input() mobileDisplayedColumns: string[];
    @Input() resultsLength: number;
    @Input() pageIndex: number;
    @Input() isRowDraggable: boolean = false;
    @Input() hidePageSize: boolean = false;
    @Input() pageSize: number;
    @Input() specialHTML: TemplateRef<any>;
    @Input() specialButton: TemplateRef<any>;
    @Input() mobileTemplate: TemplateRef<any>;
    @Input() featureAccessControl?: string;

    @Output() changePage = new EventEmitter();
    @Output() rowClick = new EventEmitter();
    @Output() actionClick = new EventEmitter();
    @Output() rowDrag = new EventEmitter();
    @Output() getSelectedRow = new EventEmitter();


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('table') table: MatTable<any>;

    isLoadingResults = true;
    displayedColumns = [];
    isMobileView = false;
    expandedElement: any;
    columnsToDisplayWithExpand = [];
    isToggle:boolean = false;
    selection = new SelectionModel(true, []);
    specialHTMLIsArray:boolean = false
    constructor(private breakpointObserver: BreakpointObserver,private rbacService:RbacService) {}

    ngOnInit(): void {
        let access_control: any = this.rbacService.getDataFromStore('access_control');;
        if (access_control) {
            let module: any = access_control.find(access => access.feature === this.featureAccessControl);
            if (module && !module.write && module.read) {
                this.isToggle = true;
            } else {
                this.isToggle = false;
            }
        }
        this.breakpointObserver
            .observe(['(max-width: 810px)'])
            .pipe()
            .subscribe((result: any) => {
                if (result.matches) {
                    this.displayedColumns = this.mobileDisplayedColumns;
                    this.columnsToDisplayWithExpand = this.mobileTemplate ? [...this.displayedColumns, 'expand'] : [...this.displayedColumns];
                    this.isMobileView = true;
                } else {
                    this.displayedColumns = this.desktopDisplayedColumns;
                    this.isMobileView = false;
                }
            });
    }

    // Drop event for draggable columns
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    }

    // Drop event for draggable rows
    onListDrop(event: CdkDragDrop<string[]>) {
        const previousIndex = this.data.findIndex((row) => row === event.item.data);
        moveItemInArray(this.data, previousIndex, event.currentIndex);
        this.table.renderRows();
        this.rowDrag.emit(this.data);
    }

    handlePageEvent(pageEvent: PageEvent) {
        this.changePage.emit(pageEvent);
    }

    rowClicked(row) {
        this.rowClick.emit(row);
    }

    // Click event for edit or delete like action buttons
    actionClicked(row, action: string, event?) {
        this.actionClick.emit({ action, row, checked: event?.checked });
    }

    // Function to show or hide data in a particular column based on a condition
    parseJSON(column, row) {
        let condition = true;
        if (column.hidingCondition) {
            switch (column.hidingCondition.hidingOperator) {
                case 'equals': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) == column.hidingCondition.hidingString;
                    break;
                }
                case 'notequals': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) != column.hidingCondition.hidingString;
                    break;
                }
                case 'greaterthan': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) > column.hidingCondition.hidingString;
                    break;
                }
                case 'lessthan': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) < column.hidingCondition.hidingString;
                    break;
                }
                case 'greaterthanequals': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) >= column.hidingCondition.hidingString;
                    break;
                }
                case 'lessthanequals': {
                    condition = this.deepGet(row, column.hidingCondition.hidingParam.split('.')) <= column.hidingCondition.hidingString;
                    break;
                }
            }
        }
        return condition;
    }

    // Function to check condition in nested objects
    deepGet(obj, keys) {
        return keys.reduce((xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null), obj);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
    this.selection.select(...this.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    } 
    this.getSelectedRow.emit(this.selection)   
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
