import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router, RouterModule} from '@angular/router';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {RadiusService} from './radius.service';
import Swal from 'sweetalert2';
import {FormControl} from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-delivery-radius',
    templateUrl: './delivery-radius.component.html',
    styleUrls: ['./delivery-radius.component.scss'],
    standalone: true,
    imports: [CommonModule, 
        MatIconModule, 
        TableComponent, 
        MatButtonModule, 
        RouterModule, 
        MatTooltipModule, 
        MatSelectModule, 
        MatDatepickerModule, 
        MatNativeDateModule, 
        MatFormFieldModule, 
        MatInputModule, 
        MatSlideToggleModule,
        AccessControlDirective],
    providers: [RadiusService]
})
export class DeliveryRadiusComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    cities: any;
    pincodes: any;

    filter: FormControl = new FormControl('');
    status: string = 'all';

    constructor(private radiusService: RadiusService, private router: Router) {
        this.radiusService.getCities().subscribe((cities) => (this.cities = cities));
        this.radiusService.getPincodes().subscribe((pincodes) => (this.pincodes = pincodes));
    }

    ngOnInit() {
        this.getDeliveryRadius(0, 'all');
        this.columns = [
            {
                columnDef: 'limitid',
                header: 'City/pincode',
                cell: 'limitable_id',
                sticky: true,
                isSpecialHTML: true
            },
            {
                columnDef: 'radius',
                header: 'Delivery radius',
                subHeader: '(in km)',
                cell: 'max_delivery_radius'
            },
            {
                columnDef: 'activatetime',
                header: 'Activate time',
                cell: 'activate_time',
                isDateMedium: true
            },
            {
                columnDef: 'time',
                header: 'Deactivate time',
                cell: 'deactivate_time',
                isDateMedium: true
            },
            {
                columnDef: 'active',
                header: 'Active',
                cell: 'active',
                isToggle: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['limitid', 'radius'];
    }

    getDeliveryRadius(page: number, filter) {
        this.radiusService.getRadius(page, filter).subscribe((radius: any) => {
            this.data = radius.tags;
            this.resultsLength = radius.total_count;
        });
    }

    changePage(event) {
        this.getDeliveryRadius(event.pageIndex, this.status);
    }

    actionClicked(event) {
        if (event.action === 'status') {
            this.radiusService.updateRadius({active: event.checked}, event.row.id).subscribe(() => {
                Swal.fire({title: 'Success', text: 'Status Updated Successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.getDeliveryRadius(0, this.status);
            });
        }
    }

    getName(row) {
        let name = '';
        if (row && this.cities && this.pincodes) {
            if (row.limitable_type === 'City') {
                let filter = this.cities.filter((city) => {
                    return city.id == row.limitable_id;
                });
                name = filter[0] ? filter[0].name : row.limitable_id;
            } else {
                let filter = this.pincodes.filter((pincode) => {
                    return pincode.id == row.limitable_id;
                });
                name = filter[0] ? filter[0].pincode : row.limitable_id;
            }
        }
        return name;
    }

    changeStatus(event) {
        this.getDeliveryRadius(0, event.value);
    }

    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('delivery-radius/' + event.id);
    }
}
