<div id="questionEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/delivery-radius'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Delivery Radius</h2>
        </div>
    </div>
    <form [formGroup]="deliveryForm" class="mt-30">
        <div class="formgrid">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Type</mat-label>
                <mat-select placeholder="Select Type" formControlName="limitable_type">
                    <mat-option value="City">City</mat-option>
                    <mat-option value="Pincode">Pincode</mat-option>
                </mat-select>
                <mat-error *ngIf="deliveryForm.get('limitable_type').invalid">Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20" *ngIf="deliveryForm.get('limitable_type').value === 'City'">
                <mat-label>City</mat-label>
                <input type="text" placeholder="Enter Cities" matInput formControlName="limitable_id" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionCity">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option.name }}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="deliveryForm.get('limitable_id').invalid">Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20" *ngIf="deliveryForm.get('limitable_type').value === 'Pincode'">
                <mat-label>Pincodes</mat-label>
                <input type="text" placeholder="Enter 3 digits to see results..." matInput formControlName="limitable_id" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptionPin">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option.pincode }}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="deliveryForm.get('limitable_id').invalid">Required</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Platform</mat-label>
            <mat-select placeholder="Select Platform" formControlName="platform">
                <mat-option value="consumer_app">Consumer</mat-option>
                <mat-option value="microsite">Microsite</mat-option>
                <mat-option value="both">Both</mat-option>
            </mat-select>
            <mat-error *ngIf="deliveryForm.get('platform').invalid">Required</mat-error>
        </mat-form-field>
        <mat-checkbox color="primary" formControlName="applicable_on_self">Is applicable for self delivery orders</mat-checkbox>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20 mt-20">
            <mat-label>Delivery radius (in km)</mat-label>
            <input matInput type="number" placeholder="Enter Delivery Radius" formControlName="max_delivery_radius" min="0" />
            <mat-error *ngIf="deliveryForm.get('max_delivery_radius').invalid">Required</mat-error>
        </mat-form-field>
        <div class="formgrid">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Activation date & time</mat-label>
                <input [min]="minDate" matInput type="datetime-local" (focus)="datetime2.showPicker()" #datetime2 placeholder="Enter Activation DateTime" formControlName="activate_time" />
                <mat-error *ngIf="deliveryForm.get('activate_time').invalid">Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Deactivation date & time</mat-label>
                <input [min]="deliveryForm.get('activate_time').value" matInput type="datetime-local" (focus)="datetime.showPicker()" #datetime placeholder="Enter Deactivation DateTime" formControlName="deactivate_time" />
                <mat-error *ngIf="deliveryForm.get('deactivate_time').invalid">Required</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
            <mat-label>Reason</mat-label>
            <mat-select placeholder="Select Platform" formControlName="display_text">
                <mat-option value="Currently unavailable due to rain">Currently unavailable due to rain</mat-option>
                <mat-option value="Currently unavailable">Currently unavailable</mat-option>
            </mat-select>
            <mat-error *ngIf="deliveryForm.get('display_text').invalid">Required</mat-error>
        </mat-form-field>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary"
            accessControl
            moduleType="delivery_radius" 
            mat-button (click)="submit()" 
            [disabled]="!deliveryForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId !== 'new' ? 'Update' : 'Save' }}</span>
            </button>
        </div>
    </div>
</div>
