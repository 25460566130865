<div id="niu">
    <!-- Deactivation effect -->
    <div *ngIf="!modelView">
        <div class="dialog-header">
            <h3>Are you sure you want to deactivate the merchant ?</h3>
            <button class="btn-thrive_icon" (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <ul>
                <li>Deactivation will be immediate.</li>
                <li>The merchant will lose access to all the authenticated views (For eg: merchant dashboard, staff dashboard etc).</li>
                <li>All the running features will be disabled (For eg: campaigns, reporting, transactions recording etc).</li>
            </ul>
        </div>
        <div class="dialog-footer">
            <button class="btn-thrive_secondary ml-20" (click)="modelView = true">Continue</button>
            <button class="btn-thrive_primary ml-20" (click)="dialogRef.close()">Cancel</button>
        </div>
    </div>
    <!-- Reason -->
    <div *ngIf="modelView">
        <div class="dialog-header">
            <h3>Why are you deactivating the merchant?</h3>
            <button class="btn-thrive_icon" (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <form [formGroup]="reason">
                <mat-checkbox color="primary" id="Shut down" formControlName="Shut down">
                    <span class="radio-label">Shut down</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Lost to competitor" formControlName="Lost to competitor">
                    <span class="radio-label">Lost to competitor</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Money issues" formControlName="Money issues">
                    <span class="radio-label">Money issues</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Missing features" formControlName="Missing features" (change)="missignFeature($event)">
                    <span class="radio-label">Missing features</span>
                </mat-checkbox>
                <mat-form-field *ngIf="reason.get('missing_features')" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-10 mt-10">
                    <mat-label>Enter missing features</mat-label>
                    <textarea matInput rows="2" formControlName="missing_features"></textarea>
                    <mat-error *ngIf="reason.get('missing_features').hasError('required')">Required</mat-error>
                </mat-form-field>
                <mat-checkbox color="primary" id="In-house loyalty" formControlName="In-house loyalty">
                    <span class="radio-label">In-house loyalty</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Performance issues" formControlName="Performance issues">
                    <span class="radio-label">Performance issues</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Duplicate" formControlName="Duplicate">
                    <span class="radio-label">Duplicate</span>
                </mat-checkbox>
                <mat-checkbox color="primary" id="Other" formControlName="Other" (change)="others($event)">
                    <span class="radio-label">Other</span>
                </mat-checkbox>
                <mat-form-field *ngIf="reason.get('others_reason')" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-10 mt-10">
                    <mat-label>Enter other reason</mat-label>
                    <textarea matInput rows="2" formControlName="others_reason"></textarea>
                    <mat-error *ngIf="reason.get('others_reason').hasError('required')">Required</mat-error>
                </mat-form-field>
                <mat-error *ngIf="reason.hasError('notchecked')">
                    At least one reason should be selected.
                </mat-error>
            </form>
        </div>
        <div class="dialog-footer">
            <button class="btn-thrive_secondary ml-20" (click)="submit()">Continue</button>
            <button class="btn-thrive_primary ml-20" (click)="dialogRef.close()">Cancel</button>
        </div>
    </div>
</div>