<div id="merchantHygiene" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Merchant Hygiene Consumer App</h2>
            <button class="btn-thrive_icon p-0" 
            (click)="downloadSheet()" 
            mat-icon-button 
            [matTooltip]="'Download sheet'" 
            accessControl
            moduleType="merchant_hygiene" 
            matTooltipPosition="below" 
            matTooltipClass="thrive-tooltip">
                <mat-icon class="mr-0">download_for_offline</mat-icon>
            </button>
        </div>
    </div>

    <div class="filters">
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose filter option</mat-label>
            <mat-select [(value)]="filter_category" (selectionChange)="clearText()">
                <mat-option value="id">Merchant id</mat-option>
                <mat-option value="name">Merchant name</mat-option>
                <mat-option value="chain_id">Chain id</mat-option>
                <mat-option value="pincode">Pincode</mat-option>
                <mat-option value="city">City</mat-option>
                <mat-option value="no_of_menu_items">Menu items</mat-option>
                <mat-option value="thrive_promise">Thrive promise</mat-option>
                <mat-option value="status">Status</mat-option>
                <mat-option value="created_at">Created at</mat-option>
                <mat-option value="no_of_item_images">Item images</mat-option>
                <mat-option value="background_image">Background image</mat-option>
                <mat-option value="live_offer_present">Live offers</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'id' || filter_category == 'chain_id' || filter_category == 'name'">
            <mat-label [innerHTML]="filter_category == 'id' ? 'Merchant Id' : filter_category == 'name' ? 'Merchant Name' : 'Chain Id'">Merchant id</mat-label>

            <input matInput [(ngModel)]="search" [placeholder]="'Enter Your ' + (filter_category == 'id' ? 'Merchant Id' : filter_category == 'name' ? 'Merchant Name' : 'Chain Id')" />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'pincode'">
            <mat-label innerHTML="Enter pincode">Merchant id</mat-label>

            <input matInput [(ngModel)]="search" placeholder="Enter pincode" />
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'city'">
            <mat-label>Select city</mat-label>
            <input matInput [formControl]="formControl" type="text" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCitySelected($event)">
                <mat-option *ngFor="let city of filterOptions | async" [value]="city">{{ city }}</mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category === 'no_of_menu_items' || filter_category === 'no_of_item_images' || filter_category === 'live_offer_present'">
            <mat-label [innerHTML]="filter_category == 'no_of_menu_items' ? 'Menu Items' : filter_category == 'no_of_item_images' ? 'Item Images' : 'Live Offers'">Merchant id</mat-label>
            <mat-select [(ngModel)]="search" placeholder="Select value">
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">Greater than 0</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'status'">
            <mat-label>Select Status</mat-label>
            <mat-select [(value)]="search">
                <mat-option [value]="true">Live</mat-option>
                <mat-option [value]="false">Not live</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'thrive_promise'">
            <mat-label>Select thrive promise</mat-label>
            <mat-select [(value)]="search">
                <mat-option [value]="1">Yes</mat-option>
                <mat-option [value]="0">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'background_image'">
            <mat-label>Background image present</mat-label>
            <mat-select [(value)]="search">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'created_at'">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start (DD-MM-YYYY)" />
                <input matEndDate formControlName="end" placeholder="End (DD-MM-YYYY)" />
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <button class="btn-thrive_primary" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="applyFilters(0)" [disabled]="(search?.length===0||search===null||search===undefined)&&filter_category !== 'created_at'" *ngIf="filter_category">
            <span>Apply</span>
        </button>
    </div>

    <div class="tableoverflowdiv">
        <tn-table
            [title]="'Hygiene merchants'"
            [data]="data"
            [columns]="columns"
            [desktopDisplayedColumns]="desktopDisplayedColumns"
            [mobileDisplayedColumns]="mobileDisplayedColumns"
            [resultsLength]="resultsLength"
            [specialHTML]="{'manual_popularity':popularity,
                            'google_place_id':googlePlaceId,
                            'restaurant':restaurantId}"
            [pageSize]="pageSize"
            [mobileTemplate]="mobileTemplate"
            (changePage)="changePage($event)"
            (actionClick)="actionClicked($event)"
            [featureAccessControl]="'merchant_hygiene'"
        >
        </tn-table>
    </div>
</div>
<ng-template #googlePlaceId let-option>
    <div class="link" (click)="configureMissingDetails(option.row,'Link the merchant with Google Place ID'); $event.stopPropagation()">{{!option.row.google_place_id ? 'Link Google ID' : option.row.google_place_id}}</div>
</ng-template>
<ng-template #popularity let-option>
    <div class="link" (click)="configureMissingDetails(option.row,'Update Popularity Score'); $event.stopPropagation()">
        {{option.row.manual_popularity === 0 ? 'Update Popularity' : option.row.manual_popularity}}
    </div>
</ng-template>
<ng-template #restaurantId let-option>
    <div class="link" [routerLink]="'/restaurants/'+ option.row.restaurant.id"  *ngIf="(option.row.restaurant | json) !== '{}'" >
        {{  option.row.restaurant.name }}
    </div>
    <div *ngIf="(option.row.restaurant | json) === '{}'">
        -
    </div>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Location</td>
            <td>{{ option.row.location }}</td>
        </tr>
        <tr>
            <td>Pincode</td>
            <td>{{ option.row.pincode }}</td>
        </tr>
        <tr>
            <td>City</td>
            <td>{{ option.row.city }}</td>
        </tr>
        <tr>
            <td>Outlet number</td>
            <td>{{ option.row.contact_number }}</td>
        </tr>
        <tr>
            <td>Status</td>
            <td>
                <mat-slide-toggle
                    accessControl
                    moduleType="merchant_hygiene" 
                    color="primary" 
                    (click)="$event.stopPropagation()" 
                    (change)="actionClicked({row: option.row, checked: $event.checked, action: 'status'})" 
                    [checked]="option.row.status">
                </mat-slide-toggle>
            </td>
        </tr>
        <tr>
            <td>Google Place ID</td>
            <td>
                <div class="link" (click)="configureMissingDetails(option.row,'Link the merchant with Google Place ID'); $event.stopPropagation()">{{!option.row.google_place_id ? 'Link Google ID' : option.row.google_place_id}}</div>
            </td>
        </tr>
        <tr>
            <td>Popularity</td>
            <td>
                <div class="link" (click)="configureMissingDetails(option.row,'Update Popularity Score'); $event.stopPropagation()">
                    {{option.row.manual_popularity === 0 ? 'Update Popularity' : option.row.manual_popularity}}
                </div>
            </td>
        </tr>
        <tr>
            <td>Restaurant profile</td>
            <td>
                <div class="link" [routerLink]="'/restaurants/'+ option.row.restaurant.id"  *ngIf="(option.row.restaurant | json) !== '{}'" >
                    {{  option.row.restaurant.name }}
                </div>
                <div *ngIf="(option.row.restaurant | json) === '{}'">
                    -
                </div>
            </td>
        </tr>
        <tr>
            <td>Menu items</td>
            <td>
                {{option.row.active_menu_items}}
            </td>
        </tr>
        <tr>
            <td>Timings</td>
            <td>{{ option.row.timings | json }}</td>
        </tr>
        
        <tr>
            <td>Item images</td>
            <td>{{ option.row.item_images }}</td>
        </tr>
        <tr>
            <td>Background image</td>
            <td>{{ option.row.background_image_present }}</td>
        </tr>
        <tr>
            <td>Live offfers</td>
            <td>{{ option.row.live_offers }}</td>
        </tr>
        <tr>
            <td>Bank details</td>
            <td>{{ option.row.bank_details }}</td>
        </tr>
        <tr>
            <td>Thrive promise</td>
            <td>{{ option.row.thrive_promise }}</td>
        </tr>
    </table>
</ng-template>
