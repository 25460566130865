import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { TagsService } from '../tags.service';
import {DataFilterPipe } from '../../shared/pipes/data-filter.pipe';
import {NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';

export interface tagCategoryIf {
  id: number,
  name: string,
  description:string,
  tag_type: string,
  background_image_color: string,
  image_url: string
}

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [CommonModule, MatDialogModule,NgxMatSelectSearchModule,MatSelectModule,MatButtonModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule, MatRadioModule, FormsModule, MatTooltipModule,DataFilterPipe],
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit{
  filterOption:string = 'Status';
  filters = [
      { name: 'Status', id: 'show_deleted' },
      { name: 'Name', id: 'name' },
      { name: 'Type', id: 'tag_type' },
      { name: 'Category', id: 'tag_category_id' }
  ];

  statusValue:boolean = null;
  nameValue:string = null;
  typeValue:{key:string, value:number} = null;
  categoryValue:Array<tagCategoryIf>;
  tagCategoryList:Array<tagCategoryIf>;
  tagType = {};
  search:string;
  constructor(public dialogRef: MatDialogRef<FiltersComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private tagsService:TagsService) {
      this.statusValue = data.show_deleted;
      this.nameValue = data.name;
      this.typeValue = data.tag_type;
      this.categoryValue = data.tag_category_id;
  }
  ngOnInit(): void {
    this.getTagCategory();
    this.getTagType();
  }
  
  changeFilter(filter) {
      this.filterOption = filter[0];
  }

  getName(filterOption) {
      return filterOption.split('_').join(' ');
  }

  clearValue() {
      switch (this.filterOption) {
          case 'Status':
              this.statusValue = null;
              break;
          case 'Name':
              this.nameValue = null;
              break;
          case 'Type':
              this.typeValue = null;
              break;
          case 'Category':
              this.categoryValue = null;
              break;
      }
  }

  clearAll() {
      this.statusValue = null;
      this.nameValue = null;
      this.typeValue = null;
      this.categoryValue = null;
  }

  getTagCategory(){
    this.tagsService.getTagCategory().subscribe((response:Array<tagCategoryIf>)=>{
      this.tagCategoryList = response;
    })
  }

  getTagType(){
    this.tagsService.getTagType().subscribe((response:any)=>{
      this.tagType = this.convertObjectToArray(response);
    });
  }

  // Method to convert object to array of objects
  convertObjectToArray(obj: { [key: string]: number }): Array<{ key: string, value: number }> {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  /**
   * To close the dialog with or without any submission
   * @param {string | boolean} action close the dialog or not
   */
  onClick(action: string | boolean): void {
      let filters = {
          show_deleted: this.statusValue ? this.statusValue : '',
          name: this.nameValue ? this.nameValue: '',
          tag_type: this.typeValue ? this.typeValue : '',
          tag_category_id: this.categoryValue ? this.categoryValue : '',
      };
      this.dialogRef.close(action ? filters : false);
  }
}
