<div class="dialog-header">
    <span>Change Status</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClick(false)">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
        <mat-label>Choose Status</mat-label>
        <mat-select [(value)]="status">
            <mat-option value="to_review" [disabled]="status !== 'to_review'">To Review</mat-option>
            <mat-option value="approved">Approve</mat-option>
            <mat-option value="reviewed">Reviewed</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="dialog-footer">
    <button (click)="onClick(false)" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="onClick(true)" class="btn-thrive_primary ml-20" mat-button [innerHTML]="data ? 'Update' : 'Save'"></button>
</div>
