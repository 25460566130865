import { CommonModule } from '@angular/common';
import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
@Component({
    selector: 'image-dialog',
    templateUrl: './image-dialog.html',
    styles: [
        '.upload-media { img { width: 100%; height: 70vh; object-fit: contain; } video { width: 100%; height: 70vh; object-fit: contain; }}'
    ],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule]
})
export class ImageDialog {

    constructor(public dialogRef: MatDialogRef<ImageDialog>, @Inject(MAT_DIALOG_DATA) 
                public data: any) {}
    /**
    * To close the dialog
    */
    onClick(): void {
        this.dialogRef.close();
    }
}