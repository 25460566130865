<div id="merchant" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Merchant</h2>
            <button class="btn-thrive_icon p-0" 
                accessControl
                moduleType="merchant_master"    
                mat-icon-button 
                [matTooltip]="'Bulk Upload'" 
                matTooltipPosition="below" 
                matTooltipClass="thrive-tooltip" 
                routerLink="bulk-upload">
                <mat-icon class="mr-0">published</mat-icon>
            </button>
            <button class="btn-thrive_primary" mat-button
                accessControl
                moduleType="merchant_master" 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
        <div class="filters mt-20">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Choose filter option</mat-label>
                <mat-select [(value)]="filter_category" (selectionChange)="clearText()">
                    <mat-option value="id">Merchant id</mat-option>
                    <mat-option value="name">Merchant name</mat-option>
                    <mat-option value="chain_id">Chain id</mat-option>
                    <mat-option value="city">City</mat-option>
                    <mat-option value="role">Role</mat-option>
                    <mat-option value="type">Type</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'id' || filter_category == 'chain_id' || filter_category == 'name'">
                <mat-label [innerHTML]="filter_category == 'id' ? 'Merchant Id' : filter_category == 'name' ? 'Merchant Name' : 'Chain Id'">Merchant id</mat-label>
                <input matInput [(ngModel)]="search" [placeholder]="'Enter Your ' + (filter_category == 'id' ? 'Merchant Id' : filter_category == 'name' ? 'Merchant Name' : 'Chain Id')" />
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'city'">
                <mat-label>Select city</mat-label>
                <input matInput [formControl]="formControl" type="text" [matAutocomplete]="auto" />
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let city of filterOptions | async" [value]="city">{{ city }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'role'">
                <mat-label>Select Role</mat-label>
                <mat-select [(value)]="search">
                    <mat-option value="ChainOwner">Chain Owner</mat-option>
                    <mat-option value="Merchant">Merchant</mat-option>
                    <mat-option value="Not in use">NIU</mat-option>
                    <mat-option value="Lead">Lead</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'type'">
                <mat-label>Select Type</mat-label>
                <mat-select [(value)]="search">
                    <mat-option value="Consumer">Consumer</mat-option>
                    <mat-option value="Direct">Direct</mat-option>
                    <mat-option value="Both">Both</mat-option>
                    <mat-option value="CRM only">CRM Only</mat-option>
                </mat-select>
            </mat-form-field>
            <button class="btn-thrive_primary" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="applyFilters(0)" *ngIf="filter_category">
                <span>Apply</span>
            </button>
        </div>
    </div>

    <tn-table
        [title]="'Merchants'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [specialHTML]="copyRow"
        [pageIndex]="pageIndex"
        [mobileTemplate]="mobileTemplate"
        (changePage)="changePage($event)"
        (rowClick)="navigateTo($event)"
    >
    </tn-table>
</div>
<ng-template #copyRow let-option>
    <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="copyClipBoard(option.row); $event.stopPropagation()">
        <mat-icon (click)="copyClipBoard(option.row)" color="primary">file_copy</mat-icon>
    </button> 
</ng-template>
<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>location</td>
            <td>{{ option.row.location }}</td>
        </tr>
        <tr>
            <td>city</td>
            <td>{{ option.row.city }}</td>
        </tr>
        <tr>
            <td>role</td>
            <td>{{ option.row.role }}</td>
        </tr>
        <tr>
            <td>type</td>
            <td>{{ option.row.type }}</td>
        </tr>
        <tr>
            <td>Copy details</td>
            <td>
                <mat-icon (click)="copyClipBoard(option.row)" color="primary">file_copy</mat-icon>
            </td>
        </tr>
    </table>
</ng-template>
