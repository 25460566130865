import {Component, OnInit} from '@angular/core';
import {RadiusService} from '../radius.service';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {TableComponent} from '../../shared/table/table.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import Swal from 'sweetalert2';
import {Observable, map, startWith} from 'rxjs';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';

@Component({
    selector: 'app-radius-edit',
    templateUrl: './radius-edit.component.html',
    styleUrls: ['./radius-edit.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatSelectModule,
        MatFormFieldModule,
        RouterModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatDialogModule,
        TableComponent,
        MatCheckboxModule,
        AccessControlDirective
    ],
    providers: [RadiusService]
})
export class RadiusEditComponent implements OnInit {
    deliveryForm: FormGroup;
    editId: string;
    cities: any;
    pincodes: any;
    filteredOptions: Observable<string[]>;
    minDate:any;

    constructor(private route: ActivatedRoute, private fb: FormBuilder, private radiusService: RadiusService, private router: Router) {
        this.radiusService.getCities().subscribe((cities) => (this.cities = cities));
        this.radiusService.getPincodes().subscribe((pincodes) => (this.pincodes = pincodes));
        this.editId = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.minDate = this.getDate(new Date());
        this.createForm();
        this.filteredOptions = this.deliveryForm.get('limitable_id').valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
        );
        if (this.editId !== 'new') {
            setTimeout(() => {
                this.radiusService.getRadiusById(this.editId).subscribe((data: any) => this.createForm(data.delivery_radius_limit));
            }, 1000);
        }
    }

    private _filter(value: string) {
        if (this.cities && this.pincodes && typeof value == 'string') {
            if (this.deliveryForm.get('limitable_type').value === 'City') return this.cities.filter((option) => option.name.toLowerCase().includes(value.toLowerCase()));
            if (this.deliveryForm.get('limitable_type').value === 'Pincode') {
                if (value.length > 2) {
                    return this.pincodes.filter((option) => option.pincode.toLowerCase().includes(value.toLowerCase()));
                } else return [];
            }
        }
    }

    getOptionCity(option) {
        return option.name;
    }
    getOptionPin(option) {
        return option.pincode;
    }

    createForm(values?) {
        this.deliveryForm = this.fb.group({
            limitable_type: new FormControl({value: values ? values.limitable_type : 'City', disabled: values ? true : false}, Validators.required),
            limitable_id: new FormControl({value: values ? this.getObj(values.limitable_id, values.limitable_type) : 0, disabled: values ? true : false}, Validators.required),
            platform: new FormControl(values ? values.platform : '', Validators.required),
            applicable_on_self: new FormControl(values ? values.applicable_on_self : false, Validators.required),
            max_delivery_radius: new FormControl(values ? values.max_delivery_radius : 0, Validators.required),
            activate_time: new FormControl(values ? this.getDate2(values.activate_time) : '', Validators.required),
            deactivate_time: new FormControl(values ? this.getDate(values.deactivate_time) : '', Validators.required),
            display_text: new FormControl(values ? values.display_text : '', Validators.required),
            active: new FormControl(true)
        });
    }

    getObj(id, type) {
        let obj;
        if (type === 'City' && this.cities) {
            obj = this.cities.filter((city) => {
                return city.id == id;
            })[0];
        }
        if (type === 'Pincode' && this.pincodes) {
            obj = this.pincodes.filter((pincode) => {
                return pincode.id == id;
            })[0];
        }
        return obj;
    }

    getDate(date) {
        let dateTime = new Date(date);
        var isoDateTime = new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000).toISOString();
        isoDateTime = isoDateTime.split('.')[0];
        isoDateTime = isoDateTime.slice(0, -3);
        return isoDateTime;
    }
    getDate2(date) {
        let dateTime2 = new Date(date);
        var isoDateTime2 = new Date(dateTime2.getTime() - dateTime2.getTimezoneOffset() * 60000).toISOString();
        isoDateTime2 = isoDateTime2.split('.')[0];
        isoDateTime2 = isoDateTime2.slice(0, -3);
        return isoDateTime2;
    }

    submit() {
        if (this.editId !== 'new') {
            let data = this.deliveryForm.getRawValue();
            data.limitable_id = data.limitable_id.id;
            this.radiusService.updateRadius(data, this.editId).subscribe(
                () => {
                    Swal.fire({title: 'Success', text: 'Radius Limit Updated Successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                    this.router.navigateByUrl('/delivery-radius');
                },
                (error) => {
                    if (error.status == 424) {
                        Swal.fire('Error!', error.error.errors[0], 'error');
                    }
                }
            );
        } else {
            let data = this.deliveryForm.getRawValue();
            data.limitable_id = data.limitable_id.id;
            this.radiusService.createRadius(data).subscribe(() => {
                Swal.fire({title: 'Success', text: 'Radius Limit Created Successfully!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.router.navigateByUrl('/delivery-radius');
            },
            (error) => {
                if (error.status == 424) {
                    Swal.fire('Error!', error.error.errors[0], 'error');
                }
            });
        }
    }
}
