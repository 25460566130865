import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MerchantService} from './merchant.service';
import {FormControl,FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
import {MatSnackBar,MatSnackBarModule} from '@angular/material/snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';
@Component({
    selector: 'app-merchant',
    templateUrl: './merchant.component.html',
    styleUrls: ['./merchant.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        TableComponent,
        MatButtonModule,
        RouterModule,
        MatTooltipModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AccessControlDirective,
        MatSnackBarModule,
    ]
})
export class MerchantComponent implements OnInit {
    data: any;
    pageSize: number = 10;
    pageIndex:number=0;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    showFilters: boolean = false;
    filter_category: string;
    route: any;
    search: string;
    allCity: any = [];
    filterOptions: Observable<string[]>;
    formControl = new FormControl('');

    constructor(private merchantService: MerchantService, 
        private router: Router,
        private clipboard: Clipboard,
        private snackBar: MatSnackBar) {
        this.getMerchantsList(this.pageIndex, this.pageSize);
        this.getCityList();
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'location',
                header: 'Location',
                cell: 'location'
            },
            {
                columnDef: 'city',
                header: 'City',
                cell: 'city'
            },
            {
                columnDef: 'role',
                header: 'Role',
                cell: 'role'
            },
            {
                columnDef: 'type',
                header: 'Type',
                cell: 'type'
            },
            {
                columnDef: 'copy',
                header: '',
                cell: 'copy',
                isSpecialHTML: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'name'];

        this.filterOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._FILTER(value || ''))
        );
    }

    /**
     * get merchants list
     * @param page page number
     * @param perPage number of merchants in one page i.e. page size
     */
    getMerchantsList(page: number, perPage: number) {
        this.merchantService.getMerchants(page).subscribe((merchants: any) => {
            this.data = merchants.merchants;
            this.resultsLength = merchants.total;            
            this.pageIndex=page;
        });
    }


    /**
     * get list of all cities
     */
    getCityList() {
        this.merchantService.getCities().subscribe((all_cities) => {
            this.allCity = all_cities;
        });
    }

    cityfunc() {
        this.search = this.formControl.value;
    }

    clearText() {
        this.search = null;
    }

    private _FILTER(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.allCity.filter((option) => option.toLowerCase().includes(filterValue));
    }

    /**
     * update merchant list on page change
     * @param event table event containing page index and size
     */
    changePage(event: any) {
        if (this.filter_category && this.search) {
            this.applyFilters(event.pageIndex);
        } else {
            this.getMerchantsList(event.pageIndex, event.pageSize);
        }
    }

    /**
     * fetch merchant list as per the applied filters
     * @param page  page number
     */
    applyFilters(page: any) {
        if (this.filter_category === 'city') {
            this.cityfunc();
        }
        this.merchantService.filterMerchants(this.filter_category, this.search, page).subscribe((data: any) => {
            this.data = data.merchants;
            this.resultsLength = data.total;
            this.pageIndex=page;
        });
    }

    /**
     * go to merchant create/edit page
     * @param event 
     */
    navigateTo(event: any) {
        if(!event.action) this.router.navigateByUrl('merchant/' + event.id);
    }
    /**
   * copy selected row
   * @param data copy selected row
   */
  copyClipBoard(data:any){
    this.clipboard.copy(JSON.stringify(data));
    this.snackBar.open('Copied','',{duration:500})

  }
}
