export const columnsComments = [
    {
        columnDef: 'body',
        header: 'Comment',
        cell: 'body',
        sticky: true
    },
    {
        columnDef: 'user',
        header: 'User',
        cell: 'user_name'
    },
    {
        columnDef: 'status',
        header: 'Status',
        cell: 'status',
        pipe: 'titlecase',
        isChip: true
    },
    {
        columnDef: 'action',
        header: '',
        cell: '',
        isSpecialAction: true
    }
];
export const columnsRecommendations = [
    {
        columnDef: 'name',
        header: 'Merchant',
        cell: 'name',
        sticky: true
    },
    {
        columnDef: 'mid',
        header: 'MID',
        cell: 'merchant_id'
    },
    {
        columnDef: 'votes',
        header: 'Votes',
        cell: 'total_upvotes'
    },
    {
        columnDef: 'mode',
        header: 'Ordering Mode',
        cell: 'available_on_consumer_app',
        isSpecialHTML: true
    }
];

export const uploadFiles = {
    filesObj: [],
    filesBase64: [],
    mediaFiles: [],
    mediaData: [],
    uploadedData: []
};

export const selectedTags = {
    tags:[],
    cohort:[],
    cuisine:[],
    adhoc:[]
};

export const listTag = {
    tags:[],
    cohort:[],
    cuisine:[],
    adhoc:[],
    restaurant_ad_hoc:[]  
}