import {Component, OnInit} from '@angular/core';
import {ThriveTagsService} from '../thrive-tags.service';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {ColorPickerModule} from 'ngx-color-picker';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
@Component({
    selector: 'app-thrive-tags-edit',
    templateUrl: './thrive-tags-edit.component.html',
    styleUrls: ['./thrive-tags-edit.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        RouterModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        ColorPickerModule,
        AccessControlDirective
    ],
    providers: [ThriveTagsService]
})
export class ThriveTagEditComponent implements OnInit {
    thriveTagForm: FormGroup;
    editId:string|number=null;
    colorPickerId: string;
    colorThriveTag:string;

    constructor(private route: ActivatedRoute, 
        private fb: FormBuilder, 
        private thriveTagsService: ThriveTagsService, 
        private router: Router) {
        this.editId = this.route.snapshot.params.id;
    }

    ngOnInit(): void {
        this.editId = this.route.snapshot.params.id;
        this.createForm();
        if (this.editId !== 'new') {
            this.getThriveTagDetails();
        }
    }

    getThriveTagDetails(){
        this.thriveTagsService.getThriveTagDetails(this.editId).subscribe((result:any)=>{
            this.thriveTagForm.patchValue(result);
            this.colorThriveTag = result.colour_code;
        });
    }

    createForm() {
        this.thriveTagForm = this.fb.group({
            id:new FormControl(''),
            name: new FormControl('', Validators.required),
            colour_code: new FormControl('#E66158', Validators.required),
            thrive_tag: new FormControl(true, Validators.required),
        });
        this.colorThriveTag = '#E66158';
    }

    submit() {
        if (this.editId !== 'new') {//edit thrive tag
            this.thriveTagsService.editThriveTag(this.thriveTagForm.value).subscribe((result:any) => {
                Swal.fire({title: 'Success', text: 'Tag updated!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.router.navigateByUrl('/thrive-tags');
            },(err) => {
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            });
        } else {// create new tag
            this.thriveTagsService.createThriveTag(this.thriveTagForm.value).subscribe((result:any) => {
                Swal.fire({title: 'Success', text: 'Tag created!', icon: 'success', timer: 4000, timerProgressBar: true});
                this.router.navigateByUrl('/thrive-tags');
            },(err) => {
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            });
        }
    }
}
