import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RbacService {

  constructor() { }
  //set data to local storage
  setDataInStore(key: string, value: any) {
    let data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    localStorage.setItem(key, data);
  }

  //get data from local storage
  getDataFromStore(key: string) {
    if (localStorage.getItem(key)) {
      try {
        let data = decodeURIComponent(
          escape(window.atob(localStorage.getItem(key)))
        );
        return JSON.parse(data);
      } catch (e) {
        localStorage.clear();
        return false;
      }
    } else {
      return false;
    }
  }
}
