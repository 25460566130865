export let checkMandatory: Array<string> = [
    'Sr No.',
    'Restaurant Name',
    'Owner Name',
    'Owner Mobile',
    'Owner Email',
    'Customer Type',
    'Restaurant Type',
    'Cuisines(s)',
    'Avg. Cost for 2',
    'FSSAI Registration (Y/N)',
    'FSSAI Expiry Date/Date of Application',
    'GST Registered (Y/N)',
    'PAN Number',
    'Signatory Name on PAN',
    'Registered company address',
    'Bank Account Number',
    'Account Name',
    'IFSC Code',
    'Business Type',
    'Location',
    'Outlet Address',
    'Latitude',
    'Longitude',
    'City',
    'Pin Code',
    'Outlet Number (s)',
    'Outlet Email ID',
    'Delivery Type (Self, Hybrid, 3rd Party)',
    'Thrive Promise (Yes/No)',
    'Staff Email (for Order Dashboard)',
    'Staff Password (for Order Dashboard)',
    'Contact for Logistics',
    'Contact for Order Reminder',
    'Email for Reporting and Invoicing & Order Summary',
    'Delivery',
    'Take-Away',
    'Dine-In'
];

export let checkOptional: Array<string> = [
    'Chain ID',
    'POS',
    'POS Key',
    'Logo',
    'Background Image',
    'Open Time',
    'Close Time',
    'FSSAI License Number / Registration Number',
    'FSSAI Certificate',
    'GST Number',
    'Slug',
    'Thrive Direct: Commission',
    'Thrive Direct: PG Plan',
    'Delivery Charges',
    'Consumer: New User Commission',
    'Consumer: Repeat User Commission',
    'Dine-In: Type'
];
