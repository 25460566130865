import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomMenuService {
  private url = environment;

  constructor(public http: HttpClient) {}

  getCustomItemList(page: number = 0,parPage:number,searchQuery?:string,restaurant?:any) {
    let params = new HttpParams();
        params = params.append('page_no', page+1);
        params = params.append('from_admin_panel',true);
        if(searchQuery)
        params = params.append('search_query',searchQuery);
        if(restaurant)
        params = params.append('restaurant_id',restaurant.restaurant_id);
    return this.http.get(this.url.consumerUrl + '/v1/feed/restaurant_items',{params});
  }

  getRestaurant(page:number,searchValue:any=''){
      return this.http.get(this.url.consumerUrl+'/v1/feed/restaurants?query='+searchValue+'&page_no='+page+'&list_id=803');
  }

  createCustomItem(body:any){
      return this.http.post(this.url.consumerUrl+'/v1/feed/restaurant_items',body);
  }

  updateCustomItem(id:any,body:any){
    return this.http.put(this.url.consumerUrl+'/v1/feed/restaurant_items/'+id,body);
  }

  getCustomItem(edit:any){
    return this.http.get(this.url.consumerUrl+'/v1/feed/restaurant_items/'+edit.id+'?restaurant_id='+edit.resto_id);
  }

  updateItemStatus(id:any,body:any){
    return this.http.put(this.url.consumerUrl+'/v1/feed/restaurant_items/'+id,body);
  }

  deleteItem(id:any,params:any){
    return this.http.delete(this.url.consumerUrl+'/v1/feed/restaurant_items/'+id,{params});
  }
}

