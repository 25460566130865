<div id="outlets">
    <div class="search-bar">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mb-20" >
            <mat-label >Search restaurants</mat-label>
            <input matInput type="search" [formControl]="search" placeholder="Search restaurants" />
        </mat-form-field>
        <button class="btn-thrive_primary" (click)="createOutlet()">Add new outlet</button>
    </div>

    <div class="grid-three-column mb-30">
        <mat-checkbox *ngIf="merchantList?.length" color="primary" [(ngModel)]="selectAll" (change)="selectAllFn($event)"> Select all</mat-checkbox>
        <ng-container *ngFor="let outlet of merchantList;">
            <mat-checkbox  color="primary" [(ngModel)]="outlet['checked']" (change)="selectSingleOutlet(outlet)">{{ outlet.name }} - {{outlet.locality}}</mat-checkbox>
        </ng-container>
        <div *ngIf="!merchantList?.length && search.value">Search result not found!</div>
        <div class="show-more" *ngIf="hasNextPage" (click)="showMore()">Show more</div>
    </div>
</div>
