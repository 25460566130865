import { Component, Inject } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColumnData, TableComponent } from '../shared/table/table.component';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModerationService } from './moderation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MentionModule } from 'angular-mentions';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
import { TagPipe } from './tag.pipe';
import { MentionReplacePipe } from '../shared/pipes/mention-replace.pipe';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-moderation-tool',
    templateUrl: './moderation-tool.component.html',
    styleUrls: ['./moderation-tool.component.scss'],
    standalone: true,
    imports: [CommonModule, 
        MatDialogModule, 
        MatIconModule, 
        TableComponent, 
        FormsModule,
        MatButtonModule, 
        RouterModule, 
        MatTooltipModule,
        MatSelectModule, 
        MatDatepickerModule, 
        MatNativeDateModule, 
        MatFormFieldModule, 
        MatInputModule, 
        MatSlideToggleModule,
        MatChipsModule, 
        MatTooltipModule,
        AccessControlDirective,
        TagPipe,
        MentionReplacePipe
    ],
    providers: [ModerationService, DatePipe, MentionReplacePipe]
})
export class ModerationToolComponent {
    data: any=[];
    pageSize: number = 10;
    resultsLength: number = 0;
    pageIndex: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    showFilters = true;
    filter_category: string = 'status';
    filter_value: any;
    sort_by: string;
    sort_type: any;
    botUsers: any;

    filters = {
        status: null,
        last_updated:{
            start_date:null,
            end_date:null
        },
        post_type: null,
        user_details:{
            id:null,
            user_name:null,
            display_name:null
        },
        user_id: null,
        search_query: null,
        is_pinned: null,
        recently_updated: null
    };
    filter_enable: boolean = false;
    selection:any;
    constructor(private moderationService: ModerationService, 
                private router: Router, 
                public dialog: MatDialog) {}

    ngOnInit() {
        this.getPosts(0);
        this.columns = [
            {
                columnDef: 'select',
                header: '',
                cell: '',
                isSpecialAction: true
            },
            {
                columnDef: 'id',
                header: 'Post ID',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'title',
                header: 'Title',
                cell: 'title',
                isSpecialHTML: true,
                sticky: true
            },
            {
                columnDef: 'post_type',
                header: 'Post type',
                cell: 'post_type',
                pipe: 'titlecase',
                sticky: true
            },
            {
                columnDef: 'user',
                header: 'User',
                cell: 'user',
                isSpecialHTML: true
            },
            {
                columnDef: 'comment',
                header: 'Comments',
                cell: 'total_comments'
            },
            {
                columnDef: 'recommendation',
                header: 'Recommendations',
                cell: 'total_recommendations'
            },
            {
                columnDef: 'tag',
                header: 'Tag',
                cell: 'tag',
                isSpecialHTML: true
            },
            {
                columnDef: 'sentiment_score',
                header: 'Sentiment score',
                cell: 'sentiment_score'
            },
            {
                columnDef: 'last_updated',
                header: 'Last updated',
                cell: 'updated_date_and_time'
            },
            {
                columnDef: 'created',
                header: 'Created at',
                cell: 'created_date'
            },
            {
                columnDef: 'status',
                header: 'Status',
                cell: 'status',
                pipe: 'titlecase',
                isChip: true
            },
            {
                columnDef: 'soical',
                header: 'Social link',
                cell: 'social',
                isSpecialHTML: true
            },
            {
                columnDef: 'is_pinned',
                header: 'Pinned',
                cell: 'is_pinned',
                isToggle: true
            },
            {
                columnDef: 'action',
                header: '',
                cell: '',
                isSpecialAction: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['title'];
    }

    /**
     * GET API call for fetching all Posts basis some filters
     * @param {number} page Page number for pagination support
     * @param {string} filterType Which type of filter to be applied
     * @param {string} filterValue Value of the filter to apply the filter
     * @param {string} sortType Which type of sort to be applied
     * @param {string} sortBy Order of the sort to apply
     */
    getPosts(page: number) {
        this.moderationService.getFeeds(page, this.filters).subscribe((posts: any) => {
            this.data = posts.feeds;
            this.resultsLength = posts.total_results;
            this.pageIndex = posts.current_page - 1;
        });
    }

    removeFilter(param) {
        switch (param) {
            case 'last_updated':
                this.filters[param].start_date=null;
                this.filters[param].end_date=null;
            break;
            case 'id':
            case 'user_name':
            case 'display_name':
                this.filters.user_details[param]=null;
            break;
            default:
                this.filters[param] = null;
            break;
        }
        this.getPosts(0);
    }

    clearAll() {
        this.filters = {
            status: null,
            last_updated: {
                start_date:null,
                end_date:null
            },
            post_type: null,
            user_details:{
                id:null,
                user_name:null,
                display_name:null
            },
            user_id: null,
            search_query: null,
            is_pinned: null,
            recently_updated: null
        };
        this.getPosts(0);
    }
    // Check if any filter value is non-null
    hasActiveFilters(): boolean {
        return Object.keys(this.filters).some(key => {
            const value = this.filters[key];
            if (typeof value === 'object' && value !== null) {
                Object.keys(value).some(subKey =>{ 
                    value[subKey] !== null
                })
                return Object.keys(value).some(subKey => value[subKey] !== null);
            }
            return value !== null;
        });
    }

    /**
     * To change the page and get the next array of Posts
     * @param event Event to change the page where you get pageNumber, pageSize and other pagination properties from table.component
     */
    changePage(event) {
        this.getPosts(event.pageIndex);
    }

    /**
     * Approve/Reject the post basis the content
     * @param event Row details from the table
     */
    approve(event) {
        const dialogRef = this.dialog.open(StatusDialog, {
            width: window.innerWidth < 768 ? '80vw' : '40vw',
            data: event,
            panelClass: 'thrive-dialog'
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                this.moderationService.updatePost(event.slug, { ...event, user_id: event.user.id, status: result }).subscribe(() => {
                    Swal.fire(result.toUpperCase() + '!', 'Post has been ' + result + '!', 'success');
                    setTimeout(() => this.getPosts(0), 1000);
                });
            }
        });
    }

    /**
     * onclick link icon from content table funtion will trigger
     * @param row row data use for redirect to social website
     */
     openLinkFn(row:any){
        let postType = row.post_type === 'simple_post'?'post':row.post_type;
        window.open(environment.socialWebsiteUrl+'/'+postType+'s/'+row.slug, '_blank');
    }
    
    /**
     * Apply the selected filter values and get the filtered data from API
     */
    applyFilter() {
        this.getPosts(0);
    }

    /**
     * Navigate to a different page on click
     * @param event Row details from the table
     */
    navigateTo(event) {
        if (!event.action) this.router.navigate([]).then(result => { window.open('moderation-tool/' + event.slug + '/question?user_id=' + event.user.id, '_blank'); });
    }

    actionClicked(event) {
        if (event.action) {
            this.moderationService.updatePost(event.row.slug, { user_id: event.row.user.id, is_pinned: event.checked }).subscribe(() => {
                let msg = event.checked ? 'Pinned succesfully' : 'Removed successfully';
                Swal.fire({ title: 'Updated', text: msg, icon: 'success', timer: 4000, timerProgressBar: true });
            }, (err) => {
                this.getPosts(this.pageIndex);
                let errMsg: string = 'Something went wrong. Please try agaian later.';
                if (err?.error?.errors) {
                    errMsg = typeof err.error.errors === 'string' ? err.error.errors : err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            });
        }
    }

    openFilters() {
        const dialogRef = this.dialog.open(FilterDialog, {
            width: window.innerWidth < 768 ? '80vw' : '40vw',
            panelClass: 'thrive-dialog',
            data: this.filters
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.filters = result;
                this.getPosts(0);
            }
        });
    }

    /**
     * get selected row
     * @param event get updated selected value in event.selected
     */
    getSelectedRow(event:any){
        if(!this.selection){
            setTimeout(() => {
                this.selection = event;
            }, 0); 
        } else {
            this.selection = event;
            this.selection['post_id'] = [];
            this.selection.post_id = event.selected.map(c=>c.id);  
        } 
    }

    /**
     * update status
     * @param status get selected status from drop down
     */
    bulkUpdateStatus(status:string){
        let body = {
            "post_ids": this.selection.post_id,
            "status": status
        }
        this.moderationService.bulkUpdateStatus(body).subscribe((response:any)=>{
            Swal.fire({ title: 'Updated.', text: 'Posts status updated successfully.', icon: 'success', timer: 4000, timerProgressBar: true });
            this.selection.clear();
            setTimeout(() => {
                this.getPosts(0);    
            }, 1000);
        });
    }

}

@Component({
    selector: 'comment-dialog',
    templateUrl: './status-dialog.html',
    styles: ['::ng-deep mention-list .mention-active > a { background: #6236FF !important }'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule, MentionModule],
    providers: [ModerationService]
})
export class StatusDialog {
    status: string;

    constructor(public dialogRef: MatDialogRef<StatusDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    /**
     * To close the dialog with or without any submission
     * @param {string | boolean} action close the dialog or not
     */
    onClick(action: string | boolean): void {
        this.dialogRef.close(action ? this.status : false);
    }
}

@Component({
    selector: 'filter-dialog',
    templateUrl: './filter-dialog.html',
    styleUrls: ['./filter-dialog.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, MatListModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, MatRadioModule, FormsModule,MatSelectModule, MatTooltipModule],
    providers: [ModerationService, DatePipe]
})
export class FilterDialog {
    filterOption = 'status';
    filters = [
        { name: 'Status', id: 'status' },
        { name: 'Last updated', id: 'last_updated' },
        { name: 'User details', id: 'user_details' },
        { name: 'Post type', id: 'post_type' },
        { name: 'Internal user', id: 'user_id' },
        { name: 'Title', id: 'search_query' },
        { name: 'Is pinned', id: 'is_pinned' },
    ];
    sorts = [
        { name: 'Last updated', id: 'recently_updated' }
    ];
    botUsers: any;
    statusValue = null;
    dateValue = {
        start_date:null,
        end_date:null
    };
    postTypeValue = null;
    userType:string
    userDetails = {
        id:null,
        user_name:null,
        display_name:null
    };
    userIdValue = null;
    titleValue = null;
    isPinnedValue = null;
    sortCreatedValue = null;
    sortUpdatedValue = null;
    maxDate:Date = new Date()
    constructor(public dialogRef: MatDialogRef<FilterDialog>,
                @Inject(MAT_DIALOG_DATA) public data: any, 
                private moderationService: ModerationService, 
                private datePipe: DatePipe) {
        this.moderationService.getBotUsers().subscribe((users) => (this.botUsers = users));
        this.statusValue = data.status;
        this.dateValue.start_date = data.last_updated.start_date;
        this.dateValue.end_date = data.last_updated.end_date;
        this.postTypeValue = data.post_type;
        this.userDetails = data.user_details;
        if(this.userDetails.user_name){
            this.userType = 'user_name';
        } else if(this.userDetails.display_name){
            this.userType = 'display_name';
        } else {
            this.userType = 'id';
        }
        this.userIdValue = data.user_id;
        this.titleValue = data.search_query;
        this.isPinnedValue = data.is_pinned;
        this.sortUpdatedValue = data.recently_updated;
    }
    changeFilter(filter) {
        this.filterOption = filter[0];
    }

    getName(filterOption) {
        return filterOption.split('_').join(' ');
    }

    clearValue() {
        switch (this.filterOption) {
            case 'status':
                this.statusValue = null;
                break;
            case 'last_updated':
                this.dateValue = {
                    start_date:null,
                    end_date:null
                };
                break;
            case 'post_type':
                this.postTypeValue = null;
                break;
            case 'user_details':
                this.userType = null;
                this.userDetails = {
                    id:null,
                    user_name:null,
                    display_name:null
                };
                break;
            case 'user_id':
                this.userIdValue = null;
                break;
            case 'search_query':
                this.titleValue = null;
                break;
            case 'is_pinned':
                this.isPinnedValue = null;
                break;
            case 'recently_updated':
                this.sortUpdatedValue = null;
                break;
        }
    }

    clearAll() {
        this.statusValue = null;
        this.dateValue = {
            start_date:null,
            end_date:null
        };
        this.postTypeValue = null;
        this.userDetails = {
            id:null,
            user_name:null,
            display_name:null
        };
        this.userType = null;
        this.userIdValue = null;
        this.titleValue = null;
        this.isPinnedValue = null;
        this.sortUpdatedValue = null;
    }
    onSelectionChange(event:MatSelectChange){
        this.userDetails = {
            id:null,
            user_name:null,
            display_name:null
        };
    }
    /**
     * To close the dialog with or without any submission
     * @param {string | boolean} action close the dialog or not
     */
    onClick(action: string | boolean): void {
        let filters = {
            status: this.statusValue ? this.statusValue : null,
            last_updated:{
                start_date:this.dateValue.start_date ? this.datePipe.transform(this.dateValue.start_date, 'yyyy-MM-dd') : null,
                end_date:this.dateValue.end_date ? this.datePipe.transform(this.dateValue.end_date, 'yyyy-MM-dd') : null,
            }, 
            post_type: this.postTypeValue ? this.postTypeValue : null,
            user_details: this.userDetails ? this.userDetails : null,
            user_id: this.userIdValue ? this.userIdValue : null,
            search_query: this.titleValue ? this.titleValue : null,
            is_pinned: this.isPinnedValue ? this.isPinnedValue : null,
            recently_updated: this.sortUpdatedValue ? this.sortUpdatedValue : null
        };
        this.dialogRef.close(action ? filters : false);
    }
}