<div id="categoryEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/app-category'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Category</h2>
        </div>
    </div>
    <form [formGroup]="categoryForm" class="form-display" *ngIf="showForm">
        <!-- Category type -->
        <mat-form-field  id="categoryType" subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Category type</mat-label>
            <mat-select placeholder="Choose type of category" formControlName="category_type" (selectionChange)="onCategoryTypeChange($event)">
                <mat-option value="thrive_category">Thrive category</mat-option>
                <mat-option value="custom_explore_section">Custom explore section</mat-option>
                <mat-option value="fixed_explore_section" disabled>Fixed explore section</mat-option>
            </mat-select>
            <mat-error *ngIf="categoryForm.get('category_type').hasError('required') && categoryForm.get('category_type').touched">Required</mat-error>
        </mat-form-field>
        <!-- Home card title -->
        <mat-form-field id="title" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Card title</mat-label>
            <input matInput placeholder="Enter title" formControlName="title"/>
            <!-- <mat-error *ngIf="categoryForm.get('title').invalid">Required</mat-error> -->
        </mat-form-field>
        <!-- Home card image -->
        <div id="cardIconImage" *ngIf="categoryForm.get('category_type')?.value && categoryForm.get('category_type')?.value !== 'thrive_category'" 
            [ngClass]="{disable:editId !== 'new'&&categoryForm.get('category_type')?.value==='fixed_explore_section'}">
            <p class="basetitle normal mb-10">Home card image</p>
            <image-upload [aspectRatio]="'1:1'" [imageUrl]="categoryForm.get('card_icon_image')?.value" (uploadImg)="onImageUpload($event,'card_icon_image')"></image-upload>
        </div>
         <!-- page icon image -->
         <div id="pageIconImage" *ngIf="categoryForm.get('category_type')?.value && categoryForm.get('category_type')?.value !== 'thrive_category'" 
            [ngClass]="{disable:editId !== 'new'&&categoryForm.get('category_type')?.value==='fixed_explore_section'}">
            <p class="basetitle normal mb-10">Page icon image</p>
            <image-upload [aspectRatio]="'4:3'" [imageUrl]="categoryForm.get('page_icon_image')?.value" (uploadImg)="onImageUpload($event,'page_icon_image')"></image-upload>
        </div>
        <!-- Page data -->
        <div class="grid-item form-display" formGroupName="data" *ngIf="categoryForm.get('category_type')?.value && categoryForm.get('category_type')?.value !== 'thrive_category'">
            <mat-form-field id="pageTitle" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Page title</mat-label>
                <input matInput placeholder="Enter page title" formControlName="page_title" />
                <mat-error *ngIf="categoryForm.get('data.page_title').invalid">Required</mat-error>
            </mat-form-field>
            <mat-form-field id="pageSubtitle" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Page sub title</mat-label>
                <input matInput placeholder="Enter page sub title" formControlName="page_subtitle" />
                <mat-error *ngIf="categoryForm.get('data.page_subtitle').invalid">Required</mat-error>
            </mat-form-field>
            <div [class.color-picker-focus]="colorPickerId == 'pageBg'" class="grid-item">
                <mat-form-field id="pageBgColor" subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                    <mat-label>Page bg color</mat-label>
                    <input matInput formControlName="page_bg_color" [(colorPicker)]="colorPageBg" [cpPosition]="'top'" (colorPickerChange)="categoryForm.get('data.page_bg_color').setValue($event)" (focus)="(colorPickerId == 'pageBg')" />
                    <span matSuffix class="color-circle" [style.background-color]="colorPageBg"></span>
                </mat-form-field>
            </div>
        </div>
        <!-- Custom filters -->
        <app-filters [filterValues]="filterValues" (sendFilters)="fetchFilters($event)"  class="grid-item" [ngClass]="{disable:editId !== 'new'&&categoryForm.get('category_type')?.value==='fixed_explore_section'}"></app-filters>
        <!-- Sort order -->
        <mat-form-field id="sortOrder" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="categoryForm.get('category_type')?.value && categoryForm.get('category_type')?.value !== 'thrive_category'">
            <mat-label>Sort order</mat-label>
            <input matInput placeholder="Enter sort order" formControlName="sort_order" />
            <mat-error *ngIf="categoryForm.get('sort_order').invalid">Required</mat-error>
        </mat-form-field>
        <mat-slide-toggle *ngIf="editId != 'new'" formControlName="status" color="primary">{{ categoryForm.get('status').value ? 'Enable Category' : 'Disable Category' }}</mat-slide-toggle>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button 
            accessControl
            moduleType="app_category" 
            (click)="submitCategory()">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
