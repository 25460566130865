import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {start_time_slots, end_time_slots, checkForStartTimeLessThanEndTime, checkForOverLap} from '../../../../../business/src/app/views/features/settings/open-hours/advance-settings/advance-settings.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'basic-open-hours',
    templateUrl: './basic-open-hours.component.html',
    styleUrls: ['./basic-open-hours.component.scss'],
    standalone: true,
    imports: [CommonModule, MatTooltipModule, MatCheckboxModule, MatSelectModule, MatButtonModule, MatChipsModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule]
})
export class BasicOpenHoursComponent {
    @Input() basic_default_time_slots: any;
    @Output() submitBasic = new EventEmitter<any>();
    days: any = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    start_time_slots: any = start_time_slots;
    end_time_slots: any = end_time_slots;
    basicOpenHoursForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {}

    // getter for open hours
    get basicOpenHoursFormArr(): UntypedFormArray {
        return this.basicOpenHoursForm ? (this.basicOpenHoursForm.get('open_slot') as UntypedFormArray) : null;
    }
    //more time slots in a single day
    addTimeSlot(slot: any) {
        this.basicOpenHoursFormArr.push(
            this.fb.group(
                {
                    start: [slot.start, [Validators.required]],
                    end: [slot.end, [Validators.required]]
                },
                {validator: checkForStartTimeLessThanEndTime}
            )
        );
    }
    //remove time slot from a day
    removeTimeSlot(index: number) {
        this.basicOpenHoursFormArr.removeAt(index);
    }
    toggleOpenDays(event: any) {
        const daysArray: UntypedFormArray = this.basicOpenHoursForm.get('days') as UntypedFormArray;
        daysArray.clear();
        event.value.forEach((item) => {
            daysArray.push(new UntypedFormControl(item));
        });
    }
    initialise() {
        for (let i: number = 0; i < this.basic_default_time_slots.length; i++) {
            if (!this.basic_default_time_slots[i].closed) {
                const control = <UntypedFormArray>this.basicOpenHoursForm.get('days');
                control.push(this.fb.control(this.basic_default_time_slots[i].day));
            }
        }
        const control = <UntypedFormArray>this.basicOpenHoursForm.get('open_slot');
        control.controls[0].get('start').setValue(this.basic_default_time_slots[0].open_slot[0].start);
        control.controls[0].get('end').setValue(this.basic_default_time_slots[0].open_slot[0].end);
        if (this.basic_default_time_slots[0].open_slot.length > 1) {
            for (let i: number = 1; i < this.basic_default_time_slots[0].open_slot.length; i++) {
                this.addTimeSlot(this.basic_default_time_slots[0].open_slot[i]);
            }
        }
    }
    ngOnChanges() {
        if (this.basic_default_time_slots && this.basic_default_time_slots.length) {
            this.basicOpenHoursForm = this.fb.group({
                open_slot: this.fb.array(
                    [
                        this.fb.group(
                            {
                                start: ['', [Validators.required]],
                                end: ['', [Validators.required]]
                            },
                            {validator: checkForStartTimeLessThanEndTime}
                        )
                    ],
                    checkForOverLap()
                ),
                days: this.fb.array([], [Validators.required])
            });
            this.initialise();
        }
    }
    submitForm() {
        this.submitBasic.next(this.basicOpenHoursForm);
    }
}
