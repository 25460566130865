import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { menuIf} from '../../reataurant'
@Component({
  selector: 'app-carousal',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule,SlickCarouselModule],
  templateUrl: './carousal.component.html',
  styleUrls: ['./carousal.component.scss']
})
export class CarousalComponent {
  images:Array<menuIf>=[];
  slideConfig:any = {slidesToShow: 1, slidesToScroll: 1, centerMode: true,arrows: true,
                    centerPadding: '0px',autoplay: false,autoplaySpeed: 1000,dots:true,speed: 1000};
                  
  constructor(public dialogRef: MatDialogRef<CarousalComponent>, @Inject(MAT_DIALOG_DATA) public data: any){
    this.images = data;
  }

}
