import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private url = environment;
  constructor(public http: HttpClient) {}
  
  /**
     * GET API call to fetch Questions
     * @param {number} page page number to get pagination
     * @param {string} filter type to filter data
     * @returns Observable
     */
   getTags(page: number, filters: any) {
    let show_deleted, tag_type, name, tag_category_id;
    if (filters) {
      show_deleted = filters.show_deleted ? `show_deleted&` : '';
      let type = [];
      if(filters.tag_type){
        type = filters.tag_type.map(t => t.value);
      }
      tag_type = filters.tag_type ? `tag_type=${type}&` : '';
      name = filters.name ? `name=${filters.name}&` : '';
      let categories = [];
      if(filters.tag_category_id){
        categories = filters.tag_category_id.map(c => c.id);
      }
      tag_category_id = filters.tag_category_id ? `tag_category_id=${categories}&` : '';
    }
    let filtersString = filters ? `${show_deleted}${tag_type}${name}${tag_category_id}` : '';
    return this.http.get(this.url.adminUrl + '/feed/tags?from_admin_panel=true&page_no=' + (page + 1) + '&' + filtersString);
  }

  getTagCategory(){
    return this.http.get(this.url.adminUrl+'/feed/tags/list_categories');
  }

  getTagType(){
    return this.http.get(this.url.adminUrl+'/feed/tags/tag_types');
  }

  getTagInfo(id:any){
    return this.http.get(this.url.adminUrl+'/feed/tags/'+id);
  }
  createTag(body:any){
    return this.http.post(this.url.adminUrl+'/feed/tags',body);
  }

  updateTag(id:any,body:any){
    return this.http.put(this.url.adminUrl+'/feed/tags/'+id,body)
  }
}
