<div class="thrive-table">
    <div class="table-container">
        <ng-container *ngIf="!data">
            <thrive-loader [loaderHeight]="'calc(100vh - 255px)'"></thrive-loader>
        </ng-container>
        <ng-container *ngIf="data">
            <!-- Draggable columns -->
            <table mat-table [dataSource]="data" matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" *ngIf="!isRowDraggable && !isMobileView">
                <ng-container *ngFor="let column of columns; let i = index">
                    <ng-container [matColumnDef]="column.columnDef" [sticky]="column.sticky">
                        <ng-container *ngIf="column.columnDef === 'select'" >
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row" >
                                <div  class="actions">
                                    <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                    (change)="$event ? selection.toggle(row) : null"
                                                    [checked]="selection.isSelected(row)"
                                                    [aria-label]="checkboxLabel(row)">
                                    </mat-checkbox>
                                </div>
                            </td>

                        </ng-container>
                        <th mat-header-cell cdkDrag [cdkDragDisabled]="column.columnDef === 'action'" *matHeaderCellDef class="text-{{ column.align ?? 'left' }}">
                            <p>
                                {{ column.header }}
                                <span *ngIf="column.subHeader" class="sub-header">{{ column.subHeader }}</span>
                            </p>
                        </th>
                        <ng-container *ngIf="column.columnDef !== 'action'">

                            <td mat-cell *matCellDef="let row" class="text-{{ column.align ?? 'left' }}">
                              
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <!-- Normal Text with pipes -->
                                    <span [ngSwitch]="column.pipe" *ngIf="!column.isChip && !column.isSpecialHTML && !column.isToggle && !column.isImage && !column.isDate && !column.isDateMedium && !column.isBool">
                                        <ng-container *ngIf="row[column.cell]">
                                            <span *ngSwitchCase="'titlecase'">{{ row[column.cell] | titlecase }}</span>
                                            <span *ngSwitchCase="'number'">{{ row[column.cell] | number : '0.2-2' }}</span>
                                            <span *ngSwitchCase="'json'">{{ row[column.cell] | json }}</span>
                                            <span *ngSwitchDefault class="{{ column.class }}">{{ row[column.cell] }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="!row[column.cell]">
                                            <span>-</span>
                                        </ng-container>
                                    </span>

                                    <!-- Date -->
                                    <span *ngIf="column.isDate">{{ row[column.cell] | date : 'dd/MM/yyyy' }}</span>
                                    <span *ngIf="column.isDateMedium">{{ row[column.cell] | date : 'medium' }}</span>

                                    <!-- Image -->
                                    <img *ngIf="column.isImage" src="{{ row[column.cell] }}" alt="" width="50" height="50" />

                                    <!-- Chip -->
                                    <mat-chip [ngClass]="row[column.cell]" *ngIf="column.isChip">{{ row[column.cell] | titlecase }}</mat-chip>

                                    <!-- Special HTML -->
                                    <ng-container *ngIf="column.isSpecialHTML">
                                        <ng-template [ngIf]="specialHTML.hasOwnProperty(column.cell)" [ngIfElse]="onlyOneSpecialHTML">
                                            <ng-container *ngTemplateOutlet="specialHTML[column.cell]; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                        <ng-template  #onlyOneSpecialHTML>
                                            <ng-container *ngTemplateOutlet="specialHTML; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                    </ng-container>
                                    <!-- Toggle Switch -->
                                    <ng-container *ngIf="column.isToggle">
                                        <mat-slide-toggle 
                                            color="primary" 
                                            [disabled]="isToggle"
                                            accessControl
                                            [moduleType]="featureAccessControl"   
                                            (click)="$event.stopPropagation()" 
                                            (change)="actionClicked(row, 'status', $event)" 
                                            [checked]="deepGet(row, column.cell.split('.'))">
                                        </mat-slide-toggle>
                                    </ng-container>
                                    <!-- Is Boolean -->
                                    <ng-container *ngIf="column.isBool">
                                       {{row[column.cell]?'Yes':'No'}}
                                    </ng-container>
                                   
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="column.columnDef === 'action'">
                            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <div class="actions">
                                        <button
                                        accessControl
                                        [moduleType]="featureAccessControl"
                                         mat-icon-button *ngIf="column.isEditable" [matTooltip]="'Edit'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="actionClicked(row, 'edit')">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button
                                        accessControl
                                        [moduleType]="featureAccessControl"
                                         mat-icon-button color="warn" *ngIf="column.isDeletable" [matTooltip]="'Delete'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="actionClicked(row, 'delete')">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <!-- Special Button -->
                                        <ng-container *ngIf="column.isSpecialAction">
                                            <ng-container *ngTemplateOutlet="specialButton; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="cursor: move"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowClicked(row)"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                        <div>
                            <img src="../../../assets/campaign_not_sent_at.svg" />
                            <h2>No {{ title }}</h2>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Draggable Rows -->
            <table mat-table #table cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="data" (cdkDropListDropped)="onListDrop($event)" [dataSource]="data" *ngIf="isRowDraggable && !isMobileView">
                <ng-container *ngFor="let column of columns; let i = index">
                    <ng-container [matColumnDef]="column.columnDef" [sticky]="column.sticky">
                        <th mat-header-cell *matHeaderCellDef class="text-{{ column.align ?? 'left' }}">
                            <p> 
                                {{ column.header }}
                                <span *ngIf="column.subHeader" class="sub-header">{{ column.subHeader }}</span>
                            </p>
                        </th>
                        <ng-container *ngIf="column.columnDef !== 'action'">
                            <td mat-cell *matCellDef="let row" class="text-{{ column.align ?? 'left' }}">
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <!-- Normal Text with pipes -->
                                    <span [ngSwitch]="column.pipe" *ngIf="!column.isChip && !column.isSpecialHTML && !column.isToggle && !column.isImage && !column.isDate && !column.isDateMedium && !column.isDraggable">
                                        <span *ngSwitchCase="'titlecase'">{{ row[column.cell] | titlecase }}</span>
                                        <span *ngSwitchCase="'number'">{{ row[column.cell] | number : '0.2-2' }}</span>
                                        <span *ngSwitchCase="'json'">{{ row[column.cell] | json }}</span>
                                        <span *ngSwitchDefault class="{{ column.class }}">{{ row[column.cell] }}</span>
                                    </span>

                                    <!-- Row Draggable -->
                                    <span *ngIf="column.isDraggable">
                                        <mat-icon cdkDragHandle style="cursor: move">drag_indicator</mat-icon>
                                    </span>

                                    <!-- Date -->
                                    <span *ngIf="column.isDate">{{ row[column.cell] | date : 'dd/MM/yyyy' }}</span>
                                    <span *ngIf="column.isDateMedium">{{ row[column.cell] | date : 'medium' }}</span>

                                    <!-- Image -->
                                    <img *ngIf="column.isImage" src="{{ row[column.cell] }}" alt="" width="50" height="50" />

                                    <!-- Chip -->
                                    <mat-chip [ngClass]="row[column.cell]" *ngIf="column.isChip">{{ row[column.cell] | titlecase }}</mat-chip>

                                    <!-- Special HTML -->
                                    <ng-container *ngIf="column.isSpecialHTML">
                                        <ng-template [ngIf]="specialHTML.hasOwnProperty(column.cell)" [ngIfElse]="onlyOneSpecialHTML">
                                            <ng-container *ngTemplateOutlet="specialHTML[column.cell]; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                        <ng-template  #onlyOneSpecialHTML>
                                            <ng-container *ngTemplateOutlet="specialHTML; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                    </ng-container>

                                    <!-- Toggle Switch -->
                                    <ng-container *ngIf="column.isToggle">
                                        <mat-slide-toggle 
                                        accessControl
                                        [moduleType]="featureAccessControl"
                                        color="primary" 
                                        (click)="$event.stopPropagation()" 
                                        (change)="actionClicked(row, 'status', $event)" 
                                        [checked]="deepGet(row, column.cell.split('.'))"></mat-slide-toggle>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="column.columnDef === 'action'">
                            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <div class="actions">
                                        <button accessControl
                                            [moduleType]="featureAccessControl" 
                                            mat-icon-button 
                                            *ngIf="column.isEditable" [
                                            matTooltip]="'Edit'" 
                                            matTooltipPosition="below" 
                                            matTooltipClass="thrive-tooltip" 
                                            (click)="actionClicked(row, 'edit')">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button 
                                            accessControl
                                            [moduleType]="featureAccessControl"
                                            mat-icon-button 
                                            color="warn" 
                                            *ngIf="column.isDeletable" 
                                            [matTooltip]="'Delete'" 
                                            matTooltipPosition="below" 
                                            matTooltipClass="thrive-tooltip" 
                                            (click)="actionClicked(row, 'delete')">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <!-- Special Button -->
                                        <ng-container *ngIf="column.isSpecialAction">
                                            <ng-container *ngTemplateOutlet="specialButton; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row cdkDragBoundary=".table-container" cdkDragLockAxis="y" cdkDrag [cdkDragData]="row" *matRowDef="let row; columns: displayedColumns" (click)="rowClicked(row)">
                    <div class="drag-placeholder" *cdkDragPlaceholder [attr.colspan]="displayedColumns.length"></div>
                </tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                        <div>
                            <img src="../../../assets/img/campaign_not_sent_at.svg" />
                            <h2>No {{ title }}</h2>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- Mobile Expanding View -->
            <table mat-table #table cdkDropList cdkDropListOrientation="vertical" [cdkDropListData]="data" (cdkDropListDropped)="onListDrop($event)" [dataSource]="data" multiTemplateDataRows class="mobile-view" *ngIf="isMobileView">
                <ng-container *ngFor="let column of columns; let i = index">
                    <ng-container [matColumnDef]="column.columnDef" [sticky]="column.sticky">
                        <th mat-header-cell *matHeaderCellDef class="text-{{ column.align ?? 'left' }}">
                            <p>
                                {{ column.header }}
                                <span *ngIf="column.subHeader" class="sub-header">{{ column.subHeader }}</span>
                            </p>
                        </th>
                        <ng-container *ngIf="column.columnDef !== 'action'">
                            <td mat-cell *matCellDef="let row" class="text-{{ column.align ?? 'left' }}">
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <!-- Normal Text with pipes -->
                                    <span [ngSwitch]="column.pipe" *ngIf="!column.isChip && !column.isSpecialHTML && !column.isToggle && !column.isImage && !column.isDate && !column.isDateMedium && !column.isDraggable">
                                        <span *ngSwitchCase="'titlecase'">{{ row[column.cell] | titlecase }}</span>
                                        <span *ngSwitchCase="'number'">{{ row[column.cell] | number : '0.2-2' }}</span>
                                        <span *ngSwitchCase="'json'">{{ row[column.cell] | json }}</span>
                                        <span *ngSwitchDefault class="{{ column.class }}">{{ row[column.cell] }}</span>
                                    </span>

                                    <!-- Row Draggable -->
                                    <span *ngIf="column.isDraggable">
                                        <mat-icon cdkDragHandle style="cursor: move">drag_indicator</mat-icon>
                                    </span>

                                    <!-- Date -->
                                    <span *ngIf="column.isDate">{{ row[column.cell] | date : 'dd/MM/yyyy' }}</span>
                                    <span *ngIf="column.isDateMedium">{{ row[column.cell] | date : 'medium' }}</span>

                                    <!-- Image -->
                                    <img *ngIf="column.isImage" src="{{ row[column.cell] }}" alt="" width="50" height="50" />

                                    <!-- Chip -->
                                    <mat-chip [ngClass]="row[column.cell]" *ngIf="column.isChip">{{ row[column.cell] | titlecase }}</mat-chip>

                                    <!-- Special HTML -->
                                    <ng-container *ngIf="column.isSpecialHTML">
                                        <ng-template [ngIf]="specialHTML.hasOwnProperty(column.cell)" [ngIfElse]="onlyOneSpecialHTML">
                                            <ng-container *ngTemplateOutlet="specialHTML[column.cell]; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                        <ng-template  #onlyOneSpecialHTML>
                                            <ng-container *ngTemplateOutlet="specialHTML; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-template>
                                    </ng-container>

                                    <!-- Toggle Switch -->
                                    <ng-container *ngIf="column.isToggle">
                                        <mat-slide-toggle 
                                            accessControl
                                            [moduleType]="featureAccessControl" 
                                            color="primary" 
                                            (click)="$event.stopPropagation()" 
                                            (change)="actionClicked(row, 'status', $event)" 
                                            [checked]="deepGet(row, column.cell.split('.'))">
                                        </mat-slide-toggle>
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="column.columnDef === 'action'">
                            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
                                <ng-container *ngIf="parseJSON(column, row)">
                                    <div class="actions">
                                        <button 
                                            accessControl
                                            [moduleType]="featureAccessControl" 
                                            mat-icon-button 
                                            *ngIf="column.isEditable" 
                                            [matTooltip]="'Edit'" 
                                            matTooltipPosition="below" 
                                            matTooltipClass="thrive-tooltip" 
                                            (click)="actionClicked(row, 'edit')">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button 
                                            accessControl
                                            [moduleType]="featureAccessControl" 
                                            mat-icon-button 
                                            color="warn" 
                                            *ngIf="column.isDeletable" 
                                            [matTooltip]="'Delete'" 
                                            matTooltipPosition="below" 
                                            matTooltipClass="thrive-tooltip" 
                                            (click)="actionClicked(row, 'delete')">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <!-- Special Button -->
                                        <ng-container *ngIf="column.isSpecialAction">
                                            <ng-container *ngTemplateOutlet="specialButton; context: {$implicit: {row: row}}"></ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="mobileTemplate" mat-icon-button aria-label="expand row" class="expand-btn" (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div class="row-detail" *ngIf="mobileTemplate" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <ng-container>
                                <ng-container *ngTemplateOutlet="mobileTemplate; context: {$implicit: {row: element}}"></ng-container>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand; sticky: true"></tr>
                <tr
                    mat-row
                    cdkDrag
                    [cdkDragDisabled]="!isRowDraggable"
                    [cdkDragData]="element"
                    *matRowDef="let element; columns: columnsToDisplayWithExpand"
                    class="table-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="rowClicked(element)"
                >
                    <div class="drag-placeholder" *cdkDragPlaceholder></div>
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                        <div>
                            <img src="../../../assets/campaign_not_sent_at.svg" />
                            <h2>No {{ title }}</h2>
                        </div>
                    </td>
                </tr>
            </table>
        </ng-container>
    </div>
    <mat-paginator
        *ngIf="resultsLength > 10"
        [pageIndex]="pageIndex"
        #paginator
        (page)="handlePageEvent($event)"
        [length]="resultsLength"
        [hidePageSize]="hidePageSize"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 25, 100]"
        showFirstLastButtons
    ></mat-paginator>
</div>
