import {AsyncPipe, CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {BrandCollaborationService} from '../brand-collaboration.service';
import Swal from 'sweetalert2';
import {ImageUploadComponent} from '../../shared/image-upload/image-upload.component';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ColorPickerModule} from 'ngx-color-picker';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
@Component({
    selector: 'app-brand-collaboration-edit',
    templateUrl: './brand-collaboration-edit.component.html',
    styleUrls: ['./brand-collaboration-edit.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        AsyncPipe,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ImageUploadComponent,
        MatSelectModule,
        MatAutocompleteModule,
        MatChipsModule,
        NgxMatSelectSearchModule,
        ColorPickerModule,
        AccessControlDirective
    ]
})
export class BrandCollaborationEditComponent implements OnInit {
    brandForm: FormGroup;
    editId: string;
    allMerchants: Array<any>;
    filterOptions: object = [{}];
    searchMerchantControl: FormControl = new FormControl('');
    colorHeader: string;
    colorFooter: string;
    colorPickerId: string;

    constructor(private brandCollaborationService: BrandCollaborationService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.editId = this.route.snapshot.params.id;
        this.createForm();
        this.showMerchants();
        this.initialise();
    }

    /**
     * To initialise the search value and check the length of the value
     */
    initialise() {
        this.searchMerchantControl.valueChanges.subscribe((value: string) => {
            value = value.toLowerCase();
            if (value?.length > 0) {
                this._filter(value);
            } else {
                this.filterOptions = this.allMerchants;
            }
        });
    }

    /**
     * To filter the search value from Array of objects
     * @param value To compare and check if its included in array of objects
     * @returns Values that are included in the array
     */
    private _filter(value: any) {
        this.filterOptions = this.allMerchants.filter((merchant: any) => JSON.stringify(merchant.id).includes(value) || merchant.name.toLowerCase().includes(value));

        return;
    }

    /**
     * to create a form to edit data of a collaboration or create new
     * @param merchants to check if collaboration is already present or new collaboration needs to be added
     */
    createForm(brandCollab?) {
        this.brandForm = this.fb.group<any>({
            name: new FormControl('', Validators.required),
            listing_slug: new FormControl('', Validators.required),
            banner_url: new FormControl('', Validators.required),
            heading: new FormControl('', Validators.required),
            sub_heading: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            merchant_ids: new FormControl('', Validators.required),
            heading_color: new FormControl('#E66158'),
            footer_color: new FormControl('#E66158')
        });
        this.colorHeader = '#E66158';
        this.colorFooter = '#E66158';

        //in case of editing a user
        if (this.editId !== 'new') {
            this.brandCollaborationService.getBrandCollabById(this.editId).subscribe((brandCollab: any) => {
                this.brandForm.patchValue({...brandCollab});
                this.colorHeader = brandCollab.heading_color;
                this.colorFooter = brandCollab.footer_color;
            });
        }
    }

    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if (this.editId !== 'new') {
            this.brandCollaborationService.updateBrandCollab(this.editId, this.brandForm.value).subscribe(() => {
                Swal.fire('Updated!', 'Collaboration has been successfully updated!', 'success');
                this.router.navigateByUrl('/brand-collaboration');
            });
        } else {
            this.brandCollaborationService.createBrandCollab(this.brandForm.value).subscribe(() => {
                Swal.fire('Created!', 'Collaboration has been successfully created!', 'success');
                this.router.navigateByUrl('/brand-collaboration');
            });
        }
    }

    /**
     * To set banner image as uploaded image url
     * @param image To get the image url of the uploaded image
     * @param form To get value of form and change image of background to image
     */
    onImageUpload(image: any, form: any) {
        if (form === 'background') this.brandForm.get('banner_url').setValue(image.base64);
    }

    /**
     * get API call to fetch all merchants
     */
    showMerchants() {
        this.brandCollaborationService.getMerchants().subscribe((allMerchants: any) => {
            this.allMerchants = allMerchants;
            this.filterOptions = allMerchants;
        });
    }
}
