import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tags',
  standalone: true
})
export class TagPipe implements PipeTransform {

  transform(mentionData: any): string[] | string {
    let tags: string[] = [];
    if (mentionData?.Tag) {
      Object.values(mentionData.Tag).forEach((tag: any) => {
        tags.push(tag.name + '(' + tag.tag_type + '' + (tag.tag_category_name ? ',' + tag.tag_category_name : '') + ')');
      });
      return tags;
    } else {
      return '-';
    }
  }

}
