import { Component,EventEmitter,Input,OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFilterPipe } from '../../../shared/pipes/data-filter.pipe';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule,FormControl} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatDialog ,MatDialogModule} from '@angular/material/dialog';
import { CreateRestaurantComponent } from '../outlets/create-restaurant/create-restaurant.component';
import Swal from 'sweetalert2';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { RestaurantsService } from '../../restaurants.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {cuisineIf, outletIf} from '../reataurant';
@Component({
  selector: 'app-outlets',
  standalone: true,
  imports: [CommonModule,
            MatFormFieldModule,
            MatInputModule,
            DataFilterPipe,
            MatCheckboxModule,
            CreateRestaurantComponent,
            MatDialogModule,
            ReactiveFormsModule,
            FormsModule,
            MatSnackBarModule],
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.scss']
})
export class OutletsComponent implements OnInit{
  @Input() cuisines:Array<cuisineIf>;
  @Input() outlets:Array<outletIf>;
  @Output() selectOutlet = new EventEmitter();
  search = new FormControl();
  merchantList:Array<outletIf>;
  selectedMerchant:Array<outletIf>;
  selectAll:boolean = false;
  pageNumber:number = 0;
  hasNextPage:boolean = false;
  constructor( public dialog: MatDialog,private restaurantsService: RestaurantsService, private snackbar: MatSnackBar){}

  ngOnInit(): void {
    this.outlets.forEach(merchant => merchant.checked = true);
    this.selectedMerchant = [...this.outlets];
    this.merchantList =[...this.selectedMerchant];
    this.search.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe((keyword:string) => {
      if(keyword && keyword.trim().length>1){
          this.getMerchantList(keyword,false);
      }
    }); 
  }

  getMerchantList(searchText:string,addMore:boolean){
    let filterObj={ query:searchText, exclude_all_outlets:true }
    this.restaurantsService.getRestaurantsList(this.pageNumber,25,filterObj).subscribe((response:any)=>{
        this.selectAll = false;
        this.hasNextPage = response.has_next_page;
        if(!addMore){
          this.merchantList = [];
          let restaurantList:any = response.restaurants.filter(m=>m['id']=m.restaurant_id);
          let restaurants:any =  restaurantList;
          let newRestaurant:any = [...this.selectedMerchant];
          restaurants.forEach(m=>{
              if(this.selectedMerchant.findIndex(mb=>mb.id === m.id) < 0)
              newRestaurant.push(m)
          });
          this.merchantList = [...newRestaurant];
        } else {
          let allData = [] = [...this.merchantList,...response.restaurants];
          this.merchantList = [...allData];
          this.snackbar.open('Restaurants added to the list.', '', { verticalPosition: 'bottom', duration: 5000, panelClass: 'snackbar__notification' });
        }
    });
  }

  createOutlet(){
    const dialogRef = this.dialog.open(CreateRestaurantComponent, {
      width:'50vw',
      panelClass: ['thrive-dialog', 'sm-full_width_dailog'],
      data:{cuisines:this.cuisines}
    });
    dialogRef.afterClosed().subscribe((result: any) => {
        if(result){
          this.merchantList.push(result);
          Swal.fire('Created!', 'Restaurant created!', 'success');
        }
    })
  }

  selectAllFn(event){
    if(event.checked){
      this.merchantList.forEach(merchant => merchant.checked = true);
      this.selectedMerchant = [...this.merchantList];
    } else {
      this.merchantList.forEach(merchant => merchant.checked = false);
      this.selectedMerchant=[];
    }
    this.selectOutlet.emit(this.selectedMerchant);
  }

  selectSingleOutlet(outlets:outletIf){
    if(this.selectedMerchant.findIndex(mb=>mb.id === outlets.id) < 0){
      this.selectedMerchant.push(outlets);
    } else {
      this.selectedMerchant.splice(this.selectedMerchant.findIndex(a => a.id === outlets.id) , 1);
      if(!this.selectedMerchant.length){
        this.selectAll = false;
      }
    }
    this.selectOutlet.emit(this.selectedMerchant);
  }

  showMore(){
    if(this.hasNextPage){
      this.pageNumber++;
      this.getMerchantList(this.search.value,true);
    }
  }

}
