<div id="tags" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections">
            <h2>Tags</h2>
            <button class="btn-thrive_icon" mat-icon-button (click)="openFilters()">
                <mat-icon>filter_list</mat-icon>
            </button>
            <button class="btn-thrive_primary"
                    accessControl
                    moduleType="moderation_tool"
                    mat-button
                    routerLink="/tags/new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>

        </div>
        <div class="chip-container">
            <mat-chip-listbox>
                <mat-chip *ngIf="filters.show_deleted">
                    Status: {{ filters.show_deleted | titlecase }}
                    <button matChipRemove (click)="removeFilter('show_deleted')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.tag_type">
                    Type:<span *ngFor="let type of filters.tag_type">{{ type.key }} , </span>
                    <button matChipRemove (click)="removeFilter('tag_type')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.name">
                    Name: {{ filters.name | titlecase }}
                    <button matChipRemove (click)="removeFilter('name')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
                <mat-chip *ngIf="filters.tag_category_id">
                    Category: <span *ngFor="let categories of filters.tag_category_id">{{ categories.name }} , </span>
                    <button matChipRemove (click)="removeFilter('tag_category_id')"><mat-icon>cancel</mat-icon></button>
                </mat-chip>
            </mat-chip-listbox>
            <button mat-icon-button class="btn-thrive_icon delete-btn" (click)="clearAll()" matTooltip="Clear All Filter" matTooltipClass="thrive-tooltip" *ngIf="showClearFilter()">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <tn-table
                  [title]="'Tags'"
                  [data]="data"
                  [columns]="columns"
                  [desktopDisplayedColumns]="desktopDisplayedColumns"
                  [mobileDisplayedColumns]="mobileDisplayedColumns"
                  [resultsLength]="resultsLength"
                  [pageSize]="pageSize"
                  [hidePageSize]="true"
                  [mobileTemplate]="mobileTemplate"
                  [pageIndex]="pageIndex"
                  (changePage)="changePage($event)"
                  (rowClick)="navigateTo($event)"
                  [featureAccessControl]="'moderation_tool'">
        </tn-table>
    </div>
</div>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Type</td>
            <td>{{ option.row.tag_type ? option.row.tag_type : '-' }}</td>
        </tr>
        <tr>
            <td>Category</td>
            <td>{{ option.row.tag_category_name ? option.row.tag_category_name : '-' }}</td>
        </tr>
    </table>
</ng-template>