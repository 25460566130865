<input #itemImageUpload [accept]="accept" style="display: none" name="item_img_upload" type="file" (change)="onImageUpload($event)" />
<div class="container-img w-100">
    <div (click)="itemImageUpload.click()" class="property-container mt-30" *ngIf="!imageUrl">
        <img src="assets/image-uploader.svg" alt="upload image" />
        <div class="subtitle mt-10">{{pdf?'Upload PDF':'Select the image to be uploaded'}}</div>
        <button class="btn-thrive_primary mt-10">Browse</button>
        <div class="property-row mt-30">
            <div class="property-column" *ngIf="!pdf">
                <span class="property-name">Aspect Ratio</span>
                <span class="property-value subbase">{{ aspectRatio }}</span>
            </div>
            <div class="property-column">
                <span class="property-name">File Type</span>
                <span class="property-value subbase capital">{{accept}}</span>
            </div>
            <div class="property-column">
                <span class="property-name">Max size</span>
                <span class="property-value subbase">{{fileSize ? fileSize : '5MB'}}</span>
            </div>
        </div>
    </div>
    <div class="img--4-3 position-relative" *ngIf="imageUrl && !pdf">
        <img [src]="imageUrl" />
        <div class="icon_button">
            <button (click)="itemImageUpload.click()" mat-icon-button class="btn-thrive_icon">
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="removeImage()" mat-icon-button class="btn-thrive_icon delete-btn">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </div>
    </div>
    <div class="img--4-3 position-relative" *ngIf="imageUrl && pdf">
        <iframe [src]="imageUrl | safe: 'resourceUrl'" 
        style="width: 100%;height: 100%" 
        type="application/pdf"></iframe>
    </div>
</div>
