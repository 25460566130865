import {CommonModule} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ImageEditorComponent, ImageEditorParams} from '../image-editor/image-editor.component';
import Swal from 'sweetalert2';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { SafePipe } from '../pipes/safe.pipe';
@Component({
    selector: 'image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatDialogModule,SafePipe]
})
export class ImageUploadComponent {
    @Input() pdf:boolean = false;
    @Input() fileSize:string;
    @Input() aspectRatio: string;
    @Input() imageUrl: any;
    @Input() accept:string = '.jpg, .jpeg, .png';
    @Output() uploadImg = new EventEmitter();
    @ViewChild('itemImageUpload', {static: false}) itemImageUpload: ElementRef;
    fileName:string=null;
    constructor(public dialog: MatDialog) {}

    removeImage() {
        this.itemImageUpload.nativeElement.value = '';
        this.imageUrl = '';
        this.uploadImg.emit('');
    }

    onImageUpload(event: any) {
        if (event.target.files[0].size <= 5242880) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = (e: any) => {
                if(!this.pdf){
                    this.fileName=event.target.files[0].name;
                    this.openCropImagePopup(reader.result);
                } else{
                    this.imageUrl = reader.result;
                    this.uploadImg.emit(reader.result);
                    Swal.fire('File added successfully', 'success');  
                }
            };
        } else {
            Swal.fire('Error', 'Maximum size allowed is 5 MB.', 'error');
            this.itemImageUpload.nativeElement.value = '';
        }
    }

    openCropImagePopup(image: any) {
        if (image) {
            const dialogRef = this.dialog.open(ImageEditorComponent, {
                width: '744px',
                data: {
                    image: image,
                    aspectRatio: this.aspectRatio === '4:3' ? 4 / 3 : 1 / 1,
                    message: 'The area selected in the upper container needs to be ' + this.aspectRatio + ' aspect ratio as it will appear in the same way in the menu.'
                } as ImageEditorParams,
                disableClose: true,
                panelClass: 'thrive-dialog'
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                if (result) {
                    this.imageUrl = result.base64;
                    this.uploadImg.emit({base64:result.base64,fileName:this.fileName});
                    Swal.fire('Success', result.type === 'apply' ? 'Image added successfully' : 'Image deleted successfully', 'success');
                } else {
                    this.itemImageUpload.nativeElement.value = '';
                    this.imageUrl = '';
                    this.uploadImg.emit('');
                }
            });
        }
    }
}
