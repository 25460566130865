import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RadiusService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getRadius(page: number, status: any) {
        let params = new HttpParams();
        params = params.append('page_no', page);
        if (status !== 'all') params = params.append('active', status);
        return this.http.get(this.url.adminUrl + '/delivery_radius_limits', {params});
    }

    getRadiusById(id: string) {
        return this.http.get(this.url.adminUrl + '/delivery_radius_limits/' + id);
    }

    getCities() {
        return this.http.get(this.url.adminUrl + '/cities');
    }

    getPincodes() {
        return this.http.get(this.url.adminUrl + '/pincodes');
    }

    createRadius(body) {
        return this.http.post(this.url.adminUrl + '/delivery_radius_limits', body);
    }

    updateRadius(body, id) {
        return this.http.put(this.url.adminUrl + '/delivery_radius_limits/' + id, body);
    }
}
