<div id="cuisineManagementEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/cuisine'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>{{ editId != 'new' ? 'Update' : 'Create' }} cuisine</h2>
        </div>
    </div>
    <form [formGroup]="cuisineForm">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>cuisine name</mat-label>
            <input matInput placeholder="Enter cuisine name" formControlName="name" />
            <mat-error *ngIf="cuisineForm.get('name').hasError('required') && cuisineForm.get('name').touched">Required</mat-error>
        </mat-form-field>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" 
                mat-button 
                (click)="submitForm()" 
                [disabled]="!cuisineForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
