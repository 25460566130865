<div id="sourceManagementEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/source-management'">
                <mat-icon class="arrowback">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>{{ editId != 'new' ? 'Update' : 'Create' }} Source</h2>
        </div>
    </div>
    <form [formGroup]="sourceForm">
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Sort order</mat-label>
            <input type="number" matInput placeholder="Enter sort order" formControlName="sort_order" />
            <mat-error *ngIf="sourceForm.get('sort_order').hasError('required') && sourceForm.get('sort_order').touched">Required</mat-error>
            <mat-error *ngIf="sourceForm.get('sort_order').hasError('min')">Enter correct value</mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
            <mat-label>Source name</mat-label>
            <input matInput placeholder="Enter source name" formControlName="name" />
            <mat-error *ngIf="sourceForm.get('name').hasError('required') && sourceForm.get('name').touched">Required</mat-error>
        </mat-form-field>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" 
                mat-button 
                accessControl
                moduleType="source_management" 
                (click)="submitForm()" 
                [disabled]="!sourceForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
