<div class="dialog-header">
    <span class="basetitle">Add new outlet</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClose();">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <form [formGroup]="restaurantForm">
        <div class="form-controls">
            <!-- Place id -->
            <mat-form-field id="googlePlaceId" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                <mat-label>Place Id</mat-label>
                <input matInput placeholder="Enter place id" formControlName="google_place_id" />
                <mat-error *ngIf="restaurantForm.get('google_place_id').hasError('required') && restaurantForm.get('google_place_id').touched">Required</mat-error>
            </mat-form-field>
            <!-- Name -->
            <mat-form-field id="name" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter restaurant name" formControlName="name" />
                <mat-error *ngIf="restaurantForm.get('name').hasError('required') && restaurantForm.get('name').touched">Required*</mat-error>
            </mat-form-field>
            <!-- Latitude -->
            <mat-form-field id="lat" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                <mat-label>Latitude</mat-label>
                <input matInput placeholder="Enter latitude" formControlName="lat" />
                <mat-error *ngIf="restaurantForm.get('lat').hasError('required') && restaurantForm.get('lat').touched">Required</mat-error>
                <mat-error *ngIf="restaurantForm.get('lat').hasError('pattern')">Enter correct latitude</mat-error>
            </mat-form-field>
            <!-- Longitude -->
            <mat-form-field id="lon" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                <mat-label>Longitude</mat-label>
                <input matInput placeholder="Enter longitude" formControlName="lon" />
                <mat-error *ngIf="restaurantForm.get('lon').hasError('required') && restaurantForm.get('lon').touched">Required</mat-error>
                <mat-error *ngIf="restaurantForm.get('lon').hasError('pattern')">Enter correct longitude</mat-error>
            </mat-form-field>
            <!-- Locality -->
            <mat-form-field id="locality" subscriptSizing="dynamic" floatLabel="always" appearance="outline">
                <mat-label>Locality</mat-label>
                <input matInput placeholder="Enter locality" formControlName="locality" />
                <mat-error *ngIf="restaurantForm.get('locality').hasError('required') && restaurantForm.get('locality').touched">Required</mat-error>
                <mat-error *ngIf="restaurantForm.get('locality').hasError('pattern')">Enter correct locality</mat-error>
            </mat-form-field>
            <!-- Address -->
            <mat-form-field id="fullAddress" subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="grid-item">
                <mat-label>Address</mat-label>
                <input matInput placeholder="Enter address" formControlName="full_address" />
                <mat-error *ngIf="restaurantForm.get('full_address').hasError('required') && restaurantForm.get('full_address').touched">Required</mat-error>
                <mat-error *ngIf="restaurantForm.get('full_address').hasError('pattern')">Enter correct address</mat-error>
            </mat-form-field>
            <!-- Cuisines -->
            <mat-form-field class="w-100" id="cuisines" subscriptSizing="dynamic" appearance="outline" floatLabel="never">
                <mat-label>Cuisines</mat-label>
                <mat-select formControlName="cuisines" multiple>
                    <mat-option> 
                        <ngx-mat-select-search 
                        placeholderLabel="Search" 
                        noEntriesFoundLabel="Cuisine not found" 
                        ngDefaultControl 
                        [formControl]="searchCuisine">
                    </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let type of cuisines | datafilter : searchCuisine.value :['name']" [value]="type.name">{{ type.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="restaurantForm.get('restaurantForm.cuisines')?.invalid">Required</mat-error>
            </mat-form-field>
            <!-- Cost for 2 -->
            <mat-form-field id="costForTwo" subscriptSizing="dynamic" appearance="outline" floatLabel="never">
                <mat-label>Average cost for 2</mat-label>
                <input matInput placeholder="Enter average cost for 2" formControlName="cost_for_two" />
                <mat-error *ngIf="restaurantForm.get('cost_for_two')?.errors?.pattern">Digits only</mat-error>
            </mat-form-field>
            <!-- Known for start -->
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="grid-item">
                <mat-label>Known for</mat-label>
                <mat-chip-grid #knownFor aria-label="known for tag selection">
                    <mat-chip-row *ngFor="let knownForTag of selectedKnownForTags" (removed)="removeKnownForTag(knownForTag)">
                        {{ knownForTag }}
                        <button matChipRemove [attr.aria-label]="removeknownForTag">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <input placeholder="Search tags start typing..." 
                    #known 
                    [formControl]="searchForKnownForTag" 
                    [matChipInputFor]="knownFor" 
                    [matAutocomplete]="autoKnow" 
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                    (matChipInputTokenEnd)="addknownForTag($event)" />
                <mat-autocomplete #autoKnow="matAutocomplete" 
                    (optionSelected)="selectKnownForTag($event)">
                    <mat-option *ngFor="let tag of knownForTags" [value]="tag">
                        {{ tag }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
             <!-- Ad hoc tags -->
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="grid-item">
                <mat-label>Ad hoc tags</mat-label>
                <mat-chip-grid #chipGrid aria-label="adhoc tag selection">
                    <mat-chip-row *ngFor="let adhocTag of selectedAdhocTags" (removed)="removeAdhocTag(adhocTag)">
                        {{ adhocTag }}
                        <button matChipRemove [attr.aria-label]="'remove ' + adhocTag">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                </mat-chip-grid>
                <input placeholder="Search tags start typing..." 
                    #adhoc 
                    [formControl]="searchForAdhocTag" 
                    [matChipInputFor]="chipGrid" 
                    [matAutocomplete]="auto" 
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                    (matChipInputTokenEnd)="addAdhocTag($event)" />
                <mat-autocomplete #auto="matAutocomplete" 
                    (optionSelected)="selectAdhocTag($event)">
                    <mat-option *ngFor="let addTag of adhocTags" [value]="addTag">
                        {{ addTag }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </form>
</div>
<div class="dialog-footer">
    <button (click)="onClose();" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="submit()" [disabled]="submitted" class="btn-thrive_primary ml-20" mat-button >Create new outlet</button>
</div>
