<div class="dialog-header">
    <span>Image details</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClose(false);">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <form [formGroup]="mediaForm">
    <div class="image-box mb-16">
        <img *ngIf="data.user_restaurant?.attribute_name === 'photos'" [src]="data.user_restaurant?.attribute_value[0]">
        <video width="100%" height="100%" controls [poster]="data.user_restaurant?.attribute_value?.thumbnail_url" *ngIf="data.user_restaurant?.attribute_name === 'video'" [src]="data.user_restaurant?.attribute_value?.video_url"></video>
    </div>

    <div class="basetitle normal mb-8">Uploader</div>
    <h3 class="mb-16">{{data.user_details?.name}} ({{data.user_details?.profile?.username}})</h3>

        <div class="basetitle normal mb-8">Uploaded on</div>
        <h3 class="mb-16">{{data.user_restaurant.created_at | date : "dd-MM-YYYY hh:mm:ss" }}</h3>

        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Choose Status</mat-label>
            <mat-select formControlName="status">
                <mat-option value="approved">Approved</mat-option>
                <mat-option value="rejected">Rejected</mat-option>
            </mat-select>
        </mat-form-field>

    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-16 mb-30">
        <mat-label>Tags</mat-label>
        <mat-chip-grid #chipGrid aria-label="image tag selection">
            <mat-chip-row *ngFor="let tag of selectedTags" 
            (removed)="removeTag(tag)">
                {{ tag }}
                <button matChipRemove [attr.aria-label]="'removeTag'">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="Start typing..." 
            #media 
            [formControl]="searchTags"  
            [matChipInputFor]="chipGrid" 
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
            [matAutocomplete]="tagsId" 
            (matChipInputTokenEnd)="addTag($event)" />
        <mat-autocomplete #tagsId="matAutocomplete" 
            (optionSelected)="selectTag($event)">
            <mat-option *ngFor="let tag of tags" [value]="tag">
                {{ tag }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
</div>
<div class="dialog-footer">
    <button (click)="onClose(false);" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="submit()" [disabled]="formSubmitted" class="btn-thrive_primary ml-20" mat-button >Save Changes</button>
</div>
