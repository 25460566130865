import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private url = environment;

  constructor(public http: HttpClient) {}
  
  getUsers(params){
    return this.http.get(this.url.adminUrl + '/feed/user_profiles/', {params});
  }
  updatePermission(id:number,body:{status:string,verification_type:string}){
    return this.http.put(this.url.adminUrl + '/feed/user_profiles/'+id, body);
  }
}
