<div id="moderation" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Comments</h2>
        </div>
        <div class="mt-20 filter-container">
            <div class="filters">
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                    <mat-label>Choose filter option</mat-label>
                    <mat-select [(value)]="filter_category" (selectionChange)="filter_value = ''">
                        <mat-option value="id">Comment ID</mat-option>
                        <mat-option value="feed_post_id">Post ID</mat-option>
                        <mat-option value="search_query">Content</mat-option>
                        <mat-option value="status">Status</mat-option>
                        <mat-option value="user_id">User ID</mat-option>
                        <mat-option value="post_type">Post type</mat-option>
                        <mat-option value="created_date">Created date</mat-option>
                        <mat-option value="updated_date">Last updated date</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'created_date'">
                    <mat-label>Created date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="filter_value" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filter_category == 'updated_date'">
                    <mat-label>Last updated date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="filter_value" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'status'">
                    <mat-label>Choose status</mat-label>
                    <mat-select [(value)]="filter_value">
                        <mat-option value="approved">Approved</mat-option>
                        <mat-option value="rejected">Rejected</mat-option>
                        <mat-option value="to_review">To review</mat-option>
                        <mat-option value="deleted">Deleted</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'post_type'">
                    <mat-label>Choose post type</mat-label>
                    <mat-select [(value)]="filter_value">
                        <mat-option value="question">Question</mat-option>
                        <mat-option value="simple_post">Thread(Post)</mat-option>
                        <mat-option value="list">List</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'user_id'">
                    <mat-label>Enter user id</mat-label>
                    <input matInput placeholder="Enter user id" [(ngModel)]="filter_value" />
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'id' || filter_category == 'feed_post_id'">
                    <mat-label>Enter {{filter_category == 'id' ? 'comment id' : 'post id'}}</mat-label>
                    <input matInput placeholder="Enter {{filter_category == 'id' ? 'comment id' : 'post id'}}" [(ngModel)]="filter_value" />
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filter_category == 'search_query'">
                    <mat-label>Enter content</mat-label>
                    <input matInput placeholder="Enter content" [(ngModel)]="filter_value" />
                </mat-form-field>
            </div>

            <div class="filters">
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                    <mat-label>Choose sort option</mat-label>
                    <mat-select [(value)]="sort_by" (selectionChange)="sort_type = ''">
                        <mat-option value="post_type">Post type</mat-option>
                        <mat-option value="status">Status</mat-option>
                        <mat-option value="created_at">Created date</mat-option>
                        <mat-option value="recently_updated">Recently pdated</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                    <mat-label>Choose order</mat-label>
                    <mat-select [(value)]="sort_type" [disabled]="!sort_by">
                        <mat-option value="asc">Ascending</mat-option>
                        <mat-option value="desc">Descending</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button class="btn-thrive_primary" mat-button (click)="applyFilter()">Apply</button>
        </div>
    </div>
    <div style="overflow-x: auto">
        <tn-table
                  [title]="'Comments'"
                  [data]="data"
                  [columns]="columns"
                  [desktopDisplayedColumns]="desktopDisplayedColumns"
                  [mobileDisplayedColumns]="mobileDisplayedColumns"
                  [resultsLength]="resultsLength"
                  [pageSize]="pageSize"
                  [hidePageSize]="true"
                  [specialHTML]="{'body':comment,'page':openFeed}"
                  [specialButton]="approveButton"
                  [mobileTemplate]="mobileTemplate"
                  [pageIndex]="pageIndex"
                  (changePage)="changePage($event)"
                  (rowClick)="updateComment($event)">
        </tn-table>
    </div>
</div>

<ng-template #comment let-option>
    <span [innerHTML]="((option.row['showMore']) ? option.row.body : (option.row.body | slice:0:30)) | mentionReplace:(option.row?.mentions_data?.['Restaurant'] || {})"></span>
    <span *ngIf="!option.row['showMore'] && option.row.body.length > 30">...</span> 
    <span *ngIf="!option.row['showMore'] && option.row.body.length > 30">see more</span>
</ng-template>

<ng-template #openFeed let-option>
    <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openFeedFn(option.row); $event.stopPropagation()">
        <mat-icon class="mr-0">open_in_new</mat-icon>
    </button>
</ng-template>

<ng-template #approveButton let-option>
    <button *ngIf="option.row.status !== 'approved'" class="btn-thrive_icon mr-10" mat-icon-button  (click)="changeStatus(option.row,'approved');$event.stopPropagation()" [matTooltip]="'Approve'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon >check</mat-icon>
    </button>
    <button  *ngIf="option.row.status !== 'rejected'" class="btn-thrive_icon" mat-icon-button (click)="changeStatus(option.row,'rejected');$event.stopPropagation()" [matTooltip]="'Reject'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon>close</mat-icon>
    </button>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Post type</td>
            <td>{{ option.row.post_type }}</td>
        </tr>
        <tr>
            <td>Last updated</td>
            <td>{{ option.row.updated_at }}</td>
        </tr>
        <tr>
            <td>Open feed</td>
            <td>
                <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="openFeed(option.row); $event.stopPropagation()">
                    <mat-icon class="mr-0">open_in_new</mat-icon>
                </button>
            </td>
        </tr>
        <tr>
            <td>Change status</td>
            <td>
                <button *ngIf="option.row.status !== 'approved'" class="btn-thrive_icon mr-10" mat-icon-button  (click)="changeStatus(option.row,'approved');$event.stopPropagation()" [matTooltip]="'Approve'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon >check</mat-icon>
                </button>
                <button  *ngIf="option.row.status !== 'rejected'" class="btn-thrive_icon" mat-icon-button (click)="changeStatus(option.row,'rejected');$event.stopPropagation()" [matTooltip]="'Reject'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon>close</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</ng-template>
