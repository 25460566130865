import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {AdminuserService} from '../adminuser.service';
import Swal from 'sweetalert2';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import { RbacService } from '../../shared/rbac/rbac.service';
@Component({
    selector: 'app-admin-form-edit',
    templateUrl: './admin-form-edit.component.html',
    styleUrls: ['./admin-form-edit.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatTooltipModule, MatSelectModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,MatSlideToggleModule,AccessControlDirective],
    providers: [AdminuserService]
})
export class AdminFormEditComponent implements OnInit {
    adminForm: FormGroup;
    selectedIndex: any;
    editId: string;
    adminInfo:any;
    formControl = new FormControl('');
    constructor(private adminuserService: AdminuserService, 
        private fb: FormBuilder, 
        private rbacService:RbacService,
        private router: Router, 
        private route: ActivatedRoute) {}

    ngOnInit() {
       
        this.editId = this.route.snapshot.params.id;
        this.createForm();
        if (this.editId !== 'new') {
            this.adminForm.controls['password_digest'].disable();
            // if(this.user_info.role !== 'admin')
            // this.adminForm.controls['role'].disable();
        }
    }

    /**
     * to create a form to edit data of a user or create a new user
     * @param adminuser  to check if the adminuser data is already present or new data need to be added
    */
    createForm(adminuser?) {
        this.adminForm = this.fb.group<any>({
            name: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')]),
            email: new FormControl('', [Validators.email, Validators.pattern('^[a-zA-Z0-9]+@(?:hashtagloyalty+\.)+com+$')]),
            mobile: new FormControl('', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
            password_digest: new FormControl('', Validators.required),
            role: new FormControl('', Validators.required),
            team: new FormControl('',Validators.required)
        });
        
        //in case of editing a user
        if (this.editId !== 'new') {
            this.adminuserService.getAdminUserById(this.editId).subscribe((adminuser: any) => {
                this.adminInfo = adminuser;
                this.adminForm.patchValue({...adminuser, password_digest: adminuser.password});
            });
        }
    }

    /**
     * to submit the data of the form and show pop up messages accordingly
     */
    submitForm() {
        if (this.editId !== 'new') {
            this.adminuserService.updateUser(this.editId, this.adminForm.value).subscribe(
                () => {
                    this.getAccessFeatures();
                    Swal.fire('Updated!', 'User has been updated!', 'success');
                    setTimeout(() => {
                        this.router.navigateByUrl('/admin-user');
                        if(this.adminInfo.authentication_token === localStorage.getItem('token'))
                        location.reload();
                    }, 1000); 
                },
                (error) => {
                    Swal.fire('Error!',error.error.error, 'error');
                }
            );
        } else {
            this.adminuserService.createAdminUser(this.adminForm.value).subscribe(
                () => {
                    Swal.fire('Created!', 'User has been successfully created!', 'success');
                    this.router.navigateByUrl('/admin-user');
                },
                (error) => {
                    Swal.fire('Error!', error.error.error, 'error');
                    
                }
            );
        }
    }

    /**
     * to clear previous value so that new values can be added
     */
    editPassword() {
        this.adminForm.get('password_digest').setValue('');
        this.adminForm.controls['password_digest'].enable();
    }

    getAccessFeatures(){
        this.adminuserService.getAccessControlData().subscribe((res:any)=>{
            this.rbacService.setDataInStore('access_control',res.access_control);
        });
    }
}
