import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {checkForOverLap, checkForStartTimeLessThanEndTime, end_time_slots, start_time_slots} from './advance-open-hours';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
    selector: 'advance-open-hours',
    templateUrl: './advance-open-hours.component.html',
    styleUrls: ['./advance-open-hours.component.scss'],
    standalone: true,
    imports: [CommonModule, MatTooltipModule, MatCheckboxModule, MatSelectModule, MatButtonModule, MatChipsModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, MatIconModule]
})
export class AdvanceOpenHoursComponent implements OnInit, OnChanges {
    @Input() default_time_slots: any;
    @Output() submit = new EventEmitter<any>();
    constructor(private fb: UntypedFormBuilder) {}
    days: any = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    start_time_slots: any = start_time_slots;
    end_time_slots: any = end_time_slots;
    openHoursForm: UntypedFormGroup;
    // getter for open hours
    get openHoursFormArr(): UntypedFormArray {
        return this.openHoursForm.get('open_hours') as UntypedFormArray;
    }
    // add days to open hour form
    addAWorkingDay(day: any) {
        this.openHoursFormArr.push(
            this.fb.group({
                day: [day.day],
                is_open: [!day.closed],
                open_slot: this.fb.array(
                    [
                        this.fb.group(
                            {
                                start: [day.open_slot[0] && day.open_slot[0].start ? day.open_slot[0].start : '', [Validators.required]],
                                end: [day.open_slot[0] && day.open_slot[0].end ? day.open_slot[0].end : '', [Validators.required]]
                            },
                            {validator: checkForStartTimeLessThanEndTime}
                        )
                    ],
                    checkForOverLap()
                )
            })
        );
        if (day.open_slot.length > 1) {
            let idx: number = this.openHoursFormArr.value.length - 1;
            for (let i: number = 1; i < day.open_slot.length; i++) {
                this.addMoreSlotsInADay(this.openHoursFormArr.controls[idx].get('open_slot'), day.open_slot[i]);
            }
        }
    }
    //more time slots in a single day
    addMoreSlotsInADay(control: any, value: any) {
        control.push(
            this.fb.group(
                {
                    start: [value.start, [Validators.required]],
                    end: [value.end, [Validators.required]]
                },
                {validator: checkForStartTimeLessThanEndTime}
            )
        );
    }
    //remove time slot from a day
    removeTimeSlotFromADay(control: any, index: number) {
        control.removeAt(index);
    }
    toggleOpenDays(event: any, day: any) {
        for (let i: number = 0; i < day.get('open_slot').controls.length; i++) {
            if (event.checked) {
                day.get('open_slot').controls[i].get('start').setValidators([Validators.required]);
                day.get('open_slot').controls[i].get('end').setValidators([Validators.required]);
            } else {
                day.get('open_slot').controls[i].get('start').clearValidators();
                day.get('open_slot').controls[i].get('end').clearValidators();
            }
            day.get('open_slot').controls[i].get('start').updateValueAndValidity();
            day.get('open_slot').controls[i].get('end').updateValueAndValidity();
        }
    }
    initialise() {
        for (let i: number = 0; i < this.default_time_slots.length; i++) {
            this.addAWorkingDay(this.default_time_slots[i]);
        }
    }
    ngOnInit() {}
    ngOnChanges() {
        this.openHoursForm = this.fb.group({
            open_hours: this.fb.array([])
        });
        this.initialise();
    }
    submitForm() {
        this.submit.next(this.openHoursForm);
    }
}
