<div id="Banners" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections">
            <h2>App Banners</h2>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Status</mat-label>
                <mat-select placeholder="Select Status" [(value)]="status" (selectionChange)="changeStatus($event)">
                    <mat-option [value]="'all'">All</mat-option>
                    <mat-option [value]="true">Enabled</mat-option>
                    <mat-option [value]="false">Disabled</mat-option>
                </mat-select>
            </mat-form-field>
            <button class="btn-thrive_primary" 
                accessControl
                moduleType="app_banners" 
                mat-button 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <tn-table
        [title]="'Banners'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [hidePageSize]="true"
        [mobileTemplate]="mobileTemplate"
        (changePage)="changePage($event)"
        (rowClick)="navigateTo($event)"
        (actionClick)="actionClicked($event)"
        [featureAccessControl]="'app_banners'"
    >
    </tn-table>
</div>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Description</td>
            <td>{{ option.row.description }}</td>
        </tr>
        <tr>
            <td>Status</td>
            <td>
                <mat-slide-toggle
                accessControl
                moduleType="app_banners" 
                 color="primary" (click)="$event.stopPropagation()" (change)="actionClicked({row: option.row, checked: $event.checked, action: 'status'})" [checked]="option.row.status"></mat-slide-toggle>
            </td>
        </tr>
    </table>
</ng-template>
