<div class="dialog-header">
    <h3>{{data.popup_title}}</h3>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <form autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="missingDetails" >
       <!-- Restaurant search dropdown -->
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-16">
            <mat-label>Search restaurant</mat-label>
            <input type="text"
                   placeholder="Start typing.."
                   aria-label="text"
                   matInput
                   formControlName="restaurant"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="data.popup_title === 'Update Popularity Score' ? displayPopularity : displayGoogle" (optionSelected)="selected($event.option)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{data.popup_title === 'Update Popularity Score' ? option.restaurant_location ? option.restaurant_name+'-'+option.restaurant_location : option.restaurant_name  : option.locality ? option.name+'-'+option.locality :option.name }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="missingDetails.get('restaurant').hasError('notValid')">{{errorMessage}}</mat-error>
          </mat-form-field>
         <!-- Popularity score input field -->
          <mat-form-field subscriptSizing="dynamic" *ngIf="missingDetails.get('popularity_score')" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Enter popularity score</mat-label>
            <input type="number" placeholder="Enter popularity score" matInput formControlName="popularity_score" >
            <mat-error *ngIf="missingDetails.get('popularity_score').hasError('required')">Required.</mat-error>
            <mat-error *ngIf="missingDetails.get('popularity_score').hasError('max')">Maximum 1000.</mat-error>
        </mat-form-field>
    </form>
</div>
<div class="dialog-footer">
    <button (click)="onClose()" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="onSubmit()" class="btn-thrive_primary ml-20" [disabled]="formSubmitted || missingDetails.invalid" mat-button>Save</button>
</div>
