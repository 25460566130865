<div id="banner-edit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/app-banner'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Banner</h2>
        </div>
    </div>
    <form [formGroup]="bannerForm" class="mb-60" *ngIf="showForm">
        <div class="flex-form">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Enter title</mat-label>
                <input matInput placeholder="Enter title" formControlName="title" />
                <mat-error *ngIf="bannerForm.get('title').invalid">Title is required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mb-20">
                <mat-label>Enter sort order</mat-label>
                <input matInput type="number" placeholder="Enter order" formControlName="sort_order" />
                <mat-error *ngIf="bannerForm.get('sort_order').invalid">Sort order is required</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
            <mat-label>Enter description</mat-label>
            <input matInput placeholder="Enter description" formControlName="description" />
            <mat-error *ngIf="bannerForm.get('description').invalid">Description is required</mat-error>
        </mat-form-field>
       

        <div class="container-img mt-20" *ngIf="edit_id === 'new'">
            <input #fileUpload accept=".jpeg, .png" style="display: none" name="file_upload" type="file" (change)="onImageUpload($event)" />
            <div (click)="fileUpload.click()" class="property-container mt-30" *ngIf="!bannerForm.controls['image_url'].value">
                <img src="assets/image-uploader.svg" alt="upload image" />
                <div class="subtitle mt-10">Select the image to be uploaded<sup>*</sup></div>
                <div class="btn-thrive_primary mt-10">Browse</div>
                <div class="property-row mt-30">
                    <div class="property-column">
                        <span class="property-name">Aspect ratio</span>
                        <span class="property-value subbase">16:9</span>
                    </div>
                    <div class="property-column">
                        <span class="property-name">File type</span>
                        <span class="property-value subbase">PNG, JPG</span>
                    </div>
                    <div class="property-column">
                        <span class="property-name">Max size</span>
                        <span class="property-value subbase">5MB</span>
                    </div>
                </div>
                <mat-error *ngIf="bannerForm.get('image_url').invalid && bannerForm.get('image_url').touched">*Image is Required</mat-error>
            </div>
            <div class="img--16-9 position-relative" *ngIf="bannerForm.controls['image_url'].value">
                <img [src]="uploadedImg" />
                <div class="icon_button">
                    <button (click)="fileUpload.click()" mat-icon-button class="btn-thrive_icon">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button (click)="removeImage()" mat-icon-button class="btn-thrive_icon">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <app-filters [filterValues]="filters" (sendFilters)="fetchFilters($event)"></app-filters>

        <mat-checkbox formControlName="is_clickable" color="primary">Is banner clickable</mat-checkbox><br />
        <mat-slide-toggle formControlName="status" color="primary" *ngIf="edit_id != 'new'">{{ bannerForm.get('status').value ? 'Enable Banner' : 'Disable Banner' }}</mat-slide-toggle>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button 
            accessControl
            moduleType="app_banners" 
            (click)="submitBanner()"
             [disabled]="!bannerForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ edit_id != 'new' ? 'Update' : 'Create' }}</span>
            </button>
        </div>
    </div>
</div>
