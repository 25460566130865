import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { status , verificationType} from '../user-profile';
@Component({
  selector: 'app-change-permission',
  standalone: true,
  imports: [CommonModule,
            MatDialogModule, 
            MatButtonModule, 
            MatIconModule, 
            MatFormFieldModule, 
            MatInputModule, 
            FormsModule, 
            MatSelectModule],
  templateUrl: './change-permission.component.html',
})
export class ChangePermissionComponent implements OnInit{
  correntPermission: string;
  status:Array<{id:string,name:string}> = status;
  vType:Array<{id:string,name:string}> = verificationType;
  dropDownArray:Array<{id:string,name:string}> = [];
  constructor(public dialogRef: MatDialogRef<ChangePermissionComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit(): void {
    const isStatusType = this.data.model_type === 'status';
    this.correntPermission = isStatusType 
    ? this.data.user.profile.status 
    : this.data.user.profile.verification_type;
    this.dropDownArray = isStatusType 
    ? [...this.status] 
    : [...this.vType];
  }  

  submit(){
    this.data.user['user_selection']=this.correntPermission;
    this.dialogRef.close(this.data); 
  }
}
