import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColumnData, TableComponent } from '../shared/table/table.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { forkJoin } from 'rxjs';
import { MerchantUsersService } from './merchant-users.service';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-merchant-users',
    templateUrl: './merchant-users.component.html',
    styleUrls: ['./merchant-users.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        TableComponent,
        MatButtonModule,
        RouterModule,
        MatTooltipModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AccessControlDirective
    ]
})
export class MerchantUsersComponent implements OnInit {
    data: Array<object>;
    pageSize: number = 10;
    resultsLength: number;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    showFilters: boolean = false;
    filterCategory: string;
    search: string;
    page: number;
    perPage: number;

    businesses: object = {};
    chainOwners: object = {};

    constructor(private merchantUsersService: MerchantUsersService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {
        let chainN = this.merchantUsersService.getChainName();
        let businessN = this.merchantUsersService.getBusinessName();

        forkJoin([chainN, businessN]).subscribe(([chain, business]) => {
            this.chainOwners = chain;
            this.businesses = business;
            this.getMerchantUsersTable(this.page, this.perPage);
        });
    }

    /**
     * To get merchant users and push everything into an array
     * @param page To get merchant users and paginate them accordingly
     * @param perPage To get table rows as per selection of perPage
     */
    getMerchantUsersTable(page: number, perPage: number) {
        this.merchantUsersService.getMerchantUsers(page).subscribe((users: any) => {
            let arr = [];
            (users as any).merchant_users.map((user) => {
                if (user.role === 'ChainOwner') {
                    arr.push({ ...user, chainName: this.chainOwners[user.chain_id], name: '', location: '' });
                } else {
                    arr.push({ ...user, chainName: '', name: this.businesses[user.merchant_id]?.name, location: this.businesses[user.merchant_id]?.location });
                }
            });
            this.data = arr;
            this.resultsLength = users.total;
        });
    }

    /**
     * To navigate to update page of row click
     * @param event To get row id of row click
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('merchant-users/' + event.id);
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'email',
                header: 'Email',
                cell: 'email'
            },
            {
                columnDef: 'chainName',
                header: 'Chain',
                cell: 'chainName'
            },
            {
                columnDef: 'name',
                header: 'Merchant',
                cell: 'name',
                isSpecialHTML: true
            },
            {
                columnDef: 'mobile',
                header: 'Mobile',
                cell: 'mobile'
            },
            {
                columnDef: 'otp',
                header: 'Last OTP',
                cell: 'otp'
            },
            {
                columnDef: 'role',
                header: 'Role',
                cell: 'role'
            },
            {
                columnDef: 'action',
                header: '',
                cell: 'delete',
                isDeletable: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'email', 'action'];
    }

    /**
     * To enable page to paginate
     * @param event To get page number and page size
     */
    changePage(event: any) {
        if (this.filterCategory && this.search) {
            this.goToSearch(event.pageIndex);
        } else {
            this.getMerchantUsersTable(event.pageIndex, event.pageSize);
        }
    }

    /**
     * To filter and search values
     * @param page To fetch page number
     */
    goToSearch(page: any) {
        this.merchantUsersService.filterMerchantUsers(this.filterCategory, this.search, page).subscribe((users: any) => {
            let arr = [];
            (users as any).data.map((user) => {
                if (user.role === 'ChainOwner') {
                    arr.push({ ...user, chainName: this.chainOwners[user.chain_id], name: '', location: '' });
                } else {
                    arr.push({ ...user, chainName: '', name: this.businesses[user.merchant_id]?.name, location: this.businesses[user.merchant_id]?.location });
                }
            });
            this.data = arr;
            this.resultsLength = users.total;
        });
    }

    /**
     * To clear text in filter search on change of filter option
     */
    clearText() {
        this.search = null;
    }

    /**
     * To delete the row on clicking delete button
     * @param event To fetch the id of row
     */
    deleteMerchantUsersById(event) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (event.action == 'delete' && result.isConfirmed) {
                this.merchantUsersService.deleteMerchantUsers(event.row.id).subscribe(() => this.getMerchantUsersTable(this.page, this.perPage));
            }
        });
    }
}
