export const status:Array<{id:string,name:string}> = [
    {
      id:'default',
      name:'Default'
    },
    {
      id:'wait_list',
      name:'Wait list'
    },
    {
      id:'invite_only',
      name:'Invite only'
    },
    {
      id:'cool_off',
      name:'Cool off'
    },
    {
      id:'blacklist',
      name:'Blacklist'
    }
  ];
export const verificationType:Array<{id:string,name:string}> = [
    {
      id:'unverified',
      name:'Unverified'
    },
    { 
      id:'influencer',
      name:'Influencer'
    },
    {
      id:'merchant',
      name:'Merchant'
    },
    {
      id:'internal_user',
      name:'Internal user'
    },
    {
      id:'verified',
      name:'Verified'
    } 
  ];

  export interface userIf {
    date_of_birth:string|null, 
    email:string|null,
    id:number,
    mobile:string|null,
    name:string|null,
    profile:{
      username: string;
      total_comments: number;
      verification_type: string;
      status: string;
      total_likes: number;
    }
  }