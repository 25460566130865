<div id="Version" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>App Version</h2>
            <button class="btn-thrive_primary" 
                accessControl
                moduleType="app_version" 
                mat-button (click)="showForm = !showForm">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <div class="card-container">
        <div class="thrive-card no-hover mt-0 info-container">
            <img src="assets/ios.svg" width="32" height="32" />
            <div class="info">
                <p class="basetitle normal">Current Version</p>
                <p class="basetitle">v{{ iosVersion?.current_version }}</p>
                <p class="basetitle normal">Minimum Supported Version</p>
                <p class="basetitle">v{{ iosVersion?.minimum_supported_version }}</p>
            </div>
        </div>
        <div class="thrive-card no-hover mt-0 info-container">
            <img src="assets/android.svg" width="32" height="32" />
            <div class="info">
                <p class="basetitle normal">Current Version</p>
                <p class="basetitle">v{{ androidVersion?.current_version }}</p>
                <p class="basetitle normal">Minimum Supported Version</p>
                <p class="basetitle">v{{ androidVersion?.minimum_supported_version }}</p>
            </div>
        </div>
    </div>
    <div class="thrive-card no-hover mt-20" *ngIf="showForm">
        <form [formGroup]="versionForm">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Current Version</mat-label>
                <input matInput placeholder="Enter Current Version" formControlName="current_version" />
                <mat-error *ngIf="versionForm.get('current_version').invalid">Current Version is Required</mat-error>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20 mb-20">
                <mat-label>Minimum Supported Version</mat-label>
                <input matInput placeholder="Enter Minimum Supported Version" formControlName="minimum_supported_version" />
                <mat-error *ngIf="versionForm.get('minimum_supported_version').invalid">Minimum Supported Version is Required</mat-error>
            </mat-form-field>
            <mat-radio-group formControlName="platform" color="primary">
                <mat-radio-button value="ios">iOS</mat-radio-button>
                <mat-radio-button value="android">Android</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="versionForm.get('platform').invalid && versionForm.get('platform').touched">Platform is Required</mat-error>
            <button class="btn-thrive_primary" mat-button (click)="saveVersion()">Save</button>
        </form>
    </div>
</div>
