import {CommonModule} from '@angular/common';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CuisineService} from './cuisine.service';
import {FormControl,FormsModule, ReactiveFormsModule } from '@angular/forms';
import {debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-cuisine',
  standalone: true,
  imports: [CommonModule, MatIconModule, TableComponent, MatButtonModule, RouterModule, MatTooltipModule, MatInputModule,ReactiveFormsModule,
    FormsModule,],
  templateUrl: './cuisine.component.html',
  styleUrls: ['./cuisine.component.scss']
})
export class CuisineComponent {
  data: any;
  pageSize: number = 10;
  resultsLength: number = 0;
  columns: ColumnData[] = [];
  desktopDisplayedColumns: string[] = [];
  mobileDisplayedColumns: string[] = [];
  searchQuery: FormControl = new FormControl('');
  @ViewChild('searchInput') searchInput: ElementRef;
  constructor(private router: Router, private route: ActivatedRoute,private cuisineService:CuisineService) {
    this.getCuisinesList(0,'');
  }

  /**
   * get API call to fetch cuisines that can be shown in a table
   * @param pageNumber Page number
   * @param searchText search text 
   */
  getCuisinesList(pageNumber:number,searchText:string) {
    this.cuisineService.getCuisine(pageNumber,searchText).subscribe((cuisines: any) => {
        this.data = cuisines;
        this.resultsLength = 100
    });
  }

  ngOnInit() {
    this.columns = [
      {
        columnDef: 'id',
        header: 'ID',
        cell: 'id',
      },
      {
        columnDef: 'name',
        header: 'Name',
        cell: 'name'
      }
    ];
    this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
    this.mobileDisplayedColumns = ['id', 'name'];
    this.searchQuery.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((e) => {
      this.getCuisinesList(0,e);
    })
  }

  /**
   * Navigate to a certain page
   * @param event to fetch the id of the clicked row
   */
  navigateTo(event:any) {
    if (!event.action) this.router.navigateByUrl('/cuisine/' + event.id);
  }

  /**
   * on forword and previous page change method called
   * @param event Pagination object
   */
  changePage(event:any){
    this.getCuisinesList(event.pageIndex,this.searchQuery.value);
  }
}
