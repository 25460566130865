<div id="questionEdit" class="thrive-container">
    <div class="edit-toolbar">
        <div class="edit-toolbar_heading">
            <span [routerLink]="'/tags'">
                <mat-icon style="margin-right: -10px">arrow_back_ios</mat-icon>
                Go Back
            </span>
            <h2>Tag</h2>
        </div>
    </div>
    <form [formGroup]="tagForm" class="mt-30">
        <div class="formgrid">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Enter name" formControlName="name"  />
                <mat-error *ngIf="tagForm.get('name').invalid">Required</mat-error>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Tag type</mat-label>
                <mat-select placeholder="Select tag type" formControlName="tag_type" (selectionChange)="tagTypeChange($event.value)">
                    <mat-option [value]="type.key" *ngFor="let type of tagTypes">{{type.key}}</mat-option>
                </mat-select>
                <mat-error *ngIf="tagForm.get('tag_type').invalid">Required</mat-error>
            </mat-form-field>
            
            <mat-form-field *ngIf="tagForm.get('tag_type').value" subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-20">
                <mat-label>Category</mat-label>
                <mat-select placeholder="Select tag category" formControlName="tag_category_id" (selectionChange)="categoryChange($event.value)">
                    <mat-option>
                        <ngx-mat-select-search
                                               placeholderLabel="Search"
                                               noEntriesFoundLabel="Category not found"
                                               ngDefaultControl
                                               [formControl]="search">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="category.id" *ngFor="let category of tagCategories | datafilter : search.value : ['name']">{{category.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="tagForm.get('tag_category_id').invalid">Required</mat-error>
            </mat-form-field>

            <mat-slide-toggle class="w-100 mt-30" color="primary" formControlName="status">Status</mat-slide-toggle>
        </div>
    </form>
    <div class="action-footer">
        <div class="footer-container">
            <button class="btn-thrive_primary" mat-button (click)="submit()" [disabled]="!tagForm.valid">
                <mat-icon>add</mat-icon>
                <span>{{ editId !== 'new' ? 'Update' : 'Save' }}</span>
            </button>
        </div>
    </div>
</div>
