import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ThrivePricingService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getPricingMerchants(page: number = 0) {
        return this.http.get(this.url.adminUrl + '/v2/consumer_app_pricing?page_no=' + (page + 1));
    }

    filterMerchants(filters: any, page: number) {
        let params = new HttpParams();
        params = params.append('filters', filters);
        params = params.append('page_no', page + 1);
        return this.http.get(this.url.adminUrl + '/v2/consumer_app_pricing', {params});
    }

    getCities() {
        return this.http.get(this.url.adminUrl + '/admin/dashboard/cities/');
    }

    getPricingById(id) {
        let params = new HttpParams();
        params = params.append('id', id);
        return this.http.get(this.url.adminUrl + '/v2/consumer_app_pricing/show', {params});
    }

    updatePricingMerchants(body: any) {
        return this.http.put(this.url.adminUrl + '/v2/consumer_app_pricing', body);
    }

    createTemporaryCommission(body:any){
        return this.http.post(this.url.adminUrl+'/v2/consumer_app_pricing_changes',body)
    }

    getTemporaryCommissionById(id) {
        return this.http.get(this.url.adminUrl + '/v2/consumer_app_pricing_changes/'+id);
    } 

    deleteTemporaryCommission(id:number){
        return this.http.put(this.url.adminUrl+'/v2/consumer_app_pricing_changes/'+id+'/revert',{});
    }
}
