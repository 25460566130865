<div id="Source-Management" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Source Management</h2>

            <button class="btn-thrive_primary"
                accessControl
                moduleType="source_management" 
                mat-button 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>

    <tn-table
        [title]="'Sources'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        (rowClick)="navigateTo($event)"
        (actionClick)="deleteSource($event)"
        [featureAccessControl]="'source_management'"
    >
    </tn-table>
</div>
