<mat-drawer-container class="example-container" [hasBackdrop]="is_touch_device ? true : false" autosize>
    <mat-drawer #drawer id="drawer" [mode]="is_touch_device ? 'over' : 'side'" [disableClose]="is_touch_device ? false : true" opened="true" clickOutside (clickOutside)="selectedHoverMenu = ''">
        <img [class.thrive-logo] = "isExpanded" style="padding: 11px 0" [src]="isExpanded ? 'assets/logo.svg' : 'assets/T_logo.svg'" alt="Thrive Logo" routerLink="/merchant" />

        <mat-nav-list class="menu">
            <aside>   
                <div
                    accessControl
                    [moduleType]="item.access_control"
                    navbar="true"
                    *ngFor="let item of menuItems"
                    class="menu-container"
                    [matTooltipDisabled]="isExpanded && showTooltip"
                    [matTooltip]="item.name"
                    matTooltipPosition="left"
                    matTooltipClass="thrive-tooltip"
                >
                    <a
                        *ngIf="item.display && !item.sub_menu"
                        mat-list-item
                        class="sidenav-link"
                        [style.width]="isExpanded ? (is_touch_device ? '280px' : '230px') : '56px'"
                        routerLinkActive="selected-option"
                        (click)="navigateTo(item.name); is_touch_device ? drawer.close() : null"
                        [routerLink]="[item.link]"
                    >
                        <mat-icon [ngClass]="currentLink === item.link ? 'material-icons' : 'material-icons-outlined'" [innerHTML]="item.icon"></mat-icon>
                        <p class="title-regular" *ngIf="isExpanded" mat-line>
                            {{ item.name }}
                            <sup *ngIf="item.beta">Beta</sup>
                        </p>
                    </a>
                    <a
                       *ngIf="item.display && item.sub_menu"
                       [style.width]="isExpanded ? (is_touch_device ? '280px' : '205px') : '56px'"
                       [class.selected-option]="subMenuSelectedLink(item.name)"
                       mat-list-item
                       class="sidenav-link"
                       (click)="navigateTo(item.name)"
                       [matMenuTriggerFor]="getMenuTrigger(item)">
                        <mat-icon class="arrow-right" *ngIf="item.name != 'Logout'">arrow_right</mat-icon>
                        <mat-icon [ngClass]="subMenuSelectedLink(item.name) ? 'material-icons' : 'material-icons-outlined'" [innerHTML]="item.icon"></mat-icon>
                        <p class="title-regular" *ngIf="isExpanded" mat-line>
                            {{ item.name }}
                            <sup *ngIf="item.beta">Beta</sup>
                        </p>
                    </a>
                    <div *ngIf="item.display && item.show_drop_down">
                        <div *ngFor="let sub_menu of item.sub_menu">
                            <a *ngIf="sub_menu.display" [ngClass]="{'selected-option': currentLink === sub_menu.link}" class="sub-link" [href]="sub_menu.link" [routerLink]="[sub_menu.link]" (click)="drawer.close(); closeExpandedSidebar()">
                                {{ sub_menu.name }}
                                <sup *ngIf="sub_menu.beta">Beta</sup>
                            </a>
                        </div>
                    </div>
                    <mat-menu class="hover-menu" #{{item.icon}}="matMenu" xPosition="after">
                        <mat-list>
                            <ng-container *ngFor="let sub_menu of item.sub_menu">
                                <mat-list-item [ngClass]="{'selected-option': currentLink === sub_menu.link}" class="hover-submenu" (click)="selectedHoverMenu = ''" [routerLink]="[sub_menu.link]" *ngIf="sub_menu.display">
                                    {{ sub_menu.name }}
                                    <sup *ngIf="sub_menu.beta">Beta</sup>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </mat-menu>
                </div>
            </aside>
            
            <a
                mat-list-item
                class="sidenav-link"
                [style.width]="isExpanded ? (is_touch_device ? '280px' : '205px') : '56px'"
                (click)="toggleMenu()"
                *ngIf="!is_touch_device"
                [matTooltipDisabled]="is_touch_device ? true : isExpanded && showTooltip"
                [matTooltip]="isExpanded ? 'Collapse' : 'Expand'"
                matTooltipPosition="left"
                matTooltipClass="thrive-tooltip"
            >
                <mat-icon class="material-icons">keyboard_tab</mat-icon>
                <p class="title-regular" *ngIf="isExpanded" mat-line>Collapse</p>
            </a>
        </mat-nav-list>
    </mat-drawer>

    <mat-drawer-content>
        <div class="basic-container">
            <mat-toolbar id="appToolbar" [ngClass]="{expanded: isExpanded}">
                <div class="desktop">
                    <button (click)="drawer.toggle()" class="hamburger" *ngIf="is_touch_device">
                        <mat-icon class="menu-hamburger">menu</mat-icon>
                    </button>
                    <p class="subtitle normal">
                        Hi, <span class="subtitle">{{ loggedInName }} </span>
                    </p>
                    <div class="header-right">
                        <!-- <button class="btn-thrive_icon" [matTooltip]="'Notifications'" matTooltipPosition="below" matTooltipClass="thrive-tooltip">
                            <mat-icon>notifications</mat-icon>
                        </button> -->
                    </div>
                </div>
            </mat-toolbar>

            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
