import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router, RouterLinkWithHref, RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {AdminuserService} from './adminuser.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive';
@Component({
    selector: 'app-admin-user',
    templateUrl: './admin-user.component.html',
    styleUrls: ['./admin-user.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, TableComponent, MatButtonModule, RouterModule, MatTooltipModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule, FormsModule,MatSlideToggleModule,AccessControlDirective]
})
export class AdminUserComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    constructor(private adminuserService: AdminuserService, 
        private router: Router, 
        private route: ActivatedRoute,
        ) {
        this.getUsers();
    }

    /**
     * get API call to fetch the users
     */
    getUsers() {
        this.adminuserService.getAdmins().subscribe((admin: Array<any>) => {
            this.data = admin.map(a=>a.is_deleted?{...a,status:false}:{...a,status:true});
        });
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id'
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name',
                sticky: true
            },
            {
                columnDef: 'mobile',
                header: 'Mobile',
                cell: 'mobile',
                sticky: true
            },
            {
                columnDef: 'email',
                header: 'Email',
                cell: 'email',
                sticky: true
            },
            {
                columnDef: 'team',
                header: 'Team',
                cell: 'team',
                // pipe: 'titlecase'
            },
            {
                columnDef: 'created_at',
                header: 'Created At',
                cell: 'created_at',
            },
            {
                columnDef: 'updated_at',
                header: 'Updated At',
                cell: 'updated_at',
            },
            {
                columnDef: 'role_title',
                header: 'Role',
                cell: 'role_title',
            },
            {
                columnDef: 'status',
                header: 'Status',
                cell: 'status',
                isToggle: true
            },
            {
                columnDef: 'profile_image_url',
                header: 'Profile Image',
                cell: 'profile_image_url',
                isImage: true
            },
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'name'];
    }

    /**
     * Navigate to a certain page
     * @param event to fetch the id of the clicked row
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('admin-user/' + event.id);
    }

    actionClicked(event) {
        event.row.is_deleted = !event.checked
        this.adminuserService.updateUser(event.row.id, event.row).subscribe(
            () => {
                Swal.fire('Updated!', 'Status update successfully!', 'success');
                // this.router.navigateByUrl('/admin-user');
            },
            (error) => {
                if (error.status == 500) {
                    Swal.fire('Error!', 'Email already present! Please try with another email!', 'error');
                }
            }
        );
    }
}
