import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class BulkOfferUploadService {
  private adminUrl:string = environment.adminUrl;

  constructor(public http: HttpClient) {}

  bulkUpload(formData) {
    return this.http.post(this.adminUrl + '/upload_offer_data', formData);
  }

}
