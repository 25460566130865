import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {UserProfileService} from './user-profile.service';
import Swal from 'sweetalert2';
import {FormsModule} from '@angular/forms';
import {AccessControlDirective } from '../shared/rbac/rbac.directive';
import {MatMenuModule} from '@angular/material/menu';
import {ChangePermissionComponent} from './change-permission/change-permission.component';
import {MatDialog, MatDialogModule } from '@angular/material/dialog';
export interface userIf {
  date_of_birth:string|null, 
  email:string|null,
  id:number,
  mobile:string|null,
  name:string|null,
  profile:{
    username: string;
    total_comments: number;
    verification_type: string;
    status: string;
    total_likes: number;
  }
}
@Pipe({
  name: 'formatTextPipe',
  standalone:true
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    // Remove underscores and convert to sentence case
    if (!value) return value;
    const formattedValue = value.replace(/_/g, ' '); // Remove underscores
    return formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1).toLowerCase();
  }
}
@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [CommonModule, 
    FormsModule,
    MatIconModule, 
    TableComponent, 
    MatButtonModule, 
    MatSelectModule, 
    MatFormFieldModule, 
    MatInputModule,
    MatMenuModule,
    FormatTextPipe,
    MatDialogModule,
    AccessControlDirective],
  providers: [UserProfileService],
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent  implements OnInit {
  data: userIf;
  pageNo:number = 1;
  pageSize: number = 10;
  resultsLength: number = 0;
  columns: ColumnData[] = [];
  desktopDisplayedColumns: string[] = [];
  mobileDisplayedColumns: string[] = [];
  isFilterApply:boolean = false;
  filter:string;
  filter_category: string;

  constructor(private userProfileService: UserProfileService,public dialog: MatDialog) {}

  ngOnInit() {
    this.getUserProfile({page_no:this.pageNo});
    this.columns = [
        {
          columnDef: 'username',
          header: 'Username',
          cell: 'username',
          sticky: true,
          isSpecialHTML: true
        },
        {
          columnDef: 'mobile',
          header: 'Mobile No',
          cell: 'mobile'
        },
        {
          columnDef: 'name',
          header: 'Name',
          cell: 'name'
        },
        {
          columnDef: 'email',
          header: 'Email',
          cell: 'email'
        },
        {
          columnDef: 'status',
          header: 'Status',
          cell: 'status',
          isSpecialHTML: true
        },
        {
          columnDef: 'verification_type',
          header: 'Type',
          cell: 'verification_type',
          isSpecialHTML: true
        },
        {
          columnDef: 'total_comments',
          header: 'Comments',
          cell: 'total_comments',
          isSpecialHTML: true
        },
        {
          columnDef: 'total_likes',
          header: 'Likes',
          cell: 'total_likes',
          isSpecialHTML: true
        },
        {
          columnDef: 'change_status',
          header: '',
          cell: 'change_status',
          isSpecialHTML: true
        }
    ];
    this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
    this.mobileDisplayedColumns = ['username', 'status'];
  }

  getUserProfile(params:any){
    this.userProfileService.getUsers(params).subscribe((response:any)=>{
      this.data = response.users;
      this.resultsLength = response.total_count;
    });
  }

  changePage(event:any) {
    this.pageNo = event.pageIndex;
    let params = {page_no:this.pageNo,per_page:10};
    if(this.isFilterApply)
    params = {...params,...{[this.filter_category]:this.filter}}; 
    this.getUserProfile(params);
  }
  
  submitFilter(){
    this.pageNo = 1;
    if(this.filter_category && this.filter){
      this.getUserProfile({page_no:this.pageNo,per_page:10,[this.filter_category]:this.filter});
      this.isFilterApply = true;
    }
  }

  clearFilter(){
    this.filter_category = null;
    this.filter = null;
    this.isFilterApply = false;
    this.getUserProfile({page_no:1,per_page:10});
  }

  updateStatus(user:userIf){
    this.openUpdateDialog({model_type:'status',user:user})
  }

  updateType(user:userIf){
    this.openUpdateDialog({model_type:'verification_type',user:user});
  }

  openUpdateDialog(modalData:{model_type:string,user:userIf}){
    const dialogRef = this.dialog.open(ChangePermissionComponent, {
      width: window.innerWidth < 768 ? '80vw' : '40vw',
      data: modalData,
      panelClass: 'thrive-dialog'
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result){
        let params = {
          status: result?.model_type === "status" ? result?.user?.user_selection : result?.user?.profile?.status,
          verification_type: result?.model_type === "verification_type" ?  result?.user?.user_selection : result?.user?.profile?.verification_type
        }
        this.userProfileService.updatePermission(result?.user?.id,params).subscribe((response:any)=>{
          Swal.fire({title: 'Success', text: `${ result?.model_type ? 'Status updated successfully!' : 'Verification type updated successfully!'}`, icon: 'success'});
          let params = {page_no:this.pageNo,per_page:10};
          if(this.isFilterApply)
          params = {...params,...{[this.filter_category]:this.filter}}; 
          this.getUserProfile(params);
        })   
      }
    });
  }
}