<div id="thriveTags" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections">
            <h2>Thrive tags</h2>
            <button class="btn-thrive_primary" 
                accessControl
                moduleType="thrive_tags"
                mat-button 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <tn-table
        [title]="'Thrive tags'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [mobileTemplate]="mobileTemplate"
        [hidePageSize]="true"
        (changePage)="changePage($event)"
        (rowClick)="navigateTo($event)"
        (actionClick)="actionClicked($event)"
        [featureAccessControl]="'thrive_tags'"
    >
    </tn-table>
</div>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>color</td>
            <td>{{ option.row.colour_code}}</td>
        </tr>
        <tr>
            <td>Thrive tag</td>
            <td>
                <mat-slide-toggle 
                    accessControl
                    moduleType="thrive_tags" 
                    color="primary" 
                    (click)="$event.stopPropagation()" 
                    (change)="actionClicked({row: option.row, checked: $event.checked, action: 'status'})" 
                    [checked]="option.row.thrive_tag">
                </mat-slide-toggle>
            </td>
        </tr>
    </table>
</ng-template>
