<form [formGroup]="openHoursForm">
    <p>Set hours per day</p>
    <div formArrayName="open_hours">
        <div *ngFor="let day of openHoursFormArr.controls; let i = index" [formGroupName]="i">
            <div class="input-checkbox">
                <mat-checkbox (change)="toggleOpenDays($event, day)" color="primary" formControlName="is_open">
                    <span class="radio-label">{{ day?.value?.day }}</span>
                </mat-checkbox>
            </div>
            <div id="open-hours" formArrayName="open_slot">
                <div class="flex-form" *ngFor="let slot of day.get('open_slot')['controls']; let j = index" [formGroupName]="j">
                    <!-- open time -->
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Opens at</mat-label>
                        <mat-select formControlName="start">
                            <mat-option value="" default>hh:mm aa</mat-option>
                            <mat-option *ngFor="let time of start_time_slots" [disabled]="time.id <= day.get('open_slot')['controls'][j - 1]?.get('end')?.value" [value]="time.id">{{ time.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- close time -->
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-label>Closes at</mat-label>
                        <mat-select formControlName="end">
                            <mat-option value="" default>hh:mm aa</mat-option>
                            <mat-option *ngFor="let time of end_time_slots" [disabled]="!slot.get('start').value || slot.get('start').value >= time.id" [value]="time.id">{{ time.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button matTooltip="Add Time" matTooltipClass="thrive-tooltip" type="button" class="btn-thrive_icon" (click)="addMoreSlotsInADay(day.get('open_slot'), {start: '', end: ''})">
                        <mat-icon>add</mat-icon>
                    </button>

                    <button mat-icon-button class="btn-thrive_icon delete-btn" *ngIf="day.get('open_slot')['controls']?.length > 1" (click)="removeTimeSlotFromADay(day.get('open_slot'), j)">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <mat-error *ngIf="slot?.errors?.startGreater">Store closing time cannot be less than store opening time</mat-error>
                </div>
            </div>
        </div>
    </div>
</form>
