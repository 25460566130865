import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MerchantUsersService {
    private url = environment;

    constructor(public http: HttpClient) {}

    /**
     * To get merchant users api
     * @param page To fetch page number
     * @returns Api url fetch to merchant users
     */
    getMerchantUsers(page: number = 0) {
        return this.http.get(this.url.adminUrl + '/merchant_users?page_no=' + (page + 1));
    }

    /**
     * To get chains api
     * @returns Api url to fetch chains
     */
    getChainName() {
        return this.http.get(this.url.adminUrl + '/chain_name');
    }

    /**
     * To get location api
     * @returns Api url to fetch location
     */
    getBusinessName() {
        return this.http.get(this.url.adminUrl + '/business_name_location');
    }

    /**
     * To get filtered merchant users on basis of search type and content
     * @param filterCategory To get data on basis of filter category
     * @param search To search the data or search content
     * @param page To get page number for pagination
     * @returns Api url for filtered merchant users
     */
    filterMerchantUsers(filterCategory: string, search: string, page: number = 1) {
        return this.http.get(this.url.adminUrl + '/merchant_users/filter?search_type=' + filterCategory + '&search_content=' + search + '&page_no=' + (page + 1));
    }

    /**
     * To delete merchant uer from api
     * @param id To get id of merchant user to be deleted from api
     * @returns Api url for deleting merchant users
     */
    deleteMerchantUsers(id: string) {
        return this.http.delete(this.url.adminUrl + '/merchant_users/' + id);
    }

    /**
     * To get api url create a new merchant user
     * @param body To store the form values that needs to be posted
     * @returns Api url for creating merchant user
     */
    createMerchantUser(body: string) {
        return this.http.post(this.url.adminUrl + '/merchant_users/', body);
    }

    /**
     * To get api url update merchant user
     * @param id To get id of merchant user to be updated
     * @param body To store the form values that needs to be posted
     * @returns  Api url for updating merchant user
     */
    updateMerchantUser(id: string, body: any) {
        return this.http.put(this.url.adminUrl + '/merchant_users/' + id, body);
    }
    /**
     * To get merhcnat users by id
     * @param id To get id of merchant user
     * @returns Api url for getting merchant users
     */
    getMerchantUserById(id: string) {
        return this.http.get(this.url.adminUrl + '/merchant_users/' + id);
    }
    /**
     * To get merchants
     * @returns Api url to get chain or merchants
     */
    getMerchantList(searchText?:string) {
        let params = {};
        if(searchText){
            params = {search_query:searchText};
        }
        return this.http.get(this.url.adminUrl + '/get_merchants',{params});
    }
     /**
     * To get chain 
     * @returns Api url to get chain or merchants
     */
      getChainList() {
        return this.http.get(this.url.adminUrl + '/get_chains');
    }
    /**
     * To check if email and mobile are already present or not
     * @param value To fetch value of phone number
     * @returns Api url for checking number and email
     */
    checkValidPhoneEmail(value) {
        return this.http.get(this.url.adminUrl + '/check-mobile?check=' + value);
    }
}
