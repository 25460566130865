<div id="merchant" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Custom Menu</h2>
            <button class="btn-thrive_primary" 
                mat-button 
                routerLink="new"
                accessControl
                moduleType="moderation_tool" >
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
        <div class="filters mt-20">
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
                <mat-label>Choose filter option</mat-label>
                <mat-select [(value)]="filterCategory" (selectionChange)="clearText()">
                    <mat-option value="name">Name</mat-option>
                    <mat-option value="restaurant">Restaurant</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100" *ngIf="filterCategory == 'name'">
                <mat-label>Name</mat-label>
                <input matInput type="search" [(ngModel)]="search" (input)="search.length === 0 ? applyFilters(0) : '' " [placeholder]="'Search item name'" />
            </mat-form-field>

            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" *ngIf="filterCategory == 'restaurant'">
                <mat-label>Search and select restaurant</mat-label>
                <input type="search" placeholder="Search restaurant" matInput [formControl]="formControl" [matAutocomplete]="auto" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getOptioResto">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let option of filterOptions" [value]="option">
                        {{ option.name }}
                      </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>

            <button class="btn-thrive_primary" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="applyFilters(0)" *ngIf="filterCategory === 'name'" [disabled]="!search">
                <span>Apply</span>
            </button>
        </div>
    </div>

    <tn-table
        [title]="'Merchants'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [specialHTML]="scheduleColumn"
        [specialButton]="approveButton"
        [mobileTemplate]="mobileTemplate"
        [pageIndex]="page"
        (changePage)="changePage($event)"
        (rowClick)="navigateTo($event)"
        (actionClick)="deleteItem($event)"
    >
    </tn-table>
</div>

<ng-template #scheduleColumn let-option>
    <span>{{ option.row.restaurant_details.name }}</span>
</ng-template>

<ng-template #approveButton let-option>
    <button class="btn-thrive_icon p-0" mat-icon-button (click)="approve(option.row); $event.stopPropagation()" [matTooltip]="'Change Status'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
        <mat-icon class="mr-0">change_circle</mat-icon>
    </button>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Restaurant</td>
            <td>{{ option.row.location }}</td>
        </tr>
        <tr>
            <td>Created At</td>
            <td>{{ option.row.created_date }}</td>
        </tr>
        <tr>
            <td>Last updated At</td>
            <td>{{ option.row.updated_date }}</td>
        </tr>
        <tr>
            <td>Status</td>
            <td>{{ option.row.item_status }}</td>
        </tr>
        <tr>
            <td> 
                <button class="btn-thrive_icon p-0" mat-icon-button (click)="approve(option.row); $event.stopPropagation()" [matTooltip]="'Change Status'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" style="z-index: 999">
                    <mat-icon class="mr-0">change_circle</mat-icon>
                </button>
            </td>
            <td>   
                <button mat-icon-button color="warn" [matTooltip]="'Delete'" matTooltipPosition="below" matTooltipClass="thrive-tooltip" (click)="deleteItem({action:'delete',row:option.row}, 'delete')">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</ng-template>

