import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CuisineService {
  private url = environment;

  constructor(public http: HttpClient) {}
  /**
   * To Get Cuisine from API
   * @param page Page number 
   * @param filter Search value
   * @returns 
   */
  getCuisine(page:number,filter:string) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('page_no', page + 1);
      httpParams = httpParams.append('search_content', filter);
      return this.http.get(this.url.adminUrl + '/cuisines', {params: httpParams});
  }
  /**
   * To create new cuisine
   * @param body To add new data
   * @returns To get API url to POST Cuisine
   */
  create(body) {
      return this.http.post(this.url.adminUrl + '/cuisines', body);
  }
  /**
   * To fetch Cuisine by Id
   * @param id To get id by which the cuisine needs to be searched
   * @returns To get API url to GET cuisines by id
   */
  getCuisineById(id: string) {
      return this.http.get(this.url.adminUrl + '/cuisines/' + id);
  }
  /**
   * To Update already present cuisine
   * @param id To get Id of cuisine to be updated
   * @param body To edit the Data of a cuisine
   * @returns To get API url to PUT cuisines by id
   */
  updateCuisine(id: string, body: any) {
      return this.http.put(this.url.adminUrl + '/cuisines/' + id, body);
  }
  
}
