import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getFilters() {
        return this.http.get(this.url.consumerUrl + '/v1/listings/sorts_and_filters');
    }

    createFilterAPI(FormData:{filter_name:string,filter_type:string,filter_value:string}){
        return this.http.post(this.url.consumerUrl+'/v1/thrive_custom_filters',FormData)
    }
    
}
