import {CommonModule } from '@angular/common';
import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {ImageUploadComponent} from '../../shared/image-upload/image-upload.component';
import {DataFilterPipe } from '../../shared/pipes/data-filter.pipe';
import {MerchantService} from '../../merchant/merchant.service';
import {RestaurantsService} from '../restaurants.service';
import Swal from 'sweetalert2';
import {COMMA, ENTER, I } from '@angular/cdk/keycodes';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {debounceTime, distinctUntilChanged } from 'rxjs';
import {MatExpansionModule} from '@angular/material/expansion';
import {ColumnData, TableComponent} from '../../shared/table/table.component';
import {environment} from '../../../environments/environment';
import {ImageDetailsPopupComponent} from './image-details-popup/image-details-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {OutletsComponent} from './outlets/outlets.component';
import {MatRadioModule} from '@angular/material/radio';
import {chainInfoIf,restaurantInfoIf, menuIf ,cuisineIf,outletIf,dataURItoBlob,recursivelyFindErrors , errorMessagesToShow} from './reataurant';
import {CdkDropList,CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-restaurants-edit',
  standalone: true,
  templateUrl: './restaurants-edit.component.html',
  styleUrls: ['./restaurants-edit.component.scss','./menu-card/menu-card.component.scss'],
  imports: [CommonModule, 
        RouterModule, 
        MatIconModule, 
        FormsModule, 
        ReactiveFormsModule, 
        MatFormFieldModule, 
        MatInputModule, 
        MatButtonModule,
        MatAutocompleteModule,
        MatSelectModule,
        DataFilterPipe,
        ImageUploadComponent,
        NgxMatSelectSearchModule,
        MatExpansionModule,
        TableComponent,
        MatChipsModule,
        OutletsComponent,
        MatRadioModule,
        CdkDropList, 
        CdkDrag],
  providers: [RestaurantsService,
    MerchantService]
})

export class RestaurantsEditComponent {
    restaurantId: string;
    restaurantForm: FormGroup = this.fb.group({
      role: new FormControl('member',[Validators.required]),
      part_of_chain: new FormControl('no'),
      merchant_id: new FormControl('',[Validators.pattern(/^\d+$/)]),
      google_place_id:new FormControl(''),
      name: new FormControl('',Validators.required),
      lat: new FormControl('',[Validators.required, Validators.pattern( /^(-?\d+(\.\d+)?),?\s*$/)]),
      lon: new FormControl('',[Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*$/)]),
      locality:new FormControl('',Validators.required),
      full_address:new FormControl('',Validators.required),
      image_url:new FormControl(''),
      image_blob:new FormControl(''),
      cuisines:new FormControl(''),
      cost_for_two:new FormControl('',[Validators.pattern(/^\d+$/)]),
      ad_hoc_tag:new FormControl(''),
      restaurant_known_for_tag:new FormControl(''),
      chain_id: new FormControl('',[Validators.pattern(/^\d+$/)]),
      outlets: new FormControl('')
    });
    cuisines: Array<cuisineIf> = [];
    searchCuisine = new FormControl();
    outlets:Array<outletIf> = [];
    isLoading: boolean = false;
    submitted:boolean = false;
    imageFileName:string=null;
    // adhoc tags
    searchForAdhocTag:FormControl=new FormControl('');
    separatorKeysCodes: number[] = [ENTER, COMMA];
    maxTagCharLimit: number = 30;
    adhocTagKeyWord=new FormControl('');
    adhocTags:Array<string>=[];
    selectedAdhocTags:Array<string>=[];
    @ViewChild('adhoc') adhoc: ElementRef<HTMLInputElement>;

    // cuisine
    openCuisine:boolean = false;
    columnsCuisine: ColumnData[] = [];
    desktopDisplayedColumnsCuisine: string[] = [];
    mobileDisplayedColumnsCuisine: string[] = [];
    dataCuisine:Array<{cuisine:string,mentions:number}>=[];

    // restaurant content table
    columnsContent: ColumnData[] = [];
    desktopDisplayedColumnsContent: string[] = [];
    mobileDisplayedColumnsContent: string[] = [];
    dataContent:Array<{body:string,post_type:number}>=[];

    // Average cost table
    openAvg:boolean = false;
    columnsAvg:ColumnData[]=[];
    desktopDisplayedColumnsAvg: string[] = [];
    mobileDisplayedColumnsAvg: string[] = [];
    dataAvg:Array<{cost_range:string,mentions:number}>=[]

    //Known for tags
    knownForTags:Array<string>=[];
    selectedKnownForTags:Array<string>=[];
    knowForTagKeyWord=new FormControl('');
    searchForKnownForTag:FormControl=new FormControl('');
    @ViewChild('known') knownFor: ElementRef<HTMLInputElement>;
    userUploadedMedias:Array<{}>=[];
    step:number = 1;
    menuCards:Array<menuIf>=[];
    public search: FormControl = new FormControl();
    chainList:Array<chainInfoIf> = [];
    restaurantInfo:restaurantInfoIf;
    
    constructor(private fb: FormBuilder, 
      private router: Router, 
      private route: ActivatedRoute, 
      private restaurantsService: RestaurantsService,
      private merchantService:MerchantService,
      public dialog: MatDialog
      ) {
      this.restaurantId = this.route.snapshot.params['id'];
    }

    ngOnInit() {
        //populate cuisines list
        this.merchantService.getCuisines().subscribe((cuisines: any) => (this.cuisines = cuisines));
        //autocomplete for ad hoc tags
        this.searchForAdhocTag.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe((keyword:string) => {
            if(keyword && keyword.trim().length>1){
                this.getAdhocTagsList(keyword);
            } else {
                this.adhocTags = [];
            }
        });
        // auto complete known for 
        this.searchForKnownForTag.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe((keyword:string) => {
            if(keyword && keyword.trim().length>1){
                this.getKnownForTagsList(keyword);
            } else {
                this.knownForTags = [];
            }
        });

        this.search.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe((keyword:string) => {
            if(keyword && keyword.trim().length>1){
                this.getChainInfo(keyword);
            }
        });

        if(this.restaurantId){
            this.getRestaurantDetails();
        }

        // cuisine mentions table
        this.columnsCuisine = [
            {
                columnDef: 'cuisine',
                header: 'Cuisine',
                cell: 'cuisine',
            },
            {
                columnDef: 'mentions',
                header: 'Mentions',
                cell: 'mentions',
            }
        ];
        this.desktopDisplayedColumnsCuisine = this.columnsCuisine.map((c) => c.columnDef);
        this.mobileDisplayedColumnsCuisine = ['cuisine','mentions'];
        // average cost for two mentions table
        this.columnsAvg = [
            {
                columnDef: 'cost_range',
                header: 'Cost Range',
                cell: 'cost_range',
            },
            {
                columnDef: 'mentions',
                header: 'Mentions',
                cell: 'mentions',
            }
        ];
        this.desktopDisplayedColumnsAvg = this.columnsAvg.map((c) => c.columnDef);
        this.mobileDisplayedColumnsAvg = ['cost_range','mentions'];

        //content table
        this.columnsContent = [
            {
                columnDef: 'body',
                header: 'Content',
                cell: 'body',
            },
            {
                columnDef: 'post_type',
                header: 'Type',
                cell: 'post_type',
            },
            {
                columnDef: 'link',
                header: 'Link',
                cell: 'link',
                isSpecialHTML:true
            }
        ];
        this.desktopDisplayedColumnsContent = this.columnsContent.map((c) => c.columnDef);
        this.mobileDisplayedColumnsContent = ['body'];

        this.getRestaurantMenuCard();
    }

    getRestaurantMenuCard(){
        this.restaurantsService.getMenuCards(this.restaurantId).subscribe((response:any)=>{
            this.menuCards = response.menu_category;
        })
    }

    setStep(stepNumber:number){
        this.step = stepNumber;
    }
    
    addMenuCard(menu?:menuIf){
        if(menu){
            this.router.navigateByUrl('/restaurants/'+this.restaurantId+'/'+this.restaurantInfo.name+'/'+menu.restaurant_menu_category_id);
        } else {
            this.router.navigateByUrl('/restaurants/'+this.restaurantId+'/'+this.restaurantInfo.name);
        } 
    }

    removeMenuCard(menu:menuIf){
        Swal.fire({
            title: 'Are you sure you want to delete '+menu.name+'?',
            text:'By deleting '+menu.name+', you will loose all the menu images that you have uploaded.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                menu.status = 'deleted';
                this.updateMenuCat([menu],()=>{
                    Swal.fire('Success', menu.name+' deleted successfully!', 'success'); 
                    this.getRestaurantMenuCard();
                });
            }
        });
    }

    drop(event: CdkDragDrop<string[]>) {
       moveItemInArray(this.menuCards, event.previousIndex, event.currentIndex);
       this.menuCards = this.menuCards.map((menu) => ({ ...menu, status:'draft'}));
       this.updateMenuCat(this.menuCards,()=>{})
    }

    publish(restaurant_menu_category_id:number){
        this.restaurantsService.publishMenuCategory(restaurant_menu_category_id,this.restaurantId).subscribe((response:any)=>{
            Swal.fire('Success', 'Menu published!', 'success');
            this.getRestaurantMenuCard();
        });
    }

    updateMenuCat(menuCategory:Array<menuIf>,cb){
        let params={"menu_categories":menuCategory};
        this.restaurantsService.updateMenuCategories(this.restaurantId,params).subscribe((response:any)=>{
            cb()
        });
    }

    onRestaurantTypeChange(merchantType:string){
        switch(merchantType){
            case 'member':
                this.restaurantForm.get('lat').setValidators([Validators.required, Validators.pattern( /^(-?\d+(\.\d+)?),?\s*$/)]);
                this.restaurantForm.get('lat').updateValueAndValidity();
                this.restaurantForm.get('lon').setValidators([Validators.required, Validators.pattern(/^(-?\d+(\.\d+)?),?\s*$/)]);
                this.restaurantForm.get('lon').updateValueAndValidity();
                this.restaurantForm.get('locality').setValidators([Validators.required]);
                this.restaurantForm.get('locality').updateValueAndValidity();
                this.restaurantForm.get('full_address').setValidators([Validators.required]);
                this.restaurantForm.get('full_address').updateValueAndValidity();
            break;
            case 'owner':
                this.restaurantForm.get('lat').clearValidators();
                this.restaurantForm.get('lat').updateValueAndValidity();
                this.restaurantForm.get('lon').clearValidators();
                this.restaurantForm.get('lon').updateValueAndValidity();
                this.restaurantForm.get('locality').clearValidators();
                this.restaurantForm.get('locality').updateValueAndValidity();
                this.restaurantForm.get('locality').setValue('All Outlets');
                this.restaurantForm.get('full_address').clearValidators();
                this.restaurantForm.get('full_address').updateValueAndValidity();
            break
       }
    }

    onSelectChain(event){
        this.restaurantForm.get('chain_id').setValue(event.value); 
        if(!this.restaurantId){
            // Find the chain restaurant with the specified id
            const foundChain = this.chainList.find(c => c.chain_restaurant_id === event.value);
            // Check if the chain restaurant was found
            if (foundChain) {
                // Set form values
                const { name, image_url, cuisines, cost_for_two, ad_hoc_tag, known_for_tags } = foundChain;
                this.restaurantForm.get('name').setValue(name);
                this.restaurantForm.get('image_url').setValue(image_url);
                this.restaurantForm.get('cuisines').setValue(cuisines);
                this.restaurantForm.get('cost_for_two').setValue(cost_for_two);

                // Set additional variables
                this.selectedAdhocTags = ad_hoc_tag;
                this.selectedKnownForTags = known_for_tags;
            } 
        }
    }

    getChainInfo(searchText:string){
        let filterObj={
            locality:'all outlets',
            query:searchText
        }
        this.restaurantsService.getRestaurantsList(0,10,filterObj).subscribe((response:any)=>{
            this.chainList = response.restaurants;
        });
    }

    /**
     * onclick link icon from content table funtion will trigger
     * @param row row data use for redirect to social website
     */
    openLinkFn(row:any){
        window.open(environment.socialWebsiteUrl +'/'+row.post_type+'s/'+row.slug, '_blank');
    }

    /**
     * Get restaurant item details
     */
    getRestaurantDetails(){
      this.restaurantsService.getRestaurantDetails(this.restaurantId).subscribe((response:any)=>{
        this.restaurantInfo = response;  
        this.restaurantForm.patchValue(response);
        this.restaurantForm.get('restaurant_known_for_tag').setValue(response.known_for_tags);
        if(response?.restaurant_chain && Object.keys(response?.restaurant_chain).length){
            this.restaurantForm.get('part_of_chain').setValue('yes');
            this.restaurantForm.get('chain_id').setValue(response.restaurant_chain.id);
            response.restaurant_chain['chain_restaurant_id']=response.restaurant_chain.id;
            this.chainList.push(response.restaurant_chain);
        }

        this.selectedAdhocTags=response.ad_hoc_tag;
        // cuisine mentions
        Object.keys(response.group_cusines).forEach((key)=>{ 
            this.dataCuisine.push({cuisine:key,mentions:response.group_cusines[key]})
        })
        //AVG mentions
        Object.keys(response.group_cost_range).forEach((key)=>{ 
            this.dataAvg.push({cost_range:key,mentions:response.group_cost_range[key]})
        })
        //resto content
        this.dataContent = response.featured_data;
        //user uploaded resto images
        this.userUploadedMedias=response?.restaurant_medias?.user_restaurant_media_detail;
        //show known for tags
        this.selectedKnownForTags = response.known_for_tags;
        this.onRestaurantTypeChange(response.role);
        this.outlets = response.outlets;
      }); 
    }

    /**
     * Upload cover image for the restaurant
     * @param image 
     */
    onImageUpload(image: any) {
        if(image && Object.keys(image)?.length){
            this.restaurantForm.get('image_url').setValue(image.base64);
            this.imageFileName=image.fileName;
            const imgBlob : Blob = dataURItoBlob(image.base64);
            this.restaurantForm.get('image_blob').setValue(imgBlob);
        }else{
            this.restaurantForm.get('image_url').setValue('');
            this.imageFileName=null;
            this.restaurantForm.get('image_blob').setValue('');
        }
    }

    // ad hoc tags
    /**
     * search tags
     * @param searchQuery search keyword
     */
    getAdhocTagsList(searchQuery:string){
        this.restaurantsService.getTagsList({'search_query':searchQuery,'tag_type':'restaurant_ad_hoc'}).subscribe((tags:any) => {
            this.adhocTags = tags;
        });
    }

    /**
     * Add a tag
     * @param event Represents an input event on a matChipInput.
     */
    addAdhocTag(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Trim the input text to the character limit
        const trimmedInput = value.substr(0, this.maxTagCharLimit);

        if (value.length > this.maxTagCharLimit)
        Swal.fire('Note!', `Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

        if (value) this.selectedAdhocTags.push(trimmedInput);

        event.chipInput!.clear();
        this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
    }

    /**
     * Select a tag
     * @param event Event object that is emitted when an autocomplete option is selected
     */
    selectAdhocTag(event: MatAutocompleteSelectedEvent): void {
        this.selectedAdhocTags.push(event.option.viewValue);
        this.adhoc.nativeElement.value = '';
        this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
    }

    /**
     * Remove the tag
     * @param tag tag to be removed
     */
    removeAdhocTag(tag: string): void {
        const index:number = this.selectedAdhocTags.indexOf(tag);
        if (index >-1) {
            this.selectedAdhocTags.splice(index, 1);
        }
        this.restaurantForm.controls['ad_hoc_tag'].setValue(this.selectedAdhocTags);
    }

    // Known for tags
    /**
    * search tags
    * @param searchQuery search keyword
    */
    getKnownForTagsList(searchQuery:string){
        this.restaurantsService.getTagsList({'search_query':searchQuery,'tag_type':'restaurant_known_for'}).subscribe((tags:any) => {
            this.knownForTags = tags;
        });
    }

    /**
     * Add a tag
     * @param event Represents an input event on a matChipInput.
     */
    addknownForTag(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        // Trim the input text to the character limit
        const trimmedInput = value.substr(0, this.maxTagCharLimit);

        if (value.length > this.maxTagCharLimit)
        Swal.fire('Note!', `Character limit exceeded. Trimmed text: ${trimmedInput}`, 'warning');

        if (value) this.selectedKnownForTags.push(trimmedInput);

        event.chipInput!.clear();
        this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
    }

    /**
     * Select a tag
     * @param event Event object that is emitted when an autocomplete option is selected
     */
    selectKnownForTag(event: MatAutocompleteSelectedEvent): void {
        this.selectedKnownForTags.push(event.option.viewValue);
        this.knownFor.nativeElement.value = '';
        this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
    }

    /**
     * Remove the tag
     * @param tag tag to be removed
     */
    removeKnownForTag(tag: string): void {
        const index:number = this.selectedKnownForTags.indexOf(tag);
        if (index >-1) {
            this.selectedKnownForTags.splice(index, 1);
        }
        this.restaurantForm.controls['restaurant_known_for_tag'].setValue(this.selectedKnownForTags);
    }

    /**
     * Generate the message consisting of all the form errors before the user submits the final form
     * @param errors list of errors found in the form
     * @returns 
     */
    generateErrorMessages(errors:any) {
        let message:string='';
        for (const parentKey in errors) {
            for(const childKey in errors[parentKey]){
                message+=`<span style="text-transform: capitalize;">${parentKey.replace(/_/g, ' ')}</span> <span style="color:red">${errorMessagesToShow[childKey]}</span><br/>`;
            }
        }
        return message;
    }

    /**
    * highlight all the errors that will come while submitting the form and before hitting the API
    * @param errors 
    */
    highlightAndScrollToFirstError(errors: any) {
        const firstErrorControlName = Object.keys(errors)[0];
        Object.keys(errors).forEach((key) => {
            this.restaurantForm.get(key).markAsTouched();
        });
        if (firstErrorControlName) {
            // Scroll to the error element
            const id = firstErrorControlName.replace(/_./g, match => match.charAt(1).toUpperCase());
            const errorElement = document.getElementById(id);
            if (errorElement) {
              errorElement.scrollIntoView({ behavior: 'smooth',block: 'start' });
            }
        }
    }
    /**
     * pass old selected reaturant and new selected restaurant 
     * @param restaurant it contain all selected restaurants list
     */
    selectOutlet(restaurant){
        const result = this.outlets.filter((o1) => !restaurant.some((o2) => o1.id === o2.id));
        let allRestaurant = [];
        allRestaurant = [...restaurant,...result];
        this.restaurantForm.get('outlets').setValue(allRestaurant);
    }

    /**
     * submit restaurant form value
     */
    submit() {
      if (this.restaurantForm.valid) {
        this.submitted = true;
        let data = JSON.parse(JSON.stringify(this.restaurantForm.value));
        delete data.image_blob;
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
              switch(key){
                case 'image_url':
                    if(data.image_url.includes('https://')){
                        formData.append(key, data[key]);
                    }else{
                        this.imageFileName&&data.image_url?formData.append('image_url',this.restaurantForm.value.image_blob,this.imageFileName):formData.append(key, data[key]);
                    }
                break;
                case 'cuisines':
                case 'ad_hoc_tag':
                case 'restaurant_known_for_tag':
                    if(data[key] && data[key].length){
                        for (let i = 0; i < data[key].length; i++) {
                            formData.append(`${key}[]`, data[key][i]);
                        }
                    } else {
                        formData.append(`${key}[]`,data[key]);
                    }
                break;
                case 'outlets':
                    if(data[key].length)
                        formData.append(key,JSON.stringify(data[key])); 
                    else
                        formData.append(key,JSON.stringify([])); 
                break;
                default:
                    data[key]?formData.append(key, data[key]):formData.append(key,'');
              }
            }
        }
        this.restaurantId?this.updateRestaurant(formData):this.createRestaurant(formData);
      }else{
        const allErrors = recursivelyFindErrors(this.restaurantForm);
        let msg:string=`<div style="max-height: 200px; overflow-y: auto;text-align:left;">Following fields have error. Please scroll to view them all.<br/>${this.generateErrorMessages(allErrors)}</div>`;
        Swal.fire({
            title: 'Error',
            html: msg, // Insert your HTML content here
            icon: 'error'}).then((e)=>{
            this.highlightAndScrollToFirstError(allErrors);
        }); 
      }
    }

    /**
     * Create a new restaurant 
     */
    createRestaurant(formData:any){
        this.restaurantsService.createRestaurant(formData).subscribe(
            (response:any) => {
                this.submitted = false;
                Swal.fire('Created!', 'Restaurant created!', 'success');
                setTimeout(() => {
                    this.router.navigateByUrl('/restaurants');
                }, 500);
            },
            (err) => {
                this.submitted = false;
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            }
        );
    }

    /**
     * Save the updates made to existing restaurant
     */
    updateRestaurant(formData:any){
        this.restaurantsService.updateRestaurant(this.restaurantId,formData).subscribe(
            (response:any) => {
                this.submitted = false;
                Swal.fire('Updated!', 'Changes saved!', 'success');
                setTimeout(() => {
                    this.router.navigateByUrl('/restaurants');
                }, 500);
            },
            (err) => {
                this.submitted = false;
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
            }
        );
    }

    /**
     * 
     * @param media Photo details object
     */
    openImageDetails(media:any){
        const dialogRef = this.dialog.open(ImageDetailsPopupComponent, {
            width: window.innerWidth < 768 ? '100vw' : '693px',
            data: media,
            panelClass: ['thrive-dialog','sm-full_width_dailog']
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                if(this.restaurantId)
                this.getRestaurantDetails();
            }
        });
    }
}