import {CommonModule} from '@angular/common';
import {ChangeDetectorRef, Component, EventEmitter, Input, AfterViewInit, Output} from '@angular/core';
import {FilterService} from './filters.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {FormBuilder, FormGroup, ReactiveFormsModule,FormControl} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {DataFilterPipe } from '../pipes/data-filter.pipe';
import {CreateModalComponent} from './create-modal/create-modal.component';
import { MatDialog ,MatDialogModule} from '@angular/material/dialog';
import Swal from 'sweetalert2';
@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    standalone: true,
    imports: [CommonModule, MatCheckboxModule, MatSelectModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatDividerModule,MatIconModule,DataFilterPipe,MatDialogModule],
    providers: [FilterService]
})
export class FiltersComponent implements AfterViewInit{
    filters: any;
    filterForm: FormGroup;
    search = new FormControl();
    cuisines:Array<{name:string,id:string}>;
    orderMode:Array<{id:string,name:string}>;
    @Input() filterValues: any;
    @Output() sendFilters = new EventEmitter();

    constructor(private filterService: FilterService, private fb: FormBuilder, private cdr: ChangeDetectorRef, public dialog: MatDialog) {}

    ngAfterViewInit(): void  {
        this.getFilters();
    }
    /**
     * get filters from backend
     */
    getFilters(){
        this.filterService.getFilters().subscribe((filters: {filters: any; sorts: any}) => {
            this.filterForm = this.fb.group({});
            this.cdr.detectChanges();
            filters.filters.forEach((filter) => {
                if(filter.id === 'cuisines' && !filter.is_custom_filter){
                    this.cuisines = filter.options;
                }
                if(filter.id === 'ordering_mode' && !filter.is_custom_filter){
                    this.orderMode = filter.options;
                }
                filter.filters.key = Object.keys(filter.filters)[0];
                this.filterForm.addControl(Object.keys(filter.filters)[0], this.fb.control(this.filterValues ? this.filterValues[Object.keys(filter.filters)[0]] : false));
                if (filter.options && !filter.is_multiselect) this.filterForm.addControl(Object.keys(filter.filters)[0] + '_options', this.fb.control(this.filterValues ? this.filterValues[Object.keys(filter.filters)[0]] : ''));
                if (filter.options && filter.is_multiselect) this.filterForm.addControl(Object.keys(filter.filters)[0] + '_options', this.fb.control(this.filterValues ? this.filterValues[Object.keys(filter.filters)[0]] : []));
            });
            this.filters = filters.filters;
        });
    }

    changeValues() {
        this.sendFilters.emit(this.saveFilters());
    }

    filterTick(index,filter){
        return filter
    }
    /**
     * mark active or not in chips
     * @param control form control where we need to set value true false
     */
    activateAndDeactivate(control:FormControl){
        if(control.value)
            control.setValue(false);
        else 
            control.setValue(true);

        this.sendFilters.emit(this.saveFilters());
    }
    /**
     * Open create filter popup
     */
    createFilter(){
        const dialogRef = this.dialog.open(CreateModalComponent, {
            width:'50vw',
            panelClass: ['thrive-dialog', 'sm-full_width_dailog'],
            data:''
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if(result){
                setTimeout(() => {
                    this.getFilters();
                    Swal.fire('Success', 'Filter created successfully.', 'success');
                }, 200);
            }
        })
    }

    saveFilters() {

        let filters = this.filterForm.value;
        if (filters.cuisines && filters.cuisines_options?.length > 0) filters.cuisines = filters.cuisines_options;
        else delete filters.cuisines;
        if (filters.order_type && filters.order_type_options.length > 0) filters.order_type = filters.order_type_options;
        else filters.order_type = 'delivery';
        delete filters.cuisines_options;
        delete filters.order_type_options;
        for (const key in filters) {
            if (!filters[key]) delete filters[key];
        }
        return filters;
    }
}
