<div id="brandCollaboration" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections only-create">
            <h2>Brand Collaboration</h2>

            <button class="btn-thrive_primary" 
                accessControl
                moduleType="brand_collaboration" 
                mat-button 
                routerLink="new">
                <mat-icon>add</mat-icon>
                <span>New</span>
            </button>
        </div>
    </div>

    <tn-table
        [title]="'Brand Collaboration'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [mobileTemplate]="mobileTemplate"
        [specialHTML]="openEdit"
        (rowClick)="navigateTo($event)"
        [featureAccessControl]="'brand_collaboration'"
    >
    </tn-table>

    <ng-template #openEdit let-option>
        <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="actionClicked(option.row); $event.stopPropagation()">
            <mat-icon class="mr-0">open_in_new</mat-icon>
        </button>
    </ng-template>

    <ng-template #mobileTemplate let-option>
        <table class="thrive-table_mobile">
            <tr>
                <td>Open Page</td>
                <td>
                    <button class="btn-thrive_icon p-0" style="z-index: 10" mat-icon-button (click)="actionClicked(option.row); $event.stopPropagation()">
                        <mat-icon class="mr-0">open_in_new</mat-icon>
                    </button>
                </td>
            </tr>
        </table>
    </ng-template>
</div>
