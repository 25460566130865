import {CommonModule} from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {CategoryService} from '../category.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import Swal from 'sweetalert2';
import {FiltersComponent} from '../../shared/filters/filters.component';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import {ColorPickerModule} from 'ngx-color-picker';
import {ImageUploadComponent} from '../../shared/image-upload/image-upload.component';
import {dataURItoBlob, errorMessagesToShow} from '../../restaurants/restaurants-edit/reataurant';

// Define a recursive function to loop through form controls and groups
function recursivelyFindErrors(formGroup: FormGroup | null, errors: any = {}): any {
    if (!formGroup) {
        return errors;
    }

    Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.get(key);
        if (control instanceof FormGroup) {
            if(control.errors && Object.keys(control.errors)?.length){
                errors[key] = control.errors;
            }else{
                // If it's a nested FormGroup, recursively call the function
                const nestedErrors = recursivelyFindErrors(control);
                if (Object.keys(nestedErrors).length > 0) {
                    errors[key] = nestedErrors;
                }
            }
        } else {
            // If it's a FormControl, check for errors
            if (control && control.invalid) {
                errors[key] = control.errors;
            }
        }
    });
    return errors;
}


@Component({
    selector: 'app-category-edit',
    templateUrl: './category-edit.component.html',
    styleUrls: ['./category-edit.component.scss'],
    standalone: true,
    imports: [CommonModule, 
        FiltersComponent, 
        RouterModule,
        MatIconModule, 
        FormsModule, 
        ReactiveFormsModule, 
        MatFormFieldModule,
        MatInputModule, 
        MatSelectModule, 
        MatButtonModule, 
        MatSlideToggleModule,
        ColorPickerModule,
        ImageUploadComponent,
        AccessControlDirective],
    providers: [CategoryService]
})
export class CategoryEditComponent implements OnInit {
    editId: string;
    categoryForm: FormGroup;
    categoryType:string;
    filters: any;
    filterValues: any;
    showForm:boolean = false;
    colorPageBg: string;
    imageHomeCardIconFileName:string=null;
    imagePageIconFileName:string=null;
    customCategoryFields:Array<string>=['data.page_bg_color','data.page_subtitle','data.page_title','page_icon_image','sort_order','card_icon_image'];
    @ViewChild(FiltersComponent, { static: false }) private customFilters: FiltersComponent;
    constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private categoryService: CategoryService) {}

    ngOnInit() {
        this.editId = this.route.snapshot.params.id;
        if (this.editId !== 'new') {
            this.categoryService.getCategoryById(this.editId).subscribe((category: any) => {
                this.createForm(category);
                this.filterValues = category.filters;
            });
        } else {
            this.createForm();
        }
    }

    createForm(formValue?:any) {
        this.categoryForm = this.fb.group({
            title: [{ value: '', disabled: (this.editId !== 'new'&&formValue.category_type==='fixed_explore_section') }],
            data: this.fb.group({
                page_bg_color:[{ value: '', disabled: (this.editId !== 'new'&&formValue.category_type==='fixed_explore_section') }],
                page_subtitle:[{ value: '', disabled: (this.editId !== 'new'&&formValue.category_type==='fixed_explore_section') }],
                page_title: [{ value: '', disabled: (this.editId !== 'new'&&formValue.category_type==='fixed_explore_section') }]
            }),
            sort_order:[''],
            card_icon_image:[''],
            card_icon_image_blob:[''],
            page_icon_image: [''],
            page_icon_image_blob:[''],
            category_type:[{ value: '', disabled: this.editId !== 'new' }, Validators.required],
            status: [true, Validators.required]
        });
        this.colorPageBg = '#E66158';
        if(formValue){
            this.categoryForm.patchValue(formValue);
            this.onCategoryTypeChange({value:formValue.category_type});
            this.colorPageBg=formValue.data&&formValue.data.page_bg_color?formValue.data.page_bg_color:'#E66158';
        }
        this.showForm = true;
    }

    fetchFilters(event:any) {
        this.filters = event;
    }

    /**
     * add and remove validations as per the chosen category type
     * @param event category type - thrive_category, custom_explore_section, fixed_explore_section
     */
    onCategoryTypeChange(event:any){
        this.categoryType=event.value;
        if(event.value!=="thrive_category"){
            this.customCategoryFields.forEach((field:string)=>{
                this.categoryForm.get(field).setValidators([Validators.required]);
                this.categoryForm.get(field).updateValueAndValidity();
            });
        }else{
            this.customCategoryFields.forEach((field:string)=>{
                this.categoryForm.get(field).clearValidators();
                this.categoryForm.get(field).updateValueAndValidity();
            });
        }
    }


    /**
     * Upload icon image for page
     * @param image 
     * @param key name of the field card image or page iamge icon
     */
    onImageUpload(image: any,key:string) {
        let fileNameKey:string = key==='card_icon_image'?'imageHomeCardIconFileName':'imagePageIconFileName';
        if(image && Object.keys(image)?.length){
            this.categoryForm.get(key).setValue(image.base64);
            this[fileNameKey]=image.fileName;
            const imgBlob : Blob = dataURItoBlob(image.base64);
            this.categoryForm.get(key+'_blob').setValue(imgBlob);
        }else{
            this.categoryForm.get(key).setValue('');
            this[fileNameKey]=null;
            this.categoryForm.get(key+'_blob').setValue('');
        }
    }

    /**
    * highlight all the errors that will come while submitting the form and before hitting the API
    * @param errors 
    */
      highlightAndScrollToFirstError(errors: any) {
        let firstErrorControlName:string = Object.keys(errors)[0];
        if(firstErrorControlName==='data'){
            firstErrorControlName=Object.keys(errors[firstErrorControlName])[0]
        }
        this.categoryForm.markAllAsTouched();
        if (firstErrorControlName) {
            // Scroll to the error element
            const id = firstErrorControlName.replace(/_./g, match => match.charAt(1).toUpperCase());
            const errorElement = document.getElementById(id);
            if (errorElement) {
              errorElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

      /**
     * Generate the message consisting of all the form errors before the user submits the final form
     * @param errors list of errors found in the form
     * @returns 
     */
      generateErrorMessages(errors:any) {
        let message:string='';
        for (const parentKey in errors) {
            for(const childKey in errors[parentKey]){
                if(typeof errors[parentKey][childKey] ==='object'){
                    for(const grandChildKey in errors[parentKey][childKey]){
                        message+=`<span style="text-transform: capitalize;">${childKey.replace(/_/g, ' ')}</span> <span style="color:red">${errorMessagesToShow[grandChildKey]}</span><br/>`;
                    }
                }else{
                    message+=`<span style="text-transform: capitalize;">${parentKey.replace(/_/g, ' ')}</span> <span style="color:red">${errorMessagesToShow[childKey]}</span><br/>`;
                }
            }
        }
        return message;
    }

    submitCategory() {
        if (this.categoryForm.valid) {
            if(!this.filters){
                this.customFilters.changeValues();
            }
            let data = this.categoryForm.value;
            data.filters = this.filters;
            data = JSON.parse(JSON.stringify(data));
            delete data.card_icon_image_blob;
            delete data.page_icon_image_blob;
            if(this.categoryType==='thrive_category'){
                ['data','sort_order','card_icon_image','page_icon_image'].forEach((key:string)=>{
                    delete data[key];
                });
            }
            const formData = new FormData();
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                switch(key){
                    case 'page_icon_image':
                    case 'card_icon_image':
                        let blobName:string=key+'_blob';
                        let imageFileName:string=key==='card_icon_image'?'imageHomeCardIconFileName':'imagePageIconFileName';
                        if(data[key]&&data[key].includes('https://')){
                            formData.append(key, data[key]);
                        }else{
                            this[imageFileName]&&data.card_icon_image?formData.append(key,this.categoryForm.value[blobName],this[imageFileName]):formData.append(key, data[key]);
                        }
                    break;
                    case 'data':
                    case 'filters':
                        let stringifyValue = JSON.stringify(data[key]);
                        formData.append(key, stringifyValue);
                    break;
                    default:
                        data[key]?formData.append(key, data[key]):formData.append(key,'');
                }
                }
            }
            this.editId !== 'new'?this.editCategory(formData):this.createCategory(formData);
        }else{
            const allErrors = recursivelyFindErrors(this.categoryForm);
            let msg:string=`<div style="max-height: 200px; overflow-y: auto;text-align:left;">Following fields have error. Please scroll to view them all.<br/>${this.generateErrorMessages(allErrors)}</div>`;
            Swal.fire({
                title: 'Error',
                html: msg, // Insert your HTML content here
                icon: 'error'}).then((e)=>{
                this.highlightAndScrollToFirstError(allErrors);
            }); 
        }
    }
    

    createCategory(data:any){
        this.categoryService.createCategory(data).subscribe(
            () => {
                Swal.fire('Created!', 'Category has been successfully created!', 'success');
                this.router.navigateByUrl('/app-category');
            },
            (err) => {
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
                // if (err.error.errors.filters) Swal.fire('Error!', 'Filters cannot be blank!', 'error');
            }
        );
    }

    editCategory(data:any){
        this.categoryService.updateCategory(this.editId, data).subscribe(
            () => {
                Swal.fire('Updated!', 'Category has been updated created!', 'success');
                this.router.navigateByUrl('/app-category');
            },
            (err) => {
                let errMsg:string='Something went wrong. Please try agaian later.';
                if(err?.error?.errors){
                    errMsg=typeof err.error.errors === 'string'?err.error.errors:err.error.errors[0];
                }
                Swal.fire('Error', errMsg, 'error');
                // if (err.error.errors.filters) Swal.fire('Error!', 'Filters cannot be blank!', 'error');
            }
        );
    }
}
