import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentionReplace',
  standalone:true
})
export class MentionReplacePipe implements PipeTransform {
  transform(body: string, mentions: { [id: string]: any }): string {
    if (!body || !mentions || !Object.keys(mentions).length) {
      return body  = body.replace(/<!Restaurant-\d+>/g, (match) => {
        return `&lt;${match.slice(1, -1)}&gt;`;
      }); 
    }
    
    Object.keys(mentions).forEach((key) => {
      const mention:any = mentions[key]; // Get the mention by key (id)
      const mentionTag:string = `<!Restaurant-${mention.id}>`;
      const replaceWith:string = `<span class="tag-restaurant" id="${mention.id}" data-original-text="@${mention.name}">@${mention.name}</span>`;
      
      if (body.includes(mentionTag)) {
        body = body.replace(mentionTag, replaceWith);
      }
    });
    
    return body;
  }
}