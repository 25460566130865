<div id="userProfile" class="thrive-container table-grid-container">
    <div class="toolbar">
        <div class="toolbar-sections">
            <h2>User Profile</h2>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" >
                <mat-label>Filter type</mat-label>
                <mat-select placeholder="Select filter option" [(value)]="filter_category" (selectionChange)="filter=''">
                    <mat-option [value]="'filter_by_username'">Username</mat-option>
                    <mat-option [value]="'filter_by_mobile'">Mobile No</mat-option>
                    <mat-option [value]="'filter_by_user_id'">User ID</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100" >
                <mat-label>Filter value</mat-label>
                <input matInput placeholder="Enter filter value" [(ngModel)]="filter" />
            </mat-form-field>
            <button class="btn-thrive_primary" (click)="submitFilter()" mat-button accessControl moduleType="moderation_tool">
                <span>Apply</span>
            </button>
            <button class="btn-thrive_primary" *ngIf="isFilterApply" (click)="clearFilter()" mat-button accessControl moduleType="moderation_tool">
                <span>Clear filter</span>
            </button>
        </div>
    </div>
    <tn-table
        [title]="'User Profile'"
        [data]="data"
        [columns]="columns"
        [desktopDisplayedColumns]="desktopDisplayedColumns"
        [mobileDisplayedColumns]="mobileDisplayedColumns"
        [resultsLength]="resultsLength"
        [pageSize]="pageSize"
        [mobileTemplate]="mobileTemplate"
        [specialHTML]="{
            'username':userName,
            'status':statusId,
            'verification_type':verificationType,
            'total_comments':totalComments,
            'total_likes':totalLikes,
            'change_status':menuTemplate
        }"
        [hidePageSize]="true"
        (changePage)="changePage($event)"
        [featureAccessControl]="'moderation_tool'"
    >
    </tn-table>
</div>

<ng-template #userName let-option>
    <span>{{option.row.profile?.username}}</span>
</ng-template>

<ng-template #statusId let-option>
    <span>{{option.row?.profile?.status | formatTextPipe}}</span>
</ng-template>

<ng-template #verificationType let-option>
    <span>{{option.row?.profile?.verification_type | formatTextPipe}}</span>
</ng-template>

<ng-template #totalComments let-option>
    <span>{{option.row?.profile?.total_comments}}</span>
</ng-template>

<ng-template #totalLikes let-option>
    <span>{{option.row?.profile?.total_likes}}</span>
</ng-template>

<ng-template #menuTemplate let-option>
    <button class="btn-thrive_icon p-0"
            accessControl
            moduleType="moderation_tool"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            style="z-index: 999">
            <mat-icon>more_vert</mat-icon>
    </button> 
    <mat-menu #menu="matMenu">
        <button (click)="updateStatus(option.row)" mat-menu-item>
          <span>Update Status</span>
        </button>
        <button (click)="updateType(option.row)" mat-menu-item>
          <span>Update Type</span>
        </button>
    </mat-menu>
</ng-template>

<ng-template #mobileTemplate let-option>
    <table class="thrive-table_mobile">
        <tr>
            <td>Mobile No</td>
            <td>{{ option?.row?.mobile }}</td>
        </tr>
        <tr>
            <td>Name</td>
            <td>{{ option?.row?.name }}</td>
        </tr>
        <tr>
            <td>Email</td>
            <td>{{ option?.row?.email }}</td>
        </tr>
        <tr>
            <td>Verification type</td>
            <td>{{ option?.row?.profile?.verification_type | formatTextPipe}}</td>
        </tr>
        <tr>
            <td>Total comment</td>
            <td>{{ option?.row?.profile?.total_comments }}</td>
        </tr>
        <tr>
            <td>Total like</td>
            <td>{{ option?.row?.profile?.total_likes }}</td>
        </tr>
        <tr>
            <td>Action</td>
            <td>
                <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit:{row:option?.row}}"></ng-container>
            </td>
        </tr>
    </table>
</ng-template>
