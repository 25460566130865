import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl , Validators ,FormGroup , FormBuilder,ReactiveFormsModule} from '@angular/forms';
import { RouterModule , ActivatedRoute, Router} from '@angular/router';
import { MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatButtonModule} from '@angular/material/button';
import {CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import Swal from 'sweetalert2';
import { RestaurantsService } from '../../restaurants.service';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { CarousalComponent } from './carousal/carousal.component';
import { ModerationService } from '../../../moderation-tool/moderation.service';
import { forkJoin } from 'rxjs';
import {menuIf} from '../reataurant'
@Component({
  selector: 'app-menu-card',
  standalone: true,
  imports: [CommonModule,
            ReactiveFormsModule,
            MatIconModule,
            MatFormFieldModule,
            MatInputModule,
            MatButtonModule,
            RouterModule,
            CdkDropList, 
            CdkDrag,
            MatDialogModule
          ],
  providers: [ModerationService],
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.scss']
})

export class MenuCardComponent implements OnInit{
  submitted:boolean = false;
  restaurantInfo:any={};
  removedImage:Array<menuIf>=[];
  imageProperty:Array<{name:string,value:string}>=[
    {name:'Aspect Ratio',value:'16:9'},
    {name:'File Type',value:'.jpeg .jpg .png'},
    {name:'Dimension',value:'A4'},
    {name:'Max size',value:'5MB'}
  ]
  @ViewChild('itemImageUpload') itemImageUpload: HTMLElement;
  
  constructor(public fb:FormBuilder, 
              private route: ActivatedRoute,
              public restaurantService:RestaurantsService,
              public dialog: MatDialog,
              private moderationService:ModerationService,
              private router: Router){}
  ngOnInit(): void {
    this.restaurantInfo = this.route.snapshot.params;
    if(this.restaurantInfo && this.restaurantInfo.menu_category_id){
      this.getMenuCategory();
    }
  }

  menuCard:FormGroup = this.fb.group({
    name: new FormControl('',Validators.required),
    images: new FormControl([],Validators.required)
  });

  getMenuCategory(){
    this.restaurantService.getMenuCategory(this.restaurantInfo.id,this.restaurantInfo.menu_category_id).subscribe((response:any)=>{
      this.menuCard.patchValue(response);
    });
  }

  onImageUpload(event: any) {
    let files:Array<any>=[];
    for (let index = 0; index < event.target.files.length; index++) {
        if (event.target.files[index].size <= 5242880) {
          files.push(event.target.files[index])
          Swal.fire('File added successfully', 'success'); 
        } else {
          Swal.fire('Error', event.target.files[index].name+' file size greater than 5 MB. Maximum size allowed is 5 MB.', 'error');
        }
    }
    //upload to s3
    this.getS3Url(files); 
  }

  getS3Url(files:any){
    let fileName:Array<string>=[];
    let fileType={};
    let binaryFiles={};
    fileName = files.map(file=>{
      fileType[file.name]=file.type;
      binaryFiles[file.name]= file;
     return file.name
    });
    this.menuCard.addControl('status',new FormControl('draft'));
    this.moderationService.getSignUrl({media_files:fileName}).subscribe((signUrl: any)=>{
      const innerApiCalls = [];
      for (let i = 0; i < signUrl.presigned_urls.length; i++) {
        const headers = { 'Content-Type': fileType[signUrl.presigned_urls[i].original_file_name]};
        innerApiCalls.push(this.moderationService.getS3Url(signUrl.presigned_urls[i].presigned_url, binaryFiles[signUrl.presigned_urls[i].original_file_name], { headers }));
        // get s3 url
        const url = signUrl.presigned_urls[i].presigned_url.substring(0, signUrl.presigned_urls[i].presigned_url.indexOf("?"));
        let images=this.menuCard.get('images').value;
        images.push({link:url,status:'draft'})
        this.menuCard.get('images').setValue(images);
      }forkJoin(innerApiCalls).subscribe(responses => {}, error => {});
    })
  }

  showUploadedImage(event:any,image:menuIf){
    event.target.src = image.link;
  }
  
  removeImagefromArray(index:number,image:menuIf){
    this.menuCard.get('images').value.splice(index,1);
    this.menuCard.addControl('status',new FormControl('draft'));
    this.menuCard.get('images').setValue(this.menuCard.get('images').value);
    image['status']='deleted';
    if(image?.restaurant_menu_category_id)
    this.removedImage.push(image);
  }

  drop(event: CdkDragDrop<string[]>) {
    this.menuCard.addControl('status',new FormControl('draft'));
    moveItemInArray(this.menuCard.get('images').value, event.previousIndex, event.currentIndex);
  }

  /**
   * show in carousal
   */
  openCarousal(){
    const dialogRef = this.dialog.open(CarousalComponent, {
      width: innerWidth > 768 ? '50vw' : '100vw',
      panelClass: 'thrive-dialog',
      data: this.menuCard.get('images').value
    });
  }
  
  /**
   * Get image name
   * @param url image URL
   * @returns Image name
   */
  getName(url:string){
    return url.split('/').pop().split('?')[0];
  }

  submit(){
    if(this.restaurantInfo.menu_category_id){
      this.updateMenuCategory();
    } else {
     this.createMenuCategory(); 
    }
  }

  createMenuCategory(){
    if(this.menuCard.valid){
      this.submitted = true;
      this.restaurantService.createMenuCategory(this.restaurantInfo.id,this.menuCard.value).subscribe((response:any)=>{
        Swal.fire('Success','Menu category created successfully!', 'success');
        this.router.navigateByUrl('/restaurants/'+this.restaurantInfo?.id);  
      });
    }
  }

  updateMenuCategory(){
    if(this.menuCard.valid){
      this.submitted = true;
      this.menuCard.value.images = [ ...this.menuCard.value.images,...this.removedImage];
      this.restaurantService.updateMenuCategory(this.restaurantInfo.id,this.restaurantInfo.menu_category_id,this.menuCard.value).subscribe((response:any)=>{
        Swal.fire('Success','Menu category updated successfully!', 'success');
        this.router.navigateByUrl('/restaurants/'+this.restaurantInfo?.id);
      });
    }
  }

}
