import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BrandCollaborationService {
    private url = environment;

    constructor(public http: HttpClient) {}

    getBrands() {
        return this.http.get(this.url.adminUrl + '/custom_listing_pages');
    }
    createBrandCollab(body) {
        return this.http.post(this.url.adminUrl + '/custom_listing_pages', body);
    }
    getBrandCollabById(id: string) {
        return this.http.get(this.url.adminUrl + '/custom_listing_pages/' + id);
    }
    updateBrandCollab(id: string, body: any) {
        return this.http.put(this.url.adminUrl + '/custom_listing_pages/' + id, body);
    }
    getMerchants() {
        return this.http.get(this.url.adminUrl + '/merchants_list');
    }
}
