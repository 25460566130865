import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild,ChangeDetectorRef, Renderer2, AfterViewInit } from '@angular/core';
import { ModerationService } from '../moderation.service';
import { MatListModule } from '@angular/material/list';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MentionModule } from 'angular-mentions';
import { AccessControlDirective } from '../../shared/rbac/rbac.directive';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MentionReplacePipe } from '../../shared/pipes/mention-replace.pipe';
@Component({
    selector: 'comment-dialog',
    templateUrl: './comment-dialog.html',
    styleUrls:['./comment-dialog.scss'],
    standalone: true,
    imports: [
                CommonModule, 
                MatListModule, 
                MatDialogModule, 
                MatButtonModule, 
                MatIconModule, 
                MatFormFieldModule, 
                MatInputModule, 
                ReactiveFormsModule, 
                MatSelectModule, 
                MentionModule,
                AccessControlDirective
            ],
    providers: [ModerationService,MentionReplacePipe]
})
export class CommentDialog implements AfterViewInit{
    commentForm: FormGroup;
    botUsers: any;
    postDetails:any;
    mentions = new BehaviorSubject<any[]>([]);
    @ViewChild('mentionDiv', { static: false }) mentionDiv: ElementRef;
    mentionConfig:any = {};

    constructor(public dialogRef: MatDialogRef<CommentDialog>, @Inject(MAT_DIALOG_DATA) 
                public data: any, 
                private fb: FormBuilder, 
                private moderationService: ModerationService,
                private cdr: ChangeDetectorRef, 
                private renderer: Renderer2,
                private mentionReplacePipe : MentionReplacePipe) {
        this.moderationService.getBotUsers().subscribe((users) => (this.botUsers = users));
        this.commentForm = this.fb.group<any>({
            body: new FormControl('', [Validators.required]),
            user_id: new FormControl('', Validators.required),
            status: new FormControl('to_review', Validators.required)
        });
    }
    ngAfterViewInit(): void {
        if (this.mentionDiv.nativeElement && this.commentForm.get('body')) {
            this.mentionConfig = this.moderationService.getMentionConfig(this.mentionDiv.nativeElement,this.commentForm.get('body'));
            if(this.data.comment){
                let body = this.mentionReplacePipe.transform(this.data.comment?.body,this.data.comment.mentions_data['Restaurant']);
                this.renderer.setProperty(this.mentionDiv.nativeElement, 'innerHTML', body); 
                this.commentForm.patchValue({
                    body:body,
                    user_id:this.data.comment.user_id,
                    status:this.data.comment.status
                });
                // Trigger change detection manually after updating the DOM
                this.cdr.detectChanges();
            }  

            if(!this.data.post_details) {
                this.getFeedDetails();
            } else {
                this.postDetails = this.data.post_details;
                // Trigger change detection manually after updating the DOM
                this.cdr.detectChanges();
            }
        }
        
    }

    /**
     * Trigger search of restaurants when @ is typed
     * @param event get the keystrokes being typed
     */
    triggerSearch(event:string) { 
        this.moderationService.getRestaurants(event).subscribe((restaurants: any) => {
            this.mentions.next(restaurants.restaurants.map((restaurant: any) => restaurant));
        }); 
    }

    editComment(){
        this.moderationService.editComment(this.mentionDiv.nativeElement,this.commentForm.get('body'));
    }

    getFeedDetails(){
        this.moderationService.getFeed(this.data.comment.post_slug, this.data.comment.user_id).subscribe((post: any) => {
            this.postDetails = post;
        })
    }

    openLinkFn(){
        let postType = this.postDetails.post_type === 'simple_post'?'post':this.postDetails.post_type;
        window.open(environment.socialWebsiteUrl+'/'+postType+'s/'+this.postDetails.slug, '_blank');
    }

    /**
     * To close the dialog with or without any submission
     * @param {string | boolean} action close the dialog or not
     */
     onClick(action: string | boolean): void {
        this.commentForm.get('body').setValue(this.moderationService.convertSpanTagsToPlaceholders(this.mentionDiv.nativeElement))
        this.dialogRef.close(action ? this.commentForm.value : false);
    }
}