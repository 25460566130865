<div class="dialog-header">
    <span class="basetitle">Change {{data.model_type === 'status' ? 'status': 'verification type' }}</span>
    <button mat-icon-button color="primary" class="btn-thrive_icon" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="dialog-content">
    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100">
        <mat-label>Choose {{data.model_type === 'status' ? 'status': 'verification type' }}</mat-label>
        <mat-select [(ngModel)]="correntPermission">
            <mat-option *ngFor="let permission of dropDownArray" [value]="permission?.id">{{permission?.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="dialog-footer">
    <button (click)="dialogRef.close()" class="btn-thrive_secondary" mat-button>Cancel</button>
    <button (click)="submit()" class="btn-thrive_primary ml-20" mat-button [innerHTML]="data ? 'Update' : 'Save'"></button>
</div>
