import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators} from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { debounceTime, filter, map, startWith, switchMap} from 'rxjs/operators';
import { Observable} from 'rxjs';
import { HygieneService } from '../merchant-hygiene.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-merchant-config-popup',
  standalone: true,
  imports: [CommonModule,MatDialogModule,MatAutocompleteModule, MatButtonModule, MatIconModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatSelectModule,FormsModule],
  templateUrl: './merchant-config-popup.component.html',
  providers:[MerchantConfigPopupComponent]
})
export class MerchantConfigPopupComponent implements OnInit{
  missingDetails:UntypedFormGroup
  filteredOptions: Observable<any>;
  formSubmitted:boolean = false;
  fromPlaces:boolean = false;
  selactedRestaurant:{restaurant_id:string,name:string};
  errorMessage:string;
  constructor(public dialogRef: MatDialogRef<MerchantConfigPopupComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: FormBuilder,
              public merchantHygiene:HygieneService) {}

  ngOnInit(): void {

    this.missingDetails =  this.fb.group({
      restaurant:['', Validators.required]
    });
  
    this.filteredOptions = this.missingDetails.get('restaurant').valueChanges.pipe(
      startWith(''),
        debounceTime(500),
        switchMap((value) => {
          if(typeof value !== 'object'){
            if(this.data.popup_title === 'Update Popularity Score'){
              if(this.missingDetails.get('popularity_score'))
              this.missingDetails.removeControl('popularity_score');
              return this.getMerchantList(value);
            } else {
              return this.getRestaurantList(value);
            }
          } else {
            return [];
          }
        }),
    );

     //show previously selected restaurant  
     if(this.data.popup_title === 'Update Popularity Score' && this.data.merchant_info.manual_popularity){
      this.missingDetails.addControl('popularity_score', this.fb.control(this.data.merchant_info.manual_popularity, [Validators.required,Validators.max(1000)]));
      this.merchantHygiene.getMerchants('',this.data.merchant_info.id).subscribe((response:any)=>{
        if(response.restaurants.length)
          this.missingDetails.get('restaurant').setValue(response.restaurants[0]);
        else
          this.missingDetails.get('restaurant').setValue({id:0,restaurant_name:'Other',restaurant_location:''});
      });
    }
  }

  /**
  * For showing sealcted restaurant
  * @param resto restaurant object 
  * @returns return restaurant_name 
  */
  displayPopularity(resto: any): string {
    return resto && resto.restaurant_name ? resto.restaurant_location ? resto.restaurant_name+'-'+resto.restaurant_location:resto.restaurant_name : '';
  } 

  /**
  * For showing selected Merchant
  * @param restaurant restaurant object 
  * @returns return restaurant name 
  */
  displayGoogle(restaurant: any): string {
    return restaurant && restaurant.name ? restaurant.locality ? restaurant.name+'-'+restaurant.locality: restaurant.name : '';
  }

  /**
   * Get merchant list for popularity score
   * @param searchQuery user search string
   * @returns return search merchant list 
   */
  getMerchantList(searchQuery:string){
    return  this.merchantHygiene.getMerchants(searchQuery).pipe(
      filter((data:any) => !!data?.restaurants),
      map((data:any) => {
        let restaurants = data.restaurants
        restaurants.push({id:0,restaurant_name:'Other',restaurant_location:''});
        return restaurants
      })
    )
  }
  /**
   * Get restaurant list
   * @param searchQuery user search string
   * @returns return search restaurant list  
   */
  getRestaurantList(searchQuery:string){
    return  this.merchantHygiene.getRestaurants(searchQuery).pipe(
      filter((data:any) => !!data.restaurants),
      map((data:any) => {
        this.fromPlaces = !data.restaurants.length ? false : data.from_places;
        if(!data.restaurants.length){
            data['restaurants'] = [{id:0,name:'Can’t find restaurant',locality:''}]
        }
        return data.restaurants;
      })
    )
  }
  /**
   * when user select restaurant from drop down sealcted method trigger
   * @param event selected object value 
   */
  selected(event:any){
    if(this.data.popup_title === 'Update Popularity Score' && event.value.id === 0){
      this.missingDetails.addControl('popularity_score', this.fb.control('',[Validators.required,Validators.max(1000)]));
    } else {
      if(this.fromPlaces){
        this.formSubmitted = true;
        this.merchantHygiene.getRestaurantViaPlaceId(event.value.place_id).subscribe((response:any)=>{
          this.selactedRestaurant = response;
          this.formSubmitted = false;
        });
      } else {
        this.selactedRestaurant = event.value;
      }
    }
  }
  /**
  * on click save button onSubmit method trigger
   */
  onSubmit(){
    if(this.data.popup_title === 'Update Popularity Score'){
      // Create popularity
      if(!this.missingDetails.get('popularity_score') && 
        !this.data.merchant_info.manual_popularity && 
        this.missingDetails.get('restaurant').value.id !== 0){
          this.formSubmitted = true;
          this.updatePopularityMerchantId();
      } else if(this.missingDetails.get('popularity_score')?.value &&
                !this.data.merchant_info.manual_popularity &&
                this.missingDetails.get('restaurant').value.id === 0){
                this.formSubmitted = true;
                this.submitPopularityScore();
        //Edit popularity
      } else if(this.missingDetails.get('popularity_score')?.value &&
                this.missingDetails.get('restaurant').value.id !== 0 &&
                this.data.merchant_info.manual_popularity){
                this.formSubmitted = true;
                this.updatePopularityMerchantId(this.missingDetails.get('popularity_score').value); 
      } else if(!this.missingDetails.get('popularity_score') &&
                this.missingDetails.get('restaurant').value.id !== 0 &&
                this.data.merchant_info.manual_popularity){
                  this.formSubmitted = true;
                  this.updatePopularityMerchantId();
      } else if(this.missingDetails.get('popularity_score')?.value &&
                this.data.merchant_info.manual_popularity &&
                this.missingDetails.get('restaurant').value.id === 0){
                  this.formSubmitted = true;
                  this.submitPopularityScore();
      }
    } else {
      this.formSubmitted = true;
      let params:any;
      if(this.selactedRestaurant.restaurant_id){
        params = {id:this.data.merchant_info.id,restaurant_id:this.selactedRestaurant.restaurant_id};
      } else {
        params = {id:this.data.merchant_info.id,request_type:'not_found_on_google'};
      }
      this.merchantHygiene.mapRestaurant(params).subscribe((response:any) => {
        Swal.fire({title: 'Success', text: `Google place ID ${this.data.merchant_info.google_place_id ?'updated':'added'} successfully!`, icon: 'success', timer: 5000, timerProgressBar: true});
        this.dialogRef.close(true);
      },(error)=>{
        this.missingDetails.get('restaurant').setErrors({notValid:true});
        this.errorMessage = error.error.error_message;
        this.formSubmitted = false; 
      });
    }
  }
  /**
   * submit popularuty score to which user enter
  */
  submitPopularityScore(){
    this.merchantHygiene.addPopularityScore(this.missingDetails.get('popularity_score').value,this.data.merchant_info.id).subscribe((response:any)=>{
      Swal.fire({title: 'Success', text: `Popularity score ${this.data.merchant_info.manual_popularity ?'updated':'added'} successfully. It may take upto 5 minutes for changes to reflect`, icon: 'success', timer: 5000, timerProgressBar: true});
      this.dialogRef.close(true);
    },(error)=>{
      this.formSubmitted = false;
      this.showErrorMessage(error);
    });
  }
  /**
   * Map popularity score with restaurant
   */
  updatePopularityMerchantId(popularityScore?:number){
    this.merchantHygiene.mapMerchantPopularity(this.missingDetails.get('restaurant').value.id,this.data.merchant_info.id,popularityScore).subscribe((response:any)=>{
      Swal.fire({title: 'Success', text: `Popularity score ${this.data.merchant_info.manual_popularity ?'updated':'added'} successfully. It may take upto 5 minutes for changes to reflect`, icon: 'success', timer: 5000, timerProgressBar: true});
      this.dialogRef.close(true);
    },(error)=>{
      this.formSubmitted = false;
      this.showErrorMessage(error);
    });
  }
  
  showErrorMessage(error:any){
    let errorMsg:string;
    if(error && error?.error?.errors){
      errorMsg = error?.error?.errors
    } else {
      errorMsg = 'Something went wrong. Please try again!'
    }
    Swal.fire({title: 'Failure', text: errorMsg, icon: 'error', timer: 5000, timerProgressBar: true});
  }
  /**
   * To close the dialog with or without any submission
   */
   onClose(): void {
    this.dialogRef.close();
  }
}
