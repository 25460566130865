import { Component,OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CuisineService} from '../cuisine.service'
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {MatIconModule} from '@angular/material/icon';
@Component({
  selector: 'app-cuisine-edit',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule],
  templateUrl: './cuisine-edit.component.html',
  styleUrls: ['./cuisine-edit.component.scss']
})
export class CuisineEditComponent implements OnInit {
  cuisineForm: FormGroup;
  selectedIndex: any;
  editId: string;
  allcuisines: Object;

  constructor(private cuisineService: CuisineService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
      this.editId = this.route.snapshot.params.id;
      this.createForm();
  }

  /**
   * to create a form to edit data of a cuisine or create a new cuisine
   * @param cuisines to check if cuisine data is already present or new data needs to be added
   */
  createForm(cuisines?) {
      this.cuisineForm = this.fb.group<any>({
          name: new FormControl('', Validators.required)
      });

      //in case of editing a cuisine
      if (this.editId !== 'new') {
          this.cuisineService.getCuisineById(this.editId).subscribe((cuisines: any) => {
              this.cuisineForm.patchValue({...cuisines});
          });
      }
  }

  /**
   * to submit the data of the form and show pop up messages accordingly
   */
  submitForm() {
      if (this.editId !== 'new') {
          this.cuisineService.updateCuisine(this.editId, this.cuisineForm.value).subscribe(() => {
              Swal.fire('Updated!', 'Cuisine has been updated!', 'success');
              this.router.navigateByUrl('/cuisine');
          });
      } else {
          this.cuisineService.create(this.cuisineForm.value).subscribe(() => {
              Swal.fire('Created!', 'Cuisine has been successfully created!', 'success');
              this.router.navigateByUrl('/cuisine');
          });
      }
  }

}

