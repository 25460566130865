import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {Router, RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {ThrivePricingService} from './thrive-pricing.service';

@Component({
    selector: 'app-thrive-pricing',
    templateUrl: './thrive-pricing.component.html',
    styleUrls: ['./thrive-pricing.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        TableComponent,
        MatButtonModule,
        RouterModule,
        MatTooltipModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class ThrivePricingComponent implements OnInit {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];
    showFilters: boolean = false;
    filter_category: string;
    search: any;
    merchants_data: Object;
    page: any;
    perPage: number=10;
    filterOptions: Observable<string[]>;
    formControl = new FormControl('');
    commissionLable={
        'commission_for_new_customer':'Choose operator',
        'commission_for_repeat_customer':'Choose operator',
        'commission_for_direct_platform':'Choose operator'
    }
    commission:any={
        min:'',
        max:''
    };
    constructor(private thrivePricingService: ThrivePricingService, private router: Router) {
        this.getPricing(this.page, this.perPage);
    }

    getPricing(page: number, perPage: number) {
        this.thrivePricingService.getPricingMerchants(page).subscribe((hygieneMerchants: any) => {
            this.data = hygieneMerchants.data; 
            this.resultsLength = hygieneMerchants.total_merchants;
        });
    }

    clearText() {
        this.search = null;
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'id',
                header: 'Id',
                cell: 'id',
                sticky: true
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'location',
                header: 'Location',
                cell: 'location'
            },
            {
                columnDef: 'city',
                header: 'City',
                cell: 'city'
            },
            {
                columnDef: 'platform',
                header: 'Type',
                cell: 'platform'
            },
            {
                columnDef: 'thrive_promise',
                header: 'Thrive Promise',
                cell: 'thrive_promise',
                isBool:true
            },
            {
                columnDef: 'commission_for_new_customer',
                header: 'Commission(New customer)',
                cell: 'commission_for_new_customer'
            },
            {
                columnDef: 'commission_for_repeat_customer',
                header: 'Commission(Repeat customer)',
                cell: 'commission_for_repeat_customer'
            },
            {
                columnDef: 'commission_for_direct_platform',
                header: 'Commission(Direct Platform)',
                cell: 'commission_for_direct_platform'
            },
            {
                columnDef: 'pg_plan',
                header: 'PG plan',
                cell: 'pg_plan'
            },
            {
                columnDef: 'manual_popularity',
                header: 'Popularity',
                cell: 'manual_popularity'
            },
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['id', 'name'];

    }

    changePage(event: any) {
        if (this.filter_category && this.search) {
            this.goToSearch(event.pageIndex);
        } else {
            this.getPricing(event.pageIndex, event.pageSize);
        }
    }

    goToSearch(page: any) {
        let obj = {};
        if (this.filter_category === 'city') {
            obj[this.filter_category] = this.search;
        } else {
            if(this.commissionLable[this.filter_category]){
                obj[this.filter_category] = [];
                obj[this.filter_category].push(this.search);
                if(this.search === 'between'){
                    let between = [];
                    between.push(this.commission.min);
                    between.push(this.commission.max);
                    obj[this.filter_category].push(between);
                } else {
                    obj[this.filter_category].push(this.commission.min);
                }
            } else {
                obj[this.filter_category] = this.search;
            }
        }
        this.thrivePricingService.filterMerchants(JSON.stringify(obj), page).subscribe((data: any) => {
            this.data = data.data;
            this.resultsLength = data.total_merchants;
        });
    }

    /**
     * Navigate to a certain page
     * @param event to fetch the id of the clicked row
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('thrive-pricing/' + event.id);
    }
}
