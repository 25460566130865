import {UntypedFormArray, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export function checkForStartTimeLessThanEndTime(control: UntypedFormGroup): {[key: string]: boolean} | null {
    if (control.value.start && control.value.end) {
        let start: string = control.value.start + ':00';
        let end: string = control.value.end + ':00';
        if (start >= end) {
            return {startGreater: true};
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function checkForOverLap(): ValidatorFn {
    return (formArray: UntypedFormArray): {[key: string]: boolean} | null => {
        let valid: boolean = true;
        let end: string = formArray.controls[0].value['end'];
        for (let i: number = 1; i < formArray.controls.length; i++) {
            if (formArray.controls[i].value['start'] < end) {
                valid = false;
                break;
            } else {
                end = formArray.controls[i].value['end'];
            }
        }
        return valid ? null : {timeOverlapping: true};
    };
}

export const start_time_slots = [
    {id: '00:00', name: '12:00 AM'},
    {id: '00:30', name: '12:30 AM'},
    {id: '01:00', name: '01:00 AM'},
    {id: '01:30', name: '01:30 AM'},
    {id: '02:00', name: '02:00 AM'},
    {id: '02:30', name: '02:30 AM'},
    {id: '03:00', name: '03:00 AM'},
    {id: '03:30', name: '03:30 AM'},
    {id: '04:00', name: '04:00 AM'},
    {id: '04:30', name: '04:30 AM'},
    {id: '05:00', name: '05:00 AM'},
    {id: '05:30', name: '05:30 AM'},
    {id: '06:00', name: '06:00 AM'},
    {id: '06:30', name: '06:30 AM'},
    {id: '07:00', name: '07:00 AM'},
    {id: '07:30', name: '07:30 AM'},
    {id: '08:00', name: '08:00 AM'},
    {id: '08:30', name: '08:30 AM'},
    {id: '09:00', name: '09:00 AM'},
    {id: '09:30', name: '09:30 AM'},
    {id: '10:00', name: '10:00 AM'},
    {id: '10:30', name: '10:30 AM'},
    {id: '11:00', name: '11:00 AM'},
    {id: '11:30', name: '11:30 AM'},
    {id: '12:00', name: '12:00 PM'},
    {id: '12:30', name: '12:30 PM'},
    {id: '13:00', name: '01:00 PM'},
    {id: '13:30', name: '01:30 PM'},
    {id: '14:00', name: '02:00 PM'},
    {id: '14:30', name: '02:30 PM'},
    {id: '15:00', name: '03:00 PM'},
    {id: '15:30', name: '03:30 PM'},
    {id: '16:00', name: '04:00 PM'},
    {id: '16:30', name: '04:30 PM'},
    {id: '17:00', name: '05:00 PM'},
    {id: '17:30', name: '05:30 PM'},
    {id: '18:00', name: '06:00 PM'},
    {id: '18:30', name: '06:30 PM'},
    {id: '19:00', name: '07:00 PM'},
    {id: '19:30', name: '07:30 PM'},
    {id: '20:00', name: '08:00 PM'},
    {id: '20:30', name: '08:30 PM'},
    {id: '21:00', name: '09:00 PM'},
    {id: '21:30', name: '09:30 PM'},
    {id: '22:00', name: '10:00 PM'},
    {id: '22:30', name: '10:30 PM'},
    {id: '23:00', name: '11:00 PM'},
    {id: '23:30', name: '11:30 PM'}
];
export const end_time_slots = [
    {id: '00:30', name: '12:30 AM'},
    {id: '01:00', name: '01:00 AM'},
    {id: '01:30', name: '01:30 AM'},
    {id: '02:00', name: '02:00 AM'},
    {id: '02:30', name: '02:30 AM'},
    {id: '03:00', name: '03:00 AM'},
    {id: '03:30', name: '03:30 AM'},
    {id: '04:00', name: '04:00 AM'},
    {id: '04:30', name: '04:30 AM'},
    {id: '05:00', name: '05:00 AM'},
    {id: '05:30', name: '05:30 AM'},
    {id: '06:00', name: '06:00 AM'},
    {id: '06:30', name: '06:30 AM'},
    {id: '07:00', name: '07:00 AM'},
    {id: '07:30', name: '07:30 AM'},
    {id: '08:00', name: '08:00 AM'},
    {id: '08:30', name: '08:30 AM'},
    {id: '09:00', name: '09:00 AM'},
    {id: '09:30', name: '09:30 AM'},
    {id: '10:00', name: '10:00 AM'},
    {id: '10:30', name: '10:30 AM'},
    {id: '11:00', name: '11:00 AM'},
    {id: '11:30', name: '11:30 AM'},
    {id: '12:00', name: '12:00 PM'},
    {id: '12:30', name: '12:30 PM'},
    {id: '13:00', name: '01:00 PM'},
    {id: '13:30', name: '01:30 PM'},
    {id: '14:00', name: '02:00 PM'},
    {id: '14:30', name: '02:30 PM'},
    {id: '15:00', name: '03:00 PM'},
    {id: '15:30', name: '03:30 PM'},
    {id: '16:00', name: '04:00 PM'},
    {id: '16:30', name: '04:30 PM'},
    {id: '17:00', name: '05:00 PM'},
    {id: '17:30', name: '05:30 PM'},
    {id: '18:00', name: '06:00 PM'},
    {id: '18:30', name: '06:30 PM'},
    {id: '19:00', name: '07:00 PM'},
    {id: '19:30', name: '07:30 PM'},
    {id: '20:00', name: '08:00 PM'},
    {id: '20:30', name: '08:30 PM'},
    {id: '21:00', name: '09:00 PM'},
    {id: '21:30', name: '09:30 PM'},
    {id: '22:00', name: '10:00 PM'},
    {id: '22:30', name: '10:30 PM'},
    {id: '23:00', name: '11:00 PM'},
    {id: '23:30', name: '11:30 PM'},
    {id: '24:00', name: '11:59 PM'}
];
