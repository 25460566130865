<div class="mb-20" id="appBanner" *ngIf="filterForm">
    <form [formGroup]="filterForm" class="thrive-card no-hover">
        <p class="basetitle mb-20">Choose Filters to Apply</p>
        
        <div class="grid mb-20" *ngIf="filters">
            <div>
                <mat-checkbox color="primary" (change)="changeValues()" formControlName="cuisines">Cuisines</mat-checkbox>
                <ng-container *ngIf="filterForm.get('cuisines')?.value">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-16" *ngIf="filterForm.get('cuisines')?.value">
                        <mat-label>Choose an option</mat-label>
                        <mat-select multiple="true" formControlName="cuisines_options" (selectionChange)="changeValues()">
                            <mat-option *ngFor="let option of cuisines" [value]="option.id">{{ option.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="changeValues()" formControlName="order_type">Ordering Mode</mat-checkbox>
                <ng-container *ngIf="filterForm.get('order_type')?.value">
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" floatLabel="never" class="w-100 mt-16" *ngIf="filterForm.get('order_type')?.value">
                        <mat-label>Choose an option</mat-label>
                        <mat-select  formControlName="order_type_options" (selectionChange)="changeValues()">
                            <mat-option *ngFor="let option of orderMode" [value]="option.id">{{ option.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>

        <div  class="chip-container">
            <ng-container *ngFor="let filter of filters;trackBy: filterTick;">
                <div *ngIf="filter.filters.key !== 'cuisines' && filter.filters.key !== 'order_type' && !filter?.is_custom_filter" (click)="activateAndDeactivate(filterForm.get(filter.filters.key))">
                    <span class="chip" [class.active]="filterForm.get(filter.filters.key)?.value" >
                        <mat-icon>{{ filterForm.get(filter.filters.key)?.value ? 'check' : 'add' }}</mat-icon>
                        <span>{{ filter.name }}</span>
                    </span>
                </div>
            </ng-container>
        </div>
       
        <mat-divider class="m-t-b-20"></mat-divider>

        <div class="search-bar">
            <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mb-20" >
                <mat-label >Search filters</mat-label>
                <input matInput type="search" [formControl]="search" placeholder="Search filters" />
            </mat-form-field>
            <button class="btn-thrive_primary" (click)="createFilter()">Add new filter</button>
        </div>

        <div class="grid-three-column">
            <ng-container *ngFor="let filter of filters | datafilter : search.value:['name'] ;trackBy: filterTick;">
                <mat-checkbox  *ngIf="filter.is_custom_filter" color="primary" (change)="changeValues()" [formControlName]="filter.filters.key">{{ filter.name }} </mat-checkbox>
            </ng-container>
            <div *ngIf="!(filters | datafilter : search.value:['name'])?.length">Search result not found!</div>
        </div>

    </form>
</div>
