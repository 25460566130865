export let checkMandatory: Array<string> = [
    'merchant_id',
    'start_date',
    'expiry_date',
    'title',
    'reward_category_id',
    'status',
    'value',
    'promotional_code',
    'is_multi_use',
    'is_public',
    'delivery',
    'take_away',
    'platform',
    'is_auto_applied',
    'is_thrive_level',
    'merchant_split',
    'thrive_split',
    'days'
]
export let checkOptional: Array<string> = [
    'chain_id',
    'minimum_order_value',
    'maximum_discount',
];
