<div class="login flex">
    <div class="login__card">
        <div class="login__card__line"></div>
        <div class="flex login__card--space--around">
            <div class="login__content">
                <img src="../../assets/logo-yellow.svg" class="thrive__logo" />
                <h2 class="my-16">Login for Admin Dashboard</h2>
                <form autocomplete="off" (ngSubmit)="onSubmit()" [formGroup]="loginForm" novalidate class="mt-20">
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100 mb-20">
                        <mat-label>Email Address</mat-label>
                        <input matInput placeholder="Enter email address" formControlName="email" />
                        <mat-error class="msg__error" *ngIf="loginForm.get('email')!.hasError('required') && loginForm.get('email')!.dirty">Required</mat-error>
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic" floatLabel="always" appearance="outline" class="w-100">
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="Enter Password" formControlName="password" type="password" />
                        <mat-error class="msg__error" *ngIf="loginForm.get('password')!.hasError('required') && loginForm.get('password')!.dirty">Password is required</mat-error>
                    </mat-form-field>
                    <mat-error class="msg__error" *ngIf="loginError">{{ loginError }}</mat-error>
                    <button type="submit" class="btn-thrive_primary mt-20" mat-button [disabled]="!loginForm.valid">Login</button>
                </form>
            </div>
        </div>
    </div>
</div>
