import { FormGroup } from "@angular/forms";

export interface cuisineIf{
    id:number,
    name:string,
    merchant_category_id: number,
    image_file_name:string,
    image_content_type: string
}

export interface menuIf {
    created_at:string
    menu_count:number
    menu_image:string
    name:string
    new_order_state:number
    publish_on_app:boolean
    restaurant_menu_category_id:number 
    status:string,
    link?:string
};

export interface restaurantFormDataIf {
    merchant_id?:string,
    id?:string,
    name:string,
    lat?: number,
    lon?: number,
    locality:string,
    full_address?:string,
    cuisines:Array<string>,
    cost_for_two:number,
    known_for_tags:string,
    image_url:string,
    ad_hoc_tag:string,
    chain_id?:number,
    outlets?:Array<[]>,
    google_place_id?:number,
    role:string
};

export interface chainInfoIf{
    restaurant_id:number,
    name:string,
    lat:number,
    lon:number,
    locality:string,
    full_address:string,
    image_url:string,
    merchant_id:number,
    cuisines: Array<string>,
    cost_for_two:number,
    chain_id:number,
    ad_hoc_tag:Array<string>,
    distance:number,
    google_place_id:string,
    created_at:string,
    image_file_name:string,
    image_content_type:string,
    chain_restaurant_id:number,
    known_for_tags:Array<string>,  
}


export interface restaurantInfoIf  {
    ad_hoc_tag:Array<string>,
    contact_numbers: string,
    cost_for_two:number,
    cuisines:Array<string>,
    featured_data:any,
    full_address:string,
    google_maps_direction_link: string,
    google_place_id:string,
    group_cost_range: {},
    group_cusines: {},
    image_url: string,
    known_for_tags:Array<string>,
    lat: number,
    locality:string,
    lon: number,
    merchant_average_spend:number,
    merchant_id:number,
    merchant_info: {},
    name:string,
    restaurant_chain: {},
    restaurant_medias: {},
    restaurant_photos: {},
    role: string,
    user_restaurants: []
}

export interface userRestaurantIf{
    restaurant_id:number ,
    user_id:number,
    attribute_name:string,
    attribute_value:Array<string>,
    approved: boolean,
    restaurant_photo_tag:Array<string>,
    is_deleted:boolean,
    id:number,
    created_at:string,
    updated_at:string
}

export interface outletIf{
    restaurant_id?: string,
    name: string,
    lat?: number,
    lon?: number,
    locality: string,
    full_address?:string,
    image_url?: string,
    merchant_id?:number,
    cuisines?:Array<string>,
    cost_for_two?: number,
    chain_id?: string| number,
    ad_hoc_tag?: Array<[]>,
    distance?:number,
    google_place_id?:string,
    created_at?:string,
    image_file_name?:string,
    image_content_type?: string,
    chain_restaurant_id?: number
    checked?:boolean,
    id?:number,
}

export const errorMessagesToShow:any={
    'required':'is required',
    'minlength':"is violating minimum characters/digits limit",
    'maxlength':"is violating maximum characters/digits limit",
    'pattern':'has invalid format'
};

export interface Ifmenu {
    created_at:string
    menu_count:number
    menu_image:string
    name:string
    new_order_state:number
    publish_on_app:boolean
    restaurant_menu_category_id:number 
    status:string
};

export function dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

// Define a recursive function to loop through form controls and groups
export function recursivelyFindErrors(formGroup: FormGroup | null, errors: any = {}): any {
    if (!formGroup) {
        return errors;
    }

    Object.keys(formGroup.controls).forEach((key) => {
    const control = formGroup.get(key);
        // If it's a FormControl, check for errors
        if (control && control.invalid) {
        errors[key] = control.errors;
        }
    });

    return errors;
}
 