import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ColumnData, TableComponent} from '../shared/table/table.component';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SourceManagementService} from './source-management.service';
import Swal from 'sweetalert2';
import { AccessControlDirective } from '../shared/rbac/rbac.directive'
@Component({
    selector: 'app-source-management',
    templateUrl: './source-management.component.html',
    styleUrls: ['./source-management.component.scss'],
    standalone: true,
    imports: [CommonModule, MatIconModule, TableComponent, MatButtonModule, RouterModule, MatTooltipModule, MatInputModule,AccessControlDirective]
})
export class SourceManagementComponent {
    data: any;
    pageSize: number = 10;
    resultsLength: number = 0;
    columns: ColumnData[] = [];
    desktopDisplayedColumns: string[] = [];
    mobileDisplayedColumns: string[] = [];

    constructor(private sourceManagementService: SourceManagementService, private router: Router, private route: ActivatedRoute) {
        this.getSourcesCol();
    }

    /**
     * get API call to fetch sources that can be shown in a table
     */
    getSourcesCol() {
        this.sourceManagementService.getSources().subscribe((sources: any) => {
            this.data = sources.data;
        });
    }

    ngOnInit() {
        this.columns = [
            {
                columnDef: 'sort_order',
                header: 'Sort order',
                cell: 'sort_order',
                sticky: true
            },
            {
                columnDef: 'name',
                header: 'Name',
                cell: 'name'
            },
            {
                columnDef: 'action',
                header: '',
                cell: 'delete',
                isDeletable: true
            }
        ];
        this.desktopDisplayedColumns = this.columns.map((c) => c.columnDef);
        this.mobileDisplayedColumns = ['sort_order', 'name', 'action'];
    }

    /**
     * Navigate to a certain page
     * @param event to fetch the id of the clicked row
     */
    navigateTo(event) {
        if (!event.action) this.router.navigateByUrl('source-management/' + event.id);
    }

    /**
     * To delete sources from API on pressing delete button
     * @param event To fetch the id of the clicked row
     */
    deleteSource(event) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this Source?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#6236FF',
            cancelButtonColor: '#B95000',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (event.action == 'delete') {
                this.sourceManagementService.deleteSource(event.row.id).subscribe(() => this.getSourcesCol());
            }
        });
    }
}
